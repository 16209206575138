import api from './core/api';
import Attachment from './core/attachment';
import Base from './core/base';
import Comment from './core/comment';
import Conflict from './core/conflict';
import Contact from './core/contact';
import DateHelper from './core/dateHelper';
import Document from './core/document';
import Group from './core/group';
import Item from './core/item';
import Kit from './core/kit';
import Location from './core/location';
import Order from './core/order';
import Helper from './core/helper';
import PermissionHandler from './core/permissionHandler';
import Reservation from './core/reservation';
import Template from './core/template';
import Transaction from './core/transaction';
import User from './core/user';
import UserSync from './core/usersync';
import Webhook from './core/webhook';
import common from './core/common';
import ColorLabel from './core/colorLabel';
import Field from './core/field';
import Spotcheck from './core/spotcheck';

export default {
	api,
	common,
	Attachment,
	Base,
	Comment,
	Conflict,
	Contact,
	DateHelper,
	Document,
	Group,
	Item,
	Kit,
	Location,
	Order,
	PermissionHandler,
	Reservation,
	Template,
	Transaction,
	User,
	UserSync,
	Webhook,
	Helper,
	ColorLabel,
	Field,
	Spotcheck,
};

import 'intl-tel-input-utils';
import { isValidPhoneNumber } from '@cheqroom/web/src/util';

import ko from 'knockout';
import kov from 'knockout-validation';

export default {
	mixin: function (field, options) {
		// Observables
		// ----
		field.oValue = ko.observable(field.value ?? '');
		field.basicField = options.basicField;

		// Methods
		// ----
		field.reset = function () {
			field.oValue('');

			if (field.required) {
				field.oValue.isModified(false);
			}
		};

		// Validation
		// ----
		if (field.required) {
			field.oValue.extend({ required: true });
		}

		field.oValue.extend({
			validation: {
				message: function () {
					switch (field.kind) {
						case 'float':
						case 'decimal':
						case 'currency':
							return 'Please enter a valid number';
						case 'int':
							const value = parseInt(field.value);
							if (Number.isInteger(value)) {
								if (Number.isInteger(field.min) && field.value > field.min) {
									return 'Quantity cannot be more than 9999';
								} else if (Number.isInteger(field.max) && field.value < field.max) {
									return 'Quantity cannot be less than 0';
								}
							}

							return 'Please enter a valid number without any decimals';
						case 'date':
						case 'datetime':
							return 'Please enter a valid date';
						default:
							if (field.editor == 'phone') {
								return 'Please enter a valid phone number';
							}
							if (field.editor == 'email') {
								return 'Please enter a valid email';
							}
							if (field.editor == 'url') {
								return 'Please enter a valid url';
							}
							if (field.editor == 'number') {
								return 'Please enter a valid number';
							}
							return 'Invalid value';
					}
				},
				validator: function (val) {
					field.value = $.trim(val);

					var isValid = field.isValid();
					if (field.editor == 'phone') {
						if (!field.required && field.value == '') return true;

						return isValid && isValidPhoneNumber(val);
					}

					return isValid;
				},
			},
		});

		// Computables
		// ----
		// Make sure the cr.Field properties are updated when our computeds are updated as well
		// This enables the default: isEmpty, isDirty, _toJson to work automatically
		field._updateValue = ko.computed(function () {
			field.value = field.oValue();
		});
	},
};

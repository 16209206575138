import { FC } from 'react';

export const FlagAlt: FC = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.17157 5.17157C3.92172 4.42143 4.93913 4 6 4H21C21.3905 4 21.7453 4.22734 21.9085 4.58214C22.0717 4.93694 22.0134 5.35428 21.7593 5.65079L16.3171 12L21.7593 18.3492C22.0134 18.6457 22.0717 19.0631 21.9085 19.4179C21.7453 19.7727 21.3905 20 21 20H6C4.93913 20 3.92172 19.5786 3.17157 18.8284C2.42143 18.0783 2 17.0609 2 16V8C2 6.93913 2.42143 5.92172 3.17157 5.17157ZM6 6C5.46957 6 4.96086 6.21071 4.58579 6.58579C4.21071 6.96086 4 7.46957 4 8V16C4 16.5304 4.21071 17.0391 4.58579 17.4142C4.96086 17.7893 5.46957 18 6 18H18.8258L14.2407 12.6508C13.9198 12.2763 13.9198 11.7237 14.2407 11.3492L18.8258 6H6Z"
			fill="currentColor"
		/>
	</svg>
);

import validationHelper from './common/validation';

var DEFAULTS = {
	name: null,
	value: null,
	required: false,
	unit: '',
	kind: 'string',
	form: false,
	editor: null,
	description: '',
	select: [],
};

/**
 * @name  Field
 * @class
 * @param spec
 * @constructor
 */
var Field = function (spec) {
	spec = spec || {};

	this.raw = spec;

	this.name = spec.name || DEFAULTS.name;
	this.value = spec.value ?? DEFAULTS.value;
	this.required = spec.required || DEFAULTS.required;
	this.unit = spec.unit || DEFAULTS.unit;
	this.kind = spec.kind || DEFAULTS.kind;
	this.form = spec.form || DEFAULTS.form;
	this.editor = spec.editor || DEFAULTS.editor;
	this.description = spec.description || DEFAULTS.description;
	this.select = spec.select || DEFAULTS.select;
	this.min = spec.min;
	this.max = spec.max;
};

/**
 * isValid
 * @name  Field#isValid
 * @method
 * @returns {boolean}
 */
Field.prototype.isValid = function (allowEmpty) {
	var value = typeof this.value === 'string' ? this.value.trim() : this.value;

	// skip if not required and empty
	if (!this.required && value == '') return true;

	switch (this.kind) {
		case 'float':
		case 'decimal':
		case 'currency':
			return validationHelper.isNumeric(value);
		case 'int':
			if (Number.isInteger(this.min) && value < this.min) {
				return false;
			}
			if (Number.isInteger(this.max) && value > this.max) {
				return false;
			}

			return validationHelper.isNumeric(value, true);
		case 'date':
		case 'datetime':
			return validationHelper.isValidDate(value);
		case 'string':
		case 'select':
			if (this.editor == 'phone') {
				return validationHelper.isValidPhone(value);
			}
			if (this.editor == 'email') {
				return validationHelper.isValidEmail(value);
			}
			if (this.editor == 'url') {
				return validationHelper.isValidURL(value);
			}
			if (this.editor == 'number') {
				return validationHelper.isNumeric(value);
			}

			return value != '';
		default:
			return true;
	}
};

/**
 * isDirty
 * @name  Field#isDirty
 * @method
 * @returns {boolean}
 */
Field.prototype.isDirty = function () {
	return this.raw.value != this.value;
};

/**
 * isEmpty
 * @name  Field#isEmpty
 * @method
 * @returns {boolean}
 */
Field.prototype.isEmpty = function () {
	const value = typeof this.value === 'string' ? this.value.trim() : this.value;
	return !value || value === '';
};

export default Field;

import * as XLSX from 'xlsx';

export interface CSVOptions extends XLSX.AOA2SheetOpts, XLSX.Sheet2CSVOpts {}

/* export an array of arrays to a csv file */
export const aoa_to_csv = (data: unknown[][], filename: string, props: CSVOptions = {}) => {
	// unless explicitly set to false, set the forceQuotes option to true
	if (props.forceQuotes !== false) {
		props.forceQuotes = true;
	}

	const workSheet = XLSX.utils.aoa_to_sheet(data, props);
	const csv = XLSX.utils.sheet_to_csv(workSheet, props);
	const csvFile: Blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

	const downloadLink: HTMLAnchorElement = window.document.createElement('a');
	downloadLink.download = filename;
	downloadLink.href = window.URL.createObjectURL(csvFile);
	downloadLink.style.display = 'none';
	window.document.body.appendChild(downloadLink);
	downloadLink.click();
};

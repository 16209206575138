import app from 'durandal/app.js';
import ko from 'knockout';
import kov from 'knockout-validation';
import cr from '@cheqroom/core';
import ContactModal from 'viewmodels/contact-modal.js';
import LocationModal from 'viewmodels/location-modal.js';

export default {
	mixin: function (doc, options) {
		var orig_takeCustody = doc.takeCustody,
			orig_releaseCustody = doc.releaseCustody,
			orig_transferCustody = doc.transferCustody;

		var global = options.global;
		var helper = new cr.Helper();

		// Core method overrides
		// ----
		doc.takeCustody = function (forceContact, isBusyCallback) {
			var dfdContact;

			if (!global.central.contact() || forceContact) {
				dfdContact = new Promise((resolve) => {
					ContactModal.show({
						global: global,
						title: 'Give custody to',
					}).then(function (contact) {
						resolve(helper.ensureId(contact));
					});
				});
			} else {
				dfdContact = Promise.resolve(helper.ensureId(global.central.contact()));
			}

			return dfdContact
				.then(function (contact) {
					if (contact) {
						if (isBusyCallback) isBusyCallback(true);

						return orig_takeCustody.apply(doc, [contact]).then(function () {
							// Indicate custody has changed
							return true;
						});
					}
				})
				.finally(function () {
					if (isBusyCallback) isBusyCallback(false);
				});
		};

		doc.releaseCustody = function (isBusyCallback) {
			var dfdLocation,
				perm = global.getPermissionHandler();

			//If only 1 location or a location is selected, then immediatelly
			//release at that location
			if (!global.central.hasMoreLocations()) {
				dfdLocation = Promise.resolve(helper.ensureId(global.central.activeLocations()[0]));
			} else if (perm.hasItemPermission('releaseCustodyAt')) {
				dfdLocation = new Promise((resolve) => {
					LocationModal.show({
						global: global,
						title: 'Release custody at',
					}).then(function (loc) {
						resolve(helper.ensureId(loc));
					});
				});
			} else {
				dfdLocation = Promise.resolve('');
			}

			return dfdLocation
				.then(function (loc) {
					if (loc != null) {
						if (isBusyCallback) isBusyCallback(true);

						return orig_releaseCustody.apply(doc, [loc]).then(function () {
							// Indicate custody has been released
							return true;
						});
					}
				})
				.finally(function () {
					if (isBusyCallback) isBusyCallback(false);
				});
		};

		doc.transferCustody = function (forceContact, isBusyCallback) {
			var dfdContact;

			if (!global.central.contact() || forceContact) {
				dfdContact = new Promise((resolve) => {
					ContactModal.show({
						global: global,
						title: 'Give custody to',
						showUserContact: false,
						params: {
							pk__ne: global.helper.ensureId(doc.oCustody()),
						},
					}).then(function (contact) {
						resolve(helper.ensureId(contact));
					});
				});
			} else {
				dfdContact = Promise.resolve(helper.ensureId(global.central.contact()));
			}

			return dfdContact
				.then(function (contact) {
					if (contact) {
						if (isBusyCallback) isBusyCallback(true);

						return orig_transferCustody.apply(doc, [contact]).then(function () {
							// Indicate custody has been transfered
							return true;
						});
					}
				})
				.finally(function () {
					if (isBusyCallback) isBusyCallback(false);
				});
		};
	},
};

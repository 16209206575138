// https://www.joshwcomeau.com/snippets/javascript/debounce/
export default function debounce(callback, timeout = 250) {
	let timeoutId = null;

	return function (...args) {
		window.clearTimeout(timeoutId);

		timeoutId = window.setTimeout(() => {
			callback.apply(this, args);
		}, timeout);
	};
}

const INDEFINITE_ARTICLES = ['a', 'an'] as const;
const VOWELS = ['a', 'e', 'i', 'o', 'u'] as const;

export type IndefiniteArticle = (typeof INDEFINITE_ARTICLES)[number];
export type Vowel = (typeof VOWELS)[number];

export const useIndefiniteArticle = (noun: string, override?: IndefiniteArticle): IndefiniteArticle => {
	// https://owl.purdue.edu/owl/general_writing/grammar/using_articles.html#:~:text=INDEFINITE%20ARTICLES%3A%20A%20AND%20AN
	const isVowel = VOWELS.indexOf(noun.toLowerCase()[0] as Vowel) !== -1;
	return override ?? isVowel ? 'an' : 'a';
};

import dialog from 'plugins/dialog.js';
import View from 'views/error-modal.html';

var ErrorModal = function (spec = {}) {
	this.view = View;

	this.transaction = spec.transaction;
	this.global = spec.global;

	this.transactionKind = this.transaction ? (this.transaction.isOrder ? 'check-out' : 'reservation') : '';

	this.errors = spec.errors || this.getErrors();
	this.totalErrors = this.errors.length;
	this.errorText =
		(this.totalErrors > 1 ? 'these' : 'this') +
		' <b>' +
		this.totalErrors +
		'</b> ' +
		'error'.pluralize(this.totalErrors);
	this.title = spec.title || this.getTitle();
};

ErrorModal.prototype.getTitle = function () {
	var status = this.transaction.oStatus();

	if (this.transaction.isOrder) {
		return (
			'You cannot ' +
			(status == 'open' ? 'extend this ' + this.transactionKind : 'check-out') +
			' because of ' +
			this.errorText +
			':'
		);
	} else {
		return (
			'You cannot ' +
			(status == 'open' ? 'convert to a check-out' : 'reserve') +
			' because of ' +
			this.errorText +
			':'
		);
	}
};

ErrorModal.prototype.getErrors = function () {
	var that = this,
		errors = [],
		status = that.transaction.oStatus();

	// Locations validation
	if (!that.transaction.oLocation()) {
		errors.push('No location set');
	}

	// Date
	// CHECKOUT
	if (that.transaction.isOrder) {
		var dueDate = that.transaction.oDue();

		if (dueDate) {
			if (!that.transaction.isValidDueDate()) {
				errors.push(that.transaction.oDueDateError());
			}
		} else {
			errors.push('No to date set');
		}
		// RESERVATION
	} else {
		var fromDate = that.transaction.oFrom();
		if (fromDate) {
			if (!that.transaction.isValidFromDate()) {
				errors.push(that.transaction.oFromDateError());
			}
		} else {
			errors.push('No from date set');
		}

		var toDate = that.transaction.oTo();
		if (toDate) {
			if (!that.transaction.isValidToDate()) {
				errors.push(that.transaction.oToDateError());
			}
		} else {
			errors.push('No to date set');
		}
	}

	// Contact validation
	var contact = that.transaction.oContact();
	if (contact) {
		if (contact.status == 'blocked') {
			errors.push('Contact is blocked');
		} else if (contact.status == 'archived') {
			errors.push('Contact is archived');
		}
	} else {
		errors.push('No contact set');
	}

	if (that.transaction.oItems().length == 0) {
		errors.push('No items added');
	}

	var requiredFields = (
		this.transaction.isOrder ? this.global.central.group.orderFields : this.global.central.group.reservationFields
	).filter(function (f) {
		return f.required;
	});
	var fields = this.transaction.getSortedFields(requiredFields);
	$.each(fields, function (i, field) {
		if (!field.isValid()) {
			errors.push('No ' + field.name.toLowerCase() + ' set');
		}
	});

	return errors;
};

// Durandal events
// ----
ErrorModal.show = function (spec) {
	return dialog.showBootstrapDialog(new ErrorModal(spec));
};

ErrorModal.prototype.close = function () {
	dialog.close(this);
};

ErrorModal.prototype.compositionComplete = function (v, p) {
	var that = this;
	$(p).on('hidden.bs.modal', function (e) {
		that.close();
	});
};

// UI events
// ----

export default ErrorModal;

import { AnalyticsPlugin, AnalyticsProperties, Properties } from '../analytics';

interface VitallyConfig {
	token: string;
}

interface WorkspaceEventData extends Properties {
	workspaceId: string;
	workspaceName: string;
	organisationId: string;
	organisationName: string;
}

export class Vitally implements AnalyticsPlugin {
	constructor(private readonly config: VitallyConfig) {}

	get name(): string {
		return 'vitally';
	}

	identify(props: AnalyticsProperties) {
		this.identifyOrganisation(props.organisationId);

		this.identifyWorkspace(props.workspaceId, props.organisationId, { name: props.workspaceName });

		this.identifyUser(
			props.userId,
			props.workspaceId,
			props.name,
			props.email,
			props.registrationDate,
			props.isSyncedUser,
			props.canLoginWithSSO
		);

		this.initializeNPS();
	}

	initialize(): void {
		window.Vitally.init(this.config.token);
	}

	page(): void {
		return;
	}

	track(event: string, _: string, props: WorkspaceEventData) {
		const { workspaceId, workspaceName, organisationId, organisationName } = props || {};

		switch (event) {
			case 'Workspace Added':
			case 'Workspace Edited':
			case 'Workspace Deleted':
			case 'Workspace Reset':
				return this.identifyWorkspace(workspaceId, organisationId, { name: workspaceName });
			case 'Organisation Edited':
				return this.identifyOrganisation(organisationId, organisationName);
			case 'Subscription Cancelled': {
				return this.updateOrganisationTraits(organisationId, {
					churnReason: props.reason as string,
					churnDescription: props.description as string,
				});
			}
		}
	}

	private updateOrganisationTraits(organisationId: string, traits: Record<string, unknown>) {
		window.Vitally.fetchable.post('/organization', {
			organizationId: organisationId,
			traits,
			timestamp: new Date().toISOString(),
		});
	}

	private identifyOrganisation(organisationId: string, organisationName?: string) {
		window.Vitally.methodCall('organization', () => ({
			organizationId: organisationId,
			...(organisationName && { organizationName: organisationName }),
			traits: {},
		}));
	}

	private identifyWorkspace(workspaceId: string, organisationId: string, traits: Record<string, unknown>) {
		window.Vitally.account({
			accountId: workspaceId,
			organizationId: organisationId,
			traits,
		});
	}

	private identifyUser(
		userId: string,
		workspaceId: string,
		name: string,
		email: string,
		userCreationDate: string,
		isSyncedUser: boolean,
		canLoginWithSSO: boolean
	) {
		window.Vitally.user({
			userId: userId,
			accountId: workspaceId,
			traits: {
				name: name,
				email: email,
				createdAt: userCreationDate,
				isSyncedUser,
				canLoginWithSSO,
			},
		});
	}

	private initializeNPS() {
		window.Vitally.nps('survey', {
			productName: 'Cheqroom',
			primaryColor: '#7B61FF',
			delay: 1000,
		});
	}
}

/* eslint-disable no-console */

export type Properties = Record<string, unknown>;

// Properties to send to all analytics services
export interface AnalyticsProperties {
	availableFeatures: Properties;
	conversionDate: string;
	creationDate: string;
	email: string;
	enabledFeatures: Properties;
	featureFlags: string[];
	industry: string;
	isAccountOwner: boolean;
	isAdministrator: boolean;
	isSyncedUser: boolean;
	canLoginWithSSO: boolean;
	name: string;
	renewalDate: string;
	userId: string;
	workspaceId: string;
	workspaceName: string;
	registrationDate: string;
	screenWidth: string;
	screenHeight: string;
	recurlyAccountCode: string;
	organisationId: string;
	itemQuota: number;
	itemQuotaPercentage: number;
}

export interface PluginConfig {
	apiKey: string;
}

export interface AnalyticsPlugin {
	name: string;
	identify(props: AnalyticsProperties): void;
	initialize(): void;
	page(event: string): void;
	track(event: string, source: string, props?: Properties): void;
}

const shouldDebug = process.env.DEBUG_TRACKING === 'true';

export class Analytics {
	private initializedPlugins: AnalyticsPlugin[] = [];
	private isInitialized = false;

	constructor(private readonly plugins: AnalyticsPlugin[]) {}

	destroy(): void {
		this.initializedPlugins = [];
		this.isInitialized = false;
	}

	identify(props: AnalyticsProperties): void {
		if (!this.isInitialized) this.initialize();

		this.initializedPlugins.forEach((plugin) => {
			try {
				plugin.identify(props);
			} catch (error) {
				console.warn(`Could not identify ${plugin.name}`, error);
			}
		});
	}

	initialize(): void {
		this.plugins.forEach((plugin) => {
			try {
				plugin.initialize();

				if (shouldDebug) console.warn(`Plugin ${plugin.name} has been initialized`);

				this.initializedPlugins.push(plugin);
			} catch (error) {
				console.warn(`Could not initialize ${plugin.name}`, error);
			}
		});

		this.isInitialized = true;
	}

	page(event: string): void {
		if (!this.isInitialized) this.initialize();

		if (shouldDebug) console.warn(`Tracking page ${event}`);

		this.initializedPlugins.forEach((plugin) => {
			try {
				plugin.page(event);
			} catch (error) {
				console.warn(`Plugin ${plugin.name} could not track page`, error);
			}
		});
	}

	track(event: string, source: string, props?: Properties): void {
		if (!this.isInitialized) this.initialize();

		if (shouldDebug) console.warn(`Tracking ${event} from ${source} with props ${JSON.stringify(props)}`);

		this.initializedPlugins.forEach((plugin) => {
			try {
				plugin.track(event, source, props);
			} catch (error) {
				console.warn(`Plugin ${plugin.name} could not track event ${event}`, error);
			}
		});
	}
}

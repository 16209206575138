import system from 'durandal/system.js';
import cr from '@cheqroom/core';
import ko from 'knockout';
import kov from 'knockout-validation';

export default {
	mixin: function (kv, options) {
		var orig_fromJson = kv._fromJson,
			orig_reset = kv.reset;

		var global = options.global;

		// Core method overrides
		// ----
		kv._fromJson = function (data, options) {
			return orig_fromJson.apply(kv, [data, options]).then(function () {
				kv.oComment(data.value);
				kv.oBy(data.by);
				kv.oModified(data.modified);
				kv.oId(data.id);

				kv.commentOrig = data.value || '';
			});
		};

		kv.reset = function () {
			//Reset observables to defaults
			kv.oComment(kv.commentOrig);
			kv.oIsSubmitting(false);
			kv.oIsDeleting(false);
			kv.oHasFocus(false);
		};

		// Observables
		// ----
		kv.oComment = ko.observable(kv.value || '').trimmed();
		kv.oBy = ko.observable(kv.by);
		kv.oModified = ko.observable(kv.modified);
		kv.oIsEditing = ko.observable(options.isEditing || false);
		kv.oHasFocus = ko.observable(false);
		kv.oIsSubmitting = ko.observable(false);
		kv.oIsDeleting = ko.observable(false);
		kv.oId = ko.observable(kv.id);

		// Computables
		// ----
		// Make sure the cr.Comment fields are updated when our computeds are updated as well
		// This enables the default: isEmpty, isDirty, _toJson to work automatically
		kv._updateComment = ko.computed(function () {
			kv.value = kv.oComment();
		});

		kv.isEmpty = ko.computed(function () {
			var comment = $.trim(kv.oComment());
			return comment.length == 0;
		});

		kv.canSave = ko.computed(function () {
			var isEditing = kv.oIsEditing(),
				comment = $.trim(kv.oComment());
			return isEditing && !kv.isEmpty() && comment.length > 1 && comment.length < 4096;
		});

		// Other properties
		// ----
		kv.commentOrig = kv.value || '';
		kv.helper = global.coreHelper || new cr.Helper(); //need helper for getThumbnail
	},
};

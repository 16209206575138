import dialog from 'plugins/dialog.js';
import ko from 'knockout';
import i18n from 'nls/lang.js';
import View from 'views/confirm-modal.html';

var ConfirmModal = function (spec = {}) {
	this.view = View;
	this.title = ko.observable(spec.title || i18n.general.sure);
	this.body = ko.observable(spec.body || '');
	this.yesHtml = ko.observable(spec.yesHtml || i18n.general.yes);
	this.yesCss = ko.observable(spec.yesCss || 'btn btn-danger');
	this.noHtml = ko.observable(spec.noHtml || i18n.general.no);
	this.noCss = ko.observable(spec.noCss || 'btn btn-secondary');
	this.showCancel = ko.observable(spec.showCancel || false);
	this.showNo = ko.observable(spec.showNo !== undefined ? spec.showNo : true);
	this.showYes = ko.observable(spec.showYes !== undefined ? spec.showYes : true);
	this.cancelHtml = ko.observable(spec.cancelHtml || i18n.general.cancel);
	this.cancelCss = ko.observable(spec.cancelCss || 'btn btn-link');

	this.modalOptions = { backdrop: 'static' };
};

// Durandal events
// ----
ConfirmModal.showDeleteMessage = function (spec) {
	spec = spec || {};
	spec.action = spec.action || 'delete';
	return ConfirmModal.showActionMessage(spec);
};

ConfirmModal.showActionMessage = function (spec) {
	spec = spec || {};
	spec.count = spec.count || 1;
	spec.action = spec.action || 'delete';
	spec.kind = spec.kind == 'order' ? 'check-out' : spec.kind;
	spec.kind = spec.kind == 'orders' ? 'check-outs' : spec.kind;

	var subject = spec.count == 1 ? spec.kind : spec.count + ' ' + spec.kind.pluralize(spec.count);
	var those = spec.count == 1 ? ' this ' : ' these ';
	spec.title = spec.title || spec.action.capitalize(true) + ' ' + subject + '?';
	spec.msg = spec.msg || 'Are you sure you want to ' + spec.action + those + subject + '?';
	return ConfirmModal.showMessage(spec);
};

ConfirmModal.showMessage = function (spec) {
	// Wrapper around show which just needs a spec.msg without any styling
	spec = spec || {};
	spec.submsg = spec.submsg === undefined ? '<strong>This cannot be undone.</strong>' : '';

	spec.body = `<p class='lead'>${spec.msg || ''}${spec.submsg ? '<br/>' : ''}${spec.submsg}</p>`;

	return ConfirmModal.show(spec);
};

ConfirmModal.show = function (spec) {
	return dialog.showBootstrapDialog(new ConfirmModal(spec));
};

// UI events
// ----
ConfirmModal.prototype.clickedYes = function () {
	dialog.close(this, true);
};

ConfirmModal.prototype.clickedNo = function () {
	dialog.close(this, false);
};

ConfirmModal.prototype.clickedClose = function () {
	dialog.close(this);
};

export default ConfirmModal;

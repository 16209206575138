import 'twin.macro';

import { Stack, Text } from '@cheqroom/ui';
import { StyledCheckbox } from '@cheqroom/ui/src/components/Checkbox/Checkbox.styles';
import { FC, useId } from 'react';

import { SystemField, SystemFieldType as ItemSystemFieldType } from '../../hooks/item-fields/types';
import { Field as ReservationsFieldType } from '../../pages/Reservations/Overview/python.hooks';
import { StyledSelectField } from './SelectFields.styles';

type Props = {
	fields: SystemField<ItemSystemFieldType | ReservationsFieldType | string>[];
	title: string;
	onToggleColumn: (id: string) => void;
	isFieldSelected: (id: string) => boolean;
	disabled?: boolean;
	isCustomFields?: boolean;
};

const SelectFields: FC<Props> = ({
	fields,
	title,
	onToggleColumn,
	isFieldSelected,
	disabled: globalDisabled = false,
	isCustomFields = false,
}) => {
	const selectFieldsId = useId();

	return (
		<Stack vertical spacing="loose" tw="bg-gray-50 rounded-md p-4">
			<Text weight="bold">{title}</Text>
			<Stack spacing="extraTight" wrap>
				{fields.length ? (
					fields.map((field) => {
						const isSelected = isFieldSelected(field.id);

						// Field is disabled if...
						// - field is not removable
						// - SelectFields is disabled by parent and field is not
						//   already selected
						const disabled = !field.removable || (globalDisabled && !isSelected);

						return (
							<StyledSelectField key={field.id} spacing="extraTight" align="center" disabled={disabled}>
								<StyledCheckbox
									type="checkbox"
									checked={isSelected}
									onChange={() => onToggleColumn(field.id)}
									disabled={disabled}
									id={`${selectFieldsId}-${field.id}`}
								/>
								<label
									htmlFor={`${selectFieldsId}-${field.id}`}
									tw="mb-0 select-none hover:cursor-pointer"
								>
									{field.name}
								</label>
							</StyledSelectField>
						);
					})
				) : (
					<Text>No {isCustomFields ? 'custom' : 'system'} fields found</Text>
				)}
			</Stack>
		</Stack>
	);
};

export default SelectFields;

import debounce from 'viewmodels/debounce';
import dialog from 'plugins/dialog.js';
import ko from 'knockout';
import View from 'views/location-modal.html';

var subscriptions = [];

var LocationModal = function (spec = {}) {
	this.view = View;

	var that = this;
	this.title = ko.observable(spec.title || 'Search locations');
	this.placeholder = spec.placeholder || 'Headquarters';
	this.global = spec.global;
	this.ds = spec.ds || spec.global.getDataSource('locations');
	this.params = spec.params || {};

	this.search = ko.observable('');
	this.hasSearchFocus = ko.observable(false);
	this.locations = ko.observable([]);
	this.isBusy = ko.observable(false);
	this.selected = ko.observable(-1);

	this.limit = 5;
	this.skip = 0;
	this.loadMore = ko.observable(false);
	this.isLoadingMore = ko.observable(false);

	// Binding handlers
	// Custom binding handler to initialize infinite scroll
	// in the results panel
	ko.bindingHandlers.infiniteScroll = {
		update: function (element, valueAccessor) {
			var infiniteScroll = ko.utils.unwrapObservable(valueAccessor()); //grab a dependency to the obs array

			if (!infiniteScroll) return;

			var $results = $(element);

			// scroll to top
			$results.scrollTop(0);

			$results.scroll(function () {
				if (Math.ceil($results.scrollTop()) >= $results.get(0).scrollHeight - $results.height()) {
					if (that.loadMore() && !that.isLoadingMore()) {
						that.clickedLoadMore();
					}
				}
			});
		},
	};

	this.selectMode = ko.observable(spec.selectMode || 'single');
	this.selection = ko.observableArray([]);
	this.selectionText = ko.pureComputed(function () {
		var selection = that.selection();

		return selection.length == 1 ? '1 location' : selection.length + ' locations';
	});

	// Empty message

	// show isEmpty message when:
	// - not busy
	// - searchbox isn't empty
	// - scanner search isn't running in background
	// - results is 0
	// OR
	// - no items yet
	this.isEmpty = ko
		.computed(function () {
			return that.locations() != null && that.locations().length == 0;
		})
		.extend({ rateLimit: 50 });

	this._getEmptyMessage = ko
		.computed(function () {
			var search = that.search();

			var message = 'No locations found';

			if (search.length > 0) {
				message += ' with ';

				message += '<strong>' + search.truncate() + '</strong>';
			}

			return message;
		})
		.extend({ throttle: 550 });

	/**
	 * Search implementation
	 * @param  {bool} allLocations    load more locations
	 */
	var allResults = [];
	this._locationSearch = function (loadMore) {
		var query = that.search();

		var params = $.extend({}, that.params, {
			query: query,
			listName: 'active',
			_sort: 'name',
		});

		if (!loadMore) {
			that.skip = 0;
			that.limit = 5;
			allResults = [];
			that.isBusy(true);
			that.locations(null);
			that.selected(-1);
		} else {
			that.skip = that.limit;
			that.limit += 5;
			that.isLoadingMore(true);
		}

		params._limit = that.limit;
		params._skip = that.skip;

		// Cancel previous search?
		if (that.abortController) that.abortController.abort();

		that.abortController = new AbortController();

		that.ds
			.search(params, '*', null, null, null, null, { abortController: that.abortController })
			.then(function (resp) {
				var docs = resp.docs || [];

				allResults = allResults.concat(docs);
				that.loadMore(resp.count > allResults.length);
				that.locations(allResults);

				if (docs.length > 0) {
					// Select the 1st result by default
					that.selected(0);
				}
			}, that.global.onError)
			.finally(function () {
				that.isLoadingMore(false);
				that.isBusy(false);
			});
	};

	this._locationSearchThrottled = debounce(this._locationSearch, 300);

	// Trigger search when input changes
	subscriptions.push(
		this.search.subscribe(function () {
			that.isBusy(true); //need to set it immediatelly other we could see empty message
			that._locationSearchThrottled();
		})
	);

	// Instead of a prototype function
	// we place it here because "e" param will not contain the VM,
	// so we need "that"
	this._handleKeyboard = function (e) {
		var selected = that.selected();
		var locations = that.locations() || [];
		switch (e.keyCode) {
			case 13: // ENTER
				if (selected >= 0) {
					var loc = locations[selected];
					if (loc) {
						that.close(loc);
					}
				}
				break;
			case 38: // UP
				if (selected > 0) {
					that.selected(selected - 1);
				}
				break;
			case 40: // DOWN
				if (selected < locations.length - 1) {
					that.selected(selected + 1);
				}
				break;
		}
	};
};

// Durandal events
// ----
LocationModal.show = function (spec) {
	return dialog.showBootstrapDialog(new LocationModal(spec));
};

LocationModal.prototype.compositionComplete = function (v, p) {
	var that = this;

	// Show first 5 locations
	this._locationSearch();

	// This event is fired when the modal has been made visible to the user
	// http://stackoverflow.com/questions/22547472/how-can-i-get-auto-focus-to-an-input-element-in-a-dynamically-generated-knockout
	$(p)
		.on('shown.bs.modal', function (e) {
			// Set focus to search input
			that.hasSearchFocus(true);
		})
		.on('hidden.bs.modal', function (e) {
			that.close();
		});

	// Bind keyboard events
	$(document).on('keydown.contactModal', this._handleKeyboard);
};

LocationModal.prototype.close = function (loc) {
	// Unbind keyboard events
	$(document).off('keydown.contactModal');

	//Clean up subscriptions
	for (var i = 0; i < subscriptions.length; i++) {
		subscriptions[i].dispose();
	}

	// Cancel search call
	if (this.abortController) this.abortController.abort();

	dialog.close(this, loc);
};

// UI events
// ----
LocationModal.prototype.clickedClose = function () {
	this.close();
};

LocationModal.prototype.clickedLocation = function (loc, e) {
	if (this.selectMode() == 'single') {
		this.close(loc);
	} else {
		if (e.target.type !== 'checkbox') {
			$(':checkbox', $(e.currentTarget)).trigger('click');
		}
	}

	return true;
};

LocationModal.prototype.clickedLoadMore = function () {
	this._locationSearch(true);
};

LocationModal.prototype.clickedAddSelection = function () {
	this.close(this.selection());
};

export default LocationModal;

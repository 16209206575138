import authentication from '@cheqroom/web/src/services/authentication';

class GraphQLException extends Error {
	constructor(message) {
		super(message);

		// We need the status code of an error so the global.onError knows what to do with the GraphQL exceptions
		if (message.includes('token')) {
			this.code = 401;
		} else if (message.includes('NotFound')) {
			this.code = 404;
		} else {
			this.code = 422;
		}
	}
}

const fetchGraphQLEndpoint = async (body) => {
	const response = await fetch(process.env.GRAPHQL_URL, {
		method: 'POST',
		headers: new Headers({
			Authorization: `Bearer ${await authentication.getAccessToken()}`,
			'Content-Type': 'application/json',
		}),
		body: JSON.stringify(body),
	});

	if (!response.ok) {
		throw new GraphQLException('Something went wrong on the server. Please try again later.');
	}

	const data = await response.json();

	if (data.errors && !!data.errors.length) {
		throw new GraphQLException(data.errors[0].message);
	}

	return data;
};

export default fetchGraphQLEndpoint;

import { createContext, FC, ReactNode, Suspense, useContext, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Portal } from '../..';
import { Overlay } from '../Overlay/Overlay';
import { StyledModalTransition } from './Modal.styles';

export type ModalType = FC<any>;
export type ModalKey = string;

interface ModalContextType {
	showModal(key: ModalKey, component: ModalType): void;
	hideModal(key: ModalKey): void;
}

export const ModalContext = createContext<ModalContextType | null>(null);

export const ModalProvider: FC<{ children?: ReactNode }> = ({ children }) => {
	const [modals, setModals] = useState<Record<ModalKey, ModalType>>({});
	const hasModals = !!Object.keys(modals).length;

	const hideModal = (keyToHide: ModalKey) => {
		setModals((modals) => {
			const { [keyToHide]: _, ...remainingModals } = modals;

			return remainingModals;
		});
	};

	const showModal = (key: ModalKey, Modal: ModalType) => {
		setModals((modals) => ({
			...modals,
			[key]: Modal,
		}));
	};

	return (
		<ModalContext.Provider value={{ hideModal, showModal }}>
			<Portal>
				<Suspense fallback={null}>
					<TransitionGroup>
						{Object.entries(modals).map(([key, Modal]) => (
							<CSSTransition
								key={key}
								classNames="modal"
								in={hasModals}
								mountOnEnter
								timeout={{ enter: 300, exit: 300 }}
								unmountOnExit
							>
								<StyledModalTransition>
									<Overlay />

									<Modal />
								</StyledModalTransition>
							</CSSTransition>
						))}
					</TransitionGroup>
				</Suspense>
			</Portal>

			{children}
		</ModalContext.Provider>
	);
};

export const useModalContext = () => {
	const context = useContext(ModalContext);

	if (!context) {
		throw new Error('useModalContext must be used within a ModalProvider');
	}

	return context;
};

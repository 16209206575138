import { useEventListener } from '@cheqroom/hooks';
import { useCallback, useRef, useState } from 'react';

type KeyPressHandler = {
	targetKey: string;
	onUp?: () => void;
	onDown?: () => void;
	ignoreCombinedWith?: string[];
};

type Args = {
	handlers: KeyPressHandler[];
	shouldAttach?: boolean;
	target?: EventTarget;
	options?: boolean | EventListenerOptions;
	eventCallback?: (event: Event) => void;
};

export const useKeyPress = (args: Args): { [key: string]: boolean } => {
	const { handlers, shouldAttach = true, target, options, eventCallback } = args;

	const [pressedKeys, setPressedKeys] = useState<{ [key: string]: boolean }>({});
	const ignoredCombinedKeys = useRef(new Set<string>());

	const keyboardEventMatchesKey = ({ code }: KeyboardEvent, key: string) => code === key;

	const handleOnUp = useCallback(
		(event: Event) => {
			const keyboardEvent = event as KeyboardEvent;
			handlers.forEach(({ targetKey, onUp, ignoreCombinedWith }) => {
				ignoreCombinedWith?.forEach((key) => {
					if (keyboardEventMatchesKey(keyboardEvent, key)) {
						ignoredCombinedKeys.current.delete(key);
					}
				});

				if (ignoredCombinedKeys.current.size === 0 && keyboardEventMatchesKey(keyboardEvent, targetKey)) {
					eventCallback?.(event);
					onUp?.();
					setPressedKeys((prev) => ({ ...prev, [targetKey]: false }));
				}
			});
		},
		[handlers, eventCallback]
	);

	const handleOnDown = useCallback(
		(event: Event) => {
			const keyboardEvent = event as KeyboardEvent;
			handlers.forEach(({ targetKey, onDown, ignoreCombinedWith }) => {
				ignoreCombinedWith?.forEach((key) => {
					if (keyboardEventMatchesKey(keyboardEvent, key)) {
						ignoredCombinedKeys.current.add(key);
					}
				});

				if (ignoredCombinedKeys.current.size === 0 && keyboardEventMatchesKey(keyboardEvent, targetKey)) {
					eventCallback?.(event);
					onDown?.();
					setPressedKeys((prev) => ({ ...prev, [targetKey]: true }));
				}
			});
		},
		[handlers, eventCallback]
	);

	useEventListener('keydown', handleOnDown, shouldAttach, target, options);
	useEventListener('keyup', handleOnUp, shouldAttach, target, options);

	return pressedKeys;
};

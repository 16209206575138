import debounce from 'viewmodels/debounce';
import system from 'durandal/system.js';
import ko from 'knockout';
import kov from 'knockout-validation';
import lw from 'viewmodels/labelwriter.js';
import cr from '@cheqroom/core';

// http://stackoverflow.com/questions/10745486/automatically-trim-whitespace-from-all-observable-values
ko.subscribable.fn.trimmed = function () {
	return ko.computed({
		read: function () {
			if (typeof this() != 'string') return this();

			return this().trim();
		},
		write: function (value) {
			this(typeof value == 'string' ? value.trim() : value);
			this.valueHasMutated();
		},
		owner: this,
	});
};

export default {
	mixin: function (template, options) {
		var orig_fromJson = template._fromJson,
			orig_reset = template.reset,
			orig_isDirty = template.isDirty,
			abortController = null;

		// Core method overrides
		// ----
		template._fromJson = function (data, opt) {
			// Use apply to call function so we can pass the
			// Location context otherwise it would use Window context
			return orig_fromJson.apply(template, [data, opt]).then(function () {
				template.oId(template.id);
				template.oName(template.name);
				template.oStatus(template.status);
				template.oBody(template.body);
				template.oKind(template.kind);
				template.oUnit(template.unit);
				template.oWidth(template.width);
				template.oHeight(template.height);
				template.oAskSignature(template.askSignature);
				template.oArchived(template.archived);
				template.oFormat(template.format);

				template.canEdit(!template.system);

				// Force isDirty computed update
				forceDirtyCompute(new Date().getTime());

				return data;
			});
		};

		template.reset = function () {
			// Use apply to call function so we can pass the
			// Location context otherwise it would use Window context
			orig_reset.apply(template);

			// Reset observables to defaults
			template.oId(template.id);
			template.oName(template.name);
			template.oStatus(template.status);
			template.oBody(template.body);
			template.oKind(template.kind);
			template.oUnit(template.unit);
			template.oWidth(template.width);
			template.oHeight(template.height);
			template.oAskSignature(template.askSignature);
			template.oArchived(template.archived);
			template.oFormat(template.format);

			var data = template.oData();
			data.name.isModified(false);
		};

		template.resetBody = function () {
			template.oBody(template.raw.body);
		};

		template.download = function () {
			//https://jsfiddle.net/koldev/cW7W5/
			//http://www.javascripture.com/Blob
			var saveData = (function () {
				var a = document.createElement('a');
				document.body.appendChild(a);
				a.style = 'display: none';
				return function (data, fileName) {
					var blob = new Blob([data], { type: 'octet/stream' }),
						url = window.URL.createObjectURL(blob);
					a.href = url;
					a.download = fileName;
					a.click();
					window.URL.revokeObjectURL(url);
				};
			})();

			var data = this.oBody(),
				fileName = cr.common.getFriendlyFileName(template.raw.name) + '.label';

			saveData(data, fileName);
		};

		// Knockout validation rules
		// ----
		ko.validation.rules['templateExists'] = {
			async: true,
			message: 'Template name is already taken',
			validator: debounce(function (val, params, callback) {
				// When existing template is edited,
				// we don't want to check its current name
				if (template.oId() != null && template.raw != null && template.oData().name() == template.raw.name) {
					callback(true);
				} else {
					if (template.ds) {
						if (abortController) {
							abortController.abort();
						}

						abortController = new AbortController();

						template.ds
							.search({ name: val, pk__ne: template.oId() }, '_id', null, null, null, null, {
								abortController: abortController,
							})
							.then(function (resp) {
								callback(resp.count == 0);
							})
							.catch(function () {
								callback({ isValid: false, message: 'Something went wrong' });
							});
					}
				}
			}, 250),
		};
		ko.validation.registerExtenders();

		// Observables
		// ----
		template.canSave = ko.observable(false);
		template.canEdit = ko.observable(false);
		template.oId = ko.observable(template.id || '');
		template.oName = ko.observable(template.name || '');
		template.oStatus = ko.observable(template.status || 'inactive');
		template.oBody = ko.observable(template.body || '');
		template.oKind = ko.observable(template.kind || '');
		template.oUnit = ko.observable(template.unit || 'inch');
		template.oWidth = ko.observable(template.width || 0.0);
		template.oHeight = ko.observable(template.height || 0.0);
		template.oAskSignature = ko.observable(template.askSignature);
		template.oArchived = ko.observable(template.archived);
		template.oFormat = ko.observable(template.format);

		template.oData = ko.validatedObservable({
			name: template.oName.trimmed().extend({ required: true, minLength: 3, templateExists: true }),
			oBody: template.oBody.trimmed().extend({ required: true, minLength: 3 }),
		});

		// Computables
		// ----
		template.canPreview = ko
			.computed(function () {
				var body = template.oBody(),
					isValid = template.isValid() && template.oData.isValid();
				switch (template.format) {
					case 'dymo':
						isValid = isValid && lw.DymoLabelWriter.getLabelInfo(body) != null;
						break;
					default:
						break;
				}
				return isValid;
			})
			.extend({ throttle: 250 });

		template.canDownload = ko.computed(function () {
			var format = template.oFormat();
			return format == 'dymo';
		});

		template._updateName = ko.computed(function () {
			template.name = template.oName();
		});

		template._updateBody = ko.computed(function () {
			template.body = template.oBody();
		});

		template._updateKind = ko.computed(function () {
			template.kind = template.oKind();
		});

		template._updateUnit = ko.computed(function () {
			template.unit = template.oUnit();
		});

		template._updateWidth = ko.computed(function () {
			template.width = parseFloat(template.oWidth());
		});

		template._updateHeight = ko.computed(function () {
			template.height = parseFloat(template.oHeight());
		});

		template._updateAskSignature = ko.computed(function () {
			template.askSignature = template.oAskSignature();
		});

		template._updateStatus = ko.computed(function () {
			template.status = template.oStatus();
		});

		var forceDirtyCompute = ko.observable();
		template.isDirty = ko
			.computed(function () {
				var name = template.oName(),
					body = template.oBody(),
					width = template.oWidth(),
					height = template.oHeight(),
					kind = template.oKind(),
					askSignature = template.oAskSignature(),
					unit = template.oUnit(),
					forceDirty = forceDirtyCompute();

				return orig_isDirty.apply(template);
			})
			.extend({ throttle: 50 });

		template._updateCanSave = ko.computed(function () {
			var isValid = template.isValid() && template.oData.isValid(),
				isDirty = template.isDirty();
			template.canSave(!template.system && isValid && isDirty);
		});

		// Extra functionality
		// ----
	},
};

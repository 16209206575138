import system from 'durandal/system.js';
import app from 'durandal/app.js';
import ko from 'knockout';
import kov from 'knockout-validation';
import cr from '@cheqroom/core';
import ConfirmModal from 'viewmodels/confirm-modal.js';
import ErrorModal from 'viewmodels/error-modal.js';

export default {
	mixin: function (transaction, options) {
		var orig_fromJson = transaction._fromJson,
			orig_reset = transaction.reset,
			orig_addItems = transaction.addItems,
			orig_removeItems = transaction.removeItems,
			orig_swapItem = transaction.swapItem,
			orig_setContact = transaction.setContact,
			orig_clearContact = transaction.clearContact,
			orig_getConflicts = transaction._getConflicts,
			orig_setLocation = transaction.setLocation,
			orig_clearLocation = transaction.clearLocation,
			orig_getAttachment = transaction._getAttachment,
			orig_clearName = transaction.clearName,
			orig_setName = transaction.setName,
			orig_ensureTransactionExists = transaction._ensureTransactionExists,
			orig_createTransaction = transaction._createTransaction;

		var helper = new cr.Helper(),
			global = options.global,
			perm = global.getPermissionHandler(),
			triggerSource = options.triggerSource || 'page';

		transaction.onCreated = function () {};

		// Core method overrides
		// ----
		transaction._fromJson = function (data, options) {
			//Use apply to call function so we can pass the
			//Base context otherwise it would use Window context
			return orig_fromJson.apply(transaction, [data, options]).then(function () {
				transaction.oName(transaction.name);

				transaction.oContact(transaction.contact ? transaction.contact : null);
				transaction.oItems(transaction.items);
				transaction.oItemSummary(transaction.itemSummary);
				transaction.oStatus(transaction.status);
				transaction.oArchived(transaction.archived);
				transaction.oNumber(transaction.number);

				//Auto set contact for self service
				var user = global.central.user() || {};
				if (!perm.hasContactReadOtherPermission() && !transaction.contact) {
					transaction.oContact(user.customer);
				}

				// If only 1 location, we automatically set it
				var locations = global.central.activeLocations() || [];
				var loc = global.central.location()
					? global.central.location()
					: locations.length == 1
						? locations[0]
						: null;
				transaction.oLocation(transaction.location ? transaction.location : loc);

				transaction._triggerAction(':load');

				return data;
			});
		};

		transaction.reset = async function () {
			transaction._triggerAction(':resetting');

			//Use apply to call function so we can pass the
			//Base context otherwise it would use Window context
			await orig_reset.apply(transaction);

			//Reset observables to defaults
			transaction.oName(null);
			transaction.oContact(null);
			transaction.oItems([]);
			transaction.oStatus('creating');
			transaction.oItemSummary('');
			transaction.oArchived(null);
			transaction.oNumber(null);
			transaction.showLocationError(false);

			//Auto set contact for self service
			var user = global.central.user() || {};
			if (!perm.hasContactReadOtherPermission()) {
				transaction.oContact(user.customer);
			}

			// If only 1 location, we automatically set it
			var locations = global.central.activeLocations() || [];
			var loc = global.central.location()
				? global.central.location()
				: locations.length == 1
					? locations[0]
					: null;
			transaction.oLocation(loc);

			transaction._triggerAction(':reset');
		};

		transaction.delete = function (showConfirm) {
			var transactionId = transaction.oId();

			var dfdConfirm;

			if (showConfirm) {
				dfdConfirm = new Promise((resolve) => {
					ConfirmModal.showMessage({
						title: 'Remove ' + (transaction.isOrder ? 'check-out' : 'reservation') + '?',
						msg:
							'Are you sure you want to remove this ' +
							(transaction.isOrder ? 'check-out' : 'reservation') +
							'?',
					}).then(function (resp) {
						resolve(resp == true);
					});
				});
			} else {
				dfdConfirm = Promise.resolve(true);
			}

			return dfdConfirm.then(function (resp) {
				return new Promise((resolve) => {
					if (resp) {
						transaction._triggerAction(':deleting');

						transaction.isBusy(true);

						transaction.ds
							.delete(transactionId)
							.then(function () {
								transaction._triggerAction(':deleted', transactionId);

								resolve(true);
							})
							.finally(function () {
								transaction.isBusy(false);
							});
					} else {
						resolve(false);
					}
				});
			});
		};

		transaction.getIgnore404 = function () {
			if (!transaction.oId()) {
				return Promise.resolve(null);
			} else {
				return transaction.ds.getIgnore404(transaction.oId(), transaction._fields);
			}
		};

		transaction.addItems = function (docs, skipKits) {
			return transaction.addMultipleItemsOrKits(docs, skipKits).then(function (items) {
				if (items) {
					var itemIds = global.common.getItemIds(items);
					var allItems = transaction.oItems() || [];
					var origItems = allItems.slice(0);

					//BUGFIX Don't add same items twice
					var allItemIds = global.common.getItemIds(allItems);
					itemIds = itemIds.filter(function (item) {
						return allItemIds.indexOf(item) == -1;
					});

					// Show items as adding
					$.each(items, function (index, value) {
						var found = allItems.find(function (item) {
							return item._id == value._id;
						});
						if (!found) {
							value.isAdding = ko.observable(true);

							// Has order field?
							if (!value.order) {
								value.order = transaction.id;
							}

							allItems.push(value);
						}
					});
					transaction.oItems(allItems);

					transaction.isBusy(true);

					var dfdAddItems;
					if (itemIds.length > 0) {
						dfdAddItems = orig_addItems.apply(transaction, [itemIds, true]).then(function (data) {
							$.each(items, function (index, value) {
								var found = allItems.find(function (item) {
									return item._id == value._id;
								});
								if (found && found.isAdding) {
									found.isAdding(false);
								}
							});
							transaction.oItems(allItems);
							transaction.oItemSummary(data.itemSummary);

							// Update location if it was auto set
							if (!transaction.location) {
								transaction.oLocation(data.location);
							}

							transaction._getConflicts();

							transaction._triggerAction(':changed');

							return data;
						});
					} else {
						dfdAddItems = Promise.resolve(transaction);
					}

					return dfdAddItems.finally(function () {
						transaction.isBusy(false);
					});
				}
			});
		};

		transaction.removeItems = function (docs) {
			return transaction.removeMultipleItemsOrKit(docs).then(function (items) {
				if (items) {
					var itemIds = global.common.getItemIds(items);
					var allItems = transaction.oItems() || [];
					var origItems = allItems.slice(0);

					if (transaction.isOrder) {
						//BUGFIX Don't allow to delete items that are already checked in, so no longer
						//part of order
						var ignorePartiallyCheckedInItems = global.common.getItemIds(
							allItems.filter(function (it) {
								return it.order != transaction.id;
							})
						);
						itemIds = itemIds.filter(function (item) {
							return ignorePartiallyCheckedInItems.indexOf(item) == -1;
						});
					}

					// Show items as removing
					$.each(allItems, function (i, item) {
						if (itemIds.indexOf(item._id) != -1) {
							item.isRemoving(true);
						}
					});

					transaction.isBusy(true);

					// Bugfix don't allow empty itemIds because this would delete
					// all items in the order
					var dfdRemove;
					if (itemIds.length == 0) {
						dfdRemove = transaction.get();
					} else {
						dfdRemove = orig_removeItems.apply(transaction, [itemIds, true]);
					}

					return dfdRemove
						.then(
							function (data) {
								// Don't show removed items anymore
								var currentItems = transaction.oItems() || [];
								transaction.oItems(
									currentItems.filter(function (item) {
										return itemIds.indexOf(item._id) == -1;
									})
								);
								transaction.oItemSummary(data.itemSummary);

								// Don't conflicts of removed items anymore
								var allConflicts = transaction.oConflicts() || [];
								transaction.oConflicts(
									allConflicts.filter(function (conflict) {
										return itemIds.indexOf(conflict.item) == -1;
									})
								);

								transaction._getConflicts();

								transaction._triggerAction(':changed');

								return data;
							},
							function () {
								//Error reset to old items
							}
						)
						.finally(function () {
							transaction.isBusy(false);
						});
				}
			});
		};

		transaction.swapItem = function (oldItem, newItem) {
			var oldItemId = helper.ensureId(oldItem);
			var newItemId = helper.ensureId(newItem);

			// Add/update new item info
			if (transaction.isOrder) {
				newItem.status = 'await_checkout';
				newItem.order = transaction.id;
			}
			newItem.isAdding = ko.observable(true);

			// Replace old item with new
			var allItems = transaction.oItems() || [];
			var origItems = allItems.slice(0);

			// IE doesn't support findIndex method so use for loop
			//allItems.findIndex(function(item){ return item._id == oldItemId; })
			var itemIndex = -1;
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i]._id == oldItemId) {
					itemIndex = i;
					break;
				}
			}
			if (itemIndex > -1) {
				allItems[itemIndex] = newItem;
			}

			transaction.oItems(allItems);

			// Remove conflicts of old item
			var allConflicts = transaction.oConflicts() || [];
			allConflicts = allConflicts.filter(function (conflict) {
				return conflict.item != oldItem._id;
			});
			transaction.oConflicts(allConflicts);

			transaction.isBusy(true);

			return orig_swapItem
				.apply(transaction, [oldItemId, newItemId, true])
				.then(function (data) {
					newItem.isAdding(false);

					transaction._getConflicts();

					transaction._triggerAction(':changed');
				})
				.finally(function () {
					transaction.isBusy(false);
				});
		};

		transaction.setContact = function (contact) {
			var contactId = helper.ensureId(contact);

			transaction.oContact(contact);

			transaction.isBusy(true);

			return orig_setContact
				.apply(transaction, [contactId, true])
				.then(function () {
					// Bugfix
					// internal setContact sets id string as contact
					// need object
					transaction.contact = contact;

					transaction._triggerAction(':changed');

					transaction._getConflicts();
				})
				.finally(function () {
					transaction.isBusy(false);
				});
		};

		transaction.clearContact = function () {
			transaction.oContact(null);

			transaction.isBusy(true);

			return orig_clearContact
				.apply(transaction, [true])
				.then(function () {
					transaction._triggerAction(':changed');
					transaction._getConflicts();
				})
				.finally(function () {
					transaction.isBusy(false);
				});
		};

		transaction.setLocation = function (location) {
			var locationId = helper.ensureId(location);

			transaction.oLocation(location);

			transaction.isBusy(true);

			return orig_setLocation
				.apply(transaction, [locationId, true])
				.then(function () {
					transaction._getConflicts();

					transaction._triggerAction(':changed');
				})
				.finally(function () {
					transaction.isBusy(false);
				});
		};

		transaction.clearLocation = function () {
			transaction.oLocation(null);

			transaction.isBusy(true);

			return orig_clearLocation
				.apply(transaction, [true])
				.then(function () {
					transaction._getConflicts();

					transaction._triggerAction(':changed');
				})
				.finally(function () {
					transaction.isBusy(false);
				});
		};

		transaction.setName = function (name) {
			transaction.oName(name);

			transaction.raw.name = name;

			return orig_setName.apply(transaction, [name, true]);
		};

		transaction.clearName = function () {
			transaction.oName(null);

			transaction.raw.name = null;

			return orig_clearName.apply(transaction, [true]);
		};

		transaction._getConflicts = function () {
			if (!transaction.existsInDb()) return Promise.resolve([]);

			// Abort previous conflicts call
			if (transaction.abortConflictsController) transaction.abortConflictsController.abort();

			return new Promise((resolve) => {
				// BUGFIX cannot be executed before _fromJson has completetly run
				setTimeout(function () {
					transaction.isBusyConflicts(true);

					orig_getConflicts
						.apply(transaction, arguments)
						.then(function (conflicts) {
							transaction.oConflicts(conflicts);
							resolve(conflicts);
						})
						.finally(function () {
							transaction.isBusyConflicts(false);
							resolve([]);
						});
				}, 10);
			});
		};

		transaction._getAttachment = function (attachment, opt) {
			return orig_getAttachment.apply(transaction, [
				attachment,
				$.extend(opt, {
					canBeCover: false,
				}),
			]);
		};

		transaction._ensureTransactionExists = function () {
			//Override so skipRead is always done
			return orig_ensureTransactionExists.apply(transaction, [false]);
		};

		transaction._createTransaction = function () {
			//Override to skipRead by default
			return orig_createTransaction.apply(transaction, [true]).then(function (data) {
				transaction.oId(data._id);

				transaction._triggerAction(':changed');
				transaction.onCreated();
			});
		};

		transaction.addMultipleItemsOrKits = function (docs, skip) {
			// Single items selected which are part of a kit?
			var that = this;
			var kittedItems = docs.filter(function (doc) {
				return !$.isEmptyObject(doc.kit);
			});

			var getItems = function (docs, addSingleItems) {
				var items = [];
				var kitIds = [];

				$.each(docs, function (i, doc) {
					//Kit?
					if (doc.items) {
						if (kitIds.indexOf(doc._id) == -1) kitIds.push(doc._id);

						// Item that is part of a kit?
					} else if (doc.kit && !$.isEmptyObject(doc.kit)) {
						if (addSingleItems) {
							items.push(doc);
						} else {
							if (kitIds.indexOf(helper.ensureId(doc.kit)) == -1) kitIds.push(helper.ensureId(doc.kit));
						}

						// Item?
					} else {
						items.push(doc);
					}
				});

				// Get items for given kits
				if (kitIds.length > 0) {
					return global
						.getDataSource('kits')
						.get(
							kitIds.join(','),
							'items.status,items.name,items.fields,items.location,items.codes,items.order,items.canReserve,items.canOrder,items.canCustody,items.cover'
						)
						.then(function (resp) {
							// Make sure resp is an array
							if (!$.isArray(resp)) resp = [resp];

							// Add items for each kit
							$.each(resp, function (i, kitObj) {
								var kitItems = transaction.isOrder
									? global.common.getAvailableItems(kitObj.items)
									: global.common.getActiveItems(kitObj.items);
								// Add each kit item to selection
								$.each(kitItems, function (i, kitItem) {
									//Add kit id to each obj (needed for groupedItems)
									kitItem.kit = { _id: kitObj._id };
									kitItem.isAdding = ko.observable(false);

									if (items.indexOf(kitItem._id) == -1) {
										items.push(kitItem);
									}
								});
							});

							return items;
						});
				}

				return Promise.resolve(items);
			};

			var dfd = new Promise((resolve) => {
				if (perm.hasKitPermission() && kittedItems.length != 0 && !skip) {
					var dfdTakeApart;

					// Can take apart kits?
					if (perm.hasKitPermission('takeApart')) {
						dfdTakeApart = new Promise((resolveTakeApart) => {
							// Need to wrap with a setTimeout to make sure the equipment modal is
							// closed before showing the confirm modal (otherwise it could also trigger a close for the
							// confirm modal) because bootstrapModal uses same id for both modals
							setTimeout(() => {
								ConfirmModal.show({
									title: 'Also include kits?',
									yesHtml: 'Yes, also include kits',
									noHtml: 'No, just single items',
									noCss: 'btn btn-secondary',
									yesCss: 'btn btn-success',
									body: '<p class="lead">Some items in your selection are part of a kit.<br /> Do you want to check out <b>the entire kit</b>?</p>',
								}).then(function (includeKit) {
									if (includeKit !== undefined) {
										resolveTakeApart(!includeKit);
									}
								});
							}, 500);
						});
					} else {
						dfdTakeApart = Promise.resolve(false);
					}

					dfdTakeApart.then(function (addSingleItems) {
						if (addSingleItems !== undefined) {
							getItems(docs, addSingleItems).then(function (items) {
								resolve(items);
							});
						}
					});
				} else {
					getItems(docs, true).then(function (items) {
						resolve(items);
					});
				}
			});

			return dfd.then(function (items) {
				if (transaction.isOrder) {
					$.each(items, function (i, it) {
						//Already set status on await checkout
						it.status = 'await_checkout';
					});
				}
				return items;
			});
		};

		transaction.removeMultipleItemsOrKit = function (docs) {
			var that = this;
			var transactionItems = transaction.oItems();
			var kittedItems = docs.filter(function (doc) {
				return !$.isEmptyObject(doc.kit);
			});

			var getItems = function (docs, removeSingleItems) {
				var items = [];
				var itemDictionary = {}; //exclude duplicate items

				$.each(docs, function (i, doc) {
					// If item is already added, skip it
					if (itemDictionary[doc._id]) return true;

					// Item that is part of a kit?
					if (doc.kit && !$.isEmptyObject(doc.kit)) {
						if (removeSingleItems) {
							items.push(doc);
							itemDictionary[doc._id] = true;
						} else {
							items = items.concat(
								transaction
									.oItems()
									.filter(function (it) {
										if (helper.ensureId(it.kit) == helper.ensureId(doc.kit)) {
											itemDictionary[it._id] = true;
											return true;
										}
										return false;
									})
									.sort(function (a, b) {
										return a._id == doc._id;
									})
							);
						}
						// Item?
					} else {
						items.push(doc);
						itemDictionary[doc._id] = true;
					}
				});

				return Promise.resolve(items);
			};

			return new Promise((resolve) => {
				if (perm.hasKitPermission() && kittedItems.length != 0) {
					//All kit items selected?
					var allKitItemsSelected = true;
					var removeKitIds = global.common.getKitIds(docs);
					$.each(removeKitIds, function (i, kitId) {
						var kitItemsInTransaction = transactionItems.filter(function (ti) {
							return helper.ensureId(ti.kit) == helper.ensureId(kitId);
						});
						var kitItemsToRemove = kittedItems.filter(function (ki) {
							return helper.ensureId(ki.kit) == kitId;
						});

						if (kitItemsInTransaction.length != kitItemsToRemove.length) {
							allKitItemsSelected = false;
						}
					});

					//Only show confirm modal single items of a kit are being removed
					if (!allKitItemsSelected) {
						ConfirmModal.show({
							title: 'Remove selected items only?',
							yesHtml:
								kittedItems.length == 1
									? 'Yes, just selected item'
									: 'Yes, just ' + kittedItems.length + ' selected items',
							noHtml: 'No, also remove kits',
							noCss: 'btn btn-secondary',
							yesCss: 'btn btn-success',
							showCancel: true,
							body: '<p class="lead">Some items in your selection are part of a kit.<br /> Do you want to remove <b>only the selected items</b>?</p>',
						}).then(function (removeSingleItems) {
							if (removeSingleItems !== undefined) {
								getItems(docs, removeSingleItems).then(function (items) {
									resolve(items);
								});
							}
						});
					} else {
						getItems(docs, true).then(function (items) {
							resolve(items);
						});
					}
				} else {
					getItems(docs, true).then(function (items) {
						resolve(items);
					});
				}
			});
		};

		transaction.getItemIds = function () {
			return global.common.getItemIds(transaction.oItems() || []);
		};

		transaction.getKitIds = function () {
			return global.common.getKitIds(transaction.oItems() || []);
		};

		transaction.onUpdateName = function (e, newValue) {
			if ($.trim(newValue) == '') {
				transaction.clearName().then(function () {
					transaction._triggerAction(':changed');
					app.trigger('timeline:refresh');
					app.trigger(
						'notification',
						'Updated ' + (transaction.isOrder ? 'check-out' : 'reservation') + ' name'
					);
				});
			} else if ($.trim(newValue).length >= 3) {
				transaction.setName(newValue).then(function () {
					transaction._triggerAction(':changed');
					app.trigger('timeline:refresh');
					app.trigger(
						'notification',
						'Updated ' + (transaction.isOrder ? 'check-out' : 'reservation') + ' name'
					);
				});
			} else {
				return 'Please enter at least 3 characters.';
			}
		};

		/**
		 * Trigger transaction:changed with transaction object
		 */
		transaction._triggerAction = function (action, data) {
			var trigger = transaction._getTrigger(action);

			system.log(trigger, data != undefined ? data : transaction, triggerSource);
			app.trigger(trigger, data != undefined ? data : transaction, triggerSource);
		};

		transaction._getTrigger = function (action) {
			var trigger = transaction.isOrder ? 'check-outs' : 'reservations';
			return trigger + action;
		};

		// Observables
		// ----
		transaction.oName = ko.observable(transaction.name || '').extend({
			minLength: {
				params: 3,
				onlyIf: function () {
					return $.trim(ko.utils.unwrapObservable(transaction.oName)).length > 0;
				},
			},
		});
		transaction.oStatus = ko.observable(transaction.status || 'creating');
		transaction.oLocation = ko.observable(transaction.location || null);
		transaction.oContact = ko.observable(transaction.contact || null);
		transaction.oItems = ko.observable(transaction.items || []);
		transaction.oItemSummary = ko.observable(transaction.itemSummary);
		transaction.oArchived = ko.observable(transaction.archived);
		transaction.isBusy = ko.observable(false);
		transaction.oNumber = ko.observable(transaction.number || null);
		transaction.showLocationError = ko.observable(false);

		// Computables
		// ----
		transaction._updateName = ko.computed(function () {
			transaction.name = transaction.oName();
		});
		transaction._updateItems = ko.computed(function () {
			transaction.items = transaction.oItems();
		});
		transaction._updateStatus = ko.computed(function () {
			transaction.status = transaction.oStatus();
		});
		transaction._updateItemSummary = ko.computed(function () {
			transaction.itemSummary = transaction.oItemSummary();
		});
		transaction._updateContact = ko.computed(function () {
			transaction.contact = transaction.oContact();
		});
		transaction._updateLocation = ko.computed(function () {
			transaction.location = transaction.oLocation();
		});
		transaction._updateNumber = ko.computed(function () {
			transaction.number = transaction.oNumber();
		});

		transaction.oTitle = ko.pureComputed(function () {
			var name = transaction.oName();
			var itemSummary = transaction.oItemSummary();

			return global.common.getTransactionSummary(
				transaction,
				'Untitled ' + (transaction.isReservation ? 'reservation' : 'check-out')
			);
		});

		transaction.canEditName = ko.pureComputed(function () {
			var status = transaction.oStatus(),
				id = transaction.oId();
			return status == 'creating' || status == 'open';
		});

		transaction.isOwn = ko.pureComputed(function () {
			var contact = global.central.contact() || {},
				transactionContact = transaction.oContact() || {};
			return contact._id == transactionContact._id;
		});

		//Only show location picker of multiple locations
		//OR (bugfix)
		//if location is null and needs to be selected by user
		transaction.showLocationPicker = ko.pureComputed(function () {
			var hasMore = global.central.hasMoreLocations();
			return hasMore || (transaction.oLocation() == null && !hasMore);
		});

		transaction.isOrder = transaction.crtype == 'cheqroom.types.order';
		transaction.isReservation = transaction.crtype == 'cheqroom.types.reservation';

		transaction.onClickedSetDate = async (data, event) => {
			if (transaction.oLocation() === null) {
				await ErrorModal.show({
					title: 'You cannot select a date yet',
					errors: ['No location set'],
				});

				transaction.showLocationError(true);

				// Focus location picker
				document.querySelector('.transaction-info .location-picker .btn').focus();
			}
			return true;
		};

		transaction.inactiveItems = ko.pureComputed(() => {
			const items = transaction.oItems();
			const { id, isReservation, isOrder, status } = transaction;

			return global.common.getItemsByStatus(items, (item) => {
				const flag = global.central._getFlagById(item.flag);

				return (
					((item.status == 'expired' || item.status == 'in_custody') &&
						(transaction.isOrder ? item.order == id : true)) ||
					(isOrder ? item.canOrder === 'unavailable_allow' : false) ||
					(isReservation ? item.canReserve === 'unavailable_allow' : false) ||
					(isReservation && status == 'open' ? item.canOrder === 'unavailable_allow' : false) ||
					(flag && !flag.available)
				);
			});
		});
	},
};

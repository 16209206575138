import debounce from 'viewmodels/debounce';
import cr from '@cheqroom/core';
import ko from 'knockout';
import moment from 'moment';
import global from 'viewmodels/global.js';

import 'bootstrap-daterangepicker';

import View from 'views/custom-filter-view.html';
import CustomFilterPopoverTemplate from 'views/custom-filter-popover.html';
import analytics from '@cheqroom/web/src/services/analytics';

var sanitizer = cr.common.sanitizeHtml;
var customFilterView = function () {
	this.view = View;
};

// Durandal events
// ----
customFilterView.prototype.activate = function (data) {
	var that = this;

	this.moment = moment;
	this.collection = data.collection;
	this.field = data.field;
	this.filter = data.filter || ko.observable();
	this.localCache = data.localCache || {};

	this.placeholderText = 'Search ' + data.field.name.toLowerCase();

	this.selection = ko.observableArray([]);

	this.compareOptions = this.getCompareOptionsForField(this.field);
	this.compareOption = ko.observable();
	this.compareValue = ko.observable();

	this.search = ko.observable('');
	this.values = ko.observableArray(this.localCache[that.collection + '_' + data.field.id] || []);
	this.filteredValues = ko.computed(function () {
		var search = $.trim(that.search()).toLowerCase(),
			values = that.values();
		if (search == '') return values;

		return values.filter(function (r) {
			return r && r.name && ('' + r.name).toLowerCase().indexOf(search) != -1;
		});
	});
	this.hasValues = ko.computed(function () {
		var values = that.values();
		return values.length > 0;
	});
	this.canLoadData = data.field.editor != 'text' && ['date', 'datetime'].indexOf(data.field.kind) == -1;

	this.isBusy = ko.observable(false);
	this.canClear = ko.computed(function () {
		var filter = ko.utils.unwrapObservable(that.filter),
			selection = that.selection();
		return !$.isEmptyObject(filter) || selection.length > 0;
	});
	this.canFilter = ko
		.computed(function () {
			var compareOption = that.compareOption(),
				compareValue = that.compareValue(),
				selection = that.selection();

			if (!compareOption) return false;

			if (typeof compareOption.compareValue != undefined && !compareOption.list) {
				return $.trim(compareValue).length > 0 || compareOption.compareValue != null;
			} else if (compareOption.list) {
				return selection.length > 0;
			}
		})
		.extend({ throttle: 50 });

	this.compareValueKind = ['string', 'date', 'datetime', 'select'].indexOf(data.field.kind) != -1 ? 'text' : 'number';

	this.resetToDefaults = function () {
		var filter = ko.utils.unwrapObservable(that.filter);
		if (!$.isEmptyObject(filter)) {
			var compareOption = that.compareOptions.find(function (co) {
				return (
					JSON.stringify($.isArray(co.value) ? co.value : [co.value]) == JSON.stringify(Object.keys(filter))
				);
			});
			if (compareOption) {
				that.compareOption(compareOption);
				if (compareOption.parseValue) {
					that.compareValue(compareOption.parseValue(that.filter()));
				} else if (!compareOption.compareValue && !compareOption.list) {
					that.compareValue(filter[compareOption.value]);
				} else if (compareOption.list) {
					that.selection(filter[compareOption.value]);
				}
			}
		} else {
			that.compareOption(that.compareOptions[0]);
			that.compareValue(['date', 'datetime'].indexOf(data.field.kind) != -1 ? moment().toISOString() : null);
			that.search('');
			that.selection([]);
		}
	};
	this.resetToDefaults();

	this.buttonText = ko.observable(customFilterView.getButtonText(this.field, this.filter()));

	//Update button text when filter changes
	this.updateButtonText = ko.computed(function () {
		var filter = that.filter();

		// BUGFIX for some reason computed is getting triggered
		// by observables used in helper functions to to
		// bypass this we use a timeout...
		setTimeout(function () {
			that.resetToDefaults();
			that.buttonText(customFilterView.getButtonText(that.field, filter));
		}, 10);
	});

	this.sortValues = function (a, b) {
		var selectionIds = that.selection();
		var aSelected = selectionIds.indexOf(a._id) != -1;
		var bSelected = selectionIds.indexOf(b._id) != -1;

		var countSort = b.count - a.count;

		return (aSelected === bSelected ? 0 : aSelected ? -1 : 1) || countSort;
	};

	this.listName = data.listName;

	this.trackingSource = data.trackingSource;

	this.selectedFilters = data.selectedFilters;

	var pickerId = Math.random().toString(36).substring(2, 9);
	this.getUniqueId = (id) => {
		return `${pickerId}_${id}`;
	};
};

customFilterView.prototype.attached = function (v, p) {
	var that = this,
		$btn = $('.btn', p);

	this.hidePopover = function () {
		hidePopover($btn);
	};

	this.focusTextInput = function () {
		var popover = $btn.data('bs.popover');
		if (popover && popover.tip) {
			var $popover = popover.tip;
			var $textInput = $('.text-input, .search-input', $popover);
			if ($textInput.length > 0) {
				$textInput.get(0).focus();
			}
		}
	};

	this.bindSelectItems = debounce(function () {
		var popover = $btn.data('bs.popover');

		if (popover && popover.tip) {
			var $popover = popover.tip;

			$('[data-bind*="click: $root.clickedSelect"]', $popover)
				.off('click')
				.on('click', function (e) {
					if (e.target.type !== 'checkbox') {
						$(':checkbox', $(e.currentTarget)).trigger('click');
						return false;
					}
					return true;
				});
		}
	}, 50);

	this.bindDatePicker = function () {
		var popover = $btn.data('bs.popover');

		if (popover && popover.tip) {
			var $popover = popover.tip;

			$('.btn-datepicker', $popover)
				.daterangepicker(
					{
						parentEl: '.popover-custom-filter',
						startDate: that.compareValue() ? moment(that.compareValue()) : moment(),
						singleDatePicker: true,
						showDropdowns: true,
						autoApply: true,
					},
					function (start, end, label) {
						that.compareValue(start.toISOString());
					}
				)
				.on('outsideClick.daterangepicker', function (ev, picker) {
					// BUGFIX when closing picker by clicking on
					// trigger element
					setTimeout(function () {
						picker.isShowing = false;
					}, 250);
				});
		}
	};

	$btn.popover({
		html: true,
		animate: false,
		container: $(p).get(0),
		placement: function (context, src) {
			$(context).addClass('popover-custom-filter');

			$('.popover-arrow', context).remove();

			return 'bottom';
		},
		// FIX content call twice issue
		// https://github.com/twbs/bootstrap/issues/12563
		// https://stackoverflow.com/questions/21707400/bootstrap-popover-repeats-an-action-event-twice
		title: 'New Title',
		content: function () {
			var $div = $("<div style='width:244px;' />");

			that.getPopover().then(function (content) {
				$div.append(content);
			});

			return $div;
		},
	});

	$btn.on('show.bs.popover', function (e) {
		// Align popover to the top left of the button
		// Popover width / half of button width
		var popover = $(this).data('bs.popover');
		popover.config.offset = '8 ' + (-115 + $btn.width() / 2);

		// Check if filter values need to be loaded
		if (that.canLoadData) {
			if (!that.hasValues()) {
				that.loadData();
			} else {
				var values = that.values();
				values.sort(that.sortValues);
				that.values(values);
			}
		}

		// clear any user changes that were previously made but not applied
		that.resetToDefaults();
	});

	$btn.on('shown.bs.popover', function () {
		var $popover = $(this).data('bs.popover').tip;

		//BUGFIX knockock click bindings not working
		//http://stackoverflow.com/questions/21575754/knockout-unable-to-bind-to-a-buttons-click-event-when-using-bootstrap-popover
		$('[data-bind*="click: clickedFilter"]', $popover)
			.off('click')
			.on('click', function () {
				that.clickedFilter();
			});
		$('[data-bind*="click: clickedClear"]', $popover)
			.off('click')
			.on('click', function () {
				that.clickedClear();
			});
		$('[data-bind*="click: clickedRemove"]', $popover)
			.off('click')
			.on('click', function () {
				that.clickedRemove();
			});
		$('[data-bind*="click: $root.clickedChangeFilter"]', $popover)
			.off('click')
			.on('click', function (e) {
				that.clickedChangeFilter(that.compareOptions[$(e.target).index()]);
			});
		$('[data-bind*="textInput: compareValue"]', $popover)
			.off('input')
			.on('input', function (e) {
				that.compareValue(e.target.value);
			})
			.on('keydown', function (e) {
				if (e.keyCode == 13) {
					if (that.canFilter()) {
						that.clickedFilter();
					}
				}
			});
		$('[data-bind*="textInput: search"]', $popover)
			.off('input')
			.on('input', function (e) {
				that.search(e.target.value);
			});

		that.focusTextInput();
		that.bindDatePicker();

		// BUGFIX select item(s) not being applied everytime after re-opening filter
		var tempValues = that.values();
		that.values([]);
		that.values(tempValues);
	});

	$btn.on('hidden.bs.popover', function () {
		$('[data-tether-id]').remove();
	});

	// Close popover on click outside
	// http://stackoverflow.com/questions/11703093/how-to-dismiss-a-twitter-bootstrap-popover-by-clicking-outside
	$('body').on('click.customFilter', function (e) {
		$btn.each(function () {
			//the 'is' for buttons that trigger popups
			//the 'has' for icons within a button that triggers a popup
			if (
				e.target &&
				!$(this).is(e.target) &&
				$(this).has(e.target).length === 0 &&
				$('.popover').has(e.target).length === 0 &&
				$(".popover [class='" + e.target.className + "']").length === 0
			) {
				(($(this).popover('hide').data('bs.popover') || {})._activeTrigger || {}).click = false; // fix for BS 3.3.6
			}
		});
	});

	// Auto-focus text input if filter by manuel text
	ko.computed(function () {
		var compareOption = that.compareOption();
		that.focusTextInput();
		that.search('');
		that.bindDatePicker();
	}).extend({ throttle: 50 });
};

customFilterView.prototype.getPopover = function () {
	return ko.renderCustomTemplate('custom-filter-popover-template', CustomFilterPopoverTemplate, this);
};

// Implementation
// ----

customFilterView.getButtonText = function (field, filter) {
	var fieldName = field.name,
		compareOption,
		compareValue,
		selection;

	if (!$.isEmptyObject(filter)) {
		compareOption = customFilterView.getCompareOptions(field).find(function (co) {
			return JSON.stringify($.isArray(co.value) ? co.value : [co.value]) == JSON.stringify(Object.keys(filter));
		});
		if (compareOption) {
			if (compareOption.parseValue) {
				compareValue = compareOption.parseValue(filter);

				if (field.kind == 'datetime' || field.kind == 'date') {
					compareValue = moment(compareValue).format('D MMMM YYYY');
				}
			} else if (!compareOption.compareValue && !compareOption.list) {
				compareValue = filter[compareOption.value];

				if (field.kind == 'datetime' || field.kind == 'date') {
					compareValue = moment(compareValue).format('D MMMM YYYY');
				}
			} else if (compareOption.list) {
				selection = filter[compareOption.value] || [];
			}
		}
	}

	if (compareOption) {
		if (compareOption.list && selection.length > 0) {
			selection = selection.map(function (val) {
				return val + (field.unit ? ' ' + field.unit : '');
			});

			if (selection.length < 3) {
				return field.name + ' ' + compareOption.text.toLowerCase() + ' ' + selection.join(', ');
			} else {
				return (
					field.name +
					' ' +
					compareOption.text.toLowerCase() +
					' ' +
					selection[0] +
					' and ' +
					(selection.length - 1) +
					' more'
				);
			}
		}

		if (compareOption.compareValue != null) {
			return field.name + ' ' + compareOption.text.toLowerCase();
		}

		if (compareValue != null) {
			return (
				field.name +
				' ' +
				compareOption.text.toLowerCase() +
				' ' +
				compareValue +
				(field.unit ? ' ' + field.unit : '')
			);
		}
	}

	return 'Any ' + fieldName.toLowerCase();
};

customFilterView.getCompareOptions = function (field) {
	var fieldId = field.id.replaceAll('.', '__');

	var option = {};
	switch (field.kind) {
		case 'string':
		case 'select':
			var arr = [
				$.extend({ text: 'Starts with', value: fieldId + '__istartswith' }, option),
				$.extend({ text: 'Ends with', value: fieldId + '__iendswith' }, option),
				$.extend({ text: 'Is empty', value: fieldId + '__not__gt', compareValue: '' }, option),
				$.extend({ text: 'Is not empty', value: fieldId + '__gt', compareValue: '' }, option),
			];

			if (field.editor == 'text') {
				arr.splice(0, 0, $.extend({ text: 'Contains', value: fieldId + '__icontains' }, option));
			} else {
				arr.splice(0, 0, $.extend({ text: 'Is not', value: fieldId + '__nin', list: true }, option));
				arr.splice(0, 0, $.extend({ text: 'Is', value: fieldId + '__in', list: true }, option));
			}

			return arr;
		case 'number':
		case 'int':
		case 'float':
		case 'numberonly':
			return [
				$.extend({ text: 'Is', value: fieldId }, option),
				$.extend({ text: 'Greater than', value: fieldId + '__gt' }, option),
				$.extend({ text: 'Greater than or equal', value: fieldId + '__gte' }, option),
				$.extend({ text: 'Less than', value: fieldId + '__lt' }, option),
				$.extend({ text: 'Less than or equal', value: fieldId + '__lte' }, option),
			];
		case 'datetime':
		case 'date':
			return [
				$.extend(
					{ text: 'Is', value: [fieldId + '__gte', fieldId + '__lte'] },
					{
						parseValue: function (filter) {
							return Object.values(filter)[0];
						},
					},
					option
				),
				$.extend({ text: 'Is after', value: fieldId + '__gte' }, option),
				$.extend({ text: 'Is before', value: fieldId + '__lte' }, option),
				$.extend({ text: 'Is empty', value: fieldId, compareValue: 'null' }, option),
				$.extend({ text: 'Is not empty', value: fieldId + '__ne', compareValue: 'null' }, option),
			];
	}
};

customFilterView.forceClose = function () {
	const $btn = $('.btn');
	hidePopover($btn);
};

customFilterView.prototype.getCompareOptionsForField = function (field) {
	var that = this;

	return customFilterView.getCompareOptions(field).map(function (option) {
		if (['date', 'datetime'].indexOf(field.kind) != -1 && option.text == 'Is') {
			return $.extend(option, {
				getValue: function () {
					var value = {};

					value[this.value[0]] = moment(that.compareValue()).startOf('day').toISOString();
					value[this.value[1]] = moment(that.compareValue()).endOf('day').toISOString();

					return value;
				},
			});
		}

		return $.extend(option, {
			getValue: function () {
				var valuesDictionary = {};

				if (this.list) {
					valuesDictionary[this.value] = that.selection();
				} else {
					if (this.compareValue !== undefined) {
						valuesDictionary[this.value] = this.compareValue;
					} else {
						valuesDictionary[this.value] = that.compareValue();
					}

					if (['date', 'datetime'].indexOf(field.kind) != -1) {
						// The python backend expects 'null' string instead of null value when trying to query fields that are undefined
						// Sending null will filter out the value from the query itself instead of checking on its 'non-existence'
						const dateValue = valuesDictionary[this.value];
						valuesDictionary[this.value] = dateValue === 'null' ? 'null' : moment(dateValue).toISOString();
					} else if (['number', 'int', 'float', 'numberonly'].indexOf(field.kind) != -1) {
						const numberValue = valuesDictionary[this.value];
						valuesDictionary[this.value] = Number(numberValue);
					}
				}
				return valuesDictionary;
			},
		});
	});
};

customFilterView.prototype.loadData = function () {
	var that = this;

	if (that.field.kind == 'select') {
		var values = that.field.select.map(function (val) {
			return {
				_id: val,
				name: sanitizer(val),
				description: sanitizer(val),
			};
		});

		that.values(values);
		return;
	}

	that.isBusy(true);

	var fieldId = that.field.id.replaceAll('.', '__');

	var params = {
		summaryFields: that.field.id,
		summarySort: '-count',
		docs: false,
	};

	if (this.listName) {
		params.listName = this.listName;
	}

	return global
		.getDataSource(that.collection)
		.call('', 'getReport', params)
		.then(function (resp) {
			var values = resp.summary
				.filter(function (obj) {
					var value = obj._id[fieldId];

					// Exclude blank and empty values
					if (value === null) {
						return false;
					} else if (value == '') {
						return false;
					}

					return true;
				})
				.map(function (obj) {
					const fieldValue = obj._id[fieldId];
					const sanitized = sanitizer(fieldValue);

					if (typeof fieldValue === 'object') {
						return {
							_id: fieldValue._id,
							name: fieldValue.name,
							description: fieldValue.description,
							count: obj.count,
						};
					}

					return {
						_id:
							that.field.kind == 'datetime'
								? moment(new Date(fieldValue)).format('YYYY-MM-DD')
								: fieldValue,
						name:
							that.field.kind == 'datetime'
								? moment(new Date(sanitized)).format('DD/MM/YYYY')
								: sanitized + (that.field.unit ? ' ' + that.field.unit : ''),
						description:
							that.field.kind == 'datetime'
								? moment(new Date(sanitized)).format('D MMM YYYY')
								: sanitized,
						count: obj.count,
					};
				});

			that.localCache[that.collection + '_' + that.field.id] = values;

			values.sort(that.sortValues);
			that.values(values);
		})
		.finally(function () {
			that.isBusy(false);
		});
};

// Events
// ----
customFilterView.prototype.clickedClear = function () {
	var that = this;

	this.hidePopover();

	// flicker fix
	setTimeout(function () {
		that.compareOption(that.compareOptions[0]);
		that.compareValue(null);
		that.selection([]);

		if (that.filter()) {
			that.filter(null);
		}
	}, 10);
};

customFilterView.prototype.clickedFilter = function () {
	analytics.track('Filtered', this.trackingSource, { filterName: this.field.name });
	this.hidePopover();
	this.filter(this.compareOption().getValue());
};

customFilterView.prototype.clickedChangeFilter = function (filter) {
	this.compareOption(filter);
};

customFilterView.prototype.clickedSelect = function (e) {
	if (e.target.type !== 'checkbox' && e.target.type !== 'label') {
		$(':checkbox', $(e.currentTarget)).trigger('click');
		return false;
	}
	return true;
};

customFilterView.prototype.clickedRemove = function () {
	this.hidePopover();

	const newFilters = this.selectedFilters().filter((filterField) => filterField.id !== this.field.id);
	this.selectedFilters(newFilters);
};

const hidePopover = (btnElement) => {
	((btnElement.popover('hide').data('bs.popover') || {})._activeTrigger || {}).click = false; // fix for BS 3.3.6;
};

export default customFilterView;

import { FC, ReactNode, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export interface Props {
	/**
	 * Function that will be called to get the parent element
	 * that the portal will be attached to.
	 */
	target?: HTMLElement;
	children?: ReactNode;
}

export const Portal: FC<Props> = ({ children, target }) => {
	const [portal] = useState(() => document.createElement('aside'));

	useLayoutEffect(() => {
		const container = target || document.body;

		container.appendChild(portal);

		return () => {
			if (!portal) return;

			if (container?.contains(portal)) {
				container?.removeChild(portal);
			}
		};
	}, [target, portal]);

	return createPortal(children, portal);
};

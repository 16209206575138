import debounce from 'viewmodels/debounce';
import dialog from 'plugins/dialog.js';
import ko from 'knockout';
import scannerDetection from 'viewmodels/scanner-detection.js';
import common from 'viewmodels/common.js';

import View from 'views/contact-modal.html';
import CustomerFilterModel from 'viewmodels/custom-filter-view';
import analytics from '@cheqroom/web/src/services/analytics';

var subscriptions = [];

var ContactModal = function (spec = {}) {
	this.view = View;
	this.customerFilterModel = CustomerFilterModel;

	const searchCopy = 'Search users';

	var that = this;
	this.id = spec.id || 'contact-modal';
	this.app = spec.app;
	this.title = ko.observable(spec.title || searchCopy);
	this.global = spec.global;
	this.ds = spec.ds || spec.global.getDataSource('customers');
	that.params = spec.params || {};
	this.returnTo = spec.returnTo;
	this.onNew = spec.onNew || function () {};
	this.kind = spec.kind || 'contact';
	this.common = common;
	this.showAdd = spec.showAdd;

	this.isEmptyObject = $.isEmptyObject;

	this.search = ko.observable(spec.search || '');
	this.hasSearchFocus = ko.observable(false);
	this.contacts = ko.observable([]);
	this.isBusy = ko.observable(false);
	this.selected = ko.observable(-1);

	this.loadMore = ko.observable(false);
	this.isLoadingMore = ko.observable(false);

	this.showAdvancedFilters = ko.observable(true);
	this.showFilterButton = this.showOnlyUnbooked || this.showLocation || this.showFlags;
	this.selectedFilters = that.global.central.group.customerFields
		.slice(0)
		.map(function (field) {
			field.id = 'fields.' + field.name;
			return field;
		})
		.filter(function (field) {
			return field.search;
		});
	this.customFieldFilters = ko.observable({});
	this.hasFilters = ko.pureComputed(function () {
		var customFieldFilters = that.customFieldFilters();
		var isCustomFiltering = false;
		$.each(Object.keys(customFieldFilters), function (i, id) {
			var customFilter = ko.utils.unwrapObservable(customFieldFilters[id]);
			if (!$.isEmptyObject(customFilter)) {
				isCustomFiltering = true;
				return false;
			}
		});

		return isCustomFiltering;
	});

	// Show user contact?
	this.userContact = this.global.central.contact();
	this.showUserContact = spec.showUserContact != null ? spec.showUserContact : true;
	this.showOwnContact = ko.pureComputed(function () {
		var search = that.search();
		return (
			that.showUserContact &&
			that.kind == 'contact' &&
			$.trim(search).length == 0 &&
			that.userContact &&
			['active', 'blocked'].indexOf(that.userContact.status) != -1
		);
	});

	// Empty message

	// show isEmpty message when:
	// - not busy
	// - searchbox isn't empty
	// - results is 0
	// OR
	// - no contacts yet
	this.isEmpty = ko
		.computed(function () {
			return that.contacts() != null && that.contacts().length == 0;
		})
		.extend({ rateLimit: 50 });

	this._getEmptyMessage = ko
		.computed(function () {
			var search = that.search(),
				message = 'No ' + that.kind.pluralize() + ' found';

			if (search.length > 0) {
				message = that.kind.capitalize() + ' <strong>' + search.truncate() + '</strong> not found';
			}

			return message;
		})
		.extend({ throttle: 550 });

	var selectText = function () {
		var input = $('#contactModal form input').get(0);
		if (input) {
			input.select();
		}
	};

	/**
	 * Search implementation
	 * @param  {bool} allContacts    search for all contacts
	 */
	var allResults = [];
	this._contactSearch = function (allContacts, loadMore, isScannedContact) {
		var query = that.search(),
			showOwnContact = that.showOwnContact();

		var params = $.extend(
			{},
			{
				query: query,
				_limit: 5,
				_sort: 'name',
				listName: 'active', //Only show active contacts
			},
			that.params
		);

		// Add custom filter params
		var customFieldFilters = that.customFieldFilters();
		$.each(Object.keys(customFieldFilters), function (i, filter) {
			var customFilter = customFieldFilters[filter]();
			params = $.extend(params, customFilter);
		});

		if (showOwnContact && query.length == 0 && !that.hasFilters()) {
			params.pk__ne = that.userContact._id;
		}

		if (loadMore) {
			params._skip = allResults.length; //skip already shown contacts
			params._limit = 20;
			that.isLoadingMore(true);
		} else {
			that.contacts(null);
			that.selected(-1);
			allResults = [];
			that.isBusy(true);
		}

		// Cancel previous search?
		if (that.abortController) that.abortController.abort();

		that.abortController = new AbortController();

		that.ds
			.search(params, '*,user.picture,user.role', null, null, null, null, {
				abortController: that.abortController,
			})
			.then(function (resp) {
				var docs = resp.docs || [];

				if (resp.count == 0) {
					allResults = [];

					if (isScannedContact) {
						selectText();
					}
				} else {
					if (!isScannedContact) {
						if (showOwnContact && query.length == 0 && !that.hasFilters()) {
							docs.unshift(that.userContact);
						}

						allResults = allResults.concat(docs);
					} else {
						var contact = docs[0];
						if (contact) {
							that.close(contact);
						}
						return;
					}
				}

				that.contacts(allResults);
				that.loadMore(resp.count > that.contacts().length);
				that.isBusy(false);

				var activeContactIdx = docs.findIndex(function (d) {
					return d.status == 'active';
				});
				if (activeContactIdx != -1) {
					// Select the 1st active contact by default
					that.selected(activeContactIdx);
				}
			}, that.global.onError)
			.finally(function () {
				that.isLoadingMore(false);
				that.isBusy(false);
			});
	};

	this._contactSearchThrottled = debounce(this._contactSearch, 300);

	// Trigger search when input changes
	subscriptions.push(
		this.search.subscribe(function () {
			that.isBusy(true); //need to set it immediatelly other we could see empty message
			that._contactSearchThrottled();
		})
	);
	subscriptions.push(
		this.showAdvancedFilters.subscribe(function () {
			that.global.storageHandler.save(that);
		})
	);

	var temp = {};
	$.each(this.selectedFilters, function (i, filter) {
		var filterId = filter.id;
		temp[filterId] = ko.observable();

		subscriptions.push(
			temp[filterId].subscribe(function (val) {
				that.isBusy(true);

				that.global.storageHandler.save(that);

				that._contactSearchThrottled();
			})
		);
	});
	this.customFieldFilters(temp);

	// Instead of a prototype function
	// we place it here because "e" param will not contain the VM,
	// so we need "that"
	this._handleKeyboard = function (e) {
		var selected = that.selected();
		var contacts = that.contacts() || [];

		var dfdScanCheck = scannerDetection.isScanInput(e);

		switch (e.keyCode) {
			case 13: // ENTER
				dfdScanCheck.then(function (isScanned) {
					if (isScanned) {
						analytics.track('Code Scanned', 'User Modal');
						// Pass true to let method know it is a scanner search
						that._contactSearchThrottled(false, false, true);
					} else {
						if (selected >= 0) {
							var contact = contacts[selected];
							if (contact) {
								that.close(contact);
							}
						}
					}
				});
				break;
			case 38: // UP
				if (selected >= 0) {
					var getPrevActive = function (idx) {
						if (idx >= 0) {
							var isActive = contacts[idx].status == 'active';

							if (isActive) {
								return idx;
							} else {
								return getPrevActive(idx - 1);
							}
						} else {
							return getPrevActive(contacts.length - 1);
						}
					};
					that.selected(getPrevActive(selected - 1));
				}
				break;
			case 40: // DOWN
				if (selected <= contacts.length - 1) {
					var getNextActive = function (idx) {
						if (idx <= contacts.length - 1) {
							var isActive = contacts[idx].status == 'active';

							if (isActive) {
								return idx;
							} else {
								return getNextActive(idx + 1);
							}
						} else {
							return 0;
						}
					};
					that.selected(getNextActive(selected + 1));
				}
				break;
		}
	};

	that.global.storageHandler.load(this);
};

// Durandal events
// ----
ContactModal.show = function (spec) {
	return dialog.showBootstrapDialog(new ContactModal(spec));
};

ContactModal.prototype.compositionComplete = function (v, p) {
	var that = this;

	// Show first 5 contacts
	this._contactSearch(true);

	// This event is fired when the modal has been made visible to the user
	// http://stackoverflow.com/questions/22547472/how-can-i-get-auto-focus-to-an-input-element-in-a-dynamically-generated-knockout
	$(p)
		.on('shown.bs.modal', function (e) {
			// Set focus to search input
			that.hasSearchFocus(true);
		})
		.on('hidden.bs.modal', function (e) {
			that.close();
		});

	// Bind keyboard events
	$(document).on('keydown.contactModal', this._handleKeyboard);
};

ContactModal.prototype.close = function (contact) {
	// Unbind keyboard events
	$(document).off('keydown.contactModal');

	//Clean up subscriptions
	for (var i = 0; i < subscriptions.length; i++) {
		subscriptions[i].dispose();
	}

	// Cancel search call
	if (this.abortController) this.abortController.abort();

	dialog.close(this, contact);
};

ContactModal.prototype._getFullId = function () {
	return this.id;
};

ContactModal.prototype._toStorageData = function () {
	var that = this,
		data = {};

	var selectedFilters = that.selectedFilters,
		customFieldFilters = that.customFieldFilters(),
		temp = {};

	$.each(selectedFilters, function (i, filter) {
		var filterId = typeof filter !== 'string' ? filter.id : filter;
		temp[filterId] = customFieldFilters[filterId]() || {};
	});

	data.showAdvancedFilters = this.showAdvancedFilters();
	data.customFieldFilters = temp;

	return Promise.resolve(data);
};

ContactModal.prototype._fromStorageData = function (data) {
	var that = this;

	if (data.showAdvancedFilters != undefined) {
		this.showAdvancedFilters(data.showAdvancedFilters);
	}

	if (data.hasOwnProperty('customFieldFilters')) {
		var selectedFilters = that.selectedFilters;
		$.each(selectedFilters, function (i, filter) {
			var filterId = typeof filter === 'string' ? filter : filter.id;

			var customFieldFilter = that.customFieldFilters()[filterId];
			if (!customFieldFilter) {
				customFieldFilter = that.customFieldFilters()[filterId] = ko.observable();
			}
			customFieldFilter.silentUpdate(data.customFieldFilters[filterId]);
		});
	}

	return Promise.resolve();
};

// UI events
// ----
ContactModal.prototype.clickedClose = function () {
	this.close();
};

ContactModal.prototype.clickedContact = function (contact) {
	if (contact.status == 'active') {
		this.close(contact);
	}
};

ContactModal.prototype.clickedCreate = function (name) {
	analytics.track('User Adding', 'New User Page');

	app.router.navigate('/users/new?name=' + name + (this.returnTo ? '&returnTo=' + this.returnTo : ''));

	if (typeof this.onNew === 'function') this.onNew();

	this.close();
};

ContactModal.prototype.clickedLoadMore = function () {
	this._contactSearch(false, true);
};

ContactModal.prototype.clickedClearSearchFilter = function () {
	this.search('');
	this._contactSearch(false);
};

ContactModal.prototype.clickedClearCustomFilter = function (filter) {
	var customFieldFilters = this.customFieldFilters() || {};
	var customFilter = customFieldFilters[filter.id];
	if (customFilter) {
		customFilter(null);
	}
};

export default ContactModal;

import ko from 'knockout';
import kov from 'knockout-validation';
import cr from '@cheqroom/core';

// http://stackoverflow.com/questions/10745486/automatically-trim-whitespace-from-all-observable-values
ko.subscribable.fn.trimmed = function () {
	return ko.computed({
		read: function () {
			if (typeof this() != 'string') return this();

			return this().trim();
		},
		write: function (value) {
			this(typeof value == 'string' ? value.trim() : value);
			this.valueHasMutated();
		},
		owner: this,
	});
};

ko.validation.rules['url'] = {
	validator: function (val, required) {
		if (!val) {
			return !required;
		}
		val = val.replace(/^\s+|\s+$/, ''); //Strip whitespace

		return cr.common.isValidURL(val);
	},
	message: 'This field has to be a valid URL',
};
ko.validation.registerExtenders();

export default {
	mixin: function (webhook, options) {
		var orig_fromJson = webhook._fromJson,
			orig_reset = webhook.reset;

		// Core method overrides
		// ----
		webhook._fromJson = function (data, opt) {
			// Use apply to call function so we can pass the
			// Location context otherwise it would use Window context
			return orig_fromJson.apply(webhook, [data, opt]).then(function () {
				webhook.oId(webhook.id);
				webhook.oName(webhook.name);
				webhook.oAddress(webhook.address);
				webhook.oTopic(webhook.topic);
				webhook.oFields(webhook.hookFields);
				webhook.oEnabled(webhook.enabled);
				webhook.oMinutes(webhook.minutes);

				return data;
			});
		};

		webhook.reset = function () {
			// Use apply to call function so we can pass the
			// Location context otherwise it would use Window context
			orig_reset.apply(webhook);

			//Reset observables to defaults
			webhook.oId(webhook.id);
			webhook.oName(webhook.name);
			webhook.oAddress(webhook.address);
			webhook.oTopic(webhook.topic);
			webhook.oFields(webhook.hookFields);
			webhook.oEnabled(webhook.enabled);

			var data = webhook.oData();
			data.name.isModified(false);
		};

		// Knockout validation rules
		// ----

		// Observables
		// ----
		webhook.canSave = ko.observable(false);
		webhook.oId = ko.observable(webhook.id || '');
		webhook.oName = ko.observable(webhook.name || '');
		webhook.oAddress = ko.observable(webhook.address || '');
		webhook.oTopic = ko.observable(webhook.topic || '');
		webhook.oFields = ko.observable(webhook.fields || '');
		webhook.oEnabled = ko.observable(webhook.enabled || '');

		webhook.oData = ko.validatedObservable({
			name: webhook.oName.trimmed().extend({ required: true, minLength: 3 }),
			address: webhook.oAddress.extend({ required: { message: 'URL is a required field' }, url: true }),
			topic: webhook.oTopic.extend({ required: true }),
			fields: webhook.oFields,
			enabled: webhook.oEnabled,
		});
		webhook.oMinutes = ko.observable(webhook.minutes);

		// Computables
		// ----
		webhook._updateCanSave = function () {
			var isValid = webhook.isValid() && webhook.oData.isValid(),
				isDirty = webhook.isDirty();
			webhook.canSave(isValid && isDirty);
		};

		webhook._updateName = ko
			.computed(function () {
				webhook.name = webhook.oName();
				webhook._updateCanSave();
			})
			.extend({ throttle: 250 });

		webhook._updateAddress = ko
			.computed(function () {
				webhook.address = webhook.oAddress();
				webhook._updateCanSave();
			})
			.extend({ throttle: 250 });

		webhook._updateEnabled = ko
			.computed(function () {
				webhook.enabled = webhook.oEnabled();
				webhook._updateCanSave();
			})
			.extend({ throttle: 250 });

		webhook._updateTopic = ko
			.computed(function () {
				webhook.topic = webhook.oTopic();

				// Reset hours if not due or overdue topic
				if (
					['order.due', 'order.overdue', 'reservation.due', 'reservation.overdue'].indexOf(webhook.topic) ==
					-1
				) {
					webhook.oMinutes(0);
				}

				webhook._updateCanSave();
			})
			.extend({ throttle: 250 });

		webhook._updateEnabled = ko
			.computed(function () {
				webhook.enabled = webhook.oEnabled();
				webhook._updateCanSave();
			})
			.extend({ throttle: 250 });

		webhook._updateMinutes = ko
			.computed(function () {
				webhook.minutes = webhook.oMinutes();
				webhook._updateCanSave();
			})
			.extend({ throttle: 250 });
	},
};

import app from 'durandal/app.js';
import ko from 'knockout';
import kov from 'knockout-validation';
import moment from 'moment';

export default {
	mixin: function (checkout, options) {
		var orig_fromJson = checkout._fromJson,
			orig_reset = checkout.reset,
			orig_canAddItems = checkout.canAddItems,
			orig_canRemoveItems = checkout.canRemoveItems,
			orig_canSwapItems = checkout.canSwapItems,
			orig_checkin = checkout.checkin,
			orig_checkout = checkout.checkout,
			orig_undoCheckout = checkout.undoCheckout,
			orig_addItems = checkout.addItems,
			orig_removeItems = checkout.removeItems,
			orig_delete = checkout.delete,
			orig_getConflictsForExtend = checkout._getConflictsForExtend,
			orig_clearLocation = checkout.clearLocation,
			orig_setLocation = checkout.setLocation;

		var global = options.global,
			perm = global.getPermissionHandler();

		// Core method overrides
		// ----
		checkout._fromJson = function (data, options) {
			//Use apply to call function so we can pass the
			//Base context otherwise it would use Window context
			return orig_fromJson.apply(checkout, [data, options]).then(function () {
				checkout.oFrom(
					checkout.status == 'creating'
						? checkout.getMinDateFrom()
						: checkout._getDateHelper().roundTimeFrom(checkout.from)
				);
				checkout.oDue(checkout.due);
				checkout.oTo(data.finished);
				checkout.oLabel(global.central._getLabelById('orderLabels', checkout.label));

				return data;
			});
		};

		checkout.reset = async function () {
			//Use apply to call function so we can pass the
			//Base context otherwise it would use Window context
			await orig_reset.apply(checkout);

			//Reset obsersables to defaults
			checkout.oFrom(checkout.getMinDateFrom());
			checkout.oDue(null);
			checkout.oTo(null);
			checkout.oDueDateError(null);

			checkout.isExtending(false);
		};

		checkout.addItems = function (items) {
			return orig_addItems.apply(checkout, arguments).then(function (resp) {
				app.trigger('items:changed', items);

				return resp;
			});
		};

		checkout.removeItems = function (items) {
			return orig_removeItems.apply(checkout, arguments).then(function (resp) {
				app.trigger('items:changed', items);

				return resp;
			});
		};

		checkout.delete = function () {
			var items = checkout.oItems();

			return orig_delete.apply(checkout, arguments).then(function (resp) {
				// Only trigger if deleted checkout had items
				if (items.length > 0) {
					app.trigger('items:changed', items);
				}

				return resp;
			});
		};

		checkout.checkin = function (items, loc) {
			var itemIds = global.common.getItemIds(items);
			var locationId = global.helper.ensureId(loc);

			return orig_checkin.apply(checkout, [items, locationId, null, null]).then(function () {
				checkout._triggerAction(':changed');
				app.trigger('items:changed');
				app.trigger('kits:changed');
			});
		};

		checkout.undoCheckout = function () {
			return orig_undoCheckout.apply(checkout).then(function () {
				checkout._triggerAction(':changed');
				app.trigger('items:changed');
				app.trigger('kits:changed');
			});
		};

		checkout.checkout = function () {
			return orig_checkout.apply(checkout).then(function (resp) {
				checkout._triggerAction(':changed');
				app.trigger('items:changed');
				app.trigger('kits:changed');

				checkout.oNumber(resp.number);

				return resp;
			});
		};

		//Override CoreJS method
		checkout.canUndoArchive = () => {
			return global.getDataSource('orders').call(checkout.id, 'canUndoArchive');
		};

		checkout.clearLocation = () => {
			if (checkout.oDue()) {
				checkout.onClearDueDate();
				checkout.oDue(null);
			}
			return orig_clearLocation.apply(checkout);
		};

		checkout.setLocation = (location) => {
			if (checkout.oDue()) {
				checkout.onClearDueDate();
				checkout.oDue(null);
			}
			return orig_setLocation.apply(checkout, [location]);
		};

		checkout.onDateChanged = function () {
			var from = checkout.getMinDateFrom();
			var due = checkout.oDue();

			// If due date changes on an open checkout, then start extend mode
			if (checkout.oStatus() == 'open') {
				if (checkout._isDirtyDates()) {
					checkout.startExtend();
				}
			} else {
				checkout.oFrom(from);
				checkout.isBusy(true);
				checkout
					.setDueDate(due, true)
					.then(function () {
						checkout._getConflicts();

						checkout._triggerAction(':changed');
					})
					.finally(function () {
						checkout.isBusy(false);
					});
			}
		};

		checkout.onClearDueDate = function () {
			checkout.isBusy(true);
			checkout
				.clearDueDate(true)
				.then(function () {
					checkout._getConflicts();

					checkout._triggerAction(':changed');

					checkout.oDueDateError('No to date set');
				})
				.finally(function () {
					checkout.isBusy(false);
				});
		};

		checkout._getConflictsForExtend = function () {
			// BUGFIX cannot be executed before _fromJson has completetly run
			setTimeout(function () {
				// Abort previous conflicts call
				if (checkout.abortConflictsController) checkout.abortConflictsController.abort();

				checkout.isBusyConflicts(true);

				orig_getConflictsForExtend
					.apply(checkout, arguments)
					.then(function (conflicts) {
						checkout.oConflicts(conflicts);
					})
					.finally(function () {
						checkout.isBusyConflicts(false);
					});
			}, 10);

			return Promise.resolve([]);
		};

		checkout.startExtend = function () {
			checkout.isExtending(true);

			//check for conflicts
			checkout._getConflictsForExtend();
		};

		checkout.confirmExtend = function () {
			return checkout.extend(checkout.oDue(), true).then(function (data) {
				// clear conflicts
				checkout.oConflicts([]);

				// Update raw data so _isDirtyDates works
				// correctly
				checkout.raw.due = data.due;

				checkout.isExtending(false);

				checkout._triggerAction(':changed');
			});
		};

		checkout.cancelExtend = function () {
			checkout.isExtending(false);
			checkout.oConflicts([]);

			//Reset due date
			//BUGFIX need to create another moment of due date because it
			//can be different than what it needs to be
			checkout.oDue(moment(checkout.raw.due._i));
		};

		checkout._isDirtyDates = function () {
			if (checkout.raw) {
				var due = checkout.raw.due || moment();
				return !checkout.due.isSame(due);
			} else {
				return false;
			}
		};

		// Observables
		// ----
		checkout.oFrom = ko.observable(checkout.getMinDateFrom());
		checkout.oDue = ko.observable(null);
		checkout.oDueDateError = ko.observable(null).extend({ rateLimit: 1 });

		checkout.oTo = ko.observable(null);
		checkout.isExtending = ko.observable(false);

		// Computables
		// ----
		checkout._updateFrom = ko.computed(function () {
			checkout.from = checkout.oFrom();
		});
		checkout._updateDue = ko.computed(function () {
			checkout.due = checkout.oDue();
		});

		checkout.isValidDueDate = ko.computed(function () {
			var due = checkout.oDue(),
				status = checkout.oStatus();

			return checkout.oDueDateError() === null;
		});

		checkout.canAddItems = ko.computed(function () {
			var status = checkout.oStatus();
			return (
				perm.hasCheckoutPermission('addItems', { own: checkout.isOwn() }) && orig_canAddItems.apply(checkout)
			);
		});
		checkout.canSwapItems = ko.computed(function () {
			var status = checkout.oStatus();
			return (
				perm.hasCheckoutPermission('swapItems', { own: checkout.isOwn() }) && orig_canSwapItems.apply(checkout)
			);
		});
		checkout.canRemoveItems = ko.computed(function () {
			var status = checkout.oStatus();
			return (
				perm.hasCheckoutPermission('removeItems', { own: checkout.isOwn() }) &&
				orig_canRemoveItems.apply(checkout)
			);
		});
		checkout.friendlyDuration = ko.pureComputed(function () {
			var oDue = checkout.oDue();

			if (checkout.from != null) {
				var to = checkout.oStatus() == 'closed' ? checkout.to : checkout.due;
				if (to) {
					return moment.duration(to - checkout.from).humanize();
				}
			}
			return null;
		});
	},
};

/**
 * en-us language values
 * @type {Object}
 */
export default {
	general: {
		close: 'Close',
		cancel: 'Cancel',
		yes: 'Yes',
		no: 'No',
		sure: 'Are you sure?',
		undone: 'This cannot be undone',
		something_went_wrong: 'Something went wrong',
		newest: 'Newest',
		created: 'Created',
		oldest: 'Oldest',
		modified: 'Modified',
		or: 'or',
		import_from_csv: 'Import from csv',
		export_to_csv: 'Export to csv',
		empty_with_text: 'with text {{text}}',
		at_location: 'at {{location}}',
		sort: 'Sort',
		edit: 'Edit',
		delete: 'Delete',
		save: 'Save',
		update: 'Update',
		unknown: 'Unknown',
		unsaved_confirm_title: 'Unsaved data',
		unsaved_confirm_message: 'Do you want to continue without saving these settings?',
		add: 'Add',
		add_another: 'Add another',
		duplicate: 'Duplicate',
		generating_pdf: 'Generating PDF &mdash; this can take a moment...',
		generated_pdf: 'Generated PDF {{ name }}',
		deleted_user: 'Deleted user',
	},
	getting_started: {
		title: 'Getting started',
	},
	settings: {
		reservations: 'Reservations',
		reservationsText: 'Booking equipment in advance to contacts',
		custody: 'Custody',
		custodyText: 'Giving equipment to contacts for a long term',
		selfService: 'Self service',
		selfServiceText: 'Allow selfservice users to make reservations / check-outs for themselves',
		selfServiceCanReserve: 'Self service reservations',
		selfServiceCanReserveText: 'Self service users can make reservations',
		selfServiceCanOrder: 'Self service check-outs',
		selfServiceCanOrderText: 'Self service users can check-out equipment',
	},
	comments: {
		title: 'Comments',
		title_new: 'Add new comment',
		empty: 'There are no comments yet',
		placeholder: 'Write a comment...',
		created: 'Added comment',
		updated: 'Updated comment',
		deleted: 'Deleted comment',
	},
	attachments: {
		title: 'Attachments',
		title_new: 'Add new attachment',
		empty: 'There are no attachments yet',
		make_cover: 'Make cover',
		upload: 'Upload or drop a file here',
		dropzone: 'Drop files to upload',
		attached: 'Added attachment',
		deleted: 'Deleted attachment',
		made_cover: 'Updated cover image',
		invalid_extension: 'Invalid file extension',
		file_size_limit: 'File must be less than {{ size }} {{ unit }}',
		image_size_limit: 'Image must be less than {{ size }} {{ unit }}',
	},
	locations: {
		filter_all: 'All locations',
		title: 'Locations',
		create: 'Create location',
		add: 'Add location',
		new: 'New location',
		edit: 'Edit location',
		update: 'Update location',
		delete: 'Delete location',
		name: 'Name',
		address: 'Address',
		created: 'Location {{name}} created',
		updated: 'Location {{name}} updated',
		deleted: 'Location deleted',
		name_exists: 'A location with that name already exists',
	},
	templates: {
		title: 'Templates',
		create: 'Create template',
		add: 'Add template',
		new: 'New template',
		edit: 'Edit template',
		update: 'Update template',
		created: 'Template {{name}} created',
		updated: 'Template {{name}} updated',
		deleted: 'Template {{name}} deleted',
		activated: 'Template {{name}} activated',
		deactivated: 'Template {{name}} deactivated',
		archived: 'Template {{name}} archived',
		unarchived: 'Template {{name}} unarchived',
	},
	notifications: {
		title: 'Notifications',
		new: 'New notification',
		add: 'Add notification',
		edit: 'Edit notification',
		deleted: 'Notification {{name}} deleted',
	},
	webhooks: {
		title: 'Webhooks',
		create: 'Create webhook',
		add: 'Add webhook',
		new: 'New webhook',
		edit: 'Edit webhook',
		update: 'Update webhook',
		delete: 'Delete webhook',
		created: 'Webhook {{name}} created',
		updated: 'Webhook {{name}} updated',
		deleted: 'Webhook {{name}} deleted',
		name_exists: 'A webhook with that name already exists',
	},
	items: {
		fields: 'Item fields',
		fields_new: 'New item field',
		fields_blank: 'You have no item fields yet',
		fields_exists: 'An item field with that name already exists',
		blank: "You haven't created any items yet",
		blank_no_new: 'No items have been created yet',
		blank_no_fields: 'No item fields have been created yet',
		new: 'New item',
		import: 'Import items',
		created: 'Added new item {{name}}',
		created_multiple: 'Added {{times}} new item{{#pluralize}}s{{/pluralize}}',
		filter_all_name: 'All',
		filter_all_title: 'All items',
		filter_all_empty: 'No items found',
		filter_available_name: 'Available',
		filter_available_title: 'Available items',
		filter_available_empty: 'No available items found',
		filter_checkedout_name: 'Checked out',
		filter_checkedout_title: 'Checked out items',
		filter_checkedout_empty: 'No checked out items found',
		filter_checkingout_name: 'Checking out',
		filter_checkingout_title: 'Checking out items',
		filter_checkingout_empty: 'No items checking items found',
		filter_custody_name: 'In custody',
		filter_custody_title: 'Items in custody',
		filter_custody_empty: 'No items in custody found',
		filter_expired_name: 'Retired',
		filter_expired_title: 'Retired items',
		filter_expired_empty: 'No retired items found',
		filter_inwarranty_name: 'In warranty',
		filter_inwarranty_title: 'Items in warranty',
		filter_inwarranty_empty: 'No items in warranty found',
		filter_outofwarranty_name: 'Out of warranty',
		filter_outofwarranty_title: 'Items out of warranty',
		filter_outofwarranty_empty: 'No items out of warranty found',
		flag_set: 'Flag set to {{flag}}',
		flag_cleared: 'Flag cleared',
		location_updated: 'Location set to {{location}}',

		last_known_location: 'Last known geo position',
		set_to_current: 'Set to current',
		set_to_address: 'Set to address...',
		map: 'Map',
		location_updated_message: 'Geo position updated to {{address}}',
		address_modal_title: 'Set geo position to address',
		address_invalid: 'Please enter a valid address',
		address_not_found: 'Could not locate this address',
		address_placeholder: 'Enter an address',

		subscription_limit:
			'Your subscription is limited to {{max}} items. You can still create up to {{left}} item{{#pluralize}}s{{/pluralize}}.',

		depreciation: 'Depreciation',

		button_reserve: 'Reserve',
		button_checkout: 'Check out',
		button_go_to_checkout: 'Go to check-out',
		button_take_custody: 'Take custody',
		button_give_custody: 'Give custody...',
		button_release_custody: 'Release custody',
		button_add_to_kit: 'Add to kit...',
		button_remove_from_kit: 'Remove from kit',
		button_add_new: 'Add new',
		button_duplicate: 'Duplicate...',
		button_expire: 'Retired',
		button_undo_expire: 'Undo retire',

		duplicate_title: 'Duplicate item?',
		duplicate: 'Duplicate item',
	},
	kits: {
		blank: "You haven't created any kits yet",
		blank_no_new: 'No kits have been created yet',
		blank_no_fields: 'No kit fields have been created yet',
		new: 'New kit',
		created: 'Added new kit {{name}}',
		created_multiple: 'Added {{times}} new kit{{#pluralize}}s{{/pluralize}}',
		filter_all_name: 'All',
		filter_all_title: 'All kits',
		filter_all_empty: 'No kits found',
		filter_available_name: 'Available',
		filter_available_title: 'Available kits',
		filter_available_empty: 'No available kits found',
		filter_checkedout_name: 'Checked out',
		filter_checkedout_title: 'Checked out kits',
		filter_checkedout_empty: 'No checked out kits found',
		filter_checkingout_name: 'Checking out',
		filter_checkingout_title: 'Checking out kits',
		filter_checkingout_empty: 'No kits checking out found',
		filter_custody_name: 'In custody',
		filter_custody_title: 'Kits in custody',
		filter_custody_empty: 'No kits in custody found',
		filter_expired_name: 'Retired',
		filter_expired_title: 'Retired kits',
		filter_expired_empty: 'No retired kits found',
		filter_incomplete_name: 'Incomplete',
		filter_incomplete_title: 'Incomplete kits',
		filter_incomplete_empty: 'No incomplete kits found',
		button_duplicate: 'Duplicate...',

		duplicate_title: 'Duplicate kit?',
		duplicate: 'Duplicate kit',
	},
	reservations: {
		blank: 'No reservations have been created yet',
		blank_no_new: 'No reservations have been created yet',
		blank_no_fields: 'No reservation fields have been created yet',
		new: 'New reservation',
		filter_all_name: 'All',
		filter_all_title: 'All reservations',
		filter_all_empty: 'No reservations found',
		filter_open_name: 'Booked',
		filter_open_title: 'Booked reservations',
		filter_open_empty: 'No booked reservations found',
		filter_incomplete_name: 'Draft',
		filter_incomplete_title: 'Draft reservations',
		filter_incomplete_empty: 'No draft reservations found',
		filter_converted_name: 'Converted',
		filter_converted_title: 'Converted reservations',
		filter_converted_empty: 'No converted reservations found',
		filter_closed_name: 'Closed',
		filter_closed_title: 'Closed reservations',
		filter_closed_empty: 'No closed reservations found',
		filter_overdue_name: 'Overdue',
		filter_overdue_title: 'Overdue reservations',
		filter_overdue_empty: 'No overdue reservations found',
		filter_cancelled_name: 'Cancelled',
		filter_cancelled_title: 'Cancelled reservations',
		filter_cancelled_empty: 'No cancelled reservations found',
		filter_archived_name: 'Archived',
		filter_archived_title: 'Archived reservations',
		filter_archived_empty: 'No archived reservations found',
		filter_upcoming_name: 'Upcoming',
		filter_upcoming_title: 'Upcoming reservations',
		filter_upcoming_empty: 'No upcoming reservations found',
		filter_today_name: 'Today',
		filter_today_title: 'Upcoming reservations today',
		filter_today_empty: 'No upcoming reservations for today found',
		filter_maintenance_name: 'Maintenance',
		filter_maintenance_title: 'Maintenance reservations',
		filter_maintenance_empty: 'No maintenance reservations found',
	},
	check_outs: {
		blank: 'No check-outs have been created yet',
		blank_no_new: 'No check-outs have been created yet',
		blank_no_fields: 'No check-out fields have been created yet',
		new: 'New check-out',
		filter_all_name: 'All',
		filter_all_title: 'All check-outs',
		filter_all_empty: 'No check-outs found',
		filter_open_name: 'Open',
		filter_open_title: 'Open check-outs',
		filter_open_empty: 'No open check-outs found',
		filter_upcoming_name: 'Upcoming',
		filter_upcoming_title: 'Upcoming check-outs',
		filter_upcoming_empty: 'No upcoming check-outs found',
		filter_today_name: 'Today',
		filter_today_title: 'Upcoming check-outs today',
		filter_today_empty: 'No upcoming check-outs for today found',
		filter_incomplete_name: 'Draft',
		filter_incomplete_title: 'Draft check-outs',
		filter_incomplete_empty: 'No draft check-outs found',
		filter_closed_name: 'Completed',
		filter_closed_title: 'Completed check-outs',
		filter_closed_empty: 'No completed check-outs found',
		filter_overdue_name: 'Overdue',
		filter_overdue_title: 'Overdue check-outs',
		filter_overdue_empty: 'No overdue check-outs found',
		filter_archived_name: 'Archived',
		filter_archived_title: 'Archived check-outs',
		filter_archived_empty: 'No archived check-outs found',
		filter_maintenance_name: 'Maintenance',
		filter_maintenance_title: 'Maintenance check-outs',
		filter_maintenance_empty: 'No maintenance check-outs found',
	},
	contacts: {
		new: 'New contact',
		blank_no_fields: 'No contact fields have been created yet',
		created: 'Added new contact {{name}}',
	},
	users: {
		new: 'New user',
		blank_no_fields: 'No user fields have been created yet',
		created: 'Added new user {{name}}',
		activated_user: 'Activated {{name}}',
		activated_users: 'Activated {{num}} users',
		deactivated_user: 'Deactivated {{name}}',
		deactivated_users: 'Deactivated {{num}} users',
		archived_user: 'Archived {{name}}',
		archived_users: 'Archived {{num}} users',
		unarchived_user: 'Unarchived {{name}}',
		unarchived_users: 'Unarchived {{num}} users',
	},
	navigation: {
		navigation: 'Navigation',
		dashboard: 'Dashboard',
		calendar: 'Calendar',
		items: 'Items',
		item: 'Item',
		item_new: 'New item',
		kits: 'Kits',
		kit: 'Kit',
		kit_new: 'New kit',
		contacts: 'Contacts',
		contact: 'Contact',
		contact_new: 'New contact',
		user: 'User',
		user_new: 'New user',
		reservations: 'Reservations',
		reservation: 'Reservation',
		reservation_new: 'New reservation',
		checkouts: 'Check-outs',
		checkout: 'Check-out',
		checkout_new: 'New check-out',
		settings: 'Settings',
		fields: 'Fields',
		categories: 'Categories',
		account: 'Account',
		//stickers: "Stickers",
		asset_tags: 'Buy asset tags',
		administration: 'Administration',
		locations: 'Locations',
		webhooks: 'Webhooks',
		notifications: 'Notifications',
		users: 'Users',
		import: 'Import',
		import_items: 'Import items',
		import_contacts: 'Import contacts',
		profile: 'Profile',
		help: 'Help Center',
		log_out: 'Log out',
	},
	history: {
		title: 'History',
	},
};

import dialog from 'plugins/dialog.js';
import TimezoneModalView from 'views/timezone-modal.html';

var TimeZoneModal = function (spec = {}) {
	this.view = TimezoneModalView;

	this.computerTimezone = spec.computerTimezone || '';
	this.profileTimezone = spec.profileTimezone || '';

	this.modalOptions = {
		backdrop: 'static',
		keyboard: false,
	};
};

// Durandal events
// ----
TimeZoneModal.show = function (spec) {
	return dialog.showBootstrapDialog(new TimeZoneModal(spec));
};

TimeZoneModal.prototype.close = function () {
	dialog.close(this);
};

// UI events
// ----
TimeZoneModal.prototype.clickedOk = function () {
	this.close();
};

export default TimeZoneModal;

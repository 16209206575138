import { FC } from 'react';

export const Categories: FC = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V7C16 8.10457 15.1046 9 14 9H13V11H16C17.1046 11 18 11.8954 18 13V15H19C20.1046 15 21 15.8954 21 17V20C21 21.1046 20.1046 22 19 22H15C13.8954 22 13 21.1046 13 20V17C13 15.8954 13.8954 15 15 15H16V13H8V15H9C10.1046 15 11 15.8954 11 17V20C11 21.1046 10.1046 22 9 22H5C3.89543 22 3 21.1046 3 20V17C3 15.8954 3.89543 15 5 15H6V13C6 11.8954 6.89543 11 8 11H11V9H10C8.89543 9 8 8.10457 8 7V4ZM14 4H10V7H14V4ZM5 17V20H9V17H5ZM15 17V20H19V17H15Z"
			fill="currentColor"
		/>
	</svg>
);

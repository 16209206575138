import 'twin.macro';

import { Icon, Skeleton, Stack, TextStyle } from '@cheqroom/ui';
import { IconSource } from '@cheqroom/ui/src/components/Icon/Icon';
import { FC, isValidElement, ReactNode, useId } from 'react';

export interface Props {
	icon?: IconSource | ReactNode;
	text: ReactNode;
}

interface Composition {
	Skeleton: typeof InfoSkeleton;
}

export const Info: FC<Props> & Composition = ({ icon, text }) => {
	return (
		<Stack spacing="extraTight" align="center">
			{icon &&
				(isValidElement(icon) ? icon : <Icon source={icon as IconSource} size="small" tw="text-gray-400" />)}
			<TextStyle variation="subdued" tw="text-xs">
				{text}
			</TextStyle>
		</Stack>
	);
};

const InfoSkeleton: FC<{ count?: number }> = ({ count = 3 }) => {
	const id = useId();
	return Array.from({ length: count }).map((_, i) => <Skeleton key={`${id}-${i}`} width="75px" height="10px" />);
};

Info.Skeleton = InfoSkeleton;

//
// Source:
// https://stackoverflow.com/a/2450976
//

export function shuffle<T>(array: Array<T>): Array<T> {
	if (array.length <= 1) {
		return array;
	}

	const newArray = [...array];

	for (let currentIndex = newArray.length - 1; currentIndex > 0; currentIndex--) {
		const randomIndex = Math.floor(Math.random() * (currentIndex + 1)); // random index from 0 to currentIndex

		// swap elements newArray[currentIndex] and newArray[randomIndex]
		[newArray[currentIndex], newArray[randomIndex]] = [newArray[randomIndex], newArray[currentIndex]];
	}

	const isTheSameArray = newArray.every((value, index) => value === array[index]);
	if (isTheSameArray) {
		return shuffle(array);
	}

	return newArray;
}

import { ItemConflict } from './get-item-conflict';

export const CHECKOUT_HARD_CONFLICTS = [
	ItemConflict.RETIRED,
	ItemConflict.CANNOT_CHECKOUT,
	ItemConflict.CHECKED_OUT,
	ItemConflict.ASSIGNED,
	ItemConflict.FLAGGED,
	ItemConflict.UNAVAILABLE,
	ItemConflict.UNAVAILABLE_QUANTITY,
	ItemConflict.CHECKING_OUT,
];

export const RESERVATION_HARD_CONFLICTS = [
	ItemConflict.RETIRED,
	ItemConflict.CANNOT_RESERVE,
	ItemConflict.CANNOT_CHECKOUT,
	ItemConflict.ASSIGNED,
	ItemConflict.FLAGGED,
	ItemConflict.UNAVAILABLE,
	ItemConflict.INSUFFICIENT_QUANTITY,
];

export const ASSIGN_TO_KIT_HARD_CONFLICTS = [
	ItemConflict.RETIRED,
	ItemConflict.ASSIGNED,
	ItemConflict.UNAVAILABLE_TO_ADD_TO_KIT,
	ItemConflict.KIT_IS_IN_CUSTODY,
];

import { Settings } from '@cheqroom/icons';
import { Button } from '@cheqroom/ui';
import { FC } from 'react';

import { CustomField, Field, SystemField, SystemFieldType as ItemSystemFieldType } from '../../hooks/item-fields/types';
import useModal from '../../hooks/modal/use-modal';
import { Field as ReservationField } from '../../pages/Reservations/Overview/python.hooks';
import CustomizeItemsOverviewModal, { Props as CustomizeOverviewModalProps } from './CustomizeOverviewModal';

type Props = {
	type: 'items-list' | 'check-outs-list' | 'contacts-list' | 'kits-list' | 'reservations-list' | 'spotchecks-list';
	viewType: 'table' | 'cards' | 'scheduler';
	selectedColumns: string[];
	saveSelectedColumns: (selectedColumns: string[]) => void;
} & (
	| {
			type: 'items-list';
			fields: Field<ItemSystemFieldType>[];
	  }
	| {
			type: 'reservations-list';
			fields: Field<ReservationField>[];
	  }
	| {
			type: 'check-outs-list' | 'contacts-list' | 'kits-list' | 'spotchecks-list';
			fields: Field<string>[];
	  }
);
const CustomizeOverview: FC<Props> = ({ type, viewType, fields, selectedColumns, saveSelectedColumns }) => {
	const [showModal] = useModal<CustomizeOverviewModalProps>((props) => <CustomizeItemsOverviewModal {...props} />);

	const customFields = fields.filter((field) => field.id.startsWith('fields.')) as CustomField[];
	const systemFields = fields.filter((field) => !field.id.startsWith('fields.')) as SystemField<any>[];

	const handleShowModalClick = () => {
		showModal({
			type,
			viewType,
			customFields,
			systemFields,
			selectedColumns,
			saveSelectedColumns,
		});
	};

	return (
		<Button variation="secondary" leftIcon={Settings} onClick={handleShowModalClick}>
			Customize overview
		</Button>
	);
};

export default CustomizeOverview;

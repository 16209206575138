import app from 'durandal/app.js';
import cr from '@cheqroom/core';
import mixinBase from 'viewmodels/mixins/base.js';
import mixinTransaction from 'viewmodels/mixins/transaction.js';
import mixinCheckout from 'viewmodels/mixins/checkout.js';
import mixinReservation from 'viewmodels/mixins/reservation.js';
import mixinItem from 'viewmodels/mixins/item.js';
import mixinContact from 'viewmodels/mixins/contact.js';
import mixinKit from 'viewmodels/mixins/kit.js';
import mixinUserSync from 'viewmodels/mixins/usersync.js';
import mixinCustody from 'viewmodels/mixins/custody.js';
import mixinTemplate from 'viewmodels/mixins/template.js';
import mixinConflicts from 'viewmodels/mixins/conflicts.js';
import mixinWebhook from 'viewmodels/mixins/webhook.js';
import mixinUser from 'viewmodels/mixins/user.js';
import mixinColorLabel from 'viewmodels/mixins/colorLabel.js';
import mixinDocument from 'viewmodels/mixins/document.js';
import mixinAttachment from 'viewmodels/mixins/attachment.js';
import mixinSpotcheck from 'viewmodels/mixins/spotcheck.js';

export default {
	getReservation: function (global, fields, triggerSource) {
		var reservation = new cr.Reservation({
			ds: global.getDataSource('reservations'),
			global: global,
			dateHelper: global.dateHelper,
			unavailableFlagHelper: function (flagId) {
				var flag = global.central.group.itemFlags.find(function (f) {
					return f.id == flagId;
				});
				if (flag) {
					return !flag.available;
				}
				return false;
			},
			_fields:
				fields ||
				'name,status,label,fromDate,toDate,order,number,archived,cancelled,itemQuantities,items.quantity,items.name,items.flag,items.status,items.category,items.fields,items.codes,items.barcodes,items.location,items.order,items.orders,items.kind,items.kit,items.cover,items.canReserve,items.canOrder,items.allowOrder,items.allowReserve,items.canCustody,itemSummary,fields,customer.name,customer.email,customer.barcodes,customer.fields,customer.kind,customer.status,customer.user.picture,customer.cover,location.name,comments.*,comments.by.name,comments.by.picture,comments.by.status,attachments.*,attachments.by.name,attachments.by.picture,attachments.by.status,order,repeatId,repeatFrequency',
		});
		mixinBase.mixin(reservation, { global: global, triggerSource: triggerSource });
		mixinConflicts.mixin(reservation, { global: global, triggerSource: triggerSource });
		mixinTransaction.mixin(reservation, { global: global, triggerSource: triggerSource });
		mixinReservation.mixin(reservation, { global: global, triggerSource: triggerSource });

		return reservation;
	},
	getCheckout: function (global, fields, triggerSource) {
		var checkout = new cr.Order({
			ds: global.getDataSource('orders'),
			dsItems: global.getDataSource('items'),
			dsReservations: global.getDataSource('reservations'),
			dateHelper: global.dateHelper,
			unavailableFlagHelper: function (flagId) {
				var flag = global.central.group.itemFlags.find(function (f) {
					return f.id == flagId;
				});
				if (flag) {
					return !flag.available;
				}
				return false;
			},
			global: global,
			_fields:
				fields ||
				'name,status,label,created,started,due,finished,number,archived,itemQuantities,items.quantity,items.name,items.flag,items.status,items.kind,items.category,items.fields,items.codes,items.barcodes,items.kit,items.order,items.orders,items.location,items.cover,items.canOrder,items.canReserve,items.canCustody,items.allowOrder,items.allowReserve,itemSummary,fields,customer.name,customer.email,customer.fields,customer.barcodes,customer.status,customer.kind,customer.status,customer.user.picture,customer.cover,location.name,comments.*,comments.by.name,comments.by.picture,comments.by.status,attachments.*,attachments.by.name,attachments.by.picture,attachments.by.status,reservation,reservation.comments.*,reservation.comments.by.name,reservation.comments.by.picture,comments.by.status,reservation.attachments.*,reservation.attachments.by.name,reservation.attachments.by.picture,attachments.by.status',
		});
		mixinBase.mixin(checkout, { global: global, triggerSource: triggerSource });
		mixinConflicts.mixin(checkout, { global: global, triggerSource: triggerSource });
		mixinTransaction.mixin(checkout, { global: global, triggerSource: triggerSource });
		mixinCheckout.mixin(checkout, { global: global, app: app, triggerSource: triggerSource });

		return checkout;
	},
	getItem: function (global, fields, skipUpdateTrigger) {
		var item = new cr.Item({
			ds: global.getDataSource('items'),
			global: global,
			_fields:
				fields ||
				'name,status,cover,flag,codes,barcodes,category.name,location.name,comments.*,comments.by.name,comments.by.picture,comments.by.status,attachments.*,attachments.by.name,attachments.by.picture,attachments.by.status,geo,address,model,brand,warrantyDate,purchaseDate,purchasePrice,residualValue,fields,order.customer.name,order.due,expired,custody.name,kit.name,canReserve,canOrder,canCustody,allowReserve,allowOrder,allowCustody,flagged,catalog,kind,quantity',
		});
		mixinBase.mixin(item, { global: global });
		mixinCustody.mixin(item, { global: global });
		mixinItem.mixin(item, { global: global, skipUpdateTrigger: skipUpdateTrigger });

		return item;
	},
	getKit: function (global, fields) {
		var kit = new cr.Kit({
			global: global,
			ds: global.getDataSource('kits'),
			_fields:
				fields ||
				'name,description,itemSummary,cover,status,items.flag,items.cover,codes,barcodes,items.name,items.status,items.custody.name,items.cover,items.geo,items.address,items.fields,items.codes,items.barcodes,items.order,items.location,comments.*,comments.by.name,comments.by.picture,comments.by.status,attachments.*,attachments.by.name,attachments.by.picture,attachments.by.status,fields,canReserve,canOrder,canCustody,allowReserve,allowOrder,allowCustody,items.canReserve,items.canOrder,items.canCustody',
		});
		mixinBase.mixin(kit, { global: global });
		mixinConflicts.mixin(kit, { global: global });
		mixinCustody.mixin(kit, { global: global });
		mixinKit.mixin(kit, { global: global });

		return kit;
	},
	getContact: function (global, fields) {
		var contact = new cr.Contact({
			ds: global.getDataSource('customers'),
			global: global,
			_fields:
				fields ||
				'name,cover,email,status,kind,barcodes,archived,blocked,user.status,user.name,user.sync,user.picture,user.userRole,comments.*,comments.by.name,comments.by.picture,comments.by.status,attachments.*,attachments.by.name,attachments.by.picture,attachments.by.status,fields',
		});
		mixinBase.mixin(contact, { global: global });
		mixinContact.mixin(contact, { global: global });

		return contact;
	},
	getLocation: function (global, fields) {
		var loc = new cr.Location({
			global: global,
			ds: global.getDataSource('locations'),
		});
		return loc;
	},
	getUser: function (global, fields) {
		var user = new cr.User({
			ds: global.getDataSource('users'),
			dsAnonymous: global.getDataSource('anon'),
			global: global,
			_fields: fields || '*',
		});
		mixinUser.mixin(user, { global: global });
		return user;
	},
	getUserSync: function (global, fields) {
		var userSync = new cr.UserSync({
			ds: global.getDataSource('syncs'),
			global: global,
			_fields: fields || '*',
		});
		mixinUserSync.mixin(userSync, { global: global });
		return userSync;
	},
	getTemplate: function (global, fields) {
		var template = new cr.Template({
			ds: global.getDataSource('templates'),
			global: global,
			_fields: fields || '*',
		});
		mixinTemplate.mixin(template, { global: global });
		return template;
	},
	getWebhook: function (global, fields) {
		var webhook = new cr.Webhook({
			ds: global.getDataSource('webhooks'),
			global: global,
			_fields: fields || '*',
		});
		webhook.raw = {};
		mixinWebhook.mixin(webhook, { global: global });
		return webhook;
	},
	getColorLabel: function (global) {
		var label = new cr.ColorLabel({
			global: global,
		});
		mixinColorLabel.mixin(label, { global: global });
		return label;
	},
	getGroup: function (global, fields) {
		var group = new cr.Group({
			global: global,
			ds: global.getDataSource('groups'),
			_fields: fields || '*',
		});
		mixinDocument.mixin(group, { global: global });
		return group;
	},
	getAttachment: function (global) {
		var attachment = new cr.Attachment({
			global: global,
		});
		mixinAttachment.mixin(attachment, { global: global });
		return attachment;
	},
	getSpotcheck: function (global, fields) {
		var spotcheck = new cr.Spotcheck({
			global: global,
			ds: global.getDataSource('spotchecks'),
			_fields:
				fields ||
				'by.name,by.picture,created,closed,docId,itemFilter,kind,message,modified,name,numChecked,numTotal,numUnchecked,numUnexpected,outcome,status,summary,_id,lastAddress,users,archived,comments.*,comments.by.name,comments.by.picture,comments.by.status,attachments.*,attachments.by.name,attachments.by.picture,attachments.by.status',
		});
		mixinBase.mixin(spotcheck, { global: global });
		mixinSpotcheck.mixin(spotcheck, { global: global });
		return spotcheck;
	},
};

import 'twin.macro';

import { Cross } from '@cheqroom/icons';
import { FC, ReactNode } from 'react';

import { IconButton } from '../../../IconButton/IconButton';
import { Heading } from '../../../Typography/Heading/Heading';
import { Size } from '../../Modal';
import { StyledHeader } from './Header.styles';

interface Props {
	onClose?: () => void;
	children?: ReactNode;
	size?: Size;
	truncate?: boolean;
}

export const Header: FC<Props> = ({ children, onClose, size = 'medium', truncate = false, ...rest }) => (
	<StyledHeader size={size} truncate={truncate} {...rest}>
		<Heading element={size === 'full-page' ? 'h1' : 'h3'} truncate={truncate}>
			{children}
		</Heading>

		{!!onClose && (
			<IconButton aria-label="Close modal" inverted onClick={onClose} source={Cross} variation="secondary" />
		)}
	</StyledHeader>
);

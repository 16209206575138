import global from 'viewmodels/global.js';
import PricingHelper from 'viewmodels/pricing-helper.js';
import ReactUpgradeModal from '@cheqroom/web/src/components/UpgradeModal/UpgradeModal';

export default {
	init: function (page, feature) {
		return PricingHelper.loadFeatures().then(function () {
			var subscription = global.central.subscription() || {};
			var currentPlan = subscription.subscriptionPlan || {};
			var upgradePlan =
				PricingHelper.getUpgradePlanForFeature(feature, currentPlan.family, currentPlan.limits) ||
				currentPlan.groupKey;
			var features = PricingHelper.getFeaturesForPlan(upgradePlan);

			page.features = features.slice(0, 6);
			page.otherFeatures = features.length - 6;
			page.planName = upgradePlan.replace('_', ' ').capitalize();

			page.ReactUpgradeModal = ReactUpgradeModal;

			page.currentPlanName = currentPlan.nameFriendly;

			page.upgradeLink = global.central.getUpgradeLink({ selected: upgradePlan });
			page.showComingSoon = features.find(function (f) {
				return f.id === feature && f[currentPlan.groupKey] && f.coming_soon;
			});

			page.earlyAccessMessage = 'I would like early access for ';

			page.clickedRequestEarlyAccess = function (featureName) {
				if (global.intercomLoaded) {
					window.Intercom('showNewMessage', page.earlyAccessMessage + featureName);
				} else {
					return true;
				}
			};
		});
	},
};

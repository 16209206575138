import { DateTime } from '@cheqroom/date-time';

import { SystemFieldType } from '../../../hooks/item-fields/types';
import { DateFormatter } from '../../../util';

const getDefaultValueForItemField = (fieldId: string): string | undefined => {
	if (fieldId.startsWith('fields.')) return;

	switch (fieldId) {
		case SystemFieldType.barcode:
			return 'ABC-123';
		case SystemFieldType.qr:
			return '4be6c703';
		case SystemFieldType.purchaseDate:
			return DateFormatter.shortDate(DateTime.now().addDays(-40));
		case SystemFieldType.created:
			return DateFormatter.shortDate(DateTime.now().addDays(1));
		case SystemFieldType.warrantyDate:
			return DateFormatter.shortDate(DateTime.now().addYears(3));
		case SystemFieldType.location:
			return 'Cheqroom HQ';
		case SystemFieldType.address:
			return 'Cheqroom Avenue 1';
		case SystemFieldType.purchasePrice:
			return '350';
		case SystemFieldType.residualValue:
			return '120';
		case SystemFieldType.flag:
			return 'Needs Repair';
		case SystemFieldType.contact:
			return 'Alex';
		case SystemFieldType.category:
			return 'Action Cameras';
		case SystemFieldType.kit:
			return 'Sports Kit';
		case SystemFieldType.expired:
			return 'Expired';
		case SystemFieldType.brand:
			return 'GoPro';
		case SystemFieldType.model:
			return 'Hero 10 Black';
		case SystemFieldType.quantity:
			return '138';
		default:
			return undefined;
	}
};

export default getDefaultValueForItemField;

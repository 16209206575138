import dialog from 'plugins/dialog.js';
import UpgradeHelper from 'viewmodels/upgrade-helper.js';
import UpgradeModalView from 'views/upgrade-modal.html';
import analytics from '@cheqroom/web/src/services/analytics';

import ReactUpgradeModal from '@cheqroom/web/src/components/UpgradeModal/UpgradeModal';

export default class UpgradeModal {
	constructor(spec = {}) {
		this.view = UpgradeModalView;
		this.ReactUpgradeModal = ReactUpgradeModal;

		this.featureName = spec.featureName;
		this.featureId = spec.featureId;
		this.source = spec.source;
	}

	// Durandal events
	// ----
	static show(spec = {}) {
		return dialog.showBootstrapDialog(new UpgradeModal(spec));
	}

	close(code) {
		dialog.close(this, code);
	}

	activate() {
		return UpgradeHelper.init(this, this.featureId);
	}

	compositionComplete(v, p) {
		app.trigger('intercom:event', { name: 'web-viewed-upgrade', feature: this.featureId });

		analytics.track('Upgrade Modal Shown', this.source, {
			feature: this.featureName,
		});

		// This event is fired when the modal has been made visible to the user
		// http://stackoverflow.com/questions/22547472/how-can-i-get-auto-focus-to-an-input-element-in-a-dynamically-generated-knockout
		$(p).on('hidden.bs.modal', (e) => {
			this.close();
		});
	}

	// UI events
	// ----
	clickedCancel() {
		this.close();
	}

	clickedUpgrade() {
		app.trigger('intercom:event', { name: 'web-clicked-upgrade', feature: this.featureId });

		analytics.track('Subscription Changing', this.source, {
			feature: this.featureName,
		});

		this.close();

		return true;
	}
}

import { useModalContext } from '@cheqroom/ui/src/components/Modal/Modal.context';
import { ReactElement, useRef } from 'react';
import { uuid } from 'short-uuid';

export interface ModalProps {
	isShown: boolean;
	onClose: () => void;
}

type RenderDialogFn<Props> = (outgoingProps: ModalProps & Props) => ReactElement;

export default function useModal<Props = Record<string, unknown>>(
	renderDialog: RenderDialogFn<Props>
): readonly [(props?: Props) => void, () => void] {
	const key = useRef(uuid()).current;

	const { hideModal, showModal } = useModalContext();

	const show = (props: Props = {} as Props) => {
		showModal(key, () => {
			return renderDialog({
				isShown: true,
				onClose: hide,
				...props,
			});
		});
	};

	const hide = () => {
		hideModal(key);
	};

	return [show, hide] as const;
}

import { forwardRef, ReactNode } from 'react';

import { StyledBody } from './Body.styles';

export const Body = forwardRef<HTMLElement, { children?: ReactNode }>(({ children, ...props }, ref) => (
	<StyledBody {...props} ref={ref}>
		{children}
	</StyledBody>
));

Body.displayName = 'Body';

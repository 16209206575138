import cr from '@cheqroom/core';
import system from 'durandal/system.js';
import moment from 'moment';
import codeWriter from 'viewmodels/codewriter.js';

// Create a custom error for dymo and labelwriting errors
// ----
var LabelWriterError = function (message) {
	this.name = 'LabelWriterError';
	this.message = message || '';
};
LabelWriterError.prototype = Error.prototype;

const DymoErrorCode = {
	/**
	 * Unknown dymo error
	 */
	UNKNOWN: 1,
	/**
	 * Trying to print DCD label on DLS service isn't supported
	 */
	DYMO_CONNECT_NOT_SUPPORTED: 2,
	/**
	 * Trying to print custom labels on DCD service isn't supported
	 */
	LABEL_NOT_SUPPORTED: 3,
};

class DymoError extends Error {
	constructor(message) {
		super(message || 'DYMO error');

		this.code = this.getErrorCode();
	}

	getErrorCode() {
		if (['DesktopLabel', 'Unsupported DCD label'].some((errMsg) => this.message.includes(errMsg))) {
			return DymoErrorCode.DYMO_CONNECT_NOT_SUPPORTED;
		} else if (this.message.includes('DieCutLabel')) {
			return DymoErrorCode.LABEL_NOT_SUPPORTED;
		}

		return DymoErrorCode.UNKNOWN;
	}
}

// Our LabelWriter class
// ----
var LabelWriter = function (spec) {
	spec = spec || {};
	this.urlApi = spec.urlApi || '';
	this.api = spec.api;
	this.group = spec.group;
	this.global = spec.global;
	this.kind = spec.kind;

	this.fieldDefs = spec.fieldDefs || {};
	this._fieldNamesRegex = /fieldNames\[(\d+)\]/;
	this._fieldValuesRegex = /fieldValues\[(\d+)\]/;
};

LabelWriter.prototype.getPrinters = function () {
	return [];
};

LabelWriter.prototype.hasPrinters = function () {
	return this.getPrinters().length > 0;
};

LabelWriter.prototype.getLabelDataForDoc = function (doc, layoutXml) {
	doc = doc || {};

	var that = this,
		barcode = '',
		code = '',
		url = null,
		categoryName = '';
	if (doc.barcodes && doc.barcodes.length > 0) {
		doc.barcode = barcode = doc.barcodes[0];
		doc.barcodes.forEach(function (barcode, idx) {
			// Add a reference for each barcode (f.e barcode_0, barcode_1,...)
			doc['barcode_' + idx] = barcode;
		});
	}
	if (doc.codes && doc.codes.length) {
		doc.code = code = doc.codes[0];

		// Add a reference for each code (f.e code_0,code_1,...)
		doc.codes.forEach(function (code, idx) {
			doc['code_' + idx] = code;
		});
	}

	// Need to load QR/Barcode/DataMatrix images?
	var imgDictionary = {};

	var codeGenerator = function (dfd, rgx, codeFunc, imgDicPrefix, qrUrl) {
		qrUrl = qrUrl || '';
		if (layoutXml === undefined) {
			dfd.resolve();
			return;
		}

		var matches = layoutXml.match(rgx);
		var imgCalls = [];

		if (matches) {
			matches.forEach(function (match, idx) {
				// Reset regex index
				//https://stackoverflow.com/questions/4724701/regexp-exec-returns-null-sporadically
				rgx.lastIndex = 0;

				var codeMatch = rgx.exec(match);
				if (codeMatch) {
					var codeValue = doc[codeMatch[3] || 'code'];
					imgCalls.push(
						codeFunc(codeMatch[1] == 'qr' || codeMatch[4] == 'code' ? qrUrl + codeValue : codeValue)
					);

					if (codeMatch[2]) {
						imgDictionary[imgDicPrefix + idx] = codeMatch[1] + '_' + codeMatch[3];
					} else {
						imgDictionary[imgDicPrefix + idx] = 'qr';
					}
				}
			});
		}
		if (imgCalls.length > 0) {
			Promise.all(imgCalls).then(function (imgs) {
				dfd.resolve(imgs);
			});
		} else {
			dfd.resolve();
		}
	};

	var dfdQRImg = new cr.common.DeferredPromise(),
		reQRImg = /<Name>(qr|qrImg)(_)?((code|barcode)(_[0-9])?)?<\/Name>/g;
	codeGenerator(dfdQRImg, reQRImg, codeWriter.qrCode, 'qrImg_', 'http://cheqroom.com/qr/');

	var dfdCustomQRImg = new cr.common.DeferredPromise(),
		reCustomQRImg = /<Name>(customQr|customQrImg)(_)?((code|barcode)(_[0-9])?)?<\/Name>/g;
	codeGenerator(dfdCustomQRImg, reCustomQRImg, codeWriter.qrCode, 'customQrImg_');

	var dfdBarcodeImg = new cr.common.DeferredPromise(),
		reBarcodeMatch = /<Name>(barcodeImg)(_)((code|barcode)(_[0-9])?)?<\/Name>/g;
	codeGenerator(dfdBarcodeImg, reBarcodeMatch, codeWriter.barCode, 'barcodeImg_');

	var dfdDatamatrixImg = new cr.common.DeferredPromise(),
		reDatamatrixMatch = /<Name>(datamatrixImg)(_)((code|barcode)(_[0-9])?)?<\/Name>/g;
	codeGenerator(dfdDatamatrixImg, reDatamatrixMatch, codeWriter.datamatrixCode, 'datamatrixImg_');

	const allCategories = this.global.central.itemCategories();

	// Make category user friendly for label usage
	categoryName = 'Unknown';

	if (typeof doc.category === 'string') {
		const category = allCategories.find((category) => doc.category === category._id);

		if (category) {
			categoryName = category.name;
		}
	}

	if (typeof doc.category === 'object') {
		categoryName = doc.category.name || 'Unknown';
	}
	locationName =
		typeof doc.location == 'string'
			? this.global.central._getLocationById(doc.location).name
			: doc.location
				? doc.location.name
				: 'Unknown';

	return Promise.all([dfdQRImg, dfdBarcodeImg, dfdDatamatrixImg, dfdCustomQRImg]).then(function ([
		qrImgs,
		barcodeImgs,
		datamatrixImgs,
		customQrImgs,
	]) {
		if (qrImgs) {
			qrImgs.forEach(function (qrImg, idx) {
				doc[imgDictionary['qrImg_' + idx]] = qrImg;
			});
		}
		if (customQrImgs) {
			customQrImgs.forEach(function (qrImg, idx) {
				doc[imgDictionary['customQrImg_' + idx]] = qrImg;
			});
		}
		if (barcodeImgs) {
			barcodeImgs.forEach(function (barcodeImg, idx) {
				doc[imgDictionary['barcodeImg_' + idx]] = barcodeImg;
			});
		}
		if (datamatrixImgs) {
			datamatrixImgs.forEach(function (datamatrixImg, idx) {
				doc[imgDictionary['datamatrixImg_' + idx]] = datamatrixImg;
			});
		}

		var tempDoc = $.extend(
			{
				fields: {},
			},
			doc,
			{
				code: code,
				barcode: barcode,
				propertyOf: 'Property of',
				company: that.group.name,
				organization: that.group.name,
				category: categoryName,
				location: locationName,
			}
		);

		//Add system fields to fields dictionary
		tempDoc.fields['Brand'] = doc.brand;
		tempDoc.fields['Model'] = doc.model;
		tempDoc.fields['Purchase date'] = doc.purchaseDate;
		tempDoc.fields['Purchase price'] = doc.purchasePrice;
		tempDoc.fields['Warranty date'] = doc.warrantyDate;
		tempDoc.fields['Residual value'] = doc.residualValue;

		return tempDoc;
	});
};

var availableDymoPrinters = [];
var DymoLabelWriter = function (spec) {
	LabelWriter.call(this, spec);

	this.dymoTimeout = spec.dymoTimeout || 10000; // 10s
	this.dymoUrl = spec.dymoUrl || 'https://127.0.0.1:41951/DYMO/DLS/Printing/StatusConnected';
};
DymoLabelWriter.prototype = new LabelWriter();
DymoLabelWriter.prototype.constructor = DymoLabelWriter;

const loadDymoScript = (callback) => {
	const existingScript = document.getElementById('dymo-connect-framework');

	if (!existingScript) {
		const script = document.createElement('script');
		// URL for the third-party library being loaded.
		// /!\ This needs to be kept up-to-date manually
		script.src = `${__webpack_public_path__}static/js/dymo.connect.framework.b213970ab229d70d824982dedf0ad5de.js`;
		script.id = 'dymo-connect-framework';
		document.body.appendChild(script);

		script.onload = () => {
			if (callback) callback();
		};
	}

	if (existingScript && callback) callback();
};

DymoLabelWriter.prototype.init = function () {
	var dfd = $.Deferred(),
		that = this;

	loadDymoScript(() => {
		if (typeof dymo !== 'undefined') {
			if (window._dymo_initialized) {
				dfd.resolve();
			} else {
				// cache bust ajax call chrome (default port 41951)
				$.ajax({ url: this.dymoUrl, timeout: this.dymoTimeout }).always(function () {
					try {
						dymo.label.framework.init(function () {
							window._dymo_initialized = true;
							dfd.resolve();
						});
					} catch (ex) {
						dfd.reject('Error while initializing DYMO');
					}
				});
			}
		} else {
			dfd.reject('DYMO Javascript SDK not loaded');
		}
	});

	return dfd;
};

DymoLabelWriter.prototype.isSupported = function () {
	var result = this.getEnvironmentInfo();
	return result.isBrowserSupported && result.isFrameworkInstalled && result.isWebServicePresent;
};

DymoLabelWriter.prototype.getEnvironmentInfo = function () {
	if (typeof dymo == 'undefined') {
		system.log('DYMO Javascript SDK not loaded');
		return {};
	} else {
		try {
			return dymo.label.framework.checkEnvironment();
		} catch (ex) {
			return {
				isFrameworkInstalled: false,
			};
		}
	}
};

DymoLabelWriter.prototype.getPrinters = function () {
	var $this = this;

	try {
		if (availableDymoPrinters.length == 0) {
			var isSupported = this.isSupported();
			if (isSupported) {
				var printers = dymo.label.framework.getPrinters();
				$.each(printers, function (index, printer) {
					availableDymoPrinters.push({
						name: printer.name,
						modelName: printer.modelName,
						connected: printer.isConnected,
						needsConnection: true,
						isTwinTurbo: printer.isTwinTurbo,
						printer: printer,
					});
				});
			}
		}
		return availableDymoPrinters;
	} catch (ex) {
		return availableDymoPrinters;
	}
};

DymoLabelWriter.prototype.isTwinTurbo = function () {
	var printers = this.getPrinters();
	if (printers == null || printers.length == 0) {
		return false;
	}
	return printers[0].printer.isTwinTurbo;
};

// Should pass template which we can use to determine to use DYMO or ZEBRA response
DymoLabelWriter.prototype.getLabel = function (doc, layoutXml, missingText) {
	var that = this;
	return this.getLabelDataForDoc(doc, layoutXml).then(function (labelData) {
		let label = that._drawLabel(labelData, layoutXml, missingText);

		label.renderBase64 = () => {
			try {
				const labelContent = label.render();

				// OSX DLS service returns empty string if something is invalid
				// which can have several causes of which one is printing DCD label on DLS service
				if (!labelContent) {
					throw new Error(label.isDCDLabel() ? 'Unsupported DCD label' : 'Unknown DLS error on render');
				}
				return `data:image/jpg;base64,${labelContent}`;
			} catch (ex) {
				// Rethrow ex as a specific DymoError, so FE knows it was the DYMO service
				throw new DymoError(ex ? ex.message : '');
			}
		};

		return label;
	});
};

DymoLabelWriter.prototype.getLabels = function (docs, layoutXml, missingText) {
	var dfds = [],
		dfd = null,
		labels = {},
		that = this;

	$.each(docs, function (i, doc) {
		dfd = that.getLabel(doc, layoutXml, missingText);
		dfd.then(function (label) {
			labels[i] = label;
		});
		dfds.push(dfd);
	});

	return Promise.all(dfds).then(function () {
		var sortedLabels = [],
			label = null;
		for (var i = 0; i < docs.length; i++) {
			label = labels[i];
			if (label) {
				sortedLabels.push(label);
			}
		}
		return sortedLabels;
	});
};

// Based on selected label kind draw data for DYMO or ZEBRA????
DymoLabelWriter.prototype._drawLabel = function (data, layoutXml, missingText) {
	//TODO IMPORTANT BUGFIX
	//missingText param now replaces all objects in the xml layout
	//should only by objects which name are equal to custom field name

	var that = this,
		label = dymo.label.framework.openLabelXml(layoutXml),
		elements = label._getObjectElements(),
		value = null;

	// BUGFIX: DCD label
	// Add space between opening and closing Color tag
	// https://github.com/dymosoftware/dymo-connect-framework/issues/10
	if (label.isDCDLabel()) {
		var colorElements = dymo.xml.getElements(label._doc, 'Color');
		$.each(colorElements, function (index, el) {
			dymo.xml.setElementText(el, ' ');
		});
	}

	// Run over the fields that are available in the label
	$.each(elements, function (index, el) {
		// Get name of the element
		var name = dymo.xml.getElement(el, 'Name').textContent;

		var textFitEl = dymo.xml.getElement(el, label.isDCDLabel() ? 'FitMode' : 'TextFitMode');
		var textFit = textFitEl ? textFitEl.textContent === 'None' : false;

		// Get value from item obj
		value = that._getDataBySelector(data, name, missingText, false, textFit);

		// BUGFIX value need to be string value
		// don't overwrite logo image values
		if (value !== null) {
			label.setObjectTextByObject(el, value ? '' + value : '');
		}
	});

	return label;
};

/**
 * Can use a data dict and a selector to select some data
 * E.g. data = {"fields": {"Serial number": "012345}}
 *      name = `fields.Serial number`
 *      would result in `012345`
 * @param data
 * @param name
 * @param missingText
 * @private
 */
DymoLabelWriter.prototype._getDataBySelector = function (data, name, missingText, forceMissingText, autoFitText) {
	// We'll support a special syntax for getting `fields` by index
	// This only works if you've passed in `fieldDefs`
	var fieldDefs = $.isEmptyObject(this.fieldDefs) ? [] : this.fieldDefs[this.kind];

	if (fieldDefs && fieldDefs.length > 0) {
		var index = -1,
			def = null,
			match = this._fieldNamesRegex.exec(name);
		if (match) {
			index = parseInt(match[1]);
			def = fieldDefs[index];
			if (def) {
				return def.name || missingText;
			} else {
				return '';
			}
		} else {
			match = this._fieldValuesRegex.exec(name);
			if (match) {
				index = parseInt(match[1]);
				def = fieldDefs[index];
				if (def) {
					name = 'fields.' + def.name;
				} else {
					return missingText;
				}
			}
		}
	}

	var parts = name ? name.split('.') : [];
	if (parts.length > 1) {
		var first = parts.shift();
		var sub = data[first];
		if (data.hasOwnProperty(first)) {
			return this._getDataBySelector(sub, parts.join('.'), missingText, true, autoFitText);
		} else {
			return null;
		}
	} else {
		if (data.hasOwnProperty(name) || forceMissingText) {
			var fieldDef =
				fieldDefs && fieldDefs.length > 0
					? fieldDefs.find(function (f) {
							return f.name == name;
						})
					: {};

			var wrapText = (fieldDef && fieldDef.editor == 'text') || autoFitText;

			if (!$.isEmptyObject(fieldDef)) {
				switch (fieldDef.kind) {
					case 'date':
					case 'datetime':
						value = data[name] ? moment(data[name]).format('MMM Do YYYY') : '';
						break;
					default:
						value = data[name] ? data[name] + (fieldDef.unit ? ' ' + fieldDef.unit : '') : '';
						break;
				}
			} else {
				value = data[name];
			}

			value = value || missingText;

			return wrapText ? wordwrap(value) : value;
		} else {
			return null;
		}
	}
};

//http://locutus.io/php/strings/wordwrap/
function wordwrap(str, intWidth, strBreak, cut) {
	intWidth = arguments.length >= 2 ? +intWidth : 30;
	strBreak = arguments.length >= 3 ? '' + strBreak : '\n';
	cut = arguments.length >= 4 ? !!cut : false;

	var i, j, line;

	str += '';

	if (intWidth < 1) {
		return str;
	}

	var reLineBreaks = /\r\n|\n|\r/;
	var reBeginningUntilFirstWhitespace = /^\S*/;
	var reLastCharsWithOptionalTrailingWhitespace = /\S*(\s)?$/;

	var lines = str.split(reLineBreaks);
	var l = lines.length;
	var match;

	// for each line of text
	for (i = 0; i < l; lines[i++] += line) {
		line = lines[i];
		lines[i] = '';

		while (line.length > intWidth) {
			// get slice of length one char above limit
			var slice = line.slice(0, intWidth + 1);

			// remove leading whitespace from rest of line to parse
			var ltrim = 0;
			// remove trailing whitespace from new line content
			var rtrim = 0;

			match = slice.match(reLastCharsWithOptionalTrailingWhitespace);

			// if the slice ends with whitespace
			if (match[1]) {
				// then perfect moment to cut the line
				j = intWidth;
				ltrim = 1;
			} else {
				// otherwise cut at previous whitespace
				j = slice.length - match[0].length;

				if (j) {
					rtrim = 1;
				}

				// but if there is no previous whitespace
				// and cut is forced
				// cut just at the defined limit
				if (!j && cut && intWidth) {
					j = intWidth;
				}

				// if cut wasn't forced
				// cut at next possible whitespace after the limit
				if (!j) {
					var charsUntilNextWhitespace = (line.slice(intWidth).match(reBeginningUntilFirstWhitespace) || [
						'',
					])[0];

					j = slice.length + charsUntilNextWhitespace.length;
				}
			}

			lines[i] += line.slice(0, j - rtrim);
			line = line.slice(j + ltrim);
			lines[i] += line.length ? strBreak : '';
		}
	}

	return lines.join('\n');
}

// Static methods
// ----
/**
 * Ideally this would also have been part of the dymo labelframework but it's not
 * I've decided to add it as a static function to the LabelWriter class
 * (since we don't really need to init before knowing how to read the xml format)
 * @param labelXml
 * @returns {{paperName: *}}
 */
DymoLabelWriter.getLabelInfo = function (labelXml, unit) {
	// More information about the file format
	// http://developers.dymo.com/2010/03/23/understanding-label-file-formats-in-dymo-label-software-v-8-overview/
	try {
		var xmlDoc = $.parseXML(labelXml);
	} catch (err) {
		return {};
	}

	var twipsRatio = 1.0 / 1440.0,
		inchToMmRatio = unit == 'mm' ? 25.4 : 1.0,
		xml = $(xmlDoc),
		rect = xml.find('DrawCommands > RoundRectangle'),
		strWidth = rect.attr('Width') || 0,
		strHeight = rect.attr('Height') || 0,
		paper = xml.find('PaperName').text(),
		orientation = xml.find('PaperOrientation').text(),
		width = parseFloat(strWidth) * twipsRatio * inchToMmRatio,
		height = parseFloat(strHeight) * twipsRatio * inchToMmRatio;

	if (orientation == 'Landscape') {
		var oldWidth = width;
		width = height;
		height = oldWidth;
	}

	return {
		paperName: paper,
		paperOrientation: orientation,
		unit: unit == 'mm' ? 'mm' : 'inch',
		width: unit == 'mm' ? Math.round(width) : width,
		height: unit == 'mm' ? Math.round(height) : height,
	};
};

var availableZebraPrinters = [];
var ZebraLabelWriter = function (spec) {
	LabelWriter.call(this, spec);
};
ZebraLabelWriter.prototype = new LabelWriter();
ZebraLabelWriter.prototype.constructor = ZebraLabelWriter;

const loadZebraScript = (callback) => {
	import(/* webpackChunkName: "browserPrint" */ 'browserPrint')
		.then((module) => callback(module.BrowserPrint))
		.catch(() => callback());
};

ZebraLabelWriter.prototype.init = function () {
	var dfd = $.Deferred();

	loadZebraScript((browserPrint) => {
		if (typeof browserPrint !== 'undefined') {
			if (availableZebraPrinters.length != 0) {
				dfd.resolve();
			} else {
				browserPrint.getLocalDevices(
					function (resp) {
						availableZebraPrinters = resp.printer;

						dfd.resolve();
					},
					function () {
						dfd.reject('No Zebra printers found');
					}
				);
			}
		} else {
			dfd.reject('Zebra Javascript SDK not loaded');
		}
	});

	return dfd;
};

ZebraLabelWriter.prototype.getPrinters = function () {
	return availableZebraPrinters;
};

ZebraLabelWriter.prototype.getTemplateWithData = function (doc, zplTemplate) {
	return this.getLabelDataForDoc(doc).then(function (lbl) {
		//loop over doc properties
		var properties = Object.keys(lbl);

		// add fields properties
		delete properties.fields;
		$.each(Object.keys(lbl.fields), function (i, field) {
			var key = 'fields.' + field;

			properties.push(key);
			lbl[key] = lbl.fields[field];
		});

		$.each(properties, function (i, prop) {
			zplTemplate = zplTemplate.replace(new RegExp('{{' + prop.replace(/\s+/gim, '_') + '}}', 'g'), lbl[prop]);
		});

		return zplTemplate;
	});
};

window.zplLabels = [];

ZebraLabelWriter.prototype.printLabel = function (doc, zplTemplate) {
	var that = this,
		dfd = $.Deferred();
	that.getTemplateWithData(doc, zplTemplate).then(function (labelData) {
		window.zplLabels.push(labelData);

		var printer = availableZebraPrinters[0];
		printer.send(
			labelData,
			function () {
				dfd.resolve(true);
			},
			function () {
				dfd.reject();
			}
		);
	});

	return dfd;
};

export default {
	LabelWriterError,
	DymoLabelWriter,
	ZebraLabelWriter,
	DymoError,
	DymoErrorCode,
};

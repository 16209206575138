import app from 'durandal/app.js';
import ko from 'knockout';
import moment from 'moment';
import cr from '@cheqroom/core';
import system from 'durandal/system.js';
import message from 'viewmodels/message.js';
import modelFactory from 'viewmodels/model-factory';
import graphQL from 'viewmodels/graphql.js';
import featureFlags from '@cheqroom/web/src/services/feature-flags';

import { formatCategories } from './helpers/format-categories';
import currencies from './constants/currencies';

var GROUP_FIELDS = '*,cleanup,admin.email,subscription.*,subscription.subscriptionPlan.*',
	USER_FIELDS =
		'*,group.*,group.cleanup,group.admin.email,group.subscription.*,group.subscription.subscriptionPlan.*,group.subscription.pending.renewalSubscriptionPlan.*,group.admin.name,customer.*,customer.user.picture,picture,userRole.permissions,userRole.restrictLocations',
	USER_MSG_SETUP_WIZARD = 'setup-panel',
	USER_MSG_FIRST_TIME_SETUP = 'first-use',
	DEFAULTS = {
		profile: {},
		limits: {},
		cleanup: {},
		subscription: {},
		isSandbox: false,
		isFirstLogin: false,
		showAccountSetup: false,
		showSetupWizard: false,
		showBlocked: true,
	};
var central = {
	// Will be set during init
	global: null,
	onError: null,
	userId: null,
	groupId: null,
	locationId: null,
	_docTemplates: ko.observable(),
	_labelTemplates: ko.observable(),
	_group: ko.observable(),
	_permissions: [],

	group: null, // group object
	user: ko.observable(), // user
	contact: ko.observable(), // user.customer
	stats: ko.observable(), // getStats
	location: ko.observable(), // current location, based on locationId in init()
	locations: ko.observable(), // locations
	itemCategories: ko.observable(), // item categories for "cheqroom.types.item"
	subscriptionsAll: ko.observable(), // all known subscriptions, even inactive
	sheetLayouts: ko.observable(), // label sheet layouts
	contactGroups: ko.observable(), // contact groups

	// Vincent: these properties could theoretically be ko.computeds
	// I'm using actual ko.observables because computed functions go off too much
	profile: ko.observable(DEFAULTS.profile),
	limits: ko.observable(DEFAULTS.limits),
	cleanup: ko.observable(DEFAULTS.cleanup),
	subscription: ko.observable(DEFAULTS.subscription),
	isFirstLogin: ko.observable(DEFAULTS.isFirstLogin),
	showSetupWizard: ko.observable(DEFAULTS.showSetupWizard),
	forceHideSetupWizard: ko.observable(false),
	showAccountSetup: ko.observable(DEFAULTS.showAccountSetup),
	showBlocked: ko.observable(DEFAULTS.showBlocked),
};

central.activeLocations = ko.pureComputed(function () {
	var locations = central.locations() || [];
	return locations.filter(function (loc) {
		return loc.status == 'active';
	});
});

central.subscriptions = ko
	.pureComputed(function () {
		var subsAll = central.subscriptionsAll();
		var subsActive = {};
		if (subsAll) {
			$.each(subsAll, function (key, value) {
				if (value.active) {
					subsActive[key] = value;
				}
			});
		}
		return subsActive;
	})
	.extend({ throttle: 500 });

central.showLocationColumn = ko
	.pureComputed(function () {
		var locations = central.locations(),
			location = central.location();
		return location == null && locations != null && locations.length > 1;
	})
	.extend({ throttle: 500 });

central.showCart = ko.pureComputed(function () {
	var profile = central.profile(),
		perm = central.global.getPermissionHandler();

	return (
		(perm.hasCheckoutPermission('create') || perm.hasReservationPermission('create')) && !central.isAccountExpired()
	);
});

central.showBlockedBar = ko.pureComputed(function () {
	var user = central.user() || {},
		showBlocked = user.customer && user.customer.status == 'blocked' && central.showBlocked();

	return showBlocked;
});

central.isOwner = ko.pureComputed(function () {
	var user = central.user() || {};
	return user.isOwner;
});

central.isAccountExpired = ko.pureComputed(() => {
	const subscription = central.subscription();
	return subscription.status == 'cancelled_expired' || subscription.status == 'expired';
});

central.showProductChanges = ko.pureComputed(function () {
	var user = central.user() || {},
		isFirstLogin = user.created && moment().diff(user.created, 'day') < 1, //don't show product changes on firstlogin (1 day)
		perm = central.global.getPermissionHandler();

	return perm.hasAnyAdminPermission() && !isFirstLogin;
});

central.pastDueDunningLeft = ko.pureComputed(function () {
	var subscription = central.subscription() || {};
	return moment().diff(subscription.recurlyCurrentPeriodEndsAt) + 1;
});

central.showRecurlyPastDue = ko.pureComputed(function () {
	var subscription = central.subscription() || {},
		user = central.user() || {},
		perm = central.global.getPermissionHandler(),
		daysLeft = central.pastDueDunningLeft();

	return subscription.recurlyPastDue && perm.hasAnyAdminPermission() && daysLeft > 0;
});

central.showTrialBar = ko
	.pureComputed(function () {
		const subscription = central.subscription() || {};
		const user = central.user() || {};
		const profile = central.profile();
		const group = central.group;

		const isTrial = subscription.kind == 'trial';
		const isFakeTrialForInternalAccounts =
			isTrial &&
			subscription.trialValidTo?.isAfter(moment()) &&
			subscription.trialValidTo?.diff(subscription.trialValidFrom, 'days') > process.env.TRIAL_DURATION;

		const isCancelledSubscription =
			subscription.status == 'cancelled_not_expired' || subscription.status == 'cancelled_expired';

		const isSubscriptionRecentlyActivated = moment(group?.raw?.activated)?.isAfter(moment().subtract(1, 'hour'));

		const isOnBillingPage = app?.router?.activeInstruction?.()?.fragment?.includes('admin/settings/account');

		// Show if:
		// - subscription in trial
		// - owner
		// - subscription not cancelled
		// - group.actived > 1 hour
		// - not on billing page
		const shouldShow =
			isTrial &&
			!isFakeTrialForInternalAccounts &&
			user.isOwner &&
			!isCancelledSubscription &&
			!isSubscriptionRecentlyActivated &&
			!isOnBillingPage;

		return shouldShow;
	})
	.extend({ throttle: 500 });

central.hasMoreLocationsUnthrottled = ko.pureComputed(function () {
	var locations = central.activeLocations();
	return locations != null && locations.length > 1;
});
central.hasMoreLocations = ko.pureComputed(central.hasMoreLocationsUnthrottled).extend({ throttle: 500 });

central.numItems = ko.computed(function () {
	var stats = central.stats();
	if (central.global) {
		return central.global.helper.getStat(stats, 'items', 'active');
	} else {
		return 0;
	}
});

central.numItemsLeft = ko.computed(function () {
	var limits = central.limits(),
		stats = central.stats();
	if (central.global && limits && stats) {
		return central.global.helper.getNumItemsLeft(limits, stats);
	} else {
		return 0;
	}
});

central.numCategories = ko.computed(function () {
	var stats = central.stats();
	if (central.global) {
		return central.global.helper.getStat(stats, 'categories', 'total');
	} else {
		return 0;
	}
});

central.numMaintenanceContacts = ko.computed(function () {
	var stats = central.stats();
	if (stats) {
		var contactKinds = central.global.helper.getStat(stats, 'customers', 'kind') || {},
			numMaintenance = contactKinds.maintenance || 0;

		return numMaintenance;
	} else {
		return 0;
	}
});

central.checkoutTemplates = ko
	.computed(function () {
		return $.grep(central._docTemplates() || [], function (templ, i) {
			return templ.kind == 'order';
		});
	})
	.extend({ throttle: 500 });

central.reservationTemplates = ko
	.computed(function () {
		return $.grep(central._docTemplates() || [], function (templ, i) {
			return templ.kind == 'reservation';
		});
	})
	.extend({ throttle: 500 });

central.contactTemplates = ko
	.computed(function () {
		return $.grep(central._docTemplates() || [], function (templ, i) {
			return templ.kind == 'customer';
		});
	})
	.extend({ throttle: 500 });

central.hoursFormat = ko.pureComputed(function () {
	var profile = central.profile() || {};
	return profile.timeFormat24 ? 'H:mm' : 'h:mm a';
});

central.currency = ko.pureComputed(function () {
	var profile = central.profile() || {};
	var currency = currencies.find(function (curr) {
		return curr.code == profile.currency;
	});

	return currency || { symbol: '$', code: 'USD' };
});

central.canUseMaintenance = ko.pureComputed(function () {
	var numMaintenanceContacts = central.numMaintenanceContacts();

	return numMaintenanceContacts > 0;
});

//
// Business logic
//
central.init = function (spec = {}) {
	system.log('central: init', spec);
	central.app = spec.app;
	central.global = spec.global;
	central.userId = spec.userId;
	central.locationId = spec.locationId;

	// Make a Group object (we'll load in its data later)
	central.group = modelFactory.getGroup(central.global, GROUP_FIELDS);

	central.app.on('location:change').then(function (location) {
		central.locationId = location ? location._id : null;
		central.location(location);

		central.loadStats();

		//Store location in localstorage
		central.global.storageHandler.save(central);
	});

	central.app.on('location:archived').then(function (archivedLocationId) {
		// If the archived location is the currently selected location in the navigation
		// The we want to reset back to all locations
		const currentLocation = central.location();
		if (currentLocation && currentLocation._id === archivedLocationId) {
			central.location(null);
		}
		central.loadLocations(true);
	});

	central.app.on('location:edited').then(function () {
		central.loadLocations(true);
	});

	// Load localstorage data
	central.global.storageHandler.load(central);

	return central.loadUser(true);
};

central.reload = function () {
	system.log('central: reload');

	central.loadItemCategories(true);
	central.loadStats();
	central.checkUserMessages();
	central.loadContactGroups();

	return central.loadLocations(true);
};

/**
 * loadUser loads the user by its pk
 * but also loads the group related stuff using the same response
 * @param force
 * @returns {*}
 */
central.loadUser = function (force) {
	system.log('central: loadUser', force);
	var user = central.user();

	if (user == null || force) {
		return central.global
			.getDataSource('users')
			.call(null, 'me', { _fields: USER_FIELDS })
			.then(function (data) {
				central._loadUser(data);
				return data;
			})
			.catch((e) => {
				if (e.code == 401) {
					central.onError(e);
					return Promise.reject(e);
				} else {
					app.trigger('error', 'Something went wrong, please try again.', true);
				}
			});
	} else {
		return Promise.resolve(user);
	}
};

/**
 * loadLocations loads all locations
 * @param force
 * @returns {*}
 */
let loadingLocations = false;
central.loadLocations = function (force) {
	system.log('central: loadLocations', force);
	var locations = central.locations();
	if (locations == null || force) {
		// Don't trigger multiple location calls if we already have one pending
		if (!force && loadingLocations) {
			return this.dfdLoadLocations;
		}

		locations = [];
		var fetchCount = 0;
		var loader = function (skip) {
			skip = skip || 0;
			return central.global
				.getDataSource('locations')
				.search({}, null, 250, skip, 'name')
				.then(function (resp) {
					locations = locations.concat(resp.docs);
					fetchCount += 250;

					if (resp.count > 250 && fetchCount < resp.count) {
						return loader(fetchCount);
					}

					return locations;
				});
		};

		this.dfdLoadLocations = loader()
			.then(function (data) {
				loadingLocations = true;

				central.locations(data);

				// Fill in location if locationId is given
				if (central.locationId) {
					var location = central._getLocationById(central.locationId, true);
					if (location && location.status == 'active') {
						central.location(location);
					}

					// If location doesn't exist anymore, update locationId
					if (!location) {
						central.locationId = null;
					}
				}

				return data;
			}, central.onError)
			.finally(() => (loadingLocations = false));

		return this.dfdLoadLocations;
	} else {
		return Promise.resolve(locations);
	}
};

/**
 * Add a new location
 * @param {cr.Location} location
 */
central.addLocation = function (location) {
	return location.create().then(function (data) {
		// Refresh locations in central
		return central.loadLocations(true).then(function () {
			return data;
		});
	});
};

/**
 * Update a location
 * @param {cr.Location} location
 */
central.updateLocation = function (location) {
	return location.update().then(function (data) {
		// Refresh locations in central
		return central.loadLocations(true).then(function () {
			return data;
		});
	});
};

/**
 * Unarchive a location
 * @param {string} locationId
 */
central.unarchiveLocation = function (locationId) {
	return central.global
		.getDataSource('locations')
		.call(locationId, 'undoArchive')
		.then(function (data) {
			// Refresh locations in central
			return central.loadLocations(true).then(function () {
				return data;
			});
		});
};

central.enableAddon = function (addon, skipRead) {
	return central.group
		._doApiCall({ method: 'enableAddon', params: { addon: addon }, _fields: GROUP_FIELDS, skipRead: true })
		.then(function (data) {
			if (skipRead != true) {
				return central._loadGroup(data);
			}
		});
};

central.disableAddon = function (addon, skipRead) {
	return central.group
		._doApiCall({ method: 'disableAddon', params: { addon: addon }, _fields: GROUP_FIELDS, skipRead: true })
		.then(function (data) {
			if (skipRead != true) {
				return central._loadGroup(data);
			}
		});
};

/**
 * Updates the group profile
 * @param params
 * @returns {*}
 */
central.updateGroupProfile = function (params, skipRead) {
	return central.group
		._doApiCall({ method: 'updateProfile', params: params, _fields: GROUP_FIELDS, skipRead: true })
		.then(function (data) {
			if (skipRead != true) {
				return central._loadGroup(data);
			}
		});
};

/**
 * Updates the group cleanup
 * @param params
 * @returns {*}
 */
central.updateGroupCleanup = function (params, skipRead) {
	return central.group._doApiCall({ method: 'updateCleanup', params: params, skipRead: true }).then(function (data) {
		if (skipRead != true) {
			return central._loadGroup(data);
		}
	});
};

/**
 * Updates the group name
 * @param name
 * @param skipRead
 * @returns {*}
 */
central.updateGroupName = function (name, skipRead) {
	return central.group
		._doApiCall({ method: 'updateName', params: { name: name }, skipRead: true })
		.then(function (data) {
			if (skipRead != true) {
				return central._loadGroup(data);
			}
		});
};

/**
 * Updates the group flags for a certain collection
 * @param collection
 * @param flags
 * @returns {*}
 */
central.updateGroupFlags = function (collection, flags, skipRead) {
	return central.group
		._doApiCall({ method: 'updateFlags', params: { collection: collection, flags: flags }, skipRead: true })
		.then(function (data) {
			if (skipRead != true) {
				central._loadGroup(data);
			}

			central.group.itemFlags = central.group.getFlags(data.itemFlags);

			return flags;
		});
};

/**
 * Creates a group flag
 * @param collection
 * @param flag
 * @returns {*}
 */
central.createFlag = function (flag, skipRead) {
	return central.group
		._doApiCall({
			method: 'createFlag',
			params: {
				collection: 'items',
				name: flag.name,
				description: flag.description,
				color: flag.color,
				available: flag.available,
			},
			skipRead: true,
		})
		.then(function (data) {
			if (skipRead != true) {
				central._loadGroup(data);
			}

			central.group.itemFlags = central.group.getFlags(data.itemFlags);

			app.trigger('flags:changed');

			return data;
		});
};

central.updateFlag = function (flag, skipRead) {
	return central.group
		._doApiCall({
			method: 'updateFlag',
			params: {
				collection: 'items',
				flagId: flag.id,
				name: flag.name,
				description: flag.description,
				color: flag.color,
				available: flag.available,
			},
			skipRead: true,
		})
		.then(function (data) {
			if (skipRead != true) {
				central._loadGroup(data);
			}

			central.group.itemFlags = central.group.getFlags(data.itemFlags);

			app.trigger('flags:changed');

			return data;
		});
};

central.deleteFlag = function (flagId, skipRead) {
	return central.group
		._doApiCall({
			method: 'deleteFlag',
			params: {
				collection: 'items',
				flagId: flagId,
			},
			skipRead: skipRead,
		})
		.then(function (data) {
			if (skipRead != true) {
				central._loadGroup(data);
			}

			central.group.itemFlags = central.group.getFlags(data.itemFlags);

			app.trigger('flags:changed');

			return data;
		});
};

central.moveFlag = function (oldPos, newPos, skipRead) {
	return central.group
		._doApiCall({
			method: 'moveFlag',
			params: {
				collection: 'items',
				oldPos: oldPos,
				newPos: newPos,
			},
			skipRead: skipRead,
		})
		.then(function (data) {
			if (skipRead != true) {
				central._loadGroup(data);
			}

			central.group.itemFlags = central.group.getFlags(data.itemFlags);

			app.trigger('flags:changed');

			return data;
		});
};

/**
 * Updates the depreciation configuration
 * @param params
 * @returns {*}
 */
central.updateGroupDepreciation = function (params, skipRead) {
	return central.group
		._doApiCall({ method: 'updateDepreciation', params: params, skipRead: true })
		.then(function (data) {
			if (skipRead != true) {
				return central._loadGroup(data);
			}
		});
};

/**
 * Adds label to given document collection
 * @param params
 * @returns {*}
 */
central.createLabel = function (collection, color, name, useAsDefault, skipRead) {
	return central.group.createLabel(collection, color, name, useAsDefault).then(function (data) {
		if (skipRead != true) {
			return central._loadGroup(data);
		}
	});
};

/**
 * Updates label for given document collection
 * @param params
 * @returns {*}
 */
central.updateLabel = function (collection, id, color, name, useAsDefault, skipRead) {
	return central.group.updateLabel(collection, id, color, name, useAsDefault).then(function (data) {
		if (skipRead != true) {
			return central._loadGroup(data);
		}
	});
};

/**
 * Removes label for given document collection
 * @param params
 * @returns {*}
 */
central.deleteLabel = function (collection, id, skipRead) {
	return central.group.deleteLabel(collection, id).then(function (data) {
		if (skipRead != true) {
			return central._loadGroup(data);
		}
	});
};

/**
 * Adds a field to given document collection
 * @param params
 * @returns {*}
 */
central.createField = function (
	collection,
	name,
	kind,
	required,
	showOnForm,
	unit,
	editor,
	description,
	select,
	search,
	skipRead
) {
	return central.group
		.createField(collection, name, kind, required, showOnForm, unit, editor, description, select, search, skipRead)
		.then(function (data) {
			if (skipRead != true) {
				return central._loadGroup(data);
			}

			return data;
		});
};

/**
 * Updates a field for given document collection
 * @param params
 * @returns {*}
 */
central.updateField = function (
	collection,
	name,
	newName,
	required,
	showOnForm,
	unit,
	editor,
	description,
	select,
	search,
	skipRead
) {
	return central.group
		.updateField(
			collection,
			name,
			newName,
			required,
			showOnForm,
			unit,
			editor,
			description,
			select,
			search,
			skipRead
		)
		.then(function (data) {
			if (skipRead != true) {
				return central._loadGroup(data);
			}

			return data;
		});
};

/**
 * Removes a field from given document collection
 * @param params
 * @returns {*}
 */
central.deleteField = function (collection, name, skipRead) {
	return central.group.deleteField(collection, name).then(function (data) {
		if (skipRead != true) {
			return central._loadGroup(data);
		}
	});
};

/**
 * Adds one or more categories based on text notation
 * @param {string} text
 */
central.addCategoriesFromText = function (text, skipRead) {
	return central.global
		.getDataSource('categories')
		.call(null, 'importFromTextNotation', { text: text })
		.then(function (data) {
			if (skipRead != true) {
				return central.loadItemCategories(true).then(function (cats) {
					return data;
				});
			}

			return data;
		});
};

/**
 * Deletes the category (and moves the items to another category)
 * @param categoryId
 * @param moveToCategoryId
 * @returns {*}
 */
central.deleteCategory = function (categoryId, moveToCategoryId) {
	return central.global
		.getDataSource('categories')
		.call(categoryId, 'deleteCategory', { moveToCategory: moveToCategoryId })
		.then(function (data) {
			return central.loadItemCategories(true).then(function (cats) {
				return cats;
			});
		});
};

/**
 * gets all categories for items
 * @param force
 * @returns {*}
 */
var itemCategoryDict = {};
central.loadItemCategories = function (force) {
	system.log('central: loadItemCategories', force);

	var categories = central.itemCategories();

	if (categories == null || force) {
		var categories = [];
		var queue = new cr.common.ajaxQueue();
		var params = { name__contains: '' };

		var getCategories = function (skip) {
			return function () {
				return central.global
					.getDataSource('categories')
					.search(params, null, 200, skip, 'pk')
					.then(function (cats) {
						categories = categories.concat(cats.docs);
					});
			};
		};

		return new Promise((resolve) => {
			central.global
				.getDataSource('categories')
				.search(params, null, 1, 0, 'pk')
				.then(function (resp) {
					for (var skip = 0; skip <= resp.count; skip += 200) {
						queue(getCategories(skip));
					}

					queue(function () {
						itemCategoryDict = formatCategories(categories);

						central.itemCategories(categories);

						resolve(categories);
						return Promise.resolve();
					});
				});
		});
	} else {
		return Promise.resolve(categories);
	}
};

/**
 * Checks if a specific user message needs to be shown
 * - show wizard steps?
 * - show first time account setup?
 */
central.checkUserMessages = function () {
	var user = central.user() || {},
		perm = central.global.getPermissionHandler();

	return new Promise(async (resolve, reject) => {
		if (user.messages != null && user.messages.length > 0) {
			// FIRST TIME WELCOME MESSAGE
			// -------
			var showAccountSetup =
				user.messages.find(function (msg) {
					return msg.kind == USER_MSG_FIRST_TIME_SETUP && msg.archived == null;
				}) != null;
			central.showAccountSetup(showAccountSetup);

			// SETUP WIZARD MESSAGE
			// -------
			// Only load setup module if USER_MSG_SETUP_WIZARD is found
			// AND
			// user role is admin
			var showSetupWizard =
				user.messages.find(function (msg) {
					return msg.kind == USER_MSG_SETUP_WIZARD && msg.archived == null;
				}) != null && !central.isAccountExpired();
			if (showSetupWizard && perm.hasAnyAdminPermission()) {
				const setupWizard = (await import('viewmodels/setup-wizard')).default;

				setupWizard.load().then(
					function () {
						central.setupWizard = setupWizard;
						central.showSetupWizard(setupWizard.stepsLeft() > 0);

						resolve();
					},
					function (resp) {
						if (user.role != 'admin') {
							reject(resp);
						} else {
							resolve();
						}
					}
				);
			} else {
				resolve();
				central.userArchiveSetupMessage();
			}
		} else {
			resolve();
		}
	});
};

/**
 * loadStats calls getStats for the current location or all locations
 * @param ignoreError
 * @returns {*}
 */
central.loadStats = function (ignoreError) {
	return new Promise((resolve) => {
		system.log('central: loadStats', ignoreError);
		var locationId = central.locationId && central.locationId != 'null' ? central.locationId : 'all';

		return central.global
			.getDataSource('groups')
			.call(central.groupId, 'getStats', { location: locationId })
			.then(
				function (data) {
					// Handle stats data
					central._loadStats(data);

					resolve(data);
				},
				ignoreError == true ? central.onSilentError : central.onError
			);
	});
};

/**
 * loadContactGroups loads all contact groups
 * @returns {*}
 */
central.loadContactGroups = function () {
	system.log('central: loadContactGroups');

	return graphQL({
		query: `
				query {
					contactGroups {
						id
						name
					}
				}
			`,
	}).then(({ data }) => central.contactGroups(data.contactGroups));
};

central.reset = function () {
	// Reset observables
	central.location(null);
	central.locations(null);
	central.user(null);
	central.itemCategories(null);
	central.showAccountSetup(false);
	central.showSetupWizard(false);
	central.groupId = null;
	central._docTemplates(null);
	central._labelTemplates(null);
	central._group(null);
	central.contactGroups(null);

	delete central._templates;
};

/**
 * Loads the known sheet layouts from json file
 * @param force
 * @returns {*}
 */
central.loadSheetLayouts = async function (force) {
	var layouts = central.sheetLayouts();
	if (layouts == null || force) {
		const sheetLayoutJson = await import('viewmodels/sheet-layouts.json');

		let resp = {
			...sheetLayoutJson,
		};

		// Currently only support custom items table
		var customSheetPath = (groupId) => {
			try {
				return `${__webpack_public_path__}templates/labels/${groupId}/label.json`;
			} catch (e) {
				return Promise.reject();
			}
		};
		layouts = [];

		var dfdCustom = new Promise((resolveCustom) => {
			fetch(customSheetPath(central.groupId))
				.then(
					async (response) => {
						if (response.ok) {
							const templates = await response.json();
							resp.data = resp.data.concat(templates);
						}
					},
					() => {}
				)
				.finally(() => {
					resolveCustom(resp);
				});
		});

		return dfdCustom.then(function (resp) {
			$.each(resp.data, function (i, doc) {
				var friendlySize = cr.common.getFriendlyTemplateSize(doc.pageWidth, doc.pageHeight, doc.unit);
				var friendlyText =
					(doc.numRows > 0 && doc.numCols > 0 ? doc.numRows + 'x' + doc.numCols + ' ' : '') + friendlySize;
				$.each(doc.codes, function (j, code) {
					layouts.push(
						$.extend(
							{
								name: code,
								friendly: code + " <span class='text-muted'>" + friendlyText + '</span>',
							},
							doc
						)
					);
				});
			});

			layouts = layouts.sort(function (a, b) {
				return a.friendly > b.friendly;
			});

			central.sheetLayouts(layouts);

			return layouts;
		});
	} else {
		return layouts;
	}
};

/**
 * Updates a template
 * @param template
 * @returns {promise}
 */
central.updateTemplate = function (template) {
	return template.update().then(function (data) {
		return central.loadTemplates(template.format, true).then(function () {
			return data;
		});
	});
};

/**
 * Clones a template
 * @param templateId
 * @returns {promise}
 */
central.cloneTemplate = function (templateId, templateFormat) {
	return central.global
		.getDataSource('templates')
		.call(templateId, 'clone')
		.then(function (data) {
			return central.loadTemplates(templateFormat, true).then(function () {
				return data;
			});
		});
};

/**
 * Deletes a template
 * @param templateId
 * @returns {promise}
 */
central.deleteTemplate = function (templateId, templateFormat) {
	return central.global
		.getDataSource('templates')
		.delete(templateId)
		.then(function (data) {
			return central.loadTemplates(templateFormat, true).then(function () {
				return data;
			});
		});
};

/**
 * Activates a template
 * @param templateId
 * @returns {promise}
 */
central.activateTemplate = function (templateId, templateFormat) {
	return central.global
		.getDataSource('templates')
		.call(templateId, 'activate')
		.then(function (data) {
			return central.loadTemplates(templateFormat, true).then(function () {
				return data;
			});
		});
};

/**
 * Deactivates a template
 * @param templateId
 * @returns {promise}
 */
central.deactivateTemplate = function (templateId, templateFormat) {
	return central.global
		.getDataSource('templates')
		.call(templateId, 'deactivate')
		.then(function (data) {
			return central.loadTemplates(templateFormat, true).then(function () {
				return data;
			});
		});
};

central.loadTemplates = function (force) {
	system.log('central: loadTemplates', force);

	var templates = central._templates;

	if (templates == null || force) {
		return central.global
			.getDataSource('templates')
			.search({ status: 'active' }, null, 100, null, 'format,name,kind,askSignature')
			.then(function (resp) {
				central._templates = resp.docs;

				var docTemplates = [],
					labelTemplates = [];

				$.each(resp.docs, function (i, doc) {
					if (doc.kind) {
						docTemplates.push(doc);
					} else if (['dymo', 'zebra', 'label'].indexOf(doc.format) != -1) {
						labelTemplates.push(doc);
					}
				});

				central._docTemplates(docTemplates);
				central._labelTemplates(labelTemplates);

				return resp.docs;
			});
	} else {
		return Promise.resolve(templates);
	}
};

central.loadRoles = function (force) {
	system.log('central: loadRoles', force);

	var roles = central._roles;

	if (roles == null || force) {
		return central.global
			.getDataSource('groups')
			.call(central.groupId, 'getUserRoles')
			.then(function (resp) {
				central._roles = resp;

				return resp.slice(0);
			});
	} else {
		return Promise.resolve(roles.slice(0));
	}
};

/**
 * getSubscriptions
 * @returns {*}
 */
central.getSubscriptions = function (custom, force) {
	var subscriptions = central.subscriptionsAll();

	if (subscriptions == null || force) {
		var params = {};
		if (custom) {
			params.custom = custom;
		}

		return central.global
			.getDataSource('groups')
			.call(central.groupId, 'getSubscriptionPlans', params)
			.then(function (resp) {
				central.subscriptionsAll(resp);
				return resp;
			});
	} else {
		return Promise.resolve(subscriptions);
	}
};

/**
 * userUpdateProfile
 */
central.userUpdateProfile = function (profile) {
	return central.global
		.getDataSource('users')
		.call(central.userId, 'updateProfile', profile, USER_FIELDS)
		.then(function (data) {
			central._loadUser(data, true);

			return data;
		});
};

/**
 * userUpdate
 */
central.userUpdate = function (user) {
	return central.global
		.getDataSource('users')
		.update(central.userId, user, USER_FIELDS)
		.then(function (data) {
			central._loadUser(data);

			return data;
		});
};

/**
 * userSetPicture
 */
central.userSetPicture = function (attachment) {
	return central.global
		.getDataSource('users')
		.call(central.userId, 'setPicture', { attachment: attachment._id }, USER_FIELDS)
		.then(function (data) {
			central._loadUser(data);

			return data;
		});
};

/**
 * userClearPicture
 */
central.userClearPicture = function (attachment) {
	return central.global
		.getDataSource('users')
		.call(central.userId, 'clearPicture', {}, USER_FIELDS)
		.then(function (data) {
			central._loadUser(data);

			return data;
		});
};

/**
 * userArchiveSetupMessage
 */
central.userArchiveSetupMessage = function () {
	system.log('central: userArchiveSetupMessage');
	var user = central.user(),
		found = null;

	if (user && user.messages) {
		found = user.messages.find(function (msg) {
			return msg.kind == USER_MSG_SETUP_WIZARD && msg.archived == null;
		});
	}

	// If no user message is found,
	// immediatelly hide setup panel
	if (!found) {
		central.showSetupWizard(false);
		return Promise.resolve();
	}

	return this.userArchiveMessage(found.id).then(function () {
		// update user show setup
		central.showSetupWizard(false);
	});
};

central.userArchiveFirstUseMessage = function () {
	var user = central.user() || { messages: [] },
		found = null;

	if (user && user.messages) {
		found = user.messages.find(function (msg) {
			return msg.kind == USER_MSG_FIRST_TIME_SETUP && msg.archived == null;
		});
	}

	// Skip if no user message is found
	if (!found) return;

	return this.userArchiveMessage(found.id).then(function () {
		central.showAccountSetup(false);
	});
};

/**
 * userArchiveMessage
 * @param id
 */
central.userArchiveMessage = function (id) {
	system.log('central: userArchiveMessage ' + id);
	return central.global.getDataSource('users').longCall(central.userId, 'archiveMessage', { id: id });
};

//
// Online / offline stuff
//
central.onOffline = function () {
	message.showOffline();
};

central.onOnline = function () {
	message.hideOffline();
};

/**
 * Transfers the ownership of the account to another admin user
 */
central.changeAccountOwner = function (user) {
	return central.group._doApiCall({ method: 'changeOwner', params: { user: user._id } }).then(function (data) {
		return central.loadUser(true).then(function () {
			central._loadGroup(data);
		});
	});
};

central.changeAccount = function (params) {
	// Call timeouts after 2 minute
	var timeOut = 2 * 60 * 1000;

	return central.group
		._doApiCall({
			method: params.verify ? 'changeAccountCustom' : 'changeAccount',
			params: params,
			_fields: GROUP_FIELDS,
			timeOut: timeOut,
		})
		.then(function (data) {
			//Also reload available plans
			return central.getSubscriptions(null, true).then(function () {
				return central._loadGroup(data);
			});
		});
};

central.changePlan = function (params) {
	return central.group
		._doApiCall({
			method: params.verify ? 'changePlanCustom' : 'changePlan',
			params: params,
			_fields: GROUP_FIELDS,
		})
		.then(function (data) {
			//Also reload available plans
			return central.getSubscriptions(null, true).then(function () {
				return central._loadGroup(data);
			});
		});
};

//
// Implementation
//
central._loadUser = function (data, skipGroup) {
	central.user(data);
	central.contact(data.customer);

	central._permissions = data.userRole.permissions;

	if (skipGroup == null || skipGroup == false) {
		central._loadGroup(data.group);
	}
};

central._loadGroup = function (data) {
	// When we have the user dictionary, along with the group
	// we can instantiate a PermissionHandler which will decide
	// which tabs we can see
	central.global.permissionHandler = new cr.PermissionHandler(
		central.user(),
		data.profile,
		data.limits,
		central._permissions,
		function (featureId) {
			return featureFlags.isFeatureEnabled(featureId);
		}
	);

	central.groupId = data._id;

	// Use the same JSON data to fill our Group object
	central.group._fromJson(data);

	central._loadGroupCleanUp(data);
	central._loadGroupLimits(data);
	central._loadGroupProfile(data);
	central._loadGroupSubscription(data);

	central._group(data);

	return data;
};

central._loadGroupProfile = function (data) {
	var profile = data.profile || {};
	var limits = data.limits || {};

	// Overwrite the available modules according to the subscription lilts
	profile.useOrders = profile.useOrders && limits.allowOrders;
	profile.useReservations = profile.useReservations && limits.allowReservations;
	profile.useOrderAgreements = profile.useOrderAgreements && limits.allowGeneratePdf;
	profile.useSelfService = profile.useSelfService && limits.allowSelfService;
	profile.useKits = profile.useKits && limits.allowKits;
	profile.useCustody = profile.useCustody && limits.allowCustody;

	//TODO replace by server side setting
	//profile.forceConflictResolving = true;

	// If useGeo isn't enabled, disable autoUpdateGeo automatically also
	profile.autoUpdateGeo = profile.useGeo && profile.autoUpdateGeo;

	// IMPORTANT: convert server settings 1-7 (Mon-Sun) to plugin supported setting 0-6 (Sun-Sat)
	profile.weekStart = profile.weekStart ? profile.weekStart % 7 : 1;

	central.profile(profile);

	// Load the momentjs locale depending on the profile
	if (profile.useHours) {
		var hoursFormat = central.hoursFormat();

		moment.locale('en', {
			week: {
				dow: profile.weekStart,
			},
			calendar: {
				lastDay: '[Yesterday at] ' + hoursFormat,
				sameDay: '[Today at] ' + hoursFormat,
				nextDay: '[Tomorrow at] ' + hoursFormat,
				lastWeek: '[last] dddd [at] ' + hoursFormat,
				nextWeek: 'dddd [at] ' + hoursFormat,
				// If not same year also show year in dates
				//http://momentjs.com/docs/#/customization/calendar/
				//http://stackoverflow.com/questions/29251500/how-to-display-just-today-with-nothing-else
				sameElse: function (now) {
					if (this.year() == now.year()) {
						return 'MMM DD [at] ' + hoursFormat;
					} else {
						return 'MMM DD YYYY [at] ' + hoursFormat;
					}
				},
			},
		});
	} else {
		moment.locale('en', {
			week: {
				dow: profile.weekStart,
			},
			calendar: {
				lastDay: '[Yesterday]',
				sameDay: '[Today]',
				nextDay: '[Tomorrow]',
				lastWeek: '[last] dddd',
				nextWeek: 'dddd',
				// If not same year also show year in dates
				//http://momentjs.com/docs/#/customization/calendar/
				//http://stackoverflow.com/questions/29251500/how-to-display-just-today-with-nothing-else
				sameElse: function (now) {
					if (this.year() == now.year()) {
						return 'MMM DD';
					} else {
						return 'MMM DD YYYY';
					}
				},
			},
		});
	}
};

central._loadGroupSubscription = function (data) {
	central.subscription(data.subscription || {});
};

central._loadGroupLimits = function (data) {
	central.limits(data.limits || {});
};

central._loadGroupCleanUp = function (data) {
	central.cleanup(data.cleanup || {});
};

central._loadLocations = function (data) {
	central.locations(data);
};

central._loadStats = function (data) {
	central.stats(data);
};

central._getLocationById = function (locationId, skipEmpty) {
	// Don't return any location info if locationId is null
	if (!locationId) return null;

	var locations = central.locations() || [];

	var foundLocation = locations.find(function (loc) {
		return loc._id == locationId;
	});

	// Add empty location
	if (!skipEmpty) {
		foundLocation = foundLocation || { _id: locationId, name: 'Unknown location' };
	}

	return foundLocation;
};

central._getItemCategoryById = function (categoryId) {
	return itemCategoryDict[categoryId] || {};
};

central._getLabelById = function (collection, labelId) {
	var labels = this.group[collection] || [];
	return labels.find(function (l) {
		return l.id == labelId;
	});
};

central._getFlagById = function (flagId) {
	var flags = this.group.itemFlags || [];
	return flags.find(function (l) {
		return l.id == flagId;
	});
};

central._getFullId = function () {
	return 'app.central';
};

central.getUpgradeLink = (params = {}) => {
	const queryString = new URLSearchParams(params).toString();
	return `/admin/settings/account/subscription${queryString ? `?${queryString}` : ''}`;
};

central._toStorageData = function () {
	return Promise.resolve({
		location: central.locationId,
	});
};

central._fromStorageData = function (data) {
	if (data) {
		if (data.location) {
			central.locationId = data.location;
		}
	}
	return Promise.resolve();
};

central.onError = function (err) {
	central.global.onError(err);
};

central.onSilentError = function (err) {
	// Trigger error if "401 Unauthorized"
	if (err && err.code == 401) {
		central.onError(err);
	}
};

export default central;

import ko from 'knockout';
import kov from 'knockout-validation';

export default {
	mixin: function (doc, options) {
		var orig_reset = doc.reset,
			orig_fromJson = doc._fromJson;

		doc._fromJson = function (data, options) {
			//Use apply to call function so we can pass the
			//Base context otherwise it would use Window context
			return orig_fromJson.apply(doc, [data, options]).then(function () {
				doc.showAllConflicts(false);
			});
		};

		doc.reset = async function () {
			//Use apply to call function so we can pass the
			//Base context otherwise it would use Window context
			await orig_reset.apply(doc);

			doc.oConflicts([]);
			doc.isBusyConflicts(false);
			doc.showAllConflicts(false);

			// Abort previous conflicts call
			if (doc.abortConflictsCall) doc.abortConflictsCall.abort();
		};

		doc.getConflictsPerKind = function () {
			var conflicts = doc.oConflicts();
			var dictionary = {};
			var uniqueItem = {};

			$.each(conflicts, function (i, conflict) {
				// only add unique item kind conflicts
				if (uniqueItem[conflict.kind + '_' + conflict.item]) return true;

				var conflictsPerKind = dictionary[conflict.kind] || [];
				conflictsPerKind.push(conflict);

				uniqueItem[conflict.kind + '_' + conflict.item] = true;

				dictionary[conflict.kind] = conflictsPerKind;
			});

			return dictionary;
		};

		// Observables
		// ----
		doc.oConflicts = ko.observable(doc.conflicts || []);
		doc.isBusyConflicts = ko.observable(false);
		doc.showAllConflicts = ko.observable(false);

		// Computables
		// ----
		doc.oConflictsPerItem = ko
			.pureComputed(function () {
				var conflicts = doc.oConflicts();
				var dictionary = {};

				$.each(conflicts, function (i, conflict) {
					var conflictsPerItem = dictionary[conflict.item] || [];
					conflictsPerItem.push(conflict);

					dictionary[conflict.item] = conflictsPerItem;
				});

				return Object.values(dictionary);
			})
			.extend({ throttle: 50 });

		doc.oOrderConflicts = ko.pureComputed(function () {
			var conflictsPerKind = doc.getConflictsPerKind();
			return conflictsPerKind['order'] || [];
		});

		doc.oConflictsMessage = ko.computed(function () {
			var conflictsPerItem = doc.oConflictsPerItem();
			return conflictsPerItem.length + ' unresolved conflict'.pluralize(conflictsPerItem.length);
		});

		doc.oVisibleConflicts = ko.pureComputed(function () {
			var conflicts = doc.oConflicts(),
				showAllConflicts = doc.showAllConflicts();

			return conflicts;
		});
	},
};

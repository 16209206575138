import { FC } from 'react';

export const Number: FC = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.1644 2.01352C10.7092 2.10431 11.0772 2.61954 10.9864 3.16431L10.3471 6.99991H16.3195L17.0136 2.83551C17.1044 2.29074 17.6196 1.92272 18.1644 2.01352C18.7091 2.10431 19.0772 2.61954 18.9864 3.16431L18.3471 6.99991H21C21.5523 6.99991 22 7.44763 22 7.99991C22 8.5522 21.5523 8.99991 21 8.99991H18.0138L17.0138 14.9999H20C20.5523 14.9999 21 15.4476 21 15.9999C21 16.5522 20.5523 16.9999 20 16.9999H16.6804L15.9864 21.1643C15.8956 21.7091 15.3803 22.0771 14.8356 21.9863C14.2908 21.8955 13.9228 21.3803 14.0136 20.8355L14.6528 16.9999H8.68045L7.98638 21.1643C7.89558 21.7091 7.38036 22.0771 6.83559 21.9863C6.29082 21.8955 5.9228 21.3803 6.01359 20.8355L6.65286 16.9999H3C2.44772 16.9999 2 16.5522 2 15.9999C2 15.4476 2.44772 14.9999 3 14.9999H6.98619L7.98619 8.99991H4C3.44772 8.99991 3 8.5522 3 7.99991C3 7.44763 3.44772 6.99991 4 6.99991H8.31953L9.01359 2.83551C9.10439 2.29074 9.61961 1.92272 10.1644 2.01352ZM10.0138 8.99991L9.01378 14.9999H14.9862L15.9862 8.99991H10.0138Z"
			fill="currentColor"
		/>
	</svg>
);

import { useEffect } from 'react';

export const useEventListener = (
	type: string,
	listener: EventListenerOrEventListenerObject,
	shouldAttach = true,
	target: EventTarget = window,
	options?: boolean | EventListenerOptions
): void => {
	useEffect(() => {
		if (!listener || !target || !shouldAttach) {
			return;
		}

		target.addEventListener(type, listener, options);
		return () => {
			target.removeEventListener(type, listener, options);
		};
	}, [listener, type, options, target, shouldAttach]);
};

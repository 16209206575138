/*!
 * Bootstrap v4.0.0-alpha.4 (http://getbootstrap.com)
 * Copyright 2011-2016 The Bootstrap Authors (https://github.com/twbs/bootstrap/graphs/contributors)
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

(function (root, factory) {
	if (typeof define === 'function' && define.amd) {
		// AMD. Register as an anonymous module unless amdModuleId is set
		define(['jquery', 'tether'], function (a0, tether) {
			return factory(a0, tether);
		});
	} else if (typeof exports === 'object') {
		// Node. Does not work with strict CommonJS, but
		// only CommonJS-like environments that support module.exports,
		// like Node.
		module.exports = factory(require('jquery'), require('tether'));
	} else {
		factory(jQuery);
	}
})(this, function (jQuery, Tether) {
	window.Tether = Tether;

	if (typeof jQuery === 'undefined') {
		throw new Error("Bootstrap's JavaScript requires jQuery");
	}

	+(function ($) {
		var version = $.fn.jquery.split(' ')[0].split('.');
		if (
			(version[0] < 2 && version[1] < 9) ||
			(version[0] == 1 && version[1] == 9 && version[2] < 1) ||
			version[0] >= 4
		) {
			throw new Error("Bootstrap's JavaScript requires at least jQuery v1.9.1 but less than v4.0.0");
		}
	})(jQuery);

	+(function ($) {
		/**
		 * --------------------------------------------------------------------------
		 * Bootstrap (v4.0.0-alpha.4): util.js
		 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
		 * --------------------------------------------------------------------------
		 */

		'use strict';

		var _get = function get(_x, _x2, _x3) {
			var _again = true;
			_function: while (_again) {
				var object = _x,
					property = _x2,
					receiver = _x3;
				_again = false;
				if (object === null) object = Function.prototype;
				var desc = Object.getOwnPropertyDescriptor(object, property);
				if (desc === undefined) {
					var parent = Object.getPrototypeOf(object);
					if (parent === null) {
						return undefined;
					} else {
						_x = parent;
						_x2 = property;
						_x3 = receiver;
						_again = true;
						desc = parent = undefined;
						continue _function;
					}
				} else if ('value' in desc) {
					return desc.value;
				} else {
					var getter = desc.get;
					if (getter === undefined) {
						return undefined;
					}
					return getter.call(receiver);
				}
			}
		};

		var _createClass = (function () {
			function defineProperties(target, props) {
				for (var i = 0; i < props.length; i++) {
					var descriptor = props[i];
					descriptor.enumerable = descriptor.enumerable || false;
					descriptor.configurable = true;
					if ('value' in descriptor) descriptor.writable = true;
					Object.defineProperty(target, descriptor.key, descriptor);
				}
			}
			return function (Constructor, protoProps, staticProps) {
				if (protoProps) defineProperties(Constructor.prototype, protoProps);
				if (staticProps) defineProperties(Constructor, staticProps);
				return Constructor;
			};
		})();

		function _inherits(subClass, superClass) {
			if (typeof superClass !== 'function' && superClass !== null) {
				throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass);
			}
			subClass.prototype = Object.create(superClass && superClass.prototype, {
				constructor: { value: subClass, enumerable: false, writable: true, configurable: true },
			});
			if (superClass)
				Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : (subClass.__proto__ = superClass);
		}

		function _classCallCheck(instance, Constructor) {
			if (!(instance instanceof Constructor)) {
				throw new TypeError('Cannot call a class as a function');
			}
		}

		var Util = (function ($) {
			/**
			 * ------------------------------------------------------------------------
			 * Private TransitionEnd Helpers
			 * ------------------------------------------------------------------------
			 */

			var transition = false;

			var MAX_UID = 1000000;

			var TransitionEndEvent = {
				WebkitTransition: 'webkitTransitionEnd',
				MozTransition: 'transitionend',
				OTransition: 'oTransitionEnd otransitionend',
				transition: 'transitionend',
			};

			// shoutout AngusCroll (https://goo.gl/pxwQGp)
			function toType(obj) {
				return {}.toString
					.call(obj)
					.match(/\s([a-zA-Z]+)/)[1]
					.toLowerCase();
			}

			function isElement(obj) {
				return (obj[0] || obj).nodeType;
			}

			function getSpecialTransitionEndEvent() {
				return {
					bindType: transition.end,
					delegateType: transition.end,
					handle: function handle(event) {
						if ($(event.target).is(this)) {
							return event.handleObj.handler.apply(this, arguments); // eslint-disable-line prefer-rest-params
						}
						return undefined;
					},
				};
			}

			function transitionEndTest() {
				if (window.QUnit) {
					return false;
				}

				var el = document.createElement('bootstrap');

				for (var _name in TransitionEndEvent) {
					if (el.style[_name] !== undefined) {
						return { end: TransitionEndEvent[_name] };
					}
				}

				return false;
			}

			function transitionEndEmulator(duration) {
				var _this = this;

				var called = false;

				$(this).one(Util.TRANSITION_END, function () {
					called = true;
				});

				setTimeout(function () {
					if (!called) {
						Util.triggerTransitionEnd(_this);
					}
				}, duration);

				return this;
			}

			function setTransitionEndSupport() {
				transition = transitionEndTest();

				$.fn.emulateTransitionEnd = transitionEndEmulator;

				if (Util.supportsTransitionEnd()) {
					$.event.special[Util.TRANSITION_END] = getSpecialTransitionEndEvent();
				}
			}

			/**
			 * --------------------------------------------------------------------------
			 * Public Util Api
			 * --------------------------------------------------------------------------
			 */

			var Util = {
				TRANSITION_END: 'bsTransitionEnd',

				getUID: function getUID(prefix) {
					do {
						/* eslint-disable no-bitwise */
						prefix += ~~(Math.random() * MAX_UID); // "~~" acts like a faster Math.floor() here
						/* eslint-enable no-bitwise */
					} while (document.getElementById(prefix));
					return prefix;
				},

				getSelectorFromElement: function getSelectorFromElement(element) {
					var selector = element.getAttribute('data-target');

					if (!selector) {
						selector = element.getAttribute('href') || '';
						selector = /^#[a-z]/i.test(selector) ? selector : null;
					}

					return selector;
				},

				reflow: function reflow(element) {
					new Function('bs', 'return bs')(element.offsetHeight);
				},

				triggerTransitionEnd: function triggerTransitionEnd(element) {
					$(element).trigger(transition.end);
				},

				supportsTransitionEnd: function supportsTransitionEnd() {
					return Boolean(transition);
				},

				typeCheckConfig: function typeCheckConfig(componentName, config, configTypes) {
					for (var property in configTypes) {
						if (configTypes.hasOwnProperty(property)) {
							var expectedTypes = configTypes[property];
							var value = config[property];
							var valueType = undefined;

							if (value && isElement(value)) {
								valueType = 'element';
							} else {
								valueType = toType(value);
							}

							if (!new RegExp(expectedTypes).test(valueType)) {
								throw new Error(
									componentName.toUpperCase() +
										': ' +
										('Option "' + property + '" provided type "' + valueType + '" ') +
										('but expected type "' + expectedTypes + '".')
								);
							}
						}
					}
				},
			};

			setTransitionEndSupport();

			return Util;
		})(jQuery);

		/**
		 * --------------------------------------------------------------------------
		 * Bootstrap (v4.0.0-alpha.4): alert.js
		 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
		 * --------------------------------------------------------------------------
		 */

		var Alert = (function ($) {
			/**
			 * ------------------------------------------------------------------------
			 * Constants
			 * ------------------------------------------------------------------------
			 */

			var NAME = 'alert';
			var VERSION = '4.0.0-alpha.4';
			var DATA_KEY = 'bs.alert';
			var EVENT_KEY = '.' + DATA_KEY;
			var DATA_API_KEY = '.data-api';
			var JQUERY_NO_CONFLICT = $.fn[NAME];
			var TRANSITION_DURATION = 150;

			var Selector = {
				DISMISS: '[data-dismiss="alert"]',
			};

			var Event = {
				CLOSE: 'close' + EVENT_KEY,
				CLOSED: 'closed' + EVENT_KEY,
				CLICK_DATA_API: 'click' + EVENT_KEY + DATA_API_KEY,
			};

			var ClassName = {
				ALERT: 'alert',
				FADE: 'fade',
				IN: 'in',
			};

			/**
			 * ------------------------------------------------------------------------
			 * Class Definition
			 * ------------------------------------------------------------------------
			 */

			var Alert = (function () {
				function Alert(element) {
					_classCallCheck(this, Alert);

					this._element = element;
				}

				/**
				 * ------------------------------------------------------------------------
				 * Data Api implementation
				 * ------------------------------------------------------------------------
				 */

				// getters

				_createClass(
					Alert,
					[
						{
							key: 'close',

							// public

							value: function close(element) {
								element = element || this._element;

								var rootElement = this._getRootElement(element);
								var customEvent = this._triggerCloseEvent(rootElement);

								if (customEvent.isDefaultPrevented()) {
									return;
								}

								this._removeElement(rootElement);
							},
						},
						{
							key: 'dispose',
							value: function dispose() {
								$.removeData(this._element, DATA_KEY);
								this._element = null;
							},

							// private
						},
						{
							key: '_getRootElement',
							value: function _getRootElement(element) {
								var selector = Util.getSelectorFromElement(element);
								var parent = false;

								if (selector) {
									parent = $(selector)[0];
								}

								if (!parent) {
									parent = $(element).closest('.' + ClassName.ALERT)[0];
								}

								return parent;
							},
						},
						{
							key: '_triggerCloseEvent',
							value: function _triggerCloseEvent(element) {
								var closeEvent = $.Event(Event.CLOSE);

								$(element).trigger(closeEvent);
								return closeEvent;
							},
						},
						{
							key: '_removeElement',
							value: function _removeElement(element) {
								$(element).removeClass(ClassName.IN);

								if (!Util.supportsTransitionEnd() || !$(element).hasClass(ClassName.FADE)) {
									this._destroyElement(element);
									return;
								}

								$(element)
									.one(Util.TRANSITION_END, $.proxy(this._destroyElement, this, element))
									.emulateTransitionEnd(TRANSITION_DURATION);
							},
						},
						{
							key: '_destroyElement',
							value: function _destroyElement(element) {
								$(element).detach().trigger(Event.CLOSED).remove();
							},

							// static
						},
					],
					[
						{
							key: '_jQueryInterface',
							value: function _jQueryInterface(config) {
								return this.each(function () {
									var $element = $(this);
									var data = $element.data(DATA_KEY);

									if (!data) {
										data = new Alert(this);
										$element.data(DATA_KEY, data);
									}

									if (config === 'close') {
										data[config](this);
									}
								});
							},
						},
						{
							key: '_handleDismiss',
							value: function _handleDismiss(alertInstance) {
								return function (event) {
									if (event) {
										event.preventDefault();
									}

									alertInstance.close(this);
								};
							},
						},
						{
							key: 'VERSION',
							get: function get() {
								return VERSION;
							},
						},
					]
				);

				return Alert;
			})();

			$(document).on(Event.CLICK_DATA_API, Selector.DISMISS, Alert._handleDismiss(new Alert()));

			/**
			 * ------------------------------------------------------------------------
			 * jQuery
			 * ------------------------------------------------------------------------
			 */

			$.fn[NAME] = Alert._jQueryInterface;
			$.fn[NAME].Constructor = Alert;
			$.fn[NAME].noConflict = function () {
				$.fn[NAME] = JQUERY_NO_CONFLICT;
				return Alert._jQueryInterface;
			};

			return Alert;
		})(jQuery);

		/**
		 * --------------------------------------------------------------------------
		 * Bootstrap (v4.0.0-alpha.4): button.js
		 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
		 * --------------------------------------------------------------------------
		 */

		var Button = (function ($) {
			/**
			 * ------------------------------------------------------------------------
			 * Constants
			 * ------------------------------------------------------------------------
			 */

			var NAME = 'button';
			var VERSION = '4.0.0-alpha.4';
			var DATA_KEY = 'bs.button';
			var EVENT_KEY = '.' + DATA_KEY;
			var DATA_API_KEY = '.data-api';
			var JQUERY_NO_CONFLICT = $.fn[NAME];

			var ClassName = {
				ACTIVE: 'active',
				BUTTON: 'btn',
				FOCUS: 'focus',
			};

			var Selector = {
				DATA_TOGGLE_CARROT: '[data-toggle^="button"]',
				DATA_TOGGLE: '[data-toggle="buttons"]',
				INPUT: 'input',
				ACTIVE: '.active',
				BUTTON: '.btn',
			};

			var Event = {
				CLICK_DATA_API: 'click' + EVENT_KEY + DATA_API_KEY,
				FOCUS_BLUR_DATA_API: 'focus' + EVENT_KEY + DATA_API_KEY + ' ' + ('blur' + EVENT_KEY + DATA_API_KEY),
			};

			/**
			 * ------------------------------------------------------------------------
			 * Class Definition
			 * ------------------------------------------------------------------------
			 */

			var Button = (function () {
				function Button(element) {
					_classCallCheck(this, Button);

					this._element = element;
				}

				/**
				 * ------------------------------------------------------------------------
				 * Data Api implementation
				 * ------------------------------------------------------------------------
				 */

				// getters

				_createClass(
					Button,
					[
						{
							key: 'toggle',

							// public

							value: function toggle() {
								var triggerChangeEvent = true;
								var rootElement = $(this._element).closest(Selector.DATA_TOGGLE)[0];

								if (rootElement) {
									var input = $(this._element).find(Selector.INPUT)[0];

									if (input) {
										if (input.type === 'radio') {
											if (input.checked && $(this._element).hasClass(ClassName.ACTIVE)) {
												triggerChangeEvent = false;
											} else {
												var activeElement = $(rootElement).find(Selector.ACTIVE)[0];

												if (activeElement) {
													$(activeElement).removeClass(ClassName.ACTIVE);
												}
											}
										}

										if (triggerChangeEvent) {
											input.checked = !$(this._element).hasClass(ClassName.ACTIVE);
											$(this._element).trigger('change');
										}

										input.focus();
									}
								} else {
									this._element.setAttribute(
										'aria-pressed',
										!$(this._element).hasClass(ClassName.ACTIVE)
									);
								}

								if (triggerChangeEvent) {
									$(this._element).toggleClass(ClassName.ACTIVE);
								}
							},
						},
						{
							key: 'dispose',
							value: function dispose() {
								$.removeData(this._element, DATA_KEY);
								this._element = null;
							},

							// static
						},
					],
					[
						{
							key: '_jQueryInterface',
							value: function _jQueryInterface(config) {
								return this.each(function () {
									var data = $(this).data(DATA_KEY);

									if (!data) {
										data = new Button(this);
										$(this).data(DATA_KEY, data);
									}

									if (config === 'toggle') {
										data[config]();
									}
								});
							},
						},
						{
							key: 'VERSION',
							get: function get() {
								return VERSION;
							},
						},
					]
				);

				return Button;
			})();

			$(document)
				.on(Event.CLICK_DATA_API, Selector.DATA_TOGGLE_CARROT, function (event) {
					event.preventDefault();

					var button = event.target;

					if (!$(button).hasClass(ClassName.BUTTON)) {
						button = $(button).closest(Selector.BUTTON);
					}

					Button._jQueryInterface.call($(button), 'toggle');
				})
				.on(Event.FOCUS_BLUR_DATA_API, Selector.DATA_TOGGLE_CARROT, function (event) {
					var button = $(event.target).closest(Selector.BUTTON)[0];
					$(button).toggleClass(ClassName.FOCUS, /^focus(in)?$/.test(event.type));
				});

			/**
			 * ------------------------------------------------------------------------
			 * jQuery
			 * ------------------------------------------------------------------------
			 */

			$.fn[NAME] = Button._jQueryInterface;
			$.fn[NAME].Constructor = Button;
			$.fn[NAME].noConflict = function () {
				$.fn[NAME] = JQUERY_NO_CONFLICT;
				return Button._jQueryInterface;
			};

			return Button;
		})(jQuery);

		/**
		 * --------------------------------------------------------------------------
		 * Bootstrap (v4.0.0-alpha.4): carousel.js
		 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
		 * --------------------------------------------------------------------------
		 */

		var Carousel = (function ($) {
			/**
			 * ------------------------------------------------------------------------
			 * Constants
			 * ------------------------------------------------------------------------
			 */

			var NAME = 'carousel';
			var VERSION = '4.0.0-alpha.4';
			var DATA_KEY = 'bs.carousel';
			var EVENT_KEY = '.' + DATA_KEY;
			var DATA_API_KEY = '.data-api';
			var JQUERY_NO_CONFLICT = $.fn[NAME];
			var TRANSITION_DURATION = 600;
			var ARROW_LEFT_KEYCODE = 37; // KeyboardEvent.which value for left arrow key
			var ARROW_RIGHT_KEYCODE = 39; // KeyboardEvent.which value for right arrow key

			var Default = {
				interval: 5000,
				keyboard: true,
				slide: false,
				pause: 'hover',
				wrap: true,
			};

			var DefaultType = {
				interval: '(number|boolean)',
				keyboard: 'boolean',
				slide: '(boolean|string)',
				pause: '(string|boolean)',
				wrap: 'boolean',
			};

			var Direction = {
				NEXT: 'next',
				PREVIOUS: 'prev',
			};

			var Event = {
				SLIDE: 'slide' + EVENT_KEY,
				SLID: 'slid' + EVENT_KEY,
				KEYDOWN: 'keydown' + EVENT_KEY,
				MOUSEENTER: 'mouseenter' + EVENT_KEY,
				MOUSELEAVE: 'mouseleave' + EVENT_KEY,
				LOAD_DATA_API: 'load' + EVENT_KEY + DATA_API_KEY,
				CLICK_DATA_API: 'click' + EVENT_KEY + DATA_API_KEY,
			};

			var ClassName = {
				CAROUSEL: 'carousel',
				ACTIVE: 'active',
				SLIDE: 'slide',
				RIGHT: 'right',
				LEFT: 'left',
				ITEM: 'carousel-item',
			};

			var Selector = {
				ACTIVE: '.active',
				ACTIVE_ITEM: '.active.carousel-item',
				ITEM: '.carousel-item',
				NEXT_PREV: '.next, .prev',
				INDICATORS: '.carousel-indicators',
				DATA_SLIDE: '[data-slide], [data-slide-to]',
				DATA_RIDE: '[data-ride="carousel"]',
			};

			/**
			 * ------------------------------------------------------------------------
			 * Class Definition
			 * ------------------------------------------------------------------------
			 */

			var Carousel = (function () {
				function Carousel(element, config) {
					_classCallCheck(this, Carousel);

					this._items = null;
					this._interval = null;
					this._activeElement = null;

					this._isPaused = false;
					this._isSliding = false;

					this._config = this._getConfig(config);
					this._element = $(element)[0];
					this._indicatorsElement = $(this._element).find(Selector.INDICATORS)[0];

					this._addEventListeners();
				}

				/**
				 * ------------------------------------------------------------------------
				 * Data Api implementation
				 * ------------------------------------------------------------------------
				 */

				// getters

				_createClass(
					Carousel,
					[
						{
							key: 'next',

							// public

							value: function next() {
								if (!this._isSliding) {
									this._slide(Direction.NEXT);
								}
							},
						},
						{
							key: 'nextWhenVisible',
							value: function nextWhenVisible() {
								// Don't call next when the page isn't visible
								if (!document.hidden) {
									this.next();
								}
							},
						},
						{
							key: 'prev',
							value: function prev() {
								if (!this._isSliding) {
									this._slide(Direction.PREVIOUS);
								}
							},
						},
						{
							key: 'pause',
							value: function pause(event) {
								if (!event) {
									this._isPaused = true;
								}

								if ($(this._element).find(Selector.NEXT_PREV)[0] && Util.supportsTransitionEnd()) {
									Util.triggerTransitionEnd(this._element);
									this.cycle(true);
								}

								clearInterval(this._interval);
								this._interval = null;
							},
						},
						{
							key: 'cycle',
							value: function cycle(event) {
								if (!event) {
									this._isPaused = false;
								}

								if (this._interval) {
									clearInterval(this._interval);
									this._interval = null;
								}

								if (this._config.interval && !this._isPaused) {
									this._interval = setInterval(
										$.proxy(document.visibilityState ? this.nextWhenVisible : this.next, this),
										this._config.interval
									);
								}
							},
						},
						{
							key: 'to',
							value: function to(index) {
								var _this2 = this;

								this._activeElement = $(this._element).find(Selector.ACTIVE_ITEM)[0];

								var activeIndex = this._getItemIndex(this._activeElement);

								if (index > this._items.length - 1 || index < 0) {
									return;
								}

								if (this._isSliding) {
									$(this._element).one(Event.SLID, function () {
										return _this2.to(index);
									});
									return;
								}

								if (activeIndex === index) {
									this.pause();
									this.cycle();
									return;
								}

								var direction = index > activeIndex ? Direction.NEXT : Direction.PREVIOUS;

								this._slide(direction, this._items[index]);
							},
						},
						{
							key: 'dispose',
							value: function dispose() {
								$(this._element).off(EVENT_KEY);
								$.removeData(this._element, DATA_KEY);

								this._items = null;
								this._config = null;
								this._element = null;
								this._interval = null;
								this._isPaused = null;
								this._isSliding = null;
								this._activeElement = null;
								this._indicatorsElement = null;
							},

							// private
						},
						{
							key: '_getConfig',
							value: function _getConfig(config) {
								config = $.extend({}, Default, config);
								Util.typeCheckConfig(NAME, config, DefaultType);
								return config;
							},
						},
						{
							key: '_addEventListeners',
							value: function _addEventListeners() {
								if (this._config.keyboard) {
									$(this._element).on(Event.KEYDOWN, $.proxy(this._keydown, this));
								}

								if (this._config.pause === 'hover' && !('ontouchstart' in document.documentElement)) {
									$(this._element)
										.on(Event.MOUSEENTER, $.proxy(this.pause, this))
										.on(Event.MOUSELEAVE, $.proxy(this.cycle, this));
								}
							},
						},
						{
							key: '_keydown',
							value: function _keydown(event) {
								event.preventDefault();

								if (/input|textarea/i.test(event.target.tagName)) {
									return;
								}

								switch (event.which) {
									case ARROW_LEFT_KEYCODE:
										this.prev();
										break;
									case ARROW_RIGHT_KEYCODE:
										this.next();
										break;
									default:
										return;
								}
							},
						},
						{
							key: '_getItemIndex',
							value: function _getItemIndex(element) {
								this._items = $.makeArray($(element).parent().find(Selector.ITEM));
								return this._items.indexOf(element);
							},
						},
						{
							key: '_getItemByDirection',
							value: function _getItemByDirection(direction, activeElement) {
								var isNextDirection = direction === Direction.NEXT;
								var isPrevDirection = direction === Direction.PREVIOUS;
								var activeIndex = this._getItemIndex(activeElement);
								var lastItemIndex = this._items.length - 1;
								var isGoingToWrap =
									(isPrevDirection && activeIndex === 0) ||
									(isNextDirection && activeIndex === lastItemIndex);

								if (isGoingToWrap && !this._config.wrap) {
									return activeElement;
								}

								var delta = direction === Direction.PREVIOUS ? -1 : 1;
								var itemIndex = (activeIndex + delta) % this._items.length;

								return itemIndex === -1 ? this._items[this._items.length - 1] : this._items[itemIndex];
							},
						},
						{
							key: '_triggerSlideEvent',
							value: function _triggerSlideEvent(relatedTarget, directionalClassname) {
								var slideEvent = $.Event(Event.SLIDE, {
									relatedTarget: relatedTarget,
									direction: directionalClassname,
								});

								$(this._element).trigger(slideEvent);

								return slideEvent;
							},
						},
						{
							key: '_setActiveIndicatorElement',
							value: function _setActiveIndicatorElement(element) {
								if (this._indicatorsElement) {
									$(this._indicatorsElement).find(Selector.ACTIVE).removeClass(ClassName.ACTIVE);

									var nextIndicator = this._indicatorsElement.children[this._getItemIndex(element)];

									if (nextIndicator) {
										$(nextIndicator).addClass(ClassName.ACTIVE);
									}
								}
							},
						},
						{
							key: '_slide',
							value: function _slide(direction, element) {
								var _this3 = this;

								var activeElement = $(this._element).find(Selector.ACTIVE_ITEM)[0];
								var nextElement =
									element || (activeElement && this._getItemByDirection(direction, activeElement));

								var isCycling = Boolean(this._interval);

								var directionalClassName =
									direction === Direction.NEXT ? ClassName.LEFT : ClassName.RIGHT;

								if (nextElement && $(nextElement).hasClass(ClassName.ACTIVE)) {
									this._isSliding = false;
									return;
								}

								var slideEvent = this._triggerSlideEvent(nextElement, directionalClassName);
								if (slideEvent.isDefaultPrevented()) {
									return;
								}

								if (!activeElement || !nextElement) {
									// some weirdness is happening, so we bail
									return;
								}

								this._isSliding = true;

								if (isCycling) {
									this.pause();
								}

								this._setActiveIndicatorElement(nextElement);

								var slidEvent = $.Event(Event.SLID, {
									relatedTarget: nextElement,
									direction: directionalClassName,
								});

								if (Util.supportsTransitionEnd() && $(this._element).hasClass(ClassName.SLIDE)) {
									$(nextElement).addClass(direction);

									Util.reflow(nextElement);

									$(activeElement).addClass(directionalClassName);
									$(nextElement).addClass(directionalClassName);

									$(activeElement)
										.one(Util.TRANSITION_END, function () {
											$(nextElement).removeClass(directionalClassName).removeClass(direction);

											$(nextElement).addClass(ClassName.ACTIVE);

											$(activeElement)
												.removeClass(ClassName.ACTIVE)
												.removeClass(direction)
												.removeClass(directionalClassName);

											_this3._isSliding = false;

											setTimeout(function () {
												return $(_this3._element).trigger(slidEvent);
											}, 0);
										})
										.emulateTransitionEnd(TRANSITION_DURATION);
								} else {
									$(activeElement).removeClass(ClassName.ACTIVE);
									$(nextElement).addClass(ClassName.ACTIVE);

									this._isSliding = false;
									$(this._element).trigger(slidEvent);
								}

								if (isCycling) {
									this.cycle();
								}
							},

							// static
						},
					],
					[
						{
							key: '_jQueryInterface',
							value: function _jQueryInterface(config) {
								return this.each(function () {
									var data = $(this).data(DATA_KEY);
									var _config = $.extend({}, Default, $(this).data());

									if (typeof config === 'object') {
										$.extend(_config, config);
									}

									var action = typeof config === 'string' ? config : _config.slide;

									if (!data) {
										data = new Carousel(this, _config);
										$(this).data(DATA_KEY, data);
									}

									if (typeof config === 'number') {
										data.to(config);
									} else if (typeof action === 'string') {
										if (data[action] === undefined) {
											throw new Error('No method named "' + action + '"');
										}
										data[action]();
									} else if (_config.interval) {
										data.pause();
										data.cycle();
									}
								});
							},
						},
						{
							key: '_dataApiClickHandler',
							value: function _dataApiClickHandler(event) {
								var selector = Util.getSelectorFromElement(this);

								if (!selector) {
									return;
								}

								var target = $(selector)[0];

								if (!target || !$(target).hasClass(ClassName.CAROUSEL)) {
									return;
								}

								var config = $.extend({}, $(target).data(), $(this).data());
								var slideIndex = this.getAttribute('data-slide-to');

								if (slideIndex) {
									config.interval = false;
								}

								Carousel._jQueryInterface.call($(target), config);

								if (slideIndex) {
									$(target).data(DATA_KEY).to(slideIndex);
								}

								event.preventDefault();
							},
						},
						{
							key: 'VERSION',
							get: function get() {
								return VERSION;
							},
						},
						{
							key: 'Default',
							get: function get() {
								return Default;
							},
						},
					]
				);

				return Carousel;
			})();

			$(document).on(Event.CLICK_DATA_API, Selector.DATA_SLIDE, Carousel._dataApiClickHandler);

			$(window).on(Event.LOAD_DATA_API, function () {
				$(Selector.DATA_RIDE).each(function () {
					var $carousel = $(this);
					Carousel._jQueryInterface.call($carousel, $carousel.data());
				});
			});

			/**
			 * ------------------------------------------------------------------------
			 * jQuery
			 * ------------------------------------------------------------------------
			 */

			$.fn[NAME] = Carousel._jQueryInterface;
			$.fn[NAME].Constructor = Carousel;
			$.fn[NAME].noConflict = function () {
				$.fn[NAME] = JQUERY_NO_CONFLICT;
				return Carousel._jQueryInterface;
			};

			return Carousel;
		})(jQuery);

		/**
		 * --------------------------------------------------------------------------
		 * Bootstrap (v4.0.0-alpha.4): collapse.js
		 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
		 * --------------------------------------------------------------------------
		 */

		var Collapse = (function ($) {
			/**
			 * ------------------------------------------------------------------------
			 * Constants
			 * ------------------------------------------------------------------------
			 */

			var NAME = 'collapse';
			var VERSION = '4.0.0-alpha.4';
			var DATA_KEY = 'bs.collapse';
			var EVENT_KEY = '.' + DATA_KEY;
			var DATA_API_KEY = '.data-api';
			var JQUERY_NO_CONFLICT = $.fn[NAME];
			var TRANSITION_DURATION = 600;

			var Default = {
				toggle: true,
				parent: '',
			};

			var DefaultType = {
				toggle: 'boolean',
				parent: 'string',
			};

			var Event = {
				SHOW: 'show' + EVENT_KEY,
				SHOWN: 'shown' + EVENT_KEY,
				HIDE: 'hide' + EVENT_KEY,
				HIDDEN: 'hidden' + EVENT_KEY,
				CLICK_DATA_API: 'click' + EVENT_KEY + DATA_API_KEY,
			};

			var ClassName = {
				IN: 'in',
				COLLAPSE: 'collapse',
				COLLAPSING: 'collapsing',
				COLLAPSED: 'collapsed',
			};

			var Dimension = {
				WIDTH: 'width',
				HEIGHT: 'height',
			};

			var Selector = {
				ACTIVES: '.panel > .in, .panel > .collapsing',
				DATA_TOGGLE: '[data-toggle="collapse"]',
			};

			/**
			 * ------------------------------------------------------------------------
			 * Class Definition
			 * ------------------------------------------------------------------------
			 */

			var Collapse = (function () {
				function Collapse(element, config) {
					_classCallCheck(this, Collapse);

					this._isTransitioning = false;
					this._element = element;
					this._config = this._getConfig(config);
					this._triggerArray = $.makeArray(
						$(
							'[data-toggle="collapse"][href="#' +
								element.id +
								'"],' +
								('[data-toggle="collapse"][data-target="#' + element.id + '"]')
						)
					);

					this._parent = this._config.parent ? this._getParent() : null;

					if (!this._config.parent) {
						this._addAriaAndCollapsedClass(this._element, this._triggerArray);
					}

					if (this._config.toggle) {
						this.toggle();
					}
				}

				/**
				 * ------------------------------------------------------------------------
				 * Data Api implementation
				 * ------------------------------------------------------------------------
				 */

				// getters

				_createClass(
					Collapse,
					[
						{
							key: 'toggle',

							// public

							value: function toggle() {
								if ($(this._element).hasClass(ClassName.IN)) {
									this.hide();
								} else {
									this.show();
								}
							},
						},
						{
							key: 'show',
							value: function show() {
								var _this4 = this;

								if (this._isTransitioning || $(this._element).hasClass(ClassName.IN)) {
									return;
								}

								var actives = undefined;
								var activesData = undefined;

								if (this._parent) {
									actives = $.makeArray($(Selector.ACTIVES));
									if (!actives.length) {
										actives = null;
									}
								}

								if (actives) {
									activesData = $(actives).data(DATA_KEY);
									if (activesData && activesData._isTransitioning) {
										return;
									}
								}

								var startEvent = $.Event(Event.SHOW);
								$(this._element).trigger(startEvent);
								if (startEvent.isDefaultPrevented()) {
									return;
								}

								if (actives) {
									Collapse._jQueryInterface.call($(actives), 'hide');
									if (!activesData) {
										$(actives).data(DATA_KEY, null);
									}
								}

								var dimension = this._getDimension();

								$(this._element).removeClass(ClassName.COLLAPSE).addClass(ClassName.COLLAPSING);

								this._element.style[dimension] = 0;
								this._element.setAttribute('aria-expanded', true);

								if (this._triggerArray.length) {
									$(this._triggerArray).removeClass(ClassName.COLLAPSED).attr('aria-expanded', true);
								}

								this.setTransitioning(true);

								var complete = function complete() {
									$(_this4._element)
										.removeClass(ClassName.COLLAPSING)
										.addClass(ClassName.COLLAPSE)
										.addClass(ClassName.IN);

									_this4._element.style[dimension] = '';

									_this4.setTransitioning(false);

									$(_this4._element).trigger(Event.SHOWN);
								};

								if (!Util.supportsTransitionEnd()) {
									complete();
									return;
								}

								var capitalizedDimension = dimension[0].toUpperCase() + dimension.slice(1);
								var scrollSize = 'scroll' + capitalizedDimension;

								$(this._element)
									.one(Util.TRANSITION_END, complete)
									.emulateTransitionEnd(TRANSITION_DURATION);

								this._element.style[dimension] = this._element[scrollSize] + 'px';
							},
						},
						{
							key: 'hide',
							value: function hide() {
								var _this5 = this;

								if (this._isTransitioning || !$(this._element).hasClass(ClassName.IN)) {
									return;
								}

								var startEvent = $.Event(Event.HIDE);
								$(this._element).trigger(startEvent);
								if (startEvent.isDefaultPrevented()) {
									return;
								}

								var dimension = this._getDimension();
								var offsetDimension = dimension === Dimension.WIDTH ? 'offsetWidth' : 'offsetHeight';

								this._element.style[dimension] = this._element[offsetDimension] + 'px';

								Util.reflow(this._element);

								$(this._element)
									.addClass(ClassName.COLLAPSING)
									.removeClass(ClassName.COLLAPSE)
									.removeClass(ClassName.IN);

								this._element.setAttribute('aria-expanded', false);

								if (this._triggerArray.length) {
									$(this._triggerArray).addClass(ClassName.COLLAPSED).attr('aria-expanded', false);
								}

								this.setTransitioning(true);

								var complete = function complete() {
									_this5.setTransitioning(false);
									$(_this5._element)
										.removeClass(ClassName.COLLAPSING)
										.addClass(ClassName.COLLAPSE)
										.trigger(Event.HIDDEN);
								};

								this._element.style[dimension] = 0;

								if (!Util.supportsTransitionEnd()) {
									complete();
									return;
								}

								$(this._element)
									.one(Util.TRANSITION_END, complete)
									.emulateTransitionEnd(TRANSITION_DURATION);
							},
						},
						{
							key: 'setTransitioning',
							value: function setTransitioning(isTransitioning) {
								this._isTransitioning = isTransitioning;
							},
						},
						{
							key: 'dispose',
							value: function dispose() {
								$.removeData(this._element, DATA_KEY);

								this._config = null;
								this._parent = null;
								this._element = null;
								this._triggerArray = null;
								this._isTransitioning = null;
							},

							// private
						},
						{
							key: '_getConfig',
							value: function _getConfig(config) {
								config = $.extend({}, Default, config);
								config.toggle = Boolean(config.toggle); // coerce string values
								Util.typeCheckConfig(NAME, config, DefaultType);
								return config;
							},
						},
						{
							key: '_getDimension',
							value: function _getDimension() {
								var hasWidth = $(this._element).hasClass(Dimension.WIDTH);
								return hasWidth ? Dimension.WIDTH : Dimension.HEIGHT;
							},
						},
						{
							key: '_getParent',
							value: function _getParent() {
								var _this6 = this;

								var parent = $(this._config.parent)[0];
								var selector = '[data-toggle="collapse"][data-parent="' + this._config.parent + '"]';

								$(parent)
									.find(selector)
									.each(function (i, element) {
										_this6._addAriaAndCollapsedClass(Collapse._getTargetFromElement(element), [
											element,
										]);
									});

								return parent;
							},
						},
						{
							key: '_addAriaAndCollapsedClass',
							value: function _addAriaAndCollapsedClass(element, triggerArray) {
								if (element) {
									var isOpen = $(element).hasClass(ClassName.IN);
									element.setAttribute('aria-expanded', isOpen);

									if (triggerArray.length) {
										$(triggerArray)
											.toggleClass(ClassName.COLLAPSED, !isOpen)
											.attr('aria-expanded', isOpen);
									}
								}
							},

							// static
						},
					],
					[
						{
							key: '_getTargetFromElement',
							value: function _getTargetFromElement(element) {
								var selector = Util.getSelectorFromElement(element);
								return selector ? $(selector)[0] : null;
							},
						},
						{
							key: '_jQueryInterface',
							value: function _jQueryInterface(config) {
								return this.each(function () {
									var $this = $(this);
									var data = $this.data(DATA_KEY);
									var _config = $.extend(
										{},
										Default,
										$this.data(),
										typeof config === 'object' && config
									);

									if (!data && _config.toggle && /show|hide/.test(config)) {
										_config.toggle = false;
									}

									if (!data) {
										data = new Collapse(this, _config);
										$this.data(DATA_KEY, data);
									}

									if (typeof config === 'string') {
										if (data[config] === undefined) {
											throw new Error('No method named "' + config + '"');
										}
										data[config]();
									}
								});
							},
						},
						{
							key: 'VERSION',
							get: function get() {
								return VERSION;
							},
						},
						{
							key: 'Default',
							get: function get() {
								return Default;
							},
						},
					]
				);

				return Collapse;
			})();

			$(document).on(Event.CLICK_DATA_API, Selector.DATA_TOGGLE, function (event) {
				event.preventDefault();

				var target = Collapse._getTargetFromElement(this);
				var data = $(target).data(DATA_KEY);
				var config = data ? 'toggle' : $(this).data();

				Collapse._jQueryInterface.call($(target), config);
			});

			/**
			 * ------------------------------------------------------------------------
			 * jQuery
			 * ------------------------------------------------------------------------
			 */

			$.fn[NAME] = Collapse._jQueryInterface;
			$.fn[NAME].Constructor = Collapse;
			$.fn[NAME].noConflict = function () {
				$.fn[NAME] = JQUERY_NO_CONFLICT;
				return Collapse._jQueryInterface;
			};

			return Collapse;
		})(jQuery);

		/**
		 * --------------------------------------------------------------------------
		 * Bootstrap (v4.0.0-alpha.4): dropdown.js
		 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
		 * --------------------------------------------------------------------------
		 */

		var Dropdown = (function ($) {
			/**
			 * ------------------------------------------------------------------------
			 * Constants
			 * ------------------------------------------------------------------------
			 */

			var NAME = 'dropdown';
			var VERSION = '4.0.0-alpha.4';
			var DATA_KEY = 'bs.dropdown';
			var EVENT_KEY = '.' + DATA_KEY;
			var DATA_API_KEY = '.data-api';
			var JQUERY_NO_CONFLICT = $.fn[NAME];
			var ESCAPE_KEYCODE = 27; // KeyboardEvent.which value for Escape (Esc) key
			var ARROW_UP_KEYCODE = 38; // KeyboardEvent.which value for up arrow key
			var ARROW_DOWN_KEYCODE = 40; // KeyboardEvent.which value for down arrow key
			var RIGHT_MOUSE_BUTTON_WHICH = 3; // MouseEvent.which value for the right button (assuming a right-handed mouse)

			var Event = {
				HIDE: 'hide' + EVENT_KEY,
				HIDDEN: 'hidden' + EVENT_KEY,
				SHOW: 'show' + EVENT_KEY,
				SHOWN: 'shown' + EVENT_KEY,
				CLICK: 'click' + EVENT_KEY,
				CLICK_DATA_API: 'click' + EVENT_KEY + DATA_API_KEY,
				KEYDOWN_DATA_API: 'keydown' + EVENT_KEY + DATA_API_KEY,
			};

			var ClassName = {
				BACKDROP: 'dropdown-backdrop',
				DISABLED: 'disabled',
				OPEN: 'open',
			};

			var Selector = {
				BACKDROP: '.dropdown-backdrop',
				DATA_TOGGLE: '[data-toggle="dropdown"]',
				FORM_CHILD: '.dropdown form',
				ROLE_MENU: '[role="menu"]',
				ROLE_LISTBOX: '[role="listbox"]',
				NAVBAR_NAV: '.navbar-nav',
				VISIBLE_ITEMS: '[role="menu"] li:not(.disabled) a, ' + '[role="listbox"] li:not(.disabled) a',
			};

			/**
			 * ------------------------------------------------------------------------
			 * Class Definition
			 * ------------------------------------------------------------------------
			 */

			var Dropdown = (function () {
				function Dropdown(element) {
					_classCallCheck(this, Dropdown);

					this._element = element;

					this._addEventListeners();
				}

				/**
				 * ------------------------------------------------------------------------
				 * Data Api implementation
				 * ------------------------------------------------------------------------
				 */

				// getters

				_createClass(
					Dropdown,
					[
						{
							key: 'toggle',

							// public

							value: function toggle() {
								if (this.disabled || $(this).hasClass(ClassName.DISABLED)) {
									return false;
								}

								var parent = Dropdown._getParentFromElement(this);
								var isActive = $(parent).hasClass(ClassName.OPEN);

								Dropdown._clearMenus();

								if (isActive) {
									return false;
								}

								if (
									'ontouchstart' in document.documentElement &&
									!$(parent).closest(Selector.NAVBAR_NAV).length
								) {
									// if mobile we use a backdrop because click events don't delegate
									var dropdown = document.createElement('div');
									dropdown.className = ClassName.BACKDROP;
									$(dropdown).insertBefore(this);
									$(dropdown).on('click', Dropdown._clearMenus);
								}

								var relatedTarget = { relatedTarget: this };
								var showEvent = $.Event(Event.SHOW, relatedTarget);

								$(parent).trigger(showEvent);

								if (showEvent.isDefaultPrevented()) {
									return false;
								}

								this.focus();
								this.setAttribute('aria-expanded', 'true');

								$(parent).toggleClass(ClassName.OPEN);
								$(parent).trigger($.Event(Event.SHOWN, relatedTarget));

								return false;
							},
						},
						{
							key: 'dispose',
							value: function dispose() {
								$.removeData(this._element, DATA_KEY);
								$(this._element).off(EVENT_KEY);
								this._element = null;
							},

							// private
						},
						{
							key: '_addEventListeners',
							value: function _addEventListeners() {
								$(this._element).on(Event.CLICK, this.toggle);
							},

							// static
						},
					],
					[
						{
							key: '_jQueryInterface',
							value: function _jQueryInterface(config) {
								return this.each(function () {
									var data = $(this).data(DATA_KEY);

									if (!data) {
										$(this).data(DATA_KEY, (data = new Dropdown(this)));
									}

									if (typeof config === 'string') {
										if (data[config] === undefined) {
											throw new Error('No method named "' + config + '"');
										}
										data[config].call(this);
									}
								});
							},
						},
						{
							key: '_clearMenus',
							value: function _clearMenus(event) {
								if (event && event.which === RIGHT_MOUSE_BUTTON_WHICH) {
									return;
								}

								var backdrop = $(Selector.BACKDROP)[0];
								if (backdrop) {
									backdrop.parentNode.removeChild(backdrop);
								}

								var toggles = $.makeArray($(Selector.DATA_TOGGLE));

								for (var i = 0; i < toggles.length; i++) {
									var _parent = Dropdown._getParentFromElement(toggles[i]);
									var relatedTarget = { relatedTarget: toggles[i] };

									if (!$(_parent).hasClass(ClassName.OPEN)) {
										continue;
									}

									if (
										event &&
										event.type === 'click' &&
										/input|textarea/i.test(event.target.tagName) &&
										$.contains(_parent, event.target)
									) {
										continue;
									}

									var hideEvent = $.Event(Event.HIDE, relatedTarget);
									$(_parent).trigger(hideEvent);
									if (hideEvent.isDefaultPrevented()) {
										continue;
									}

									toggles[i].setAttribute('aria-expanded', 'false');

									$(_parent)
										.removeClass(ClassName.OPEN)
										.trigger($.Event(Event.HIDDEN, relatedTarget));
								}
							},
						},
						{
							key: '_getParentFromElement',
							value: function _getParentFromElement(element) {
								var parent = undefined;
								var selector = Util.getSelectorFromElement(element);

								if (selector) {
									parent = $(selector)[0];
								}

								return parent || element.parentNode;
							},
						},
						{
							key: '_dataApiKeydownHandler',
							value: function _dataApiKeydownHandler(event) {
								if (
									!/(38|40|27|32)/.test(event.which) ||
									/input|textarea/i.test(event.target.tagName)
								) {
									return;
								}

								event.preventDefault();
								event.stopPropagation();

								if (this.disabled || $(this).hasClass(ClassName.DISABLED)) {
									return;
								}

								var parent = Dropdown._getParentFromElement(this);
								var isActive = $(parent).hasClass(ClassName.OPEN);

								if (
									(!isActive && event.which !== ESCAPE_KEYCODE) ||
									(isActive && event.which === ESCAPE_KEYCODE)
								) {
									if (event.which === ESCAPE_KEYCODE) {
										var toggle = $(parent).find(Selector.DATA_TOGGLE)[0];
										$(toggle).trigger('focus');
									}

									$(this).trigger('click');
									return;
								}

								var items = $.makeArray($(Selector.VISIBLE_ITEMS));

								items = items.filter(function (item) {
									return item.offsetWidth || item.offsetHeight;
								});

								if (!items.length) {
									return;
								}

								var index = items.indexOf(event.target);

								if (event.which === ARROW_UP_KEYCODE && index > 0) {
									// up
									index--;
								}

								if (event.which === ARROW_DOWN_KEYCODE && index < items.length - 1) {
									// down
									index++;
								}

								if (index < 0) {
									index = 0;
								}

								items[index].focus();
							},
						},
						{
							key: 'VERSION',
							get: function get() {
								return VERSION;
							},
						},
					]
				);

				return Dropdown;
			})();

			$(document)
				.on(Event.KEYDOWN_DATA_API, Selector.DATA_TOGGLE, Dropdown._dataApiKeydownHandler)
				.on(Event.KEYDOWN_DATA_API, Selector.ROLE_MENU, Dropdown._dataApiKeydownHandler)
				.on(Event.KEYDOWN_DATA_API, Selector.ROLE_LISTBOX, Dropdown._dataApiKeydownHandler)
				.on(Event.CLICK_DATA_API, Dropdown._clearMenus)
				.on(Event.CLICK_DATA_API, Selector.DATA_TOGGLE, Dropdown.prototype.toggle)
				.on(Event.CLICK_DATA_API, Selector.FORM_CHILD, function (e) {
					e.stopPropagation();
				});

			/**
			 * ------------------------------------------------------------------------
			 * jQuery
			 * ------------------------------------------------------------------------
			 */

			$.fn[NAME] = Dropdown._jQueryInterface;
			$.fn[NAME].Constructor = Dropdown;
			$.fn[NAME].noConflict = function () {
				$.fn[NAME] = JQUERY_NO_CONFLICT;
				return Dropdown._jQueryInterface;
			};

			return Dropdown;
		})(jQuery);

		/**
		 * --------------------------------------------------------------------------
		 * Bootstrap (v4.0.0-alpha.4): modal.js
		 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
		 * --------------------------------------------------------------------------
		 */

		var Modal = (function ($) {
			/**
			 * ------------------------------------------------------------------------
			 * Constants
			 * ------------------------------------------------------------------------
			 */

			var NAME = 'modal';
			var VERSION = '4.0.0-alpha.4';
			var DATA_KEY = 'bs.modal';
			var EVENT_KEY = '.' + DATA_KEY;
			var DATA_API_KEY = '.data-api';
			var JQUERY_NO_CONFLICT = $.fn[NAME];
			var TRANSITION_DURATION = 300;
			var BACKDROP_TRANSITION_DURATION = 150;
			var ESCAPE_KEYCODE = 27; // KeyboardEvent.which value for Escape (Esc) key

			var Default = {
				backdrop: true,
				keyboard: true,
				focus: true,
				show: true,
			};

			var DefaultType = {
				backdrop: '(boolean|string)',
				keyboard: 'boolean',
				focus: 'boolean',
				show: 'boolean',
			};

			var Event = {
				HIDE: 'hide' + EVENT_KEY,
				HIDDEN: 'hidden' + EVENT_KEY,
				SHOW: 'show' + EVENT_KEY,
				SHOWN: 'shown' + EVENT_KEY,
				FOCUSIN: 'focusin' + EVENT_KEY,
				RESIZE: 'resize' + EVENT_KEY,
				CLICK_DISMISS: 'click.dismiss' + EVENT_KEY,
				KEYDOWN_DISMISS: 'keydown.dismiss' + EVENT_KEY,
				MOUSEUP_DISMISS: 'mouseup.dismiss' + EVENT_KEY,
				MOUSEDOWN_DISMISS: 'mousedown.dismiss' + EVENT_KEY,
				CLICK_DATA_API: 'click' + EVENT_KEY + DATA_API_KEY,
			};

			var ClassName = {
				SCROLLBAR_MEASURER: 'modal-scrollbar-measure',
				BACKDROP: 'modal-backdrop',
				OPEN: 'modal-open',
				FADE: 'fade',
				IN: 'in',
			};

			var Selector = {
				DIALOG: '.modal-dialog',
				DATA_TOGGLE: '[data-toggle="modal"]',
				DATA_DISMISS: '[data-dismiss="modal"]',
				FIXED_CONTENT: '.navbar-fixed-top, .navbar-fixed-bottom, .is-fixed',
			};

			/**
			 * ------------------------------------------------------------------------
			 * Class Definition
			 * ------------------------------------------------------------------------
			 */

			var Modal = (function () {
				function Modal(element, config) {
					_classCallCheck(this, Modal);

					this._config = this._getConfig(config);
					this._element = element;
					this._dialog = $(element).find(Selector.DIALOG)[0];
					this._backdrop = null;
					this._isShown = false;
					this._isBodyOverflowing = false;
					this._ignoreBackdropClick = false;
					this._originalBodyPadding = 0;
					this._scrollbarWidth = 0;
				}

				/**
				 * ------------------------------------------------------------------------
				 * Data Api implementation
				 * ------------------------------------------------------------------------
				 */

				// getters

				_createClass(
					Modal,
					[
						{
							key: 'toggle',

							// public

							value: function toggle(relatedTarget) {
								return this._isShown ? this.hide() : this.show(relatedTarget);
							},
						},
						{
							key: 'show',
							value: function show(relatedTarget) {
								var _this7 = this;

								var showEvent = $.Event(Event.SHOW, {
									relatedTarget: relatedTarget,
								});

								$(this._element).trigger(showEvent);

								if (this._isShown || showEvent.isDefaultPrevented()) {
									return;
								}

								this._isShown = true;

								this._checkScrollbar();
								this._setScrollbar();

								$(document.body).addClass(ClassName.OPEN);

								this._setEscapeEvent();
								this._setResizeEvent();

								$(this._element).on(
									Event.CLICK_DISMISS,
									Selector.DATA_DISMISS,
									$.proxy(this.hide, this)
								);

								$(this._dialog).on(Event.MOUSEDOWN_DISMISS, function () {
									$(_this7._element).one(Event.MOUSEUP_DISMISS, function (event) {
										if ($(event.target).is(_this7._element)) {
											_this7._ignoreBackdropClick = true;
										}
									});
								});

								this._showBackdrop($.proxy(this._showElement, this, relatedTarget));
							},
						},
						{
							key: 'hide',
							value: function hide(event) {
								if (event) {
									event.preventDefault();
								}

								var hideEvent = $.Event(Event.HIDE);

								$(this._element).trigger(hideEvent);

								if (!this._isShown || hideEvent.isDefaultPrevented()) {
									return;
								}

								this._isShown = false;

								this._setEscapeEvent();
								this._setResizeEvent();

								$(document).off(Event.FOCUSIN);

								$(this._element).removeClass(ClassName.IN);

								$(this._element).off(Event.CLICK_DISMISS);
								$(this._dialog).off(Event.MOUSEDOWN_DISMISS);

								if (Util.supportsTransitionEnd() && $(this._element).hasClass(ClassName.FADE)) {
									$(this._element)
										.one(Util.TRANSITION_END, $.proxy(this._hideModal, this))
										.emulateTransitionEnd(TRANSITION_DURATION);
								} else {
									this._hideModal();
								}
							},
						},
						{
							key: 'dispose',
							value: function dispose() {
								$.removeData(this._element, DATA_KEY);

								$(window).off(EVENT_KEY);
								$(document).off(EVENT_KEY);
								$(this._element).off(EVENT_KEY);
								$(this._backdrop).off(EVENT_KEY);

								this._config = null;
								this._element = null;
								this._dialog = null;
								this._backdrop = null;
								this._isShown = null;
								this._isBodyOverflowing = null;
								this._ignoreBackdropClick = null;
								this._originalBodyPadding = null;
								this._scrollbarWidth = null;
							},

							// private
						},
						{
							key: '_getConfig',
							value: function _getConfig(config) {
								config = $.extend({}, Default, config);
								Util.typeCheckConfig(NAME, config, DefaultType);
								return config;
							},
						},
						{
							key: '_showElement',
							value: function _showElement(relatedTarget) {
								var _this8 = this;

								var transition =
									Util.supportsTransitionEnd() && $(this._element).hasClass(ClassName.FADE);

								if (
									!this._element.parentNode ||
									this._element.parentNode.nodeType !== Node.ELEMENT_NODE
								) {
									// don't move modals dom position
									document.body.appendChild(this._element);
								}

								this._element.style.display = 'block';
								this._element.removeAttribute('aria-hidden');
								this._element.scrollTop = 0;

								if (transition) {
									Util.reflow(this._element);
								}

								$(this._element).addClass(ClassName.IN);

								if (this._config.focus) {
									this._enforceFocus();
								}

								var shownEvent = $.Event(Event.SHOWN, {
									relatedTarget: relatedTarget,
								});

								var transitionComplete = function transitionComplete() {
									if (_this8._config.focus) {
										_this8._element.focus();
									}
									$(_this8._element).trigger(shownEvent);
								};

								if (transition) {
									$(this._dialog)
										.one(Util.TRANSITION_END, transitionComplete)
										.emulateTransitionEnd(TRANSITION_DURATION);
								} else {
									transitionComplete();
								}
							},
						},
						{
							key: '_enforceFocus',
							value: function _enforceFocus() {
								var _this9 = this;

								$(document)
									.off(Event.FOCUSIN) // guard against infinite focus loop
									.on(Event.FOCUSIN, function (event) {
										if (
											document !== event.target &&
											_this9._element !== event.target &&
											!$(_this9._element).has(event.target).length
										) {
											_this9._element.focus();
										}
									});
							},
						},
						{
							key: '_setEscapeEvent',
							value: function _setEscapeEvent() {
								var _this10 = this;

								if (this._isShown && this._config.keyboard) {
									$(this._element).on(Event.KEYDOWN_DISMISS, function (event) {
										if (event.which === ESCAPE_KEYCODE) {
											_this10.hide();
										}
									});
								} else if (!this._isShown) {
									$(this._element).off(Event.KEYDOWN_DISMISS);
								}
							},
						},
						{
							key: '_setResizeEvent',
							value: function _setResizeEvent() {
								if (this._isShown) {
									$(window).on(Event.RESIZE, $.proxy(this._handleUpdate, this));
								} else {
									$(window).off(Event.RESIZE);
								}
							},
						},
						{
							key: '_hideModal',
							value: function _hideModal() {
								var _this11 = this;

								this._element.style.display = 'none';
								this._element.setAttribute('aria-hidden', 'true');
								this._showBackdrop(function () {
									$(document.body).removeClass(ClassName.OPEN);
									_this11._resetAdjustments();
									_this11._resetScrollbar();
									$(_this11._element).trigger(Event.HIDDEN);
								});
							},
						},
						{
							key: '_removeBackdrop',
							value: function _removeBackdrop() {
								if (this._backdrop) {
									$(this._backdrop).remove();
									this._backdrop = null;
								}
							},
						},
						{
							key: '_showBackdrop',
							value: function _showBackdrop(callback) {
								var _this12 = this;

								var animate = $(this._element).hasClass(ClassName.FADE) ? ClassName.FADE : '';

								if (this._isShown && this._config.backdrop) {
									var doAnimate = Util.supportsTransitionEnd() && animate;

									this._backdrop = document.createElement('div');
									this._backdrop.className = ClassName.BACKDROP;

									if (animate) {
										$(this._backdrop).addClass(animate);
									}

									$(this._backdrop).appendTo(document.body);

									$(this._element).on(Event.CLICK_DISMISS, function (event) {
										if (_this12._ignoreBackdropClick) {
											_this12._ignoreBackdropClick = false;
											return;
										}
										if (event.target !== event.currentTarget) {
											return;
										}
										if (_this12._config.backdrop === 'static') {
											_this12._element.focus();
										} else {
											_this12.hide();
										}
									});

									if (doAnimate) {
										Util.reflow(this._backdrop);
									}

									$(this._backdrop).addClass(ClassName.IN);

									if (!callback) {
										return;
									}

									if (!doAnimate) {
										callback();
										return;
									}

									$(this._backdrop)
										.one(Util.TRANSITION_END, callback)
										.emulateTransitionEnd(BACKDROP_TRANSITION_DURATION);
								} else if (!this._isShown && this._backdrop) {
									$(this._backdrop).removeClass(ClassName.IN);

									var callbackRemove = function callbackRemove() {
										_this12._removeBackdrop();
										if (callback) {
											callback();
										}
									};

									if (Util.supportsTransitionEnd() && $(this._element).hasClass(ClassName.FADE)) {
										$(this._backdrop)
											.one(Util.TRANSITION_END, callbackRemove)
											.emulateTransitionEnd(BACKDROP_TRANSITION_DURATION);
									} else {
										callbackRemove();
									}
								} else if (callback) {
									callback();
								}
							},

							// ----------------------------------------------------------------------
							// the following methods are used to handle overflowing modals
							// todo (fat): these should probably be refactored out of modal.js
							// ----------------------------------------------------------------------
						},
						{
							key: '_handleUpdate',
							value: function _handleUpdate() {
								this._adjustDialog();
							},
						},
						{
							key: '_adjustDialog',
							value: function _adjustDialog() {
								var isModalOverflowing =
									this._element.scrollHeight > document.documentElement.clientHeight;

								if (!this._isBodyOverflowing && isModalOverflowing) {
									this._element.style.paddingLeft = this._scrollbarWidth + 'px';
								}

								if (this._isBodyOverflowing && !isModalOverflowing) {
									this._element.style.paddingRight = this._scrollbarWidth + 'px';
								}
							},
						},
						{
							key: '_resetAdjustments',
							value: function _resetAdjustments() {
								this._element.style.paddingLeft = '';
								this._element.style.paddingRight = '';
							},
						},
						{
							key: '_checkScrollbar',
							value: function _checkScrollbar() {
								this._isBodyOverflowing = document.body.clientWidth < window.innerWidth;
								this._scrollbarWidth = this._getScrollbarWidth();
							},
						},
						{
							key: '_setScrollbar',
							value: function _setScrollbar() {
								var bodyPadding = parseInt($(Selector.FIXED_CONTENT).css('padding-right') || 0, 10);

								this._originalBodyPadding = document.body.style.paddingRight || '';

								if (this._isBodyOverflowing) {
									document.body.style.paddingRight = bodyPadding + this._scrollbarWidth + 'px';
								}
							},
						},
						{
							key: '_resetScrollbar',
							value: function _resetScrollbar() {
								document.body.style.paddingRight = this._originalBodyPadding;
							},
						},
						{
							key: '_getScrollbarWidth',
							value: function _getScrollbarWidth() {
								// thx d.walsh
								var scrollDiv = document.createElement('div');
								scrollDiv.className = ClassName.SCROLLBAR_MEASURER;
								document.body.appendChild(scrollDiv);
								var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
								document.body.removeChild(scrollDiv);
								return scrollbarWidth;
							},

							// static
						},
					],
					[
						{
							key: '_jQueryInterface',
							value: function _jQueryInterface(config, relatedTarget) {
								return this.each(function () {
									var data = $(this).data(DATA_KEY);
									var _config = $.extend(
										{},
										Modal.Default,
										$(this).data(),
										typeof config === 'object' && config
									);

									if (!data) {
										data = new Modal(this, _config);
										$(this).data(DATA_KEY, data);
									}

									if (typeof config === 'string') {
										if (data[config] === undefined) {
											throw new Error('No method named "' + config + '"');
										}
										data[config](relatedTarget);
									} else if (_config.show) {
										data.show(relatedTarget);
									}
								});
							},
						},
						{
							key: 'VERSION',
							get: function get() {
								return VERSION;
							},
						},
						{
							key: 'Default',
							get: function get() {
								return Default;
							},
						},
					]
				);

				return Modal;
			})();

			$(document).on(Event.CLICK_DATA_API, Selector.DATA_TOGGLE, function (event) {
				var _this13 = this;

				var target = undefined;
				var selector = Util.getSelectorFromElement(this);

				if (selector) {
					target = $(selector)[0];
				}

				var config = $(target).data(DATA_KEY) ? 'toggle' : $.extend({}, $(target).data(), $(this).data());

				if (this.tagName === 'A') {
					event.preventDefault();
				}

				var $target = $(target).one(Event.SHOW, function (showEvent) {
					if (showEvent.isDefaultPrevented()) {
						// only register focus restorer if modal will actually get shown
						return;
					}

					$target.one(Event.HIDDEN, function () {
						if ($(_this13).is(':visible')) {
							_this13.focus();
						}
					});
				});

				Modal._jQueryInterface.call($(target), config, this);
			});

			/**
			 * ------------------------------------------------------------------------
			 * jQuery
			 * ------------------------------------------------------------------------
			 */

			$.fn[NAME] = Modal._jQueryInterface;
			$.fn[NAME].Constructor = Modal;
			$.fn[NAME].noConflict = function () {
				$.fn[NAME] = JQUERY_NO_CONFLICT;
				return Modal._jQueryInterface;
			};

			return Modal;
		})(jQuery);

		/**
		 * --------------------------------------------------------------------------
		 * Bootstrap (v4.0.0-alpha.4): scrollspy.js
		 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
		 * --------------------------------------------------------------------------
		 */

		var ScrollSpy = (function ($) {
			/**
			 * ------------------------------------------------------------------------
			 * Constants
			 * ------------------------------------------------------------------------
			 */

			var NAME = 'scrollspy';
			var VERSION = '4.0.0-alpha.4';
			var DATA_KEY = 'bs.scrollspy';
			var EVENT_KEY = '.' + DATA_KEY;
			var DATA_API_KEY = '.data-api';
			var JQUERY_NO_CONFLICT = $.fn[NAME];

			var Default = {
				offset: 10,
				method: 'auto',
				target: '',
			};

			var DefaultType = {
				offset: 'number',
				method: 'string',
				target: '(string|element)',
			};

			var Event = {
				ACTIVATE: 'activate' + EVENT_KEY,
				SCROLL: 'scroll' + EVENT_KEY,
				LOAD_DATA_API: 'load' + EVENT_KEY + DATA_API_KEY,
			};

			var ClassName = {
				DROPDOWN_ITEM: 'dropdown-item',
				DROPDOWN_MENU: 'dropdown-menu',
				NAV_LINK: 'nav-link',
				NAV: 'nav',
				ACTIVE: 'active',
			};

			var Selector = {
				DATA_SPY: '[data-spy="scroll"]',
				ACTIVE: '.active',
				LIST_ITEM: '.list-item',
				LI: 'li',
				LI_DROPDOWN: 'li.dropdown',
				NAV_LINKS: '.nav-link',
				DROPDOWN: '.dropdown',
				DROPDOWN_ITEMS: '.dropdown-item',
				DROPDOWN_TOGGLE: '.dropdown-toggle',
			};

			var OffsetMethod = {
				OFFSET: 'offset',
				POSITION: 'position',
			};

			/**
			 * ------------------------------------------------------------------------
			 * Class Definition
			 * ------------------------------------------------------------------------
			 */

			var ScrollSpy = (function () {
				function ScrollSpy(element, config) {
					_classCallCheck(this, ScrollSpy);

					this._element = element;
					this._scrollElement = element.tagName === 'BODY' ? window : element;
					this._config = this._getConfig(config);
					this._selector =
						this._config.target +
						' ' +
						Selector.NAV_LINKS +
						',' +
						(this._config.target + ' ' + Selector.DROPDOWN_ITEMS);
					this._offsets = [];
					this._targets = [];
					this._activeTarget = null;
					this._scrollHeight = 0;

					$(this._scrollElement).on(Event.SCROLL, $.proxy(this._process, this));

					this.refresh();
					this._process();
				}

				/**
				 * ------------------------------------------------------------------------
				 * Data Api implementation
				 * ------------------------------------------------------------------------
				 */

				// getters

				_createClass(
					ScrollSpy,
					[
						{
							key: 'refresh',

							// public

							value: function refresh() {
								var _this14 = this;

								var autoMethod =
									this._scrollElement !== this._scrollElement.window
										? OffsetMethod.POSITION
										: OffsetMethod.OFFSET;

								var offsetMethod = this._config.method === 'auto' ? autoMethod : this._config.method;

								var offsetBase = offsetMethod === OffsetMethod.POSITION ? this._getScrollTop() : 0;

								this._offsets = [];
								this._targets = [];

								this._scrollHeight = this._getScrollHeight();

								var targets = $.makeArray($(this._selector));

								targets
									.map(function (element) {
										var target = undefined;
										var targetSelector = Util.getSelectorFromElement(element);

										if (targetSelector) {
											target = $(targetSelector)[0];
										}

										if (target && (target.offsetWidth || target.offsetHeight)) {
											// todo (fat): remove sketch reliance on jQuery position/offset
											return [$(target)[offsetMethod]().top + offsetBase, targetSelector];
										}
										return null;
									})
									.filter(function (item) {
										return item;
									})
									.sort(function (a, b) {
										return a[0] - b[0];
									})
									.forEach(function (item) {
										_this14._offsets.push(item[0]);
										_this14._targets.push(item[1]);
									});
							},
						},
						{
							key: 'dispose',
							value: function dispose() {
								$.removeData(this._element, DATA_KEY);
								$(this._scrollElement).off(EVENT_KEY);

								this._element = null;
								this._scrollElement = null;
								this._config = null;
								this._selector = null;
								this._offsets = null;
								this._targets = null;
								this._activeTarget = null;
								this._scrollHeight = null;
							},

							// private
						},
						{
							key: '_getConfig',
							value: function _getConfig(config) {
								config = $.extend({}, Default, config);

								if (typeof config.target !== 'string') {
									var id = $(config.target).attr('id');
									if (!id) {
										id = Util.getUID(NAME);
										$(config.target).attr('id', id);
									}
									config.target = '#' + id;
								}

								Util.typeCheckConfig(NAME, config, DefaultType);

								return config;
							},
						},
						{
							key: '_getScrollTop',
							value: function _getScrollTop() {
								return this._scrollElement === window
									? this._scrollElement.scrollY
									: this._scrollElement.scrollTop;
							},
						},
						{
							key: '_getScrollHeight',
							value: function _getScrollHeight() {
								return (
									this._scrollElement.scrollHeight ||
									Math.max(document.body.scrollHeight, document.documentElement.scrollHeight)
								);
							},
						},
						{
							key: '_process',
							value: function _process() {
								var scrollTop = this._getScrollTop() + this._config.offset;
								var scrollHeight = this._getScrollHeight();
								var maxScroll = this._config.offset + scrollHeight - this._scrollElement.offsetHeight;

								if (this._scrollHeight !== scrollHeight) {
									this.refresh();
								}

								if (scrollTop >= maxScroll) {
									var target = this._targets[this._targets.length - 1];

									if (this._activeTarget !== target) {
										this._activate(target);
									}
								}

								if (this._activeTarget && scrollTop < this._offsets[0]) {
									this._activeTarget = null;
									this._clear();
									return;
								}

								for (var i = this._offsets.length; i--; ) {
									var isActiveTarget =
										this._activeTarget !== this._targets[i] &&
										scrollTop >= this._offsets[i] &&
										(this._offsets[i + 1] === undefined || scrollTop < this._offsets[i + 1]);

									if (isActiveTarget) {
										this._activate(this._targets[i]);
									}
								}
							},
						},
						{
							key: '_activate',
							value: function _activate(target) {
								this._activeTarget = target;

								this._clear();

								var queries = this._selector.split(',');
								queries = queries.map(function (selector) {
									return (
										selector +
										'[data-target="' +
										target +
										'"],' +
										(selector + '[href="' + target + '"]')
									);
								});

								var $link = $(queries.join(','));

								if ($link.hasClass(ClassName.DROPDOWN_ITEM)) {
									$link
										.closest(Selector.DROPDOWN)
										.find(Selector.DROPDOWN_TOGGLE)
										.addClass(ClassName.ACTIVE);
									$link.addClass(ClassName.ACTIVE);
								} else {
									// todo (fat) this is kinda sus...
									// recursively add actives to tested nav-links
									$link.parents(Selector.LI).find(Selector.NAV_LINKS).addClass(ClassName.ACTIVE);
								}

								$(this._scrollElement).trigger(Event.ACTIVATE, {
									relatedTarget: target,
								});
							},
						},
						{
							key: '_clear',
							value: function _clear() {
								$(this._selector).filter(Selector.ACTIVE).removeClass(ClassName.ACTIVE);
							},

							// static
						},
					],
					[
						{
							key: '_jQueryInterface',
							value: function _jQueryInterface(config) {
								return this.each(function () {
									var data = $(this).data(DATA_KEY);
									var _config = (typeof config === 'object' && config) || null;

									if (!data) {
										data = new ScrollSpy(this, _config);
										$(this).data(DATA_KEY, data);
									}

									if (typeof config === 'string') {
										if (data[config] === undefined) {
											throw new Error('No method named "' + config + '"');
										}
										data[config]();
									}
								});
							},
						},
						{
							key: 'VERSION',
							get: function get() {
								return VERSION;
							},
						},
						{
							key: 'Default',
							get: function get() {
								return Default;
							},
						},
					]
				);

				return ScrollSpy;
			})();

			$(window).on(Event.LOAD_DATA_API, function () {
				var scrollSpys = $.makeArray($(Selector.DATA_SPY));

				for (var i = scrollSpys.length; i--; ) {
					var $spy = $(scrollSpys[i]);
					ScrollSpy._jQueryInterface.call($spy, $spy.data());
				}
			});

			/**
			 * ------------------------------------------------------------------------
			 * jQuery
			 * ------------------------------------------------------------------------
			 */

			$.fn[NAME] = ScrollSpy._jQueryInterface;
			$.fn[NAME].Constructor = ScrollSpy;
			$.fn[NAME].noConflict = function () {
				$.fn[NAME] = JQUERY_NO_CONFLICT;
				return ScrollSpy._jQueryInterface;
			};

			return ScrollSpy;
		})(jQuery);

		/**
		 * --------------------------------------------------------------------------
		 * Bootstrap (v4.0.0-alpha.4): tab.js
		 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
		 * --------------------------------------------------------------------------
		 */

		var Tab = (function ($) {
			/**
			 * ------------------------------------------------------------------------
			 * Constants
			 * ------------------------------------------------------------------------
			 */

			var NAME = 'tab';
			var VERSION = '4.0.0-alpha.4';
			var DATA_KEY = 'bs.tab';
			var EVENT_KEY = '.' + DATA_KEY;
			var DATA_API_KEY = '.data-api';
			var JQUERY_NO_CONFLICT = $.fn[NAME];
			var TRANSITION_DURATION = 150;

			var Event = {
				HIDE: 'hide' + EVENT_KEY,
				HIDDEN: 'hidden' + EVENT_KEY,
				SHOW: 'show' + EVENT_KEY,
				SHOWN: 'shown' + EVENT_KEY,
				CLICK_DATA_API: 'click' + EVENT_KEY + DATA_API_KEY,
			};

			var ClassName = {
				DROPDOWN_MENU: 'dropdown-menu',
				ACTIVE: 'active',
				FADE: 'fade',
				IN: 'in',
			};

			var Selector = {
				A: 'a',
				LI: 'li',
				DROPDOWN: '.dropdown',
				UL: 'ul:not(.dropdown-menu)',
				FADE_CHILD: '> .nav-item .fade, > .fade',
				ACTIVE: '.active',
				ACTIVE_CHILD: '> .nav-item > .active, > .active',
				DATA_TOGGLE: '[data-toggle="tab"], [data-toggle="pill"]',
				DROPDOWN_TOGGLE: '.dropdown-toggle',
				DROPDOWN_ACTIVE_CHILD: '> .dropdown-menu .active',
			};

			/**
			 * ------------------------------------------------------------------------
			 * Class Definition
			 * ------------------------------------------------------------------------
			 */

			var Tab = (function () {
				function Tab(element) {
					_classCallCheck(this, Tab);

					this._element = element;
				}

				/**
				 * ------------------------------------------------------------------------
				 * Data Api implementation
				 * ------------------------------------------------------------------------
				 */

				// getters

				_createClass(
					Tab,
					[
						{
							key: 'show',

							// public

							value: function show() {
								var _this15 = this;

								if (
									this._element.parentNode &&
									this._element.parentNode.nodeType === Node.ELEMENT_NODE &&
									$(this._element).hasClass(ClassName.ACTIVE)
								) {
									return;
								}

								var target = undefined;
								var previous = undefined;
								var ulElement = $(this._element).closest(Selector.UL)[0];
								var selector = Util.getSelectorFromElement(this._element);

								if (ulElement) {
									previous = $.makeArray($(ulElement).find(Selector.ACTIVE));
									previous = previous[previous.length - 1];
								}

								var hideEvent = $.Event(Event.HIDE, {
									relatedTarget: this._element,
								});

								var showEvent = $.Event(Event.SHOW, {
									relatedTarget: previous,
								});

								if (previous) {
									$(previous).trigger(hideEvent);
								}

								$(this._element).trigger(showEvent);

								if (showEvent.isDefaultPrevented() || hideEvent.isDefaultPrevented()) {
									return;
								}

								if (selector) {
									target = $(selector)[0];
								}

								this._activate(this._element, ulElement);

								var complete = function complete() {
									var hiddenEvent = $.Event(Event.HIDDEN, {
										relatedTarget: _this15._element,
									});

									var shownEvent = $.Event(Event.SHOWN, {
										relatedTarget: previous,
									});

									$(previous).trigger(hiddenEvent);
									$(_this15._element).trigger(shownEvent);
								};

								if (target) {
									this._activate(target, target.parentNode, complete);
								} else {
									complete();
								}
							},
						},
						{
							key: 'dispose',
							value: function dispose() {
								$.removeClass(this._element, DATA_KEY);
								this._element = null;
							},

							// private
						},
						{
							key: '_activate',
							value: function _activate(element, container, callback) {
								var active = $(container).find(Selector.ACTIVE_CHILD)[0];
								var isTransitioning =
									callback &&
									Util.supportsTransitionEnd() &&
									((active && $(active).hasClass(ClassName.FADE)) ||
										Boolean($(container).find(Selector.FADE_CHILD)[0]));

								var complete = $.proxy(
									this._transitionComplete,
									this,
									element,
									active,
									isTransitioning,
									callback
								);

								if (active && isTransitioning) {
									$(active)
										.one(Util.TRANSITION_END, complete)
										.emulateTransitionEnd(TRANSITION_DURATION);
								} else {
									complete();
								}

								if (active) {
									$(active).removeClass(ClassName.IN);
								}
							},
						},
						{
							key: '_transitionComplete',
							value: function _transitionComplete(element, active, isTransitioning, callback) {
								if (active) {
									$(active).removeClass(ClassName.ACTIVE);

									var dropdownChild = $(active).find(Selector.DROPDOWN_ACTIVE_CHILD)[0];

									if (dropdownChild) {
										$(dropdownChild).removeClass(ClassName.ACTIVE);
									}

									active.setAttribute('aria-expanded', false);
								}

								$(element).addClass(ClassName.ACTIVE);
								element.setAttribute('aria-expanded', true);

								if (isTransitioning) {
									Util.reflow(element);
									$(element).addClass(ClassName.IN);
								} else {
									$(element).removeClass(ClassName.FADE);
								}

								if (element.parentNode && $(element.parentNode).hasClass(ClassName.DROPDOWN_MENU)) {
									var dropdownElement = $(element).closest(Selector.DROPDOWN)[0];
									if (dropdownElement) {
										$(dropdownElement).find(Selector.DROPDOWN_TOGGLE).addClass(ClassName.ACTIVE);
									}

									element.setAttribute('aria-expanded', true);
								}

								if (callback) {
									callback();
								}
							},

							// static
						},
					],
					[
						{
							key: '_jQueryInterface',
							value: function _jQueryInterface(config) {
								return this.each(function () {
									var $this = $(this);
									var data = $this.data(DATA_KEY);

									if (!data) {
										data = data = new Tab(this);
										$this.data(DATA_KEY, data);
									}

									if (typeof config === 'string') {
										if (data[config] === undefined) {
											throw new Error('No method named "' + config + '"');
										}
										data[config]();
									}
								});
							},
						},
						{
							key: 'VERSION',
							get: function get() {
								return VERSION;
							},
						},
					]
				);

				return Tab;
			})();

			$(document).on(Event.CLICK_DATA_API, Selector.DATA_TOGGLE, function (event) {
				event.preventDefault();
				Tab._jQueryInterface.call($(this), 'show');
			});

			/**
			 * ------------------------------------------------------------------------
			 * jQuery
			 * ------------------------------------------------------------------------
			 */

			$.fn[NAME] = Tab._jQueryInterface;
			$.fn[NAME].Constructor = Tab;
			$.fn[NAME].noConflict = function () {
				$.fn[NAME] = JQUERY_NO_CONFLICT;
				return Tab._jQueryInterface;
			};

			return Tab;
		})(jQuery);

		/* global Tether */

		/**
		 * --------------------------------------------------------------------------
		 * Bootstrap (v4.0.0-alpha.4): tooltip.js
		 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
		 * --------------------------------------------------------------------------
		 */

		var Tooltip = (function ($) {
			/**
			 * Check for Tether dependency
			 * Tether - http://github.hubspot.com/tether/
			 */
			if (window.Tether === undefined) {
				throw new Error('Bootstrap tooltips require Tether (http://github.hubspot.com/tether/)');
			}

			/**
			 * ------------------------------------------------------------------------
			 * Constants
			 * ------------------------------------------------------------------------
			 */

			var NAME = 'tooltip';
			var VERSION = '4.0.0-alpha.4';
			var DATA_KEY = 'bs.tooltip';
			var EVENT_KEY = '.' + DATA_KEY;
			var JQUERY_NO_CONFLICT = $.fn[NAME];
			var TRANSITION_DURATION = 150;
			var CLASS_PREFIX = 'bs-tether';

			var Default = {
				animation: true,
				template:
					'<div class="tooltip" role="tooltip">' +
					'<div class="tooltip-arrow"></div>' +
					'<div class="tooltip-inner"></div></div>',
				trigger: 'hover focus',
				title: '',
				delay: 0,
				html: false,
				selector: false,
				placement: 'top',
				offset: '0 0',
				constraints: [],
			};

			var DefaultType = {
				animation: 'boolean',
				template: 'string',
				title: '(string|element|function)',
				trigger: 'string',
				delay: '(number|object)',
				html: 'boolean',
				selector: '(string|boolean)',
				placement: '(string|function)',
				offset: 'string',
				constraints: 'array',
			};

			var AttachmentMap = {
				TOP: 'bottom center',
				RIGHT: 'middle left',
				BOTTOM: 'top center',
				LEFT: 'middle right',
			};

			var HoverState = {
				IN: 'in',
				OUT: 'out',
			};

			var Event = {
				HIDE: 'hide' + EVENT_KEY,
				HIDDEN: 'hidden' + EVENT_KEY,
				SHOW: 'show' + EVENT_KEY,
				SHOWN: 'shown' + EVENT_KEY,
				INSERTED: 'inserted' + EVENT_KEY,
				CLICK: 'click' + EVENT_KEY,
				FOCUSIN: 'focusin' + EVENT_KEY,
				FOCUSOUT: 'focusout' + EVENT_KEY,
				MOUSEENTER: 'mouseenter' + EVENT_KEY,
				MOUSELEAVE: 'mouseleave' + EVENT_KEY,
			};

			var ClassName = {
				FADE: 'fade',
				IN: 'in',
			};

			var Selector = {
				TOOLTIP: '.tooltip',
				TOOLTIP_INNER: '.tooltip-inner',
			};

			var TetherClass = {
				element: false,
				enabled: false,
			};

			var Trigger = {
				HOVER: 'hover',
				FOCUS: 'focus',
				CLICK: 'click',
				MANUAL: 'manual',
			};

			/**
			 * ------------------------------------------------------------------------
			 * Class Definition
			 * ------------------------------------------------------------------------
			 */

			var Tooltip = (function () {
				function Tooltip(element, config) {
					_classCallCheck(this, Tooltip);

					// private
					this._isEnabled = true;
					this._timeout = 0;
					this._hoverState = '';
					this._activeTrigger = {};
					this._tether = null;

					// protected
					this.element = element;
					this.config = this._getConfig(config);
					this.tip = null;

					this._setListeners();
				}

				/**
				 * ------------------------------------------------------------------------
				 * jQuery
				 * ------------------------------------------------------------------------
				 */

				// getters

				_createClass(
					Tooltip,
					[
						{
							key: 'enable',

							// public

							value: function enable() {
								this._isEnabled = true;
							},
						},
						{
							key: 'disable',
							value: function disable() {
								this._isEnabled = false;
							},
						},
						{
							key: 'toggleEnabled',
							value: function toggleEnabled() {
								this._isEnabled = !this._isEnabled;
							},
						},
						{
							key: 'toggle',
							value: function toggle(event) {
								if (event) {
									var dataKey = this.constructor.DATA_KEY;
									var context = $(event.currentTarget).data(dataKey);

									if (!context) {
										context = new this.constructor(event.currentTarget, this._getDelegateConfig());
										$(event.currentTarget).data(dataKey, context);
									}

									context._activeTrigger.click = !context._activeTrigger.click;

									if (context._isWithActiveTrigger()) {
										context._enter(null, context);
									} else {
										context._leave(null, context);
									}
								} else {
									if ($(this.getTipElement()).hasClass(ClassName.IN)) {
										this._leave(null, this);
										return;
									}

									this._enter(null, this);
								}
							},
						},
						{
							key: 'dispose',
							value: function dispose() {
								clearTimeout(this._timeout);

								this.cleanupTether();

								$.removeData(this.element, this.constructor.DATA_KEY);

								$(this.element).off(this.constructor.EVENT_KEY);

								if (this.tip) {
									$(this.tip).remove();
								}

								this._isEnabled = null;
								this._timeout = null;
								this._hoverState = null;
								this._activeTrigger = null;
								this._tether = null;

								this.element = null;
								this.config = null;
								this.tip = null;
							},
						},
						{
							key: 'show',
							value: function show() {
								var _this16 = this;

								var showEvent = $.Event(this.constructor.Event.SHOW);

								if (this.isWithContent() && this._isEnabled) {
									$(this.element).trigger(showEvent);

									var isInTheDom = $.contains(
										this.element.ownerDocument.documentElement,
										this.element
									);

									if (showEvent.isDefaultPrevented() || !isInTheDom) {
										return;
									}

									var tip = this.getTipElement();
									var tipId = Util.getUID(this.constructor.NAME);

									tip.setAttribute('id', tipId);
									this.element.setAttribute('aria-describedby', tipId);

									this.setContent();

									if (this.config.animation) {
										$(tip).addClass(ClassName.FADE);
									}

									var placement =
										typeof this.config.placement === 'function'
											? this.config.placement.call(this, tip, this.element)
											: this.config.placement;

									var attachment = this._getAttachment(placement);

									$(tip).data(this.constructor.DATA_KEY, this).appendTo(document.body);

									$(this.element).trigger(this.constructor.Event.INSERTED);

									this._tether = new Tether({
										attachment: attachment,
										element: tip,
										target: this.element,
										classes: TetherClass,
										classPrefix: CLASS_PREFIX,
										offset: this.config.offset,
										constraints: this.config.constraints,
										addTargetClasses: false,
									});

									Util.reflow(tip);
									this._tether.position();

									$(tip).addClass(ClassName.IN);

									var complete = function complete() {
										var prevHoverState = _this16._hoverState;
										_this16._hoverState = null;

										$(_this16.element).trigger(_this16.constructor.Event.SHOWN);

										if (prevHoverState === HoverState.OUT) {
											_this16._leave(null, _this16);
										}
									};

									if (Util.supportsTransitionEnd() && $(this.tip).hasClass(ClassName.FADE)) {
										$(this.tip)
											.one(Util.TRANSITION_END, complete)
											.emulateTransitionEnd(Tooltip._TRANSITION_DURATION);
										return;
									}

									complete();
								}
							},
						},
						{
							key: 'hide',
							value: function hide(callback) {
								var _this17 = this;

								var tip = this.getTipElement();
								var hideEvent = $.Event(this.constructor.Event.HIDE);
								var complete = function complete() {
									if (_this17._hoverState !== HoverState.IN && tip.parentNode) {
										tip.parentNode.removeChild(tip);
									}

									_this17.element.removeAttribute('aria-describedby');
									$(_this17.element).trigger(_this17.constructor.Event.HIDDEN);
									_this17.cleanupTether();

									if (callback) {
										callback();
									}
								};

								$(this.element).trigger(hideEvent);

								if (hideEvent.isDefaultPrevented()) {
									return;
								}

								$(tip).removeClass(ClassName.IN);

								if (Util.supportsTransitionEnd() && $(this.tip).hasClass(ClassName.FADE)) {
									$(tip).one(Util.TRANSITION_END, complete).emulateTransitionEnd(TRANSITION_DURATION);
								} else {
									complete();
								}

								this._hoverState = '';
							},

							// protected
						},
						{
							key: 'isWithContent',
							value: function isWithContent() {
								return Boolean(this.getTitle());
							},
						},
						{
							key: 'getTipElement',
							value: function getTipElement() {
								return (this.tip = this.tip || $(this.config.template)[0]);
							},
						},
						{
							key: 'setContent',
							value: function setContent() {
								var $tip = $(this.getTipElement());

								this.setElementContent($tip.find(Selector.TOOLTIP_INNER), this.getTitle());

								$tip.removeClass(ClassName.FADE).removeClass(ClassName.IN);

								this.cleanupTether();
							},
						},
						{
							key: 'setElementContent',
							value: function setElementContent($element, content) {
								var html = this.config.html;
								if (typeof content === 'object' && (content.nodeType || content.jquery)) {
									// content is a DOM node or a jQuery
									if (html) {
										if (!$(content).parent().is($element)) {
											$element.empty().append(content);
										}
									} else {
										$element.text($(content).text());
									}
								} else {
									$element[html ? 'html' : 'text'](content);
								}
							},
						},
						{
							key: 'getTitle',
							value: function getTitle() {
								var title = this.element.getAttribute('data-original-title');

								if (!title) {
									title =
										typeof this.config.title === 'function'
											? this.config.title.call(this.element)
											: this.config.title;
								}

								return title;
							},
						},
						{
							key: 'cleanupTether',
							value: function cleanupTether() {
								if (this._tether) {
									this._tether.destroy();
								}
							},

							// private
						},
						{
							key: '_getAttachment',
							value: function _getAttachment(placement) {
								return AttachmentMap[placement.toUpperCase()];
							},
						},
						{
							key: '_setListeners',
							value: function _setListeners() {
								var _this18 = this;

								var triggers = this.config.trigger.split(' ');

								triggers.forEach(function (trigger) {
									if (trigger === 'click') {
										$(_this18.element).on(
											_this18.constructor.Event.CLICK,
											_this18.config.selector,
											$.proxy(_this18.toggle, _this18)
										);
									} else if (trigger !== Trigger.MANUAL) {
										var eventIn =
											trigger === Trigger.HOVER
												? _this18.constructor.Event.MOUSEENTER
												: _this18.constructor.Event.FOCUSIN;
										var eventOut =
											trigger === Trigger.HOVER
												? _this18.constructor.Event.MOUSELEAVE
												: _this18.constructor.Event.FOCUSOUT;

										$(_this18.element)
											.on(eventIn, _this18.config.selector, $.proxy(_this18._enter, _this18))
											.on(eventOut, _this18.config.selector, $.proxy(_this18._leave, _this18));
									}
								});

								if (this.config.selector) {
									this.config = $.extend({}, this.config, {
										trigger: 'manual',
										selector: '',
									});
								} else {
									this._fixTitle();
								}
							},
						},
						{
							key: '_fixTitle',
							value: function _fixTitle() {
								var titleType = typeof this.element.getAttribute('data-original-title');
								if (this.element.getAttribute('title') || titleType !== 'string') {
									this.element.setAttribute(
										'data-original-title',
										this.element.getAttribute('title') || ''
									);
									this.element.setAttribute('title', '');
								}
							},
						},
						{
							key: '_enter',
							value: function _enter(event, context) {
								var dataKey = this.constructor.DATA_KEY;

								context = context || $(event.currentTarget).data(dataKey);

								if (!context) {
									context = new this.constructor(event.currentTarget, this._getDelegateConfig());
									$(event.currentTarget).data(dataKey, context);
								}

								if (event) {
									context._activeTrigger[
										event.type === 'focusin' ? Trigger.FOCUS : Trigger.HOVER
									] = true;
								}

								if (
									$(context.getTipElement()).hasClass(ClassName.IN) ||
									context._hoverState === HoverState.IN
								) {
									context._hoverState = HoverState.IN;
									return;
								}

								clearTimeout(context._timeout);

								context._hoverState = HoverState.IN;

								if (!context.config.delay || !context.config.delay.show) {
									context.show();
									return;
								}

								context._timeout = setTimeout(function () {
									if (context._hoverState === HoverState.IN) {
										context.show();
									}
								}, context.config.delay.show);
							},
						},
						{
							key: '_leave',
							value: function _leave(event, context) {
								var dataKey = this.constructor.DATA_KEY;

								context = context || $(event.currentTarget).data(dataKey);

								if (!context) {
									context = new this.constructor(event.currentTarget, this._getDelegateConfig());
									$(event.currentTarget).data(dataKey, context);
								}

								if (event) {
									context._activeTrigger[
										event.type === 'focusout' ? Trigger.FOCUS : Trigger.HOVER
									] = false;
								}

								if (context._isWithActiveTrigger()) {
									return;
								}

								clearTimeout(context._timeout);

								context._hoverState = HoverState.OUT;

								if (!context.config.delay || !context.config.delay.hide) {
									context.hide();
									return;
								}

								context._timeout = setTimeout(function () {
									if (context._hoverState === HoverState.OUT) {
										context.hide();
									}
								}, context.config.delay.hide);
							},
						},
						{
							key: '_isWithActiveTrigger',
							value: function _isWithActiveTrigger() {
								for (var trigger in this._activeTrigger) {
									if (this._activeTrigger[trigger]) {
										return true;
									}
								}

								return false;
							},
						},
						{
							key: '_getConfig',
							value: function _getConfig(config) {
								config = $.extend({}, this.constructor.Default, $(this.element).data(), config);

								if (config.delay && typeof config.delay === 'number') {
									config.delay = {
										show: config.delay,
										hide: config.delay,
									};
								}

								Util.typeCheckConfig(NAME, config, this.constructor.DefaultType);

								return config;
							},
						},
						{
							key: '_getDelegateConfig',
							value: function _getDelegateConfig() {
								var config = {};

								if (this.config) {
									for (var key in this.config) {
										if (this.constructor.Default[key] !== this.config[key]) {
											config[key] = this.config[key];
										}
									}
								}

								return config;
							},

							// static
						},
					],
					[
						{
							key: '_jQueryInterface',
							value: function _jQueryInterface(config) {
								return this.each(function () {
									var data = $(this).data(DATA_KEY);
									var _config = typeof config === 'object' ? config : null;

									if (!data && /destroy|hide/.test(config)) {
										return;
									}

									if (!data) {
										data = new Tooltip(this, _config);
										$(this).data(DATA_KEY, data);
									}

									if (typeof config === 'string') {
										if (data[config] === undefined) {
											throw new Error('No method named "' + config + '"');
										}
										data[config]();
									}
								});
							},
						},
						{
							key: 'VERSION',
							get: function get() {
								return VERSION;
							},
						},
						{
							key: 'Default',
							get: function get() {
								return Default;
							},
						},
						{
							key: 'NAME',
							get: function get() {
								return NAME;
							},
						},
						{
							key: 'DATA_KEY',
							get: function get() {
								return DATA_KEY;
							},
						},
						{
							key: 'Event',
							get: function get() {
								return Event;
							},
						},
						{
							key: 'EVENT_KEY',
							get: function get() {
								return EVENT_KEY;
							},
						},
						{
							key: 'DefaultType',
							get: function get() {
								return DefaultType;
							},
						},
					]
				);

				return Tooltip;
			})();

			$.fn[NAME] = Tooltip._jQueryInterface;
			$.fn[NAME].Constructor = Tooltip;
			$.fn[NAME].noConflict = function () {
				$.fn[NAME] = JQUERY_NO_CONFLICT;
				return Tooltip._jQueryInterface;
			};

			return Tooltip;
		})(jQuery);

		/**
		 * --------------------------------------------------------------------------
		 * Bootstrap (v4.0.0-alpha.4): popover.js
		 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
		 * --------------------------------------------------------------------------
		 */

		var Popover = (function ($) {
			/**
			 * ------------------------------------------------------------------------
			 * Constants
			 * ------------------------------------------------------------------------
			 */

			var NAME = 'popover';
			var VERSION = '4.0.0-alpha.4';
			var DATA_KEY = 'bs.popover';
			var EVENT_KEY = '.' + DATA_KEY;
			var JQUERY_NO_CONFLICT = $.fn[NAME];

			var Default = $.extend({}, Tooltip.Default, {
				placement: 'right',
				trigger: 'click',
				content: '',
				template:
					'<div class="popover" role="tooltip">' +
					'<div class="popover-arrow"></div>' +
					'<h3 class="popover-title"></h3>' +
					'<div class="popover-content"></div></div>',
			});

			var DefaultType = $.extend({}, Tooltip.DefaultType, {
				content: '(string|element|function)',
			});

			var ClassName = {
				FADE: 'fade',
				IN: 'in',
			};

			var Selector = {
				TITLE: '.popover-title',
				CONTENT: '.popover-content',
				ARROW: '.popover-arrow',
			};

			var Event = {
				HIDE: 'hide' + EVENT_KEY,
				HIDDEN: 'hidden' + EVENT_KEY,
				SHOW: 'show' + EVENT_KEY,
				SHOWN: 'shown' + EVENT_KEY,
				INSERTED: 'inserted' + EVENT_KEY,
				CLICK: 'click' + EVENT_KEY,
				FOCUSIN: 'focusin' + EVENT_KEY,
				FOCUSOUT: 'focusout' + EVENT_KEY,
				MOUSEENTER: 'mouseenter' + EVENT_KEY,
				MOUSELEAVE: 'mouseleave' + EVENT_KEY,
			};

			/**
			 * ------------------------------------------------------------------------
			 * Class Definition
			 * ------------------------------------------------------------------------
			 */

			var Popover = (function (_Tooltip) {
				_inherits(Popover, _Tooltip);

				function Popover() {
					_classCallCheck(this, Popover);

					_get(Object.getPrototypeOf(Popover.prototype), 'constructor', this).apply(this, arguments);
				}

				/**
				 * ------------------------------------------------------------------------
				 * jQuery
				 * ------------------------------------------------------------------------
				 */

				_createClass(
					Popover,
					[
						{
							key: 'isWithContent',

							// overrides

							value: function isWithContent() {
								return this.getTitle() || this._getContent();
							},
						},
						{
							key: 'getTipElement',
							value: function getTipElement() {
								return (this.tip = this.tip || $(this.config.template)[0]);
							},
						},
						{
							key: 'setContent',
							value: function setContent() {
								var $tip = $(this.getTipElement());

								// we use append for html objects to maintain js events
								this.setElementContent($tip.find(Selector.TITLE), this.getTitle());
								this.setElementContent($tip.find(Selector.CONTENT), this._getContent());

								$tip.removeClass(ClassName.FADE).removeClass(ClassName.IN);

								this.cleanupTether();
							},

							// private
						},
						{
							key: '_getContent',
							value: function _getContent() {
								return (
									this.element.getAttribute('data-content') ||
									(typeof this.config.content === 'function'
										? this.config.content.call(this.element)
										: this.config.content)
								);
							},

							// static
						},
					],
					[
						{
							key: '_jQueryInterface',
							value: function _jQueryInterface(config) {
								return this.each(function () {
									var data = $(this).data(DATA_KEY);
									var _config = typeof config === 'object' ? config : null;

									if (!data && /destroy|hide/.test(config)) {
										return;
									}

									if (!data) {
										data = new Popover(this, _config);
										$(this).data(DATA_KEY, data);
									}

									if (typeof config === 'string') {
										if (data[config] === undefined) {
											throw new Error('No method named "' + config + '"');
										}
										data[config]();
									}
								});
							},
						},
						{
							key: 'VERSION',

							// getters

							get: function get() {
								return VERSION;
							},
						},
						{
							key: 'Default',
							get: function get() {
								return Default;
							},
						},
						{
							key: 'NAME',
							get: function get() {
								return NAME;
							},
						},
						{
							key: 'DATA_KEY',
							get: function get() {
								return DATA_KEY;
							},
						},
						{
							key: 'Event',
							get: function get() {
								return Event;
							},
						},
						{
							key: 'EVENT_KEY',
							get: function get() {
								return EVENT_KEY;
							},
						},
						{
							key: 'DefaultType',
							get: function get() {
								return DefaultType;
							},
						},
					]
				);

				return Popover;
			})(Tooltip);

			$.fn[NAME] = Popover._jQueryInterface;
			$.fn[NAME].Constructor = Popover;
			$.fn[NAME].noConflict = function () {
				$.fn[NAME] = JQUERY_NO_CONFLICT;
				return Popover._jQueryInterface;
			};

			return Popover;
		})(jQuery);
	})(jQuery);
});

export const ESCAPE_KEY_CODE = 'Escape';
export const ENTER_KEY_CODE = 'Enter';
export const ARROW_UP_KEY_CODE = 'ArrowUp';
export const ARROW_DOWN_KEY_CODE = 'ArrowDown';
export const SHIFT_RIGHT_KEY_CODE = 'ShiftRight';
export const SHIFT_LEFT_KEY_CODE = 'ShiftLeft';
export const ALT_RIGHT_KEY_CODE = 'AltRight';
export const ALT_LEFT_KEY_CODE = 'AltLeft';
export const CONTROL_RIGHT_KEY_CODE = 'ControlRight';
export const CONTROL_LEFT_KEY_CODE = 'ControlLeft';

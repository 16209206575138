import app from 'durandal/app.js';
import dialog from 'plugins/dialog.js';
import UpgradeHelper from 'viewmodels/upgrade-helper.js';
import SubscriptionLimitModalView from 'views/subscription-limit-modal.html';
import analytics from '@cheqroom/web/src/services/analytics';
import ReactSubscriptionLimitModal from '@cheqroom/web/src/components/SubscriptionLimitModal/SubscriptionLimitModal';

export default class SubscriptionLimitModal {
	constructor(spec = {}) {
		this.view = SubscriptionLimitModalView;
		this.ReactSubscriptionLimitModal = ReactSubscriptionLimitModal;

		this.kind = spec.kind || 'items'; //item or location
		this.source = spec.source;

		this.global = spec.global;

		this.maxItems = this.global ? this.global.central.limits().maxItemsSoft : 0;
		this.maxLocations = this.global ? this.global.central.limits().maxLocations : 0;
	}

	static show = function (spec) {
		return dialog.showBootstrapDialog(new SubscriptionLimitModal(spec));
	};

	activate = function () {
		return UpgradeHelper.init(this, this.kind).then(() => {
			// Doesn't need to upgrade to another plan, can upgrade current plan to
			// higher item limit
			if (this.kind === 'items') {
				this.upgradeLink = this.global.central.getUpgradeLink();
			}
		});
	};

	close = function () {
		dialog.close(this);
	};

	compositionComplete = function (v, p) {
		app.trigger('intercom:event', { name: 'web-viewed-limit', feature: this.featureId });

		const featureName = this.kind === 'items' ? 'Items Quota' : 'Locations Quota';

		analytics.track('Upgrade Modal Shown', this.source, {
			feature: featureName,
		});

		// This event is fired when the modal has been made visible to the user
		// http://stackoverflow.com/questions/22547472/how-can-i-get-auto-focus-to-an-input-element-in-a-dynamically-generated-knockout
		$(p).on('hidden.bs.modal', () => {
			this.close();
		});
	};

	clickedUpgrade = function () {
		app.trigger('intercom:event', { name: 'web-clicked-limit-upgrade', feature: this.featureId });

		const featureName = this.kind === 'items' ? 'Items Quota' : 'Locations Quota';

		analytics.track('Subscription Changing', this.source, {
			feature: featureName,
		});

		this.close();

		return true;
	};
}

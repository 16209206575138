import app from 'durandal/app.js';
import ko from 'knockout';
import kov from 'knockout-validation';
import graphQL from 'viewmodels/graphql.js';
import featureFlags from '@cheqroom/web/src/services/feature-flags';

export default {
	mixin: function (contact, options) {
		var orig_fromJson = contact._fromJson,
			orig_isDirty = contact.isDirty,
			orig_isEmpty = contact.isEmpty,
			orig_update = contact.update,
			orig_setField = contact.setField,
			orig_getAttachment = contact._getAttachment;

		var global = options.global;

		// Core method overrides
		// ----
		contact._fromJson = function (data, options) {
			//Use apply to call function so we can pass the
			//Base context otherwise it would use Window context
			return orig_fromJson.apply(contact, [data, options]).then(function () {
				var contactData = contact.oData();
				contactData.name(contact.name);
				contactData.email(contact.email);
				contactData.kind(contact.kind);

				contact.oStatus(contact.status);
				contact.oCover(contact.cover);

				return data;
			});
		};

		contact.reset = function (extraData = {}) {
			contact._fromJson(Object.assign(contact._getDefaults(), extraData), null);

			// Reset observables to defaults
			var data = contact.oData();
			data.name(contact.name);
			data.name.isModified(false);
			data.email(contact.email);
			data.email.isModified(false);
			data.kind(contact.kind);

			contact.oCover(contact.cover);
			contact.oStatus(contact.status);
		};

		contact._getAttachment = function (att, options) {
			return orig_getAttachment.apply(contact, [
				att,
				$.extend(options, {
					canBeCover: true,
					isCover: contact && contact.raw ? contact.raw.cover == att.value : false,
				}),
			]);
		};

		contact.update = function () {
			return orig_update.apply(contact, arguments).then(function () {
				app.trigger('contacts:changed', [contact]);
			});
		};

		contact.setField = function (field, value) {
			return orig_setField.apply(contact, [field, value]).then(function () {
				app.trigger('contacts:changed', [contact]);
			});
		};

		contact.onUpdateName = function (e, newValue) {
			if ($.trim(newValue).length >= 1) {
				contact.oName(newValue);

				contact.update().then(function () {
					app.trigger('contacts:changed');
					app.trigger('timeline:refresh');
					app.trigger('notification', 'Updated user name');
				});
			} else {
				return 'Please enter at least 1 character.';
			}
		};

		contact.validateAsync = function (params) {
			// ! We have to use Deferred here, since the xeditable plugin doesn't support promises
			const d = new $.Deferred();
			const newEmailValue = params.value.trim();
			if (!global.common.isValidEmail(newEmailValue)) {
				d.reject('Email is a required field');
			}

			global
				.getDataSource('invites')
				.call(null, 'emailsInvited', { emails: [params.value] })
				.then(async (response) => {
					const { data: { contacts: contactsWithSameEmail = [] } = {} } = await graphQL({
						query: `query contacts($email: String) {
									contacts(filter: { email: $email}) {
										id
									}
								}`,
						variables: { email: newEmailValue },
					});

					const isOnlyContactWithSameEmail =
						contactsWithSameEmail.length === 1 && contactsWithSameEmail[0].id === contact.id;

					const emailAlreadyInvited = Boolean(response[newEmailValue] && response[newEmailValue].invited);
					const emailAlreadyUsed =
						Boolean(response[newEmailValue] && response[newEmailValue].user) ||
						(contactsWithSameEmail.length > 0 && !isOnlyContactWithSameEmail);
					const emailAlreadyUsedInOtherAccount = Boolean(
						response[newEmailValue] && response[newEmailValue].user_status === 'other_account'
					);
					if (emailAlreadyInvited) {
						d.reject('An email has already been sent to this email address.');
					}

					if (emailAlreadyUsedInOtherAccount) {
						d.reject('This email is already used to access another account.');
					}

					if (emailAlreadyUsed) {
						d.reject('This email address is already used for another user.');
					}

					d.resolve();
				});

			return d.resolve();
		};

		contact.onUpdateEmail = async function (e, newValue) {
			const perm = global.getPermissionHandler();
			if (!perm.hasUserPermission('update')) {
				app.trigger('error', 'You do not have permission to update this user email');
			}

			const isFreeArchivedUserEmailEnabled = featureFlags.isFeatureEnabled('free_archived_user_email');

			let contactWithInviteInfo = {};
			if (!isFreeArchivedUserEmailEnabled) {
				const { data = {} } = await graphQL({
					query: `
							query contact($id: ID!) {
								contact(id: $id) {
									invite {
										id
										roleId
									}
								}
							 }
						`,
					variables: {
						id: contact.id,
					},
				});

				contactWithInviteInfo = data.contact;
			}

			// When you update the email of a contact, the original invite becomes invalid
			// And we're sending a new invite to the new email
			if (contactWithInviteInfo.invite) {
				if (!isFreeArchivedUserEmailEnabled) {
					await graphQL({
						query: `
								mutation revokeInvite($ids: [ID!]!) {
									revokeInvite(ids: $ids)
								}
							`,
						variables: {
							ids: [contactWithInviteInfo.invite.id],
						},
					});
				}

				contact.oEmail(newValue);
				await contact.update();

				if (!isFreeArchivedUserEmailEnabled) {
					await graphQL({
						query: `mutation inviteContact($ids: [ID!]!, $roleId: ID!) {
								inviteContact(ids: $ids, roleId: $roleId)
							}`,
						variables: {
							ids: [contact.id],
							roleId: contactWithInviteInfo.invite.roleId,
						},
					});
				}

				app.trigger('timeline:refresh');
				app.trigger('contact:changed');
				app.trigger('notification', 'Updated user email');
			}

			contact.oEmail(newValue);
			contact.update().then(function () {
				app.trigger('timeline:refresh');
				app.trigger('notification', 'Updated user email');
			});
		};

		// Observables
		// ----
		contact.oName = ko.observable(contact.name);
		contact.oEmail = ko.observable(contact.email);
		contact.oKind = ko.observable('contact');
		contact.oData = ko.validatedObservable({
			name: contact.oName.trimmed().extend({
				required: { message: 'Name is a required field' },
				minLength: 3,
			}),
			email: contact.oEmail.extend({ required: { message: 'Email is a required field' }, email: true }),
			kind: contact.oKind,
		});
		contact.oStatus = ko.observable(contact.status);

		// Computables
		// ----

		// Make sure the cr.Contact fields are updated when our computeds are updated as well
		// This enables the default: isEmpty, isDirty, _toJson to work automatically
		contact._updateName = ko.computed(function () {
			contact.name = contact.oName();
		});
		contact._updateEmail = ko.computed(function () {
			contact.email = contact.oEmail();
		});
		contact._updateKind = ko.computed(function () {
			contact.kind = contact.oKind();
		});
		contact._updateKind = ko.computed(function () {
			contact.cover = contact.oCover();
		});

		contact.isDirty = ko.computed(function () {
			var data = contact.oData(),
				name = data.name(),
				email = data.email();
			return orig_isDirty.apply(contact);
		});

		contact.isEmpty = ko.computed(function () {
			var data = contact.oData(),
				name = data.name(),
				email = data.email();
			return orig_isEmpty.apply(contact);
		});
	},
};

import moment from 'moment';

const _isNumeric = (n) => {
	return !isNaN(parseFloat(n)) && isFinite(n);
};

export default {
	/**
	 * isValidEmail
	 * @memberOf common
	 * @name  common#isValidEmail
	 * @method
	 * @param  {string}  email
	 * @return {Boolean}
	 */
	isValidEmail: function (email) {
		var m = email.match(/^([\w-\+']+(?:\.[\w-\+']+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,}(?:\.[a-z]{2})?)$/i);
		return m != null && m.length > 0;
	},
	/**
	 * isFreeEmail
	 * @memberOf common
	 * @name common#isFreeEmail
	 * @method
	 * @param email
	 * @returns {boolean}
	 */
	isFreeEmail: function (email) {
		var m = email.match(
			/^([\w-\+]+(?:\.[\w-\+]+)*)@(?!gmail\.com)(?!yahoo\.com)(?!hotmail\.com)(?!163\.com)(?!qq\.com)(?!mail\.ru)(?!aol\.com)(?!outlook\.com)(?!icloud\.com)((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,}(?:\.[a-z]{2})?)$/i
		);
		return m == null;
	},
	/**
	 * isValidPhone
	 * @memberOf common
	 * @name  common#isValidPhone
	 * @method
	 * @param  {string}  phone
	 * @return {Boolean}
	 */
	isValidPhone: function (phone) {
		// stip all none ascii characters
		// f.e "054-5237745‬4" --> "054-5237745%u202C4"
		// https://stackoverflow.com/questions/20856197/remove-non-ascii-character-in-string
		phone = phone.replace(/[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g, '');

		if (_isNumeric(phone)) {
			return true;
		}

		var m = phone.match(/^[\s()+-]*([0-9][\s()+-]*){7,20}(( x| ext)\d{1,5}){0,1}$/);
		return m != null && m.length > 0;
	},
	/**
	 * isValidURL
	 * @memberOf common
	 * @name common#isValidURL
	 * @method
	 * @param {string}  url
	 * @returns {boolean}
	 */
	isValidURL: function (url) {
		// https://mathiasbynens.be/demo/url-regex
		// https://gist.github.com/dperini/729294
		// NOTE: We removed the private & local networks IP exclusion from the RegEx since some customers actually use internal IPs in their URLs
		const urlRegex = new RegExp(
			"^" +
				// protocol identifier (optional)
				// short syntax // still required
				"(?:(?:(?:https?|ftp):)?\\/\\/)" +
				// user:pass BasicAuth (optional)
				"(?:\\S+(?::\\S*)?@)?" +
				"(?:" +
					// IP address dotted notation octets
					// excludes loopback network 0.0.0.0
					// excludes reserved space >= 224.0.0.0
					// excludes network & broadcast addresses
					// (first & last IP address of each class)
					"(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
					"(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
					"(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
				"|" +
					// host & domain names, may end with dot
					// can be replaced by a shortest alternative
					// (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
					"(?:" +
						"(?:" +
							"[a-z0-9\\u00a1-\\uffff]" +
							"[a-z0-9\\u00a1-\\uffff_-]{0,62}" +
						")?" +
						"[a-z0-9\\u00a1-\\uffff]\\." +
					")+" +
					// TLD identifier name, may end with dot
					"(?:[a-z\\u00a1-\\uffff]{2,}\\.?)" +
				")" +
				// port number (optional)
				"(?::\\d{2,5})?" +
				// resource path (optional)
				"(?:[/?#]\\S*)?" +
			"$", "i"
		);

		var m = url.match(urlRegex);
		return m != null && m.length > 0;
	},
	/**
	 * isValidPassword
	 * @memberOf common
	 * @name common#isValidPassword
	 * @method
	 * @param password
	 * @returns {boolean}
	 */
	isValidPassword: function (password) {
		var hasDigit = password.match(/[0-9]/);
		return password.length >= 4 && hasDigit;
	},
	/**
	 * isNumeric
	 * https://stackoverflow.com/questions/18082/validate-decimal-numbers-in-javascript-isnumeric
	 * @memberOf common
	 * @name common#isNumeric
	 * @method
	 * @param  {string}     value
	 * @param  {boolean}    onlyInteger
	 * @return {Boolean}
	 */
	isNumeric: function (value, onlyInteger) {
		if (onlyInteger) {
			return (value ^ 0) === Number(value);
		}

		return _isNumeric(value);
	},
	/**
	 * isValidDate
	 * @memberOf common
	 * @name common#isValidDate
	 * @method
	 * @param  {string}     value
	 * @return {Boolean}
	 */
	isValidDate: function (value) {
		// make sure numbers are parsed as a number
		if (/^[0-9]+$/.test(value)) {
			value = parseInt(value);
		}

		return moment(value).isValid();
	},
};

import { forwardRef, ReactNode } from 'react';

import { StyledSection } from './Section.styles';

type Props = {
	dark?: boolean;
	children?: ReactNode;
};

export const Section = forwardRef<HTMLElement, Props>(({ children, ...props }, ref) => (
	<StyledSection {...props} ref={ref}>
		{children}
	</StyledSection>
));

Section.displayName = 'Section';

import { CodeType } from '../../../@types/graphql.d';

const mapCodeTypetoBcid = {
	[CodeType.BARCODE]: 'code128',
	[CodeType.QRCODE]: 'qrcode',
};

export const getCodeUrl = (kind: CodeType, value: string, width = 40, height = 40) => {
	const CODES_API_URL = process.env.CODES_API_URL as string;

	const toMm = (px: number) => px * 0.2645833333;

	const url = new URL(CODES_API_URL);
	url.searchParams.append('bcid', mapCodeTypetoBcid[kind]);
	url.searchParams.append('text', value);
	url.searchParams.append('width', toMm(width).toString());
	url.searchParams.append('height', toMm(height).toString());

	return url.toString();
};

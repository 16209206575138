export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string };
	String: { input: string; output: string };
	Boolean: { input: boolean; output: boolean };
	Int: { input: number; output: number };
	Float: { input: number; output: number };
	CustomField: { input: [string, string | number]; output: [string, string | number] };
	ExpressionFilter: { input: any; output: any };
	StringFilter: { input: any; output: any };
};

export type Availability = {
	__typename?: 'Availability';
	excludeReservationId?: Maybe<Scalars['ID']['output']>;
	from: Scalars['String']['output'];
	itemId: Scalars['ID']['output'];
	quantity?: Maybe<Scalars['Int']['output']>;
	status: AvailabilityStatus;
	to: Scalars['String']['output'];
};

export const enum AvailabilityStatus {
	ASSIGNED = 'ASSIGNED',
	AVAILABLE = 'AVAILABLE',
	CHECKED_OUT = 'CHECKED_OUT',
	FLAGGED = 'FLAGGED',
	RESERVED = 'RESERVED',
	RETIRED = 'RETIRED',
	UNAVAILABLE = 'UNAVAILABLE',
}

export type BookableItem = {
	itemId: Scalars['ID']['input'];
	quantity: Scalars['Int']['input'];
};

export type BookedQuantity = {
	__typename?: 'BookedQuantity';
	itemId: Scalars['ID']['output'];
	quantity: Scalars['Int']['output'];
};

export type BookingCalendar = {
	__typename?: 'BookingCalendar';
	days: Array<Day>;
};

export type BookingCalendarFilter = {
	fromDate?: InputMaybe<Scalars['String']['input']>;
	locationId: Scalars['ID']['input'];
	month: Scalars['Int']['input'];
	year: Scalars['Int']['input'];
};

export type BookingSettings = {
	__typename?: 'BookingSettings';
	bookingLimit?: Maybe<Scalars['Int']['output']>;
	maximumBookingDuration?: Maybe<DurationSetting>;
	maximumInAdvance?: Maybe<DurationSetting>;
	minimumBookingDuration?: Maybe<DurationSetting>;
	minimumInAdvance?: Maybe<DurationSetting>;
	timeOptionInterval?: Maybe<Scalars['Int']['output']>;
};

export type BookingTimeOption = {
	__typename?: 'BookingTimeOption';
	status: BookingTimeOptionStatus;
	time: Scalars['String']['output'];
};

export const enum BookingTimeOptionStatus {
	AVAILABLE = 'AVAILABLE',
	RESTRICTED = 'RESTRICTED',
	UNAVAILABLE = 'UNAVAILABLE',
}

export type BookingTimeOptionsFilter = {
	date: Scalars['String']['input'];
	locationId: Scalars['ID']['input'];
};

export const enum BookingType {
	CHECKOUT = 'CHECKOUT',
	RESERVATION = 'RESERVATION',
}

export type Category = {
	__typename?: 'Category';
	id: Scalars['ID']['output'];
	itemCount: Scalars['Int']['output'];
	name: Scalars['String']['output'];
	parent?: Maybe<Category>;
	parentId?: Maybe<Scalars['ID']['output']>;
	path?: Maybe<Scalars['String']['output']>;
	retiredItemCount: Scalars['Int']['output'];
};

export type CategoryFilter = {
	name?: InputMaybe<Scalars['StringFilter']['input']>;
};

export type CategorySortOperator = {
	field: SortableCategoryField;
	order?: InputMaybe<SortOrder>;
};

export type Checkout = {
	__typename?: 'Checkout';
	archivedOn?: Maybe<Scalars['String']['output']>;
	bookedQuantities: Array<BookedQuantity>;
	contact?: Maybe<Contact>;
	contactId?: Maybe<Scalars['ID']['output']>;
	droppedOffOn?: Maybe<Scalars['String']['output']>;
	dueOn?: Maybe<Scalars['String']['output']>;
	id: Scalars['ID']['output'];
	itemIds: Array<Scalars['ID']['output']>;
	items: Array<Item>;
	label?: Maybe<Label>;
	labelId?: Maybe<Scalars['ID']['output']>;
	location?: Maybe<Location>;
	locationId?: Maybe<Scalars['ID']['output']>;
	name: Scalars['String']['output'];
	number?: Maybe<Scalars['String']['output']>;
	pickedUpOn?: Maybe<Scalars['String']['output']>;
	reservationId?: Maybe<Scalars['ID']['output']>;
	status: CheckoutStatus;
};

export type CheckoutItemsArgs = {
	contactId?: InputMaybe<Scalars['ID']['input']>;
	page?: InputMaybe<Page>;
	sort?: InputMaybe<ItemSortOperator>;
};

export type CheckoutSortOperator = {
	field: SortableCheckoutField;
	order?: InputMaybe<SortOrder>;
};

export const enum CheckoutStatus {
	CLOSED = 'CLOSED',
	DRAFT = 'DRAFT',
	OPEN = 'OPEN',
}

export type CheckoutsFilter = {
	isArchived?: InputMaybe<Scalars['Boolean']['input']>;
	isOverdue?: InputMaybe<Scalars['Boolean']['input']>;
	item?: InputMaybe<Scalars['ID']['input']>;
	name?: InputMaybe<Scalars['String']['input']>;
	status?: InputMaybe<Array<CheckoutStatus>>;
};

export type Code = {
	__typename?: 'Code';
	type: CodeType;
	value: Scalars['String']['output'];
};

export const enum CodeType {
	BARCODE = 'BARCODE',
	QRCODE = 'QRCODE',
}

export type Contact = {
	__typename?: 'Contact';
	avatar?: Maybe<Scalars['String']['output']>;
	contactGroup?: Maybe<ContactGroup>;
	contactGroupId?: Maybe<Scalars['ID']['output']>;
	email?: Maybe<Scalars['String']['output']>;
	id: Scalars['ID']['output'];
	invite?: Maybe<UserInvite>;
	isArchived: Scalars['Boolean']['output'];
	isMaintenance: Scalars['Boolean']['output'];
	name?: Maybe<Scalars['String']['output']>;
	status: ContactStatus;
	user?: Maybe<User>;
	userId?: Maybe<Scalars['ID']['output']>;
};

export type ContactAvatarArgs = {
	size?: InputMaybe<ImageSize>;
};

export type ContactGroup = {
	__typename?: 'ContactGroup';
	allowedFilterIds: Array<Scalars['ID']['output']>;
	contactCount: Scalars['Int']['output'];
	description?: Maybe<Scalars['String']['output']>;
	id: Scalars['ID']['output'];
	itemCount?: Maybe<Scalars['Int']['output']>;
	name: Scalars['String']['output'];
};

export type ContactGroupsFilter = {
	name?: InputMaybe<Scalars['String']['input']>;
};

export const enum ContactStatus {
	ACTIVE = 'ACTIVE',
	ARCHIVED = 'ARCHIVED',
	BLOCKED = 'BLOCKED',
	DELETED = 'DELETED',
}

export type ContactsFilter = {
	contactIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	email?: InputMaybe<Scalars['String']['input']>;
	excludeArchived?: InputMaybe<Scalars['Boolean']['input']>;
	excludeBlocked?: InputMaybe<Scalars['Boolean']['input']>;
	excludeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
	term?: InputMaybe<Scalars['String']['input']>;
};

export type CurrentSubscription = {
	__typename?: 'CurrentSubscription';
	currentTermEndsAt?: Maybe<Scalars['String']['output']>;
	expiresAt?: Maybe<Scalars['String']['output']>;
	id: Scalars['ID']['output'];
	kind: SubscriptionKind;
	plan: SubscriptionPlan;
	price?: Maybe<Price>;
	proposedPlan?: Maybe<ProposedSubscriptionPlan>;
};

export const enum CustomFieldKind {
	BOOL = 'BOOL',
	CUSTOMER = 'CUSTOMER',
	DATETIME = 'DATETIME',
	FLOAT = 'FLOAT',
	INT = 'INT',
	JSON = 'JSON',
	SELECT = 'SELECT',
	STRING = 'STRING',
}

export type CustomItemFieldDefinition = {
	__typename?: 'CustomItemFieldDefinition';
	id: Scalars['ID']['output'];
	kind: CustomFieldKind;
	name: Scalars['String']['output'];
	select?: Maybe<Array<Scalars['String']['output']>>;
};

export type DailyRate = {
	__typename?: 'DailyRate';
	date: Scalars['String']['output'];
	rate: Scalars['Int']['output'];
};

export type Day = {
	__typename?: 'Day';
	date: Scalars['String']['output'];
	status: DayStatus;
};

export const enum DayOfWeek {
	FRIDAY = 'FRIDAY',
	MONDAY = 'MONDAY',
	SATURDAY = 'SATURDAY',
	SUNDAY = 'SUNDAY',
	THURSDAY = 'THURSDAY',
	TUESDAY = 'TUESDAY',
	WEDNESDAY = 'WEDNESDAY',
}

export const enum DayStatus {
	AVAILABLE = 'AVAILABLE',
	RESTRICTED = 'RESTRICTED',
	UNAVAILABLE = 'UNAVAILABLE',
}

export type Duration = {
	__typename?: 'Duration';
	interval: Scalars['Int']['output'];
	unit: Scalars['String']['output'];
};

export type DurationSetting = {
	__typename?: 'DurationSetting';
	duration: Scalars['String']['output'];
	skipClosedDays: Scalars['Boolean']['output'];
};

export type DurationSettingInput = {
	duration: Scalars['String']['input'];
	skipClosedDays: Scalars['Boolean']['input'];
};

export type Equipment = EquipmentItem | EquipmentKit;

export type EquipmentAvailabilityFilter = {
	from: Scalars['String']['input'];
	to: Scalars['String']['input'];
};

export type EquipmentFilter = {
	canBeCheckedOut?: InputMaybe<Scalars['Boolean']['input']>;
	canBeReserved?: InputMaybe<Scalars['Boolean']['input']>;
	categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	codes?: InputMaybe<Array<Scalars['String']['input']>>;
	excludeRestrictedFor?: InputMaybe<BookingType>;
	excludeUnavailableBetween?: InputMaybe<EquipmentAvailabilityFilter>;
	excludedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	excludedKitIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	includeRetired?: InputMaybe<Scalars['Boolean']['input']>;
	itemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	locationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	term?: InputMaybe<Scalars['String']['input']>;
	type?: InputMaybe<EquipmentType>;
};

export type EquipmentItem = {
	__typename?: 'EquipmentItem';
	address?: Maybe<Scalars['String']['output']>;
	availability?: Maybe<Availability>;
	availableForKits: Scalars['Int']['output'];
	bookedQuantity?: Maybe<Scalars['Int']['output']>;
	brand?: Maybe<Scalars['String']['output']>;
	canBeCheckedOut: Scalars['Boolean']['output'];
	canBeInCustody: Scalars['Boolean']['output'];
	canBeReserved: Scalars['Boolean']['output'];
	category?: Maybe<Category>;
	categoryId?: Maybe<Scalars['ID']['output']>;
	checkedOutQuantity?: Maybe<Scalars['Int']['output']>;
	checkinStatus?: Maybe<ItemCheckinStatus>;
	checkoutId?: Maybe<Scalars['ID']['output']>;
	codes: Array<Code>;
	fields?: Maybe<Array<Scalars['CustomField']['output']>>;
	flag?: Maybe<Flag>;
	flagId?: Maybe<Scalars['ID']['output']>;
	id: Scalars['ID']['output'];
	image?: Maybe<Scalars['String']['output']>;
	inKits: Scalars['Int']['output'];
	isRestricted: Scalars['Boolean']['output'];
	kind?: Maybe<ItemKind>;
	location?: Maybe<Location>;
	locationId?: Maybe<Scalars['ID']['output']>;
	model?: Maybe<Scalars['String']['output']>;
	name: Scalars['String']['output'];
	number?: Maybe<Scalars['String']['output']>;
	quantity: Scalars['Int']['output'];
	status: ItemStatus;
};

export type EquipmentItemAvailabilityArgs = {
	excludeCheckoutId?: InputMaybe<Scalars['ID']['input']>;
	excludeReservationId?: InputMaybe<Scalars['ID']['input']>;
	from?: InputMaybe<Scalars['String']['input']>;
	kinds?: InputMaybe<Array<AvailabilityStatus>>;
	to?: InputMaybe<Scalars['String']['input']>;
};

export type EquipmentItemBookedQuantityArgs = {
	checkoutId?: InputMaybe<Scalars['ID']['input']>;
	reservationId?: InputMaybe<Scalars['ID']['input']>;
};

export type EquipmentItemCheckedOutQuantityArgs = {
	checkoutId: Scalars['ID']['input'];
};

export type EquipmentItemCheckinStatusArgs = {
	checkoutId: Scalars['ID']['input'];
};

export type EquipmentItemImageArgs = {
	size?: InputMaybe<ImageSize>;
};

export type EquipmentKit = {
	__typename?: 'EquipmentKit';
	codes: Array<Code>;
	id: Scalars['ID']['output'];
	image?: Maybe<Scalars['String']['output']>;
	itemIds: Array<Scalars['ID']['output']>;
	itemQuantities: Array<ItemQuantity>;
	items: Array<EquipmentItem>;
	name: Scalars['String']['output'];
};

export type EquipmentKitImageArgs = {
	size?: InputMaybe<ImageSize>;
};

export type EquipmentKitItemsArgs = {
	contactId?: InputMaybe<Scalars['ID']['input']>;
};

export const enum EquipmentType {
	all = 'all',
	item = 'item',
	kit = 'kit',
}

export type Filter = {
	__typename?: 'Filter';
	count: Scalars['Int']['output'];
	expression: Scalars['ExpressionFilter']['output'];
	id: Scalars['ID']['output'];
	name: Scalars['String']['output'];
	type: FilterType;
};

export const enum FilterType {
	ITEM = 'ITEM',
}

export type FiltersFilter = {
	fieldReferences?: InputMaybe<Array<Scalars['ID']['input']>>;
	filterIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	name?: InputMaybe<Scalars['String']['input']>;
	type?: InputMaybe<FilterType>;
};

export type Flag = {
	__typename?: 'Flag';
	color: FlagColor;
	description?: Maybe<Scalars['String']['output']>;
	id: Scalars['ID']['output'];
	makesItemsUnavailable: Scalars['Boolean']['output'];
	name: Scalars['String']['output'];
};

export const enum FlagColor {
	DARK_ORCHID = 'DARK_ORCHID',
	DARK_RED = 'DARK_RED',
	DEEP_SKY_BLUE = 'DEEP_SKY_BLUE',
	FOREST_GREEN = 'FOREST_GREEN',
	GAINSBORO = 'GAINSBORO',
	GOLD = 'GOLD',
	GREEN_YELLOW = 'GREEN_YELLOW',
	HOT_PINK = 'HOT_PINK',
	INDIGO = 'INDIGO',
	LIGHT_SALMON = 'LIGHT_SALMON',
	LIME_GREEN = 'LIME_GREEN',
	ORANGE = 'ORANGE',
	ORANGE_RED = 'ORANGE_RED',
	PERU = 'PERU',
	ROYAL_BLUE = 'ROYAL_BLUE',
	SLATE_GRAY = 'SLATE_GRAY',
	TEAL = 'TEAL',
}

export type FlagsFilter = {
	name?: InputMaybe<Scalars['String']['input']>;
};

export type Holiday = {
	__typename?: 'Holiday';
	dates: Interval;
	id: Scalars['ID']['output'];
	name: Scalars['String']['output'];
	recurrence: Recurrence;
};

export const enum ImageSize {
	EXTRASMALL = 'EXTRASMALL',
	LARGE = 'LARGE',
	MEDIUM = 'MEDIUM',
	SMALL = 'SMALL',
}

export type Interval = {
	__typename?: 'Interval';
	end: Scalars['String']['output'];
	start: Scalars['String']['output'];
};

export type IntervalInput = {
	end: Scalars['String']['input'];
	start: Scalars['String']['input'];
};

export type Item = {
	__typename?: 'Item';
	address?: Maybe<Scalars['String']['output']>;
	availability?: Maybe<Availability>;
	availableForKits: Scalars['Int']['output'];
	bookedQuantity?: Maybe<Scalars['Int']['output']>;
	brand?: Maybe<Scalars['String']['output']>;
	canBeCheckedOut: Scalars['Boolean']['output'];
	canBeInCustody: Scalars['Boolean']['output'];
	canBeReserved: Scalars['Boolean']['output'];
	canCheckout: ItemAvailabilityStatus;
	canCustody: ItemAvailabilityStatus;
	canReserve: ItemAvailabilityStatus;
	category?: Maybe<Category>;
	categoryId?: Maybe<Scalars['ID']['output']>;
	checkedOutQuantity?: Maybe<Scalars['Int']['output']>;
	checkinStatus?: Maybe<ItemCheckinStatus>;
	checkoutId?: Maybe<Scalars['ID']['output']>;
	codes: Array<Code>;
	custodyId?: Maybe<Scalars['ID']['output']>;
	fields?: Maybe<Array<Scalars['CustomField']['output']>>;
	flag?: Maybe<Flag>;
	flagId?: Maybe<Scalars['ID']['output']>;
	id: Scalars['ID']['output'];
	image?: Maybe<Scalars['String']['output']>;
	inCustodyOf?: Maybe<Contact>;
	inKits: Scalars['Int']['output'];
	isRestricted: Scalars['Boolean']['output'];
	kind?: Maybe<ItemKind>;
	kit?: Maybe<Kit>;
	kitId?: Maybe<Scalars['ID']['output']>;
	location?: Maybe<Location>;
	locationId?: Maybe<Scalars['ID']['output']>;
	model?: Maybe<Scalars['String']['output']>;
	name: Scalars['String']['output'];
	number?: Maybe<Scalars['String']['output']>;
	quantity: Scalars['Int']['output'];
	status: ItemStatus;
	totalCheckedOutQuantity?: Maybe<Scalars['Int']['output']>;
};

export type ItemAvailabilityArgs = {
	excludeCheckoutId?: InputMaybe<Scalars['ID']['input']>;
	excludeReservationId?: InputMaybe<Scalars['ID']['input']>;
	from?: InputMaybe<Scalars['String']['input']>;
	kinds?: InputMaybe<Array<AvailabilityStatus>>;
	to?: InputMaybe<Scalars['String']['input']>;
};

export type ItemBookedQuantityArgs = {
	checkoutId?: InputMaybe<Scalars['ID']['input']>;
	reservationId?: InputMaybe<Scalars['ID']['input']>;
};

export type ItemCheckedOutQuantityArgs = {
	checkoutId: Scalars['ID']['input'];
};

export type ItemCheckinStatusArgs = {
	checkoutId: Scalars['ID']['input'];
};

export type ItemImageArgs = {
	size?: InputMaybe<ImageSize>;
};

export const enum ItemAvailabilityStatus {
	AVAILABLE = 'AVAILABLE',
	UNAVAILABLE_ALLOW = 'UNAVAILABLE_ALLOW',
	UNAVAILABLE_FLAG = 'UNAVAILABLE_FLAG',
	UNAVAILABLE_OTHER = 'UNAVAILABLE_OTHER',
	UNAVAILABLE_STATUS = 'UNAVAILABLE_STATUS',
}

export const enum ItemCheckinStatus {
	ALL = 'ALL',
	NONE = 'NONE',
	PARTIAL = 'PARTIAL',
}

export const enum ItemKind {
	BULK = 'BULK',
	SIMPLE = 'SIMPLE',
}

export type ItemQuantity = {
	__typename?: 'ItemQuantity';
	itemId: Scalars['ID']['output'];
	quantity: Scalars['Int']['output'];
};

export type ItemSortOperator = {
	field: SortableItemField;
	order?: InputMaybe<SortOrder>;
};

export const enum ItemStatus {
	ASSIGNED = 'ASSIGNED',
	AVAILABLE = 'AVAILABLE',
	CHECKED_OUT = 'CHECKED_OUT',
	CHECKING_OUT = 'CHECKING_OUT',
	RETIRED = 'RETIRED',
	UNAVAILABLE = 'UNAVAILABLE',
}

export type ItemsAvailabilityFilter = {
	from: Scalars['String']['input'];
	to: Scalars['String']['input'];
};

export type ItemsFilter = {
	categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	codes?: InputMaybe<Array<Scalars['String']['input']>>;
	excludeItemsInCheckout?: InputMaybe<Scalars['Boolean']['input']>;
	excludeRestricted?: InputMaybe<Scalars['Boolean']['input']>;
	excludeRestrictedFor?: InputMaybe<BookingType>;
	excludeUnavailableBetween?: InputMaybe<ItemsAvailabilityFilter>;
	excludedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	inKit?: InputMaybe<Scalars['Boolean']['input']>;
	itemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	locationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	name?: InputMaybe<Scalars['String']['input']>;
	or?: InputMaybe<Array<ItemsFilter>>;
	retired?: InputMaybe<Scalars['Boolean']['input']>;
	term?: InputMaybe<Scalars['String']['input']>;
};

export type Kit = {
	__typename?: 'Kit';
	codes: Array<Code>;
	id: Scalars['ID']['output'];
	image?: Maybe<Scalars['String']['output']>;
	itemIds: Array<Scalars['ID']['output']>;
	items: Array<Item>;
	name: Scalars['String']['output'];
};

export type KitImageArgs = {
	size?: InputMaybe<ImageSize>;
};

export type KitItemsArgs = {
	contactId?: InputMaybe<Scalars['ID']['input']>;
};

export type KitsFilter = {
	canBeCheckedOut?: InputMaybe<Scalars['Boolean']['input']>;
	canBeInCustody?: InputMaybe<Scalars['Boolean']['input']>;
	canBeReserved?: InputMaybe<Scalars['Boolean']['input']>;
	codes?: InputMaybe<Array<Scalars['String']['input']>>;
	excludedKitIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	includeRetired?: InputMaybe<Scalars['Boolean']['input']>;
	kitIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	locationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	name?: InputMaybe<Scalars['String']['input']>;
	or?: InputMaybe<Array<KitsFilter>>;
	term?: InputMaybe<Scalars['String']['input']>;
};

export type Label = {
	__typename?: 'Label';
	color: LabelColor;
	id: Scalars['ID']['output'];
	name: Scalars['String']['output'];
	type: LabelType;
};

export const enum LabelColor {
	DARK_ORCHID = 'DARK_ORCHID',
	DARK_RED = 'DARK_RED',
	DEEP_SKY_BLUE = 'DEEP_SKY_BLUE',
	FOREST_GREEN = 'FOREST_GREEN',
	GAINSBORO = 'GAINSBORO',
	GOLD = 'GOLD',
	GREEN_YELLOW = 'GREEN_YELLOW',
	HOT_PINK = 'HOT_PINK',
	INDIGO = 'INDIGO',
	LIGHT_SALMON = 'LIGHT_SALMON',
	LIME_GREEN = 'LIME_GREEN',
	ORANGE = 'ORANGE',
	ORANGE_RED = 'ORANGE_RED',
	PERU = 'PERU',
	ROYAL_BLUE = 'ROYAL_BLUE',
	SLATE_GRAY = 'SLATE_GRAY',
	TEAL = 'TEAL',
}

export const enum LabelType {
	CHECKOUT = 'CHECKOUT',
	RESERVATION = 'RESERVATION',
}

export type Limits = {
	__typename?: 'Limits';
	maxItems: Scalars['Int']['output'];
	maxSMSPerMonth: Scalars['Int']['output'];
	maxWorkspaces: Scalars['Int']['output'];
};

export type Location = {
	__typename?: 'Location';
	address?: Maybe<Scalars['String']['output']>;
	id: Scalars['ID']['output'];
	isArchived?: Maybe<Scalars['Boolean']['output']>;
	name: Scalars['String']['output'];
	openingHours?: Maybe<Array<Period>>;
};

export type LocationsFilter = {
	archived?: InputMaybe<Scalars['Boolean']['input']>;
	excludedIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	name?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
	__typename?: 'Mutation';
	addBookableItemsToCheckout: Scalars['Boolean']['output'];
	addBookableItemsToReservation: Scalars['Boolean']['output'];
	addCategory: Scalars['ID']['output'];
	addContact: Scalars['ID']['output'];
	addContactGroup: Scalars['ID']['output'];
	addFilter: Scalars['ID']['output'];
	addHoliday: Scalars['ID']['output'];
	addItem: Scalars['Boolean']['output'];
	addItemsToCheckout: Scalars['Boolean']['output'];
	addItemsToReservation: Scalars['Boolean']['output'];
	addWorkspace: Scalars['ID']['output'];
	assignContactGroup: Scalars['Boolean']['output'];
	attachLogoToWorkspace: Scalars['Boolean']['output'];
	changeFilter: Scalars['Boolean']['output'];
	changeHoliday: Scalars['Boolean']['output'];
	changeLocationOpeningHours: Scalars['Boolean']['output'];
	changeUserRole: Scalars['Boolean']['output'];
	configureBookingSettings: Scalars['Boolean']['output'];
	configureContactGroup: Scalars['Boolean']['output'];
	createLocation: Scalars['ID']['output'];
	createReportByField: Scalars['ID']['output'];
	deleteCategory: Scalars['Boolean']['output'];
	deleteContactGroup: Scalars['Boolean']['output'];
	deleteFilter: Scalars['Boolean']['output'];
	deleteHoliday: Scalars['Boolean']['output'];
	deleteReport: Scalars['Boolean']['output'];
	deleteWorkspace: Scalars['Boolean']['output'];
	editWorkspace: Scalars['Boolean']['output'];
	favoriteReport: Scalars['Boolean']['output'];
	flagItem: Scalars['Boolean']['output'];
	inviteContact: Scalars['Boolean']['output'];
	joinBetaProgram: Scalars['Boolean']['output'];
	leaveBetaProgram: Scalars['Boolean']['output'];
	mergeCategory: Scalars['Boolean']['output'];
	reactivateContact: Scalars['Boolean']['output'];
	registerViewOnReport: Scalars['Boolean']['output'];
	removeBookableItemsFromCheckout: Scalars['Boolean']['output'];
	removeBookableItemsFromReservation: Scalars['Boolean']['output'];
	removeItemsFromCheckout: Scalars['Boolean']['output'];
	removeItemsFromReservation: Scalars['Boolean']['output'];
	removeLogoFromWorkspace: Scalars['Boolean']['output'];
	removeManager: Scalars['Boolean']['output'];
	resendInvite: Scalars['Boolean']['output'];
	revokeContactAppAccess: Scalars['Boolean']['output'];
	revokeInvite: Scalars['Boolean']['output'];
	setItemQuantityInCheckout: Scalars['Boolean']['output'];
	setItemQuantityInReservation: Scalars['Boolean']['output'];
	unassignContactGroup: Scalars['Boolean']['output'];
	unfavoriteReport: Scalars['Boolean']['output'];
	unflagItem: Scalars['Boolean']['output'];
	updateCategory: Scalars['Boolean']['output'];
	updateContact: Scalars['Boolean']['output'];
	updateItem: Scalars['Boolean']['output'];
	updateItemQuantity: Scalars['Boolean']['output'];
	updateLocation: Scalars['Boolean']['output'];
	updateOrganisation: Scalars['Boolean']['output'];
};

export type MutationAddBookableItemsToCheckoutArgs = {
	bookableItems: Array<BookableItem>;
	checkoutId: Scalars['ID']['input'];
};

export type MutationAddBookableItemsToReservationArgs = {
	bookableItems: Array<BookableItem>;
	reservationId: Scalars['ID']['input'];
};

export type MutationAddCategoryArgs = {
	id?: InputMaybe<Scalars['ID']['input']>;
	name: Scalars['String']['input'];
	parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationAddContactArgs = {
	contactGroupId?: InputMaybe<Scalars['ID']['input']>;
	email: Scalars['String']['input'];
	isMaintenance?: InputMaybe<Scalars['Boolean']['input']>;
	name: Scalars['String']['input'];
};

export type MutationAddContactGroupArgs = {
	allowedFilterIds: Array<Scalars['ID']['input']>;
	description?: InputMaybe<Scalars['String']['input']>;
	id?: InputMaybe<Scalars['ID']['input']>;
	name: Scalars['String']['input'];
};

export type MutationAddFilterArgs = {
	expression: Scalars['ExpressionFilter']['input'];
	id?: InputMaybe<Scalars['ID']['input']>;
	name: Scalars['String']['input'];
	type: FilterType;
};

export type MutationAddHolidayArgs = {
	dates: IntervalInput;
	id?: InputMaybe<Scalars['ID']['input']>;
	name: Scalars['String']['input'];
	recurrence: Recurrence;
};

export type MutationAddItemArgs = {
	brand: Scalars['String']['input'];
	categoryId: Scalars['ID']['input'];
	kind?: InputMaybe<ItemKind>;
	locationId: Scalars['ID']['input'];
	model: Scalars['String']['input'];
	name: Scalars['String']['input'];
	quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationAddItemsToCheckoutArgs = {
	checkoutId: Scalars['ID']['input'];
	itemIds: Array<Scalars['ID']['input']>;
};

export type MutationAddItemsToReservationArgs = {
	itemIds: Array<Scalars['ID']['input']>;
	reservationId: Scalars['ID']['input'];
};

export type MutationAddWorkspaceArgs = {
	id?: InputMaybe<Scalars['ID']['input']>;
	limits: WorkspaceLimitsInput;
	name: Scalars['String']['input'];
};

export type MutationAssignContactGroupArgs = {
	contactGroupId: Scalars['ID']['input'];
	ids: Array<Scalars['ID']['input']>;
};

export type MutationAttachLogoToWorkspaceArgs = {
	id: Scalars['ID']['input'];
	publicId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationChangeFilterArgs = {
	expression?: InputMaybe<Scalars['ExpressionFilter']['input']>;
	id: Scalars['ID']['input'];
	name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationChangeHolidayArgs = {
	dates?: InputMaybe<IntervalInput>;
	id: Scalars['ID']['input'];
	name?: InputMaybe<Scalars['String']['input']>;
	recurrence?: InputMaybe<Recurrence>;
};

export type MutationChangeLocationOpeningHoursArgs = {
	id: Scalars['ID']['input'];
	openingHours?: InputMaybe<Array<PeriodInput>>;
};

export type MutationChangeUserRoleArgs = {
	ids: Array<Scalars['ID']['input']>;
	roleId: Scalars['ID']['input'];
};

export type MutationConfigureBookingSettingsArgs = {
	bookingLimit?: InputMaybe<Scalars['Int']['input']>;
	maximumBookingDuration?: InputMaybe<DurationSettingInput>;
	maximumInAdvance?: InputMaybe<DurationSettingInput>;
	minimumBookingDuration?: InputMaybe<DurationSettingInput>;
	minimumInAdvance?: InputMaybe<DurationSettingInput>;
	timeOptionInterval?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationConfigureContactGroupArgs = {
	allowedFilterIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	description?: InputMaybe<Scalars['String']['input']>;
	id: Scalars['ID']['input'];
	name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateLocationArgs = {
	address?: InputMaybe<Scalars['String']['input']>;
	id?: InputMaybe<Scalars['ID']['input']>;
	name: Scalars['String']['input'];
};

export type MutationCreateReportByFieldArgs = {
	category: ReportCategory;
	description?: InputMaybe<Scalars['String']['input']>;
	field: ReportSettingsField;
	id?: InputMaybe<Scalars['ID']['input']>;
	isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
	name: Scalars['String']['input'];
};

export type MutationDeleteCategoryArgs = {
	id: Scalars['ID']['input'];
};

export type MutationDeleteContactGroupArgs = {
	id: Scalars['ID']['input'];
};

export type MutationDeleteFilterArgs = {
	id: Scalars['ID']['input'];
};

export type MutationDeleteHolidayArgs = {
	id: Scalars['ID']['input'];
};

export type MutationDeleteReportArgs = {
	id: Scalars['ID']['input'];
};

export type MutationDeleteWorkspaceArgs = {
	id: Scalars['ID']['input'];
};

export type MutationEditWorkspaceArgs = {
	id: Scalars['ID']['input'];
	limits?: InputMaybe<WorkspaceLimitsInput>;
	name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationFavoriteReportArgs = {
	id: Scalars['ID']['input'];
};

export type MutationFlagItemArgs = {
	flagId: Scalars['ID']['input'];
	itemId: Scalars['ID']['input'];
	message: Scalars['String']['input'];
};

export type MutationInviteContactArgs = {
	ids: Array<Scalars['ID']['input']>;
	roleId: Scalars['ID']['input'];
};

export type MutationMergeCategoryArgs = {
	fromId: Scalars['ID']['input'];
	toId: Scalars['ID']['input'];
};

export type MutationReactivateContactArgs = {
	ids: Array<Scalars['ID']['input']>;
};

export type MutationRegisterViewOnReportArgs = {
	id: Scalars['ID']['input'];
};

export type MutationRemoveBookableItemsFromCheckoutArgs = {
	bookableItems: Array<BookableItem>;
	checkoutId: Scalars['ID']['input'];
};

export type MutationRemoveBookableItemsFromReservationArgs = {
	bookableItems: Array<BookableItem>;
	reservationId: Scalars['ID']['input'];
};

export type MutationRemoveItemsFromCheckoutArgs = {
	checkoutId: Scalars['ID']['input'];
	itemIds: Array<Scalars['ID']['input']>;
};

export type MutationRemoveItemsFromReservationArgs = {
	itemIds: Array<Scalars['ID']['input']>;
	reservationId: Scalars['ID']['input'];
};

export type MutationRemoveLogoFromWorkspaceArgs = {
	id: Scalars['ID']['input'];
};

export type MutationRemoveManagerArgs = {
	identityId: Scalars['ID']['input'];
};

export type MutationResendInviteArgs = {
	id: Scalars['ID']['input'];
};

export type MutationRevokeContactAppAccessArgs = {
	ids: Array<Scalars['ID']['input']>;
};

export type MutationRevokeInviteArgs = {
	ids: Array<Scalars['ID']['input']>;
};

export type MutationSetItemQuantityInCheckoutArgs = {
	checkoutId: Scalars['ID']['input'];
	itemId: Scalars['ID']['input'];
	quantity: Scalars['Int']['input'];
};

export type MutationSetItemQuantityInReservationArgs = {
	itemId: Scalars['ID']['input'];
	quantity: Scalars['Int']['input'];
	reservationId: Scalars['ID']['input'];
};

export type MutationUnassignContactGroupArgs = {
	ids: Array<Scalars['ID']['input']>;
};

export type MutationUnfavoriteReportArgs = {
	id: Scalars['ID']['input'];
};

export type MutationUnflagItemArgs = {
	itemId: Scalars['ID']['input'];
	message: Scalars['String']['input'];
};

export type MutationUpdateCategoryArgs = {
	id: Scalars['ID']['input'];
	name?: InputMaybe<Scalars['String']['input']>;
	parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateContactArgs = {
	contactGroupId?: InputMaybe<Scalars['ID']['input']>;
	email?: InputMaybe<Scalars['String']['input']>;
	id: Scalars['ID']['input'];
	name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateItemArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	id: Scalars['ID']['input'];
	model?: InputMaybe<Scalars['String']['input']>;
	name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateItemQuantityArgs = {
	id: Scalars['ID']['input'];
	ignoreBookedValidation?: InputMaybe<Scalars['Boolean']['input']>;
	quantity: Scalars['Int']['input'];
};

export type MutationUpdateLocationArgs = {
	address?: InputMaybe<Scalars['String']['input']>;
	id: Scalars['ID']['input'];
	name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateOrganisationArgs = {
	name?: InputMaybe<Scalars['String']['input']>;
};

export type Organisation = {
	__typename?: 'Organisation';
	id: Scalars['ID']['output'];
	limits: Limits;
	name: Scalars['String']['output'];
	owners: Array<Owner>;
	subscription: CurrentSubscription;
	workspaces: Array<Workspace>;
};

export type Owner = {
	__typename?: 'Owner';
	email: Scalars['String']['output'];
	identityId: Scalars['ID']['output'];
	name: Scalars['String']['output'];
};

export type Page = {
	number?: InputMaybe<Scalars['Int']['input']>;
	size?: InputMaybe<Scalars['Int']['input']>;
};

export type Period = {
	__typename?: 'Period';
	dayOfWeek: DayOfWeek;
	from: Scalars['String']['output'];
	to: Scalars['String']['output'];
};

export type PeriodInput = {
	dayOfWeek: DayOfWeek;
	from: Scalars['String']['input'];
	to: Scalars['String']['input'];
};

export type Price = {
	__typename?: 'Price';
	amount: Scalars['Float']['output'];
	currency: Scalars['String']['output'];
};

export type ProposedSubscriptionPlan = {
	__typename?: 'ProposedSubscriptionPlan';
	duration: Duration;
	id: Scalars['ID']['output'];
	limits: SubscriptionPlanLimits;
	name: Scalars['String']['output'];
	price: Price;
};

export type Query = {
	__typename?: 'Query';
	bookingCalendar: BookingCalendar;
	bookingSettings?: Maybe<BookingSettings>;
	bookingTimeOptions: Array<BookingTimeOption>;
	categories: Array<Category>;
	category?: Maybe<Category>;
	checkout?: Maybe<Checkout>;
	checkouts: Array<Checkout>;
	contact?: Maybe<Contact>;
	contactGroup?: Maybe<ContactGroup>;
	contactGroups: Array<ContactGroup>;
	contacts: Array<Contact>;
	customItemFieldDefinitions: Array<CustomItemFieldDefinition>;
	dailyRate: Array<DailyRate>;
	equipment: Array<Equipment>;
	filter?: Maybe<Filter>;
	filters: Array<Filter>;
	flags: Array<Flag>;
	holiday?: Maybe<Holiday>;
	holidays: Array<Holiday>;
	isContactGroupNameUnique: Scalars['Boolean']['output'];
	isLocationNameUnique: Scalars['Boolean']['output'];
	item?: Maybe<Item>;
	items: Array<Item>;
	kit?: Maybe<Kit>;
	kits: Array<Kit>;
	location?: Maybe<Location>;
	locations: Array<Location>;
	logoUploadUrl?: Maybe<UploadSignature>;
	me?: Maybe<User>;
	organisation: Organisation;
	report?: Maybe<Report>;
	reports: Array<Report>;
	reservation?: Maybe<Reservation>;
	reservations: Array<Reservation>;
	roles: Array<Role>;
	userInvites: Array<UserInvite>;
	workspace?: Maybe<Workspace>;
};

export type QueryBookingCalendarArgs = {
	bookingType: BookingType;
	filter: BookingCalendarFilter;
};

export type QueryBookingTimeOptionsArgs = {
	bookingType: BookingType;
	filter: BookingTimeOptionsFilter;
	fromDate?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCategoriesArgs = {
	filter?: InputMaybe<CategoryFilter>;
	page?: InputMaybe<Page>;
	sort?: InputMaybe<Array<CategorySortOperator>>;
};

export type QueryCategoryArgs = {
	id: Scalars['ID']['input'];
};

export type QueryCheckoutArgs = {
	id: Scalars['ID']['input'];
};

export type QueryCheckoutsArgs = {
	filter?: InputMaybe<CheckoutsFilter>;
	page?: InputMaybe<Page>;
	sort?: InputMaybe<CheckoutSortOperator>;
};

export type QueryContactArgs = {
	id: Scalars['ID']['input'];
};

export type QueryContactGroupArgs = {
	id: Scalars['ID']['input'];
};

export type QueryContactGroupsArgs = {
	filter?: InputMaybe<ContactGroupsFilter>;
	page?: InputMaybe<Page>;
};

export type QueryContactsArgs = {
	filter?: InputMaybe<ContactsFilter>;
	page?: InputMaybe<Page>;
};

export type QueryDailyRateArgs = {
	bookingType: BookingType;
	end: Scalars['String']['input'];
	itemId: Scalars['ID']['input'];
	start: Scalars['String']['input'];
};

export type QueryEquipmentArgs = {
	contactId?: InputMaybe<Scalars['ID']['input']>;
	filter?: InputMaybe<EquipmentFilter>;
	page?: InputMaybe<Page>;
};

export type QueryFilterArgs = {
	id: Scalars['ID']['input'];
};

export type QueryFiltersArgs = {
	filter?: InputMaybe<FiltersFilter>;
	page?: InputMaybe<Page>;
};

export type QueryFlagsArgs = {
	filter?: InputMaybe<FlagsFilter>;
	page?: InputMaybe<Page>;
};

export type QueryHolidayArgs = {
	id: Scalars['ID']['input'];
};

export type QueryHolidaysArgs = {
	page?: InputMaybe<Page>;
};

export type QueryIsContactGroupNameUniqueArgs = {
	excludedIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	name: Scalars['String']['input'];
};

export type QueryIsLocationNameUniqueArgs = {
	excludedIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	name: Scalars['String']['input'];
};

export type QueryItemArgs = {
	contactId?: InputMaybe<Scalars['ID']['input']>;
	id: Scalars['ID']['input'];
};

export type QueryItemsArgs = {
	advancedItemFilter?: InputMaybe<Scalars['ExpressionFilter']['input']>;
	contactId?: InputMaybe<Scalars['ID']['input']>;
	filter?: InputMaybe<ItemsFilter>;
	page?: InputMaybe<Page>;
	sort?: InputMaybe<ItemSortOperator>;
};

export type QueryKitArgs = {
	id: Scalars['ID']['input'];
};

export type QueryKitsArgs = {
	filter?: InputMaybe<KitsFilter>;
	page?: InputMaybe<Page>;
};

export type QueryLocationArgs = {
	id: Scalars['ID']['input'];
};

export type QueryLocationsArgs = {
	filter?: InputMaybe<LocationsFilter>;
	page?: InputMaybe<Page>;
};

export type QueryLogoUploadUrlArgs = {
	id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryReportArgs = {
	id: Scalars['ID']['input'];
};

export type QueryReportsArgs = {
	filter?: InputMaybe<ReportFilter>;
	page?: InputMaybe<Page>;
	sort?: InputMaybe<Array<SortOperator>>;
	term?: InputMaybe<Scalars['String']['input']>;
};

export type QueryReservationArgs = {
	id: Scalars['ID']['input'];
};

export type QueryReservationsArgs = {
	filter?: InputMaybe<ReservationsFilter>;
	page?: InputMaybe<Page>;
	sort?: InputMaybe<ReservationSortOperator>;
};

export type QueryRolesArgs = {
	filter?: InputMaybe<RoleFilter>;
	page?: InputMaybe<Page>;
};

export type QueryUserInvitesArgs = {
	filter?: InputMaybe<UserInvitesFilter>;
	page?: InputMaybe<Page>;
};

export type QueryWorkspaceArgs = {
	id?: InputMaybe<Scalars['ID']['input']>;
};

export const enum Recurrence {
	NEVER = 'NEVER',
	YEARLY = 'YEARLY',
}

export const enum RepeatFrequency {
	EVERY_2_MONTHS = 'EVERY_2_MONTHS',
	EVERY_2_WEEKS = 'EVERY_2_WEEKS',
	EVERY_3_MONTHS = 'EVERY_3_MONTHS',
	EVERY_6_MONTHS = 'EVERY_6_MONTHS',
	EVERY_DAY = 'EVERY_DAY',
	EVERY_MONTH = 'EVERY_MONTH',
	EVERY_WEEK = 'EVERY_WEEK',
	EVERY_WEEKDAY = 'EVERY_WEEKDAY',
	EVERY_YEAR = 'EVERY_YEAR',
}

export type Report = {
	__typename?: 'Report';
	category: ReportCategory;
	createdBy?: Maybe<User>;
	description?: Maybe<Scalars['String']['output']>;
	favoritedBy: Array<User>;
	id: Scalars['ID']['output'];
	lastViewedOn?: Maybe<Scalars['String']['output']>;
	name: Scalars['String']['output'];
	settings?: Maybe<ReportSettings>;
	type: Scalars['String']['output'];
	views: Scalars['Int']['output'];
};

export const enum ReportCategory {
	CHECK_OUTS = 'CHECK_OUTS',
	ITEMS = 'ITEMS',
	KITS = 'KITS',
	RESERVATIONS = 'RESERVATIONS',
}

export type ReportField = {
	__typename?: 'ReportField';
	collection: ReportCategory;
	value: Scalars['String']['output'];
};

export type ReportFilter = {
	category?: InputMaybe<Scalars['StringFilter']['input']>;
	field?: InputMaybe<Scalars['StringFilter']['input']>;
	type?: InputMaybe<Scalars['StringFilter']['input']>;
};

export type ReportSettings = {
	__typename?: 'ReportSettings';
	field: ReportField;
};

export type ReportSettingsField = {
	collection?: InputMaybe<ReportCategory>;
	value: Scalars['String']['input'];
};

export type Reservation = {
	__typename?: 'Reservation';
	archivedOn?: Maybe<Scalars['String']['output']>;
	bookedQuantities: Array<BookedQuantity>;
	checkoutId?: Maybe<Scalars['ID']['output']>;
	contact?: Maybe<Contact>;
	contactId?: Maybe<Scalars['ID']['output']>;
	endsOn?: Maybe<Scalars['String']['output']>;
	id: Scalars['ID']['output'];
	itemIds: Array<Scalars['ID']['output']>;
	items: Array<Item>;
	label?: Maybe<Label>;
	labelId?: Maybe<Scalars['ID']['output']>;
	location?: Maybe<Location>;
	locationId?: Maybe<Scalars['ID']['output']>;
	name: Scalars['String']['output'];
	number?: Maybe<Scalars['String']['output']>;
	repeatFrequency?: Maybe<RepeatFrequency>;
	repeatId?: Maybe<Scalars['ID']['output']>;
	startsOn?: Maybe<Scalars['String']['output']>;
	status?: Maybe<ReservationStatus>;
};

export type ReservationItemsArgs = {
	contactId?: InputMaybe<Scalars['ID']['input']>;
	page?: InputMaybe<Page>;
	sort?: InputMaybe<ItemSortOperator>;
};

export type ReservationSortOperator = {
	field: SortableReservationField;
	order?: InputMaybe<SortOrder>;
};

export const enum ReservationStatus {
	CANCELLED = 'CANCELLED',
	CLOSED = 'CLOSED',
	CONVERTED = 'CONVERTED',
	DRAFT = 'DRAFT',
	OPEN = 'OPEN',
}

export type ReservationsFilter = {
	isArchived?: InputMaybe<Scalars['Boolean']['input']>;
	isOverdue?: InputMaybe<Scalars['Boolean']['input']>;
	item?: InputMaybe<Scalars['ID']['input']>;
	name?: InputMaybe<Scalars['String']['input']>;
	status?: InputMaybe<Array<ReservationStatus>>;
};

export type Role = {
	__typename?: 'Role';
	id: Scalars['ID']['output'];
	name: Scalars['String']['output'];
};

export type RoleFilter = {
	roleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SortOperator = {
	field: SortableReportField;
	order?: InputMaybe<SortOrder>;
};

export const enum SortOrder {
	asc = 'asc',
	desc = 'desc',
}

export const enum SortableCategoryField {
	name = 'name',
}

export const enum SortableCheckoutField {
	CONTACT = 'CONTACT',
	DURATION = 'DURATION',
	ENDS_ON = 'ENDS_ON',
	NAME = 'NAME',
	STARTS_ON = 'STARTS_ON',
}

export const enum SortableItemField {
	kitId = 'kitId',
	name = 'name',
}

export const enum SortableReportField {
	category = 'category',
	favorited = 'favorited',
	lastViewedOn = 'lastViewedOn',
	name = 'name',
	views = 'views',
}

export const enum SortableReservationField {
	CONTACT = 'CONTACT',
	DURATION = 'DURATION',
	ENDS_ON = 'ENDS_ON',
	NAME = 'NAME',
	STARTS_ON = 'STARTS_ON',
}

export const enum SubscriptionKind {
	PAYING = 'PAYING',
	TRIAL = 'TRIAL',
}

export type SubscriptionPlan = {
	__typename?: 'SubscriptionPlan';
	duration: Duration;
	id: Scalars['ID']['output'];
	name: Scalars['String']['output'];
	retired: Scalars['Boolean']['output'];
};

export type SubscriptionPlanLimits = {
	__typename?: 'SubscriptionPlanLimits';
	items: Scalars['Int']['output'];
};

export type UploadSignature = {
	__typename?: 'UploadSignature';
	apiKey: Scalars['String']['output'];
	publicId: Scalars['ID']['output'];
	signature: Scalars['String']['output'];
	timestamp: Scalars['Int']['output'];
	url: Scalars['String']['output'];
};

export type User = {
	__typename?: 'User';
	active: Scalars['Boolean']['output'];
	avatar?: Maybe<Scalars['String']['output']>;
	email?: Maybe<Scalars['String']['output']>;
	id: Scalars['ID']['output'];
	isSynced: Scalars['Boolean']['output'];
	lastLogin?: Maybe<Scalars['String']['output']>;
	name?: Maybe<Scalars['String']['output']>;
	role: Role;
	roleId: Scalars['String']['output'];
	status: UserStatus;
	workspaces: Array<Workspace>;
};

export type UserAvatarArgs = {
	size?: InputMaybe<ImageSize>;
};

export type UserInvite = {
	__typename?: 'UserInvite';
	createdOn: Scalars['String']['output'];
	email: Scalars['String']['output'];
	id: Scalars['ID']['output'];
	role: Role;
	roleId: Scalars['ID']['output'];
	validTo: Scalars['String']['output'];
};

export type UserInvitesFilter = {
	email?: InputMaybe<Scalars['String']['input']>;
};

export const enum UserStatus {
	ACTIVE = 'ACTIVE',
	DELETED = 'DELETED',
	INACTIVE = 'INACTIVE',
}

export type Workspace = {
	__typename?: 'Workspace';
	id: Scalars['ID']['output'];
	isInBetaProgram: Scalars['Boolean']['output'];
	limits: WorkspaceLimits;
	logo?: Maybe<Scalars['String']['output']>;
	name: Scalars['String']['output'];
	settings: WorkspaceSettings;
	usage: WorkspaceUsage;
};

export type WorkspaceLimits = {
	__typename?: 'WorkspaceLimits';
	maxBulkItems: Scalars['Int']['output'];
	maxItems: Scalars['Int']['output'];
	maxLocations: Scalars['Int']['output'];
};

export type WorkspaceLimitsInput = {
	maxItems: Scalars['Int']['input'];
};

export type WorkspaceSettings = {
	__typename?: 'WorkspaceSettings';
	ssoRequired: Scalars['Boolean']['output'];
	workspaceId: Scalars['ID']['output'];
};

export type WorkspaceUsage = {
	__typename?: 'WorkspaceUsage';
	bulkItems: Scalars['Int']['output'];
	checkouts: Scalars['Int']['output'];
	contacts: Scalars['Int']['output'];
	items: Scalars['Int']['output'];
	kits: Scalars['Int']['output'];
	locations: Scalars['Int']['output'];
	reports: Scalars['Int']['output'];
	reservations: Scalars['Int']['output'];
	sms: Scalars['Int']['output'];
	spotchecks: Scalars['Int']['output'];
	users: Scalars['Int']['output'];
	workspaceId: Scalars['ID']['output'];
};

import { FC, ReactNode } from 'react';

import { StyledFooter } from './Footer.styles';

type Props = {
	children: ReactNode;
	sticky?: boolean;
};

export const Footer: FC<Props> = ({ children, sticky = false, ...props }) => (
	<StyledFooter sticky={sticky} {...props}>
		{children}
	</StyledFooter>
);

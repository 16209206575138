import debounce from 'viewmodels/debounce';
import system from 'durandal/system.js';
import ko from 'knockout';
import ConfirmModal from 'viewmodels/confirm-modal.js';

export default {
	mixin: function (kit, options) {
		var orig_fromJson = kit._fromJson,
			orig_reset = kit.reset,
			orig_getAttachment = kit._getAttachment,
			orig_addItems = kit.addItems,
			orig_removeItems = kit.removeItems,
			orig_delete = kit.delete,
			orig_create = kit.create,
			orig_setField = kit.setField,
			orig_updateBasicFields = kit.updateBasicFields,
			orig_clearField = kit.clearField,
			orig_isEmpty = kit.isEmpty;

		var global = options.global;

		// Core method overrides
		// ----
		kit._fromJson = function (data, opt) {
			//Use apply to call function so we can pass the
			//Kit context otherwise it would use Window context
			return orig_fromJson.apply(kit, [data, opt]).then(function () {
				kit.oId(kit.id);
				kit.oName(kit.name);
				kit.oDescription(kit.description);
				kit.oItemSummary(kit.itemSummary);
				kit.oData().name(kit.name);
				kit.oCodes(kit.codes);
				kit.oItems(kit.items);
				kit.oStatus(kit.status);
				kit.oAllowReserve(kit.allowReserve);
				kit.oAllowCheckout(kit.allowCheckout);
				kit.oAllowCustody(kit.allowCustody);

				kit._getConflicts();

				kit._triggerAction(':load');

				kit._updateComputables(new Date().getTime());

				return data;
			});
		};

		kit.reset = async function () {
			//Use apply to call function so we can pass the
			//KeyValue context otherwise it would use Window context
			await orig_reset.apply(kit);

			//Reset observables to defaults
			kit.oId(kit.id);
			kit.oName(kit.name);
			kit.oCodes(kit.codes);
			kit.oItems(kit.items);
			kit.oStatus(kit.status);
			kit.oItemSummary(kit.itemSummary);
			kit.oDescription(kit.description);

			var data = kit.oData();
			data.name(kit.name);
			data.name.isModified(false);

			kit._triggerAction(':reset');
		};

		kit.create = function (skipRead = true) {
			return orig_create.apply(kit, [skipRead]).then(function (resp) {
				kit._triggerAction(':created');

				return resp;
			});
		};

		kit.delete = function () {
			var kitId = kit.oId();

			kit._triggerAction(':deleting');

			return orig_delete.apply(kit).then(function () {
				kit._triggerAction(':deleted', kitId);
			});
		};

		kit.addItems = function (items) {
			var itemIds = global.common.getItemIds(items);

			return orig_addItems.apply(kit, [itemIds]).then(function () {
				kit._getConflicts();

				kit._triggerAction(':changed');
			});
		};

		kit.removeItems = function (items) {
			return new Promise((resolve) => {
				ConfirmModal.showMessage({
					title: 'Remove from kit?',
					msg:
						items.length == 1
							? 'Are you sure you want to remove this item?'
							: 'Are you sure you want to remove the items in your selection?',
					submsg: false,
				}).then(function (resp) {
					if (resp) {
						var itemIds = global.common.getItemIds(items);

						orig_removeItems.apply(kit, [itemIds]).then(function () {
							kit._getConflicts();

							kit._triggerAction(':changed');

							resolve();
						});
					}
				});
			});
		};

		kit.changeLocation = function (location) {
			var calls = [],
				items = kit.oItems();

			$.each(items, function (i, item) {
				calls.push(
					global
						.getDataSource('items')
						.call(item._id, 'changeLocation', { location: location }, '_id', null, true)
				);
			});

			return Promise.all(calls).then(function () {
				return kit.get();
			});
		};

		kit._getConflicts = function () {
			var conflicts = [];
			var items = kit.oItems();
			var status = kit.oStatus();

			$.each(items, function (i, item) {
				switch (item.status) {
					case 'await_checkout':
						if (status != 'await_checkout') {
							conflicts.push({
								kind: 'order',
								item: item._id,
								itemName: item.name,
								friendlyKind: 'Checking out',
								order: item.order,
							});
						}
						break;
					case 'checkedout':
						if (status != 'checkedout') {
							conflicts.push({
								kind: 'order',
								item: item._id,
								itemName: item.name,
								friendlyKind: 'Checked out',
								order: item.order,
							});
						}
						break;
					case 'expired':
						if (status != 'expired') {
							conflicts.push({
								kind: 'expired',
								item: item._id,
								itemName: item.name,
								friendlyKind: 'Item is retired',
							});
						}
						break;
					case 'in_custody':
						if (status != 'in_custody') {
							conflicts.push({
								kind: 'custody',
								item: item._id,
								itemName: item.name,
								friendlyKind: 'Item is in custody',
							});
						}
				}
			});

			kit.oConflicts(conflicts);
		};

		kit.discardChanges = function () {
			return this._fromJson(kit.raw);
		};

		kit._getAttachment = function (att, opt) {
			return orig_getAttachment(
				att,
				$.extend(opt, {
					canBeCover: true,
					isCover: kit && kit.raw ? kit.raw.cover == att.value : false,
				})
			);
		};

		kit.setField = function (field, value) {
			return orig_setField(field, value).then(function () {
				kit._triggerAction(':changed');
			});
		};

		kit.updateBasicFields = function (name, description, skipUpdateTrigger = false) {
			const props = [name, description];
			return orig_updateBasicFields.apply(kit, props).then(function () {
				if (skipUpdateTrigger || options.skipUpdateTrigger) return;

				app.trigger('kits:changed', [kit.raw]);
			});
		};

		kit.updateName = function (name) {
			kit.oName(name);

			// Here, we won't skip the update trigger
			// because the Knockout page has a title that is derived from this field
			return kit.updateBasicFields(name, kit.description, false);
		};

		kit.updateDescription = function (description) {
			kit.oDescription(description);

			// Here, we won't skip the update trigger
			// because the Knockout page has a title that is derived from this field
			return kit.updateBasicFields(kit.name, description, false);
		};

		kit.clearField = function (field) {
			return orig_clearField(field).then(function () {
				kit._triggerAction(':changed');
			});
		};

		kit.getItemIds = function () {
			return global.common.getItemIds(this.oItems());
		};

		kit.onUpdateDescription = function (e, newValue) {
			kit.oDescription($.trim(newValue));

			kit.update().then(function () {
				// Bugfix always show item summary again if description is cleared
				var summary = kit.oItemSummary();
				kit.oItemSummary('');
				kit.oItemSummary(summary);

				kit._triggerAction(':changed');
				app.trigger('notification', 'Updated kit description');
			});
		};

		/**
		 * Trigger kit:changed with kit object
		 */
		kit._triggerAction = function (action, data) {
			var trigger = 'kits' + action;

			system.log(trigger, data != undefined ? data : kit);
			app.trigger(trigger, data != undefined ? data : kit);
		};

		// Knockout validation rules
		// ----

		//BUGFIX validation rule needs to be unique, because we use kit object inside it for
		//validation checks, gives strange behavior in the web app otherwise
		var kitExistsValidationId = 'kitExists' + new Date().getTime();
		var nameValidationParams = {
			required: true,
			minLength: 3,
		};
		nameValidationParams[kitExistsValidationId] = true;

		let abortController;
		ko.validation.rules[kitExistsValidationId] = {
			async: true,
			message: 'Kit name is already taken',
			validator: debounce(function (val, params, callback) {
				// When existing kit is edited, we don't want
				// to check its current name
				if (kit.oId() != null && kit.raw != null && kit.oData().name() == kit.raw.name) {
					callback(true);
				} else {
					if (kit.ds) {
						if (abortController) {
							abortController.abort();
						}

						abortController = new AbortController();

						kit.ds
							.search({ name: val, pk__ne: kit.oId() }, '_id', null, null, null, null, {
								abortController: abortController,
							})
							.then(function (resp) {
								callback(resp.count == 0);
							})
							.catch(function () {
								callback({ isValid: false, message: 'Something went wrong' });
							});
					}
				}
			}, 250),
		};
		ko.validation.registerExtenders();

		// Observables
		// ----
		kit.oId = ko.observable(kit.id || '');
		kit.oName = ko.observable(kit.name || '');
		kit.oCodes = ko.observable(kit.codes || []);
		kit.oItems = ko.observable(kit.items || []);
		kit.oStatus = ko.observable(kit.status || 'unknown');
		kit.oData = ko.validatedObservable({
			name: kit.oName.trimmed().extend(nameValidationParams),
		});
		kit.oCustody = ko.computed(function () {
			var items = kit.oItems() || [];
			var status = kit.oStatus();

			if (status != 'in_custody' || items.length == 0) return {};

			return items[0].custody;
		});
		kit.oItemSummary = ko.observable(kit.itemSummary || '');
		kit.oDescription = ko.observable(kit.description || '');
		kit.oDescriptionSummary = ko.pureComputed(function () {
			var description = kit.oDescription(),
				summary = kit.oItemSummary();
			return description || summary;
		});

		kit.oAllowReserve = ko.observable(kit.allowReserve != null ? kit.allowReserve : true);
		kit.oAllowCheckout = ko.observable(kit.allowCheckout != null ? kit.allowCheckout : true);
		kit.oAllowCustody = ko.observable(kit.allowCustody != null ? kit.allowCustody : true);

		kit._updateComputables = ko.observable(0);

		// Computables
		// ----
		// Make sure the cr.Kit fields are updated when our computeds are updated as well
		// This enables the default: isEmpty, isDirty, _toJson to work automatically
		kit._updateId = ko.computed(function () {
			kit.id = kit.oId();
		});
		kit._updateName = ko.computed(function () {
			kit.name = kit.oName();
		});
		kit._updateDescription = ko.computed(function () {
			kit.description = kit.oDescription();
		});
		kit._updateItems = ko.computed(function () {
			kit.items = kit.oItems();
		});
		kit._updateCodes = ko.computed(function () {
			kit.codes = kit.oCodes();
		});

		kit._updateAllowReserve = ko.computed(function () {
			var allowReserve = kit.oAllowReserve();
			kit.allowReserve = allowReserve;
		});
		kit._updateAllowCheckout = ko.computed(function () {
			var allowCheckout = kit.oAllowCheckout();
			kit.allowCheckout = allowCheckout;
		});
		kit._updateAllowCustody = ko.computed(function () {
			var allowCustody = kit.oAllowCustody();
			kit.allowCustody = allowCustody;
		});

		kit.canSave = ko.computed(function () {
			var name = kit.oName();

			return !kit.oData().name.isValidating() && kit.oData.isValid() && kit.isDirty();
		});

		kit.isDirty = ko.computed(function () {
			var name = kit.oData().name() != kit.oName() ? kit.oData().name : kit.oName();

			if (kit.raw) {
				return kit.raw.name != name;
			} else {
				return name.length > 0 || kit.oItems().length > 0;
			}
		});

		kit.isEmpty = ko
			.computed(function () {
				return orig_isEmpty.apply(kit);
			})
			.extend({ throttle: 50 });

		kit.inOrder = ko.pureComputed(function () {
			var items = kit.oItems();
			var kitStatus = kit.oStatus();
			return kitStatus == 'await_checkout' || kitStatus == 'checkedout';
		});

		kit.itemsInKitText = ko.computed(function () {
			var items = kit.oItems();
			return items.length + ' item'.pluralize(items.length);
		});

		kit.availableItems = ko.pureComputed(function () {
			var items = kit.oItems();
			return global.common.getAvailableItems(items);
		});

		kit.activeItems = ko.pureComputed(function () {
			var items = kit.oItems();
			return global.common.getActiveItems(items);
		});

		kit.hasUnavailableItems = ko.pureComputed(function () {
			return kit.availableItems().length != kit.oItems().length;
		});

		kit.hasInactiveItems = ko.pureComputed(function () {
			return kit.activeItems().length != kit.oItems().length;
		});

		kit.oLocation = ko.computed(function () {
			var items = kit.oItems() || [];
			var locationDictionary = {};
			$.each(items, function (i, item) {
				locationDictionary[item.location] = true;
			});
			var locations = Object.keys(locationDictionary) || [];

			return locations.length == 1 && locations[0] != 'undefined' ? locations[0] : '';
		});

		kit.oLocationName = ko.computed(function () {
			var location = kit.oLocation();

			if (!location) return '';

			return global.central._getLocationById(location).name;
		});

		// Override default conflicts message
		kit.oConflictsMessage = ko.observable('Incomplete kit');

		kit.hasGeo = ko.computed(function () {
			var items = kit.oItems();

			return (
				items.find(function (it) {
					var geo = it.geo || [];
					return geo.length == 2 && geo[0] != 0 && geo[1] != 0;
				}) != null
			);
		});
	},
};

import system from 'durandal/system.js';
import ko from 'knockout';
import kov from 'knockout-validation';
import ConfirmModal from 'viewmodels/confirm-modal.js';
import i18n from 'nls/lang.js';

export default {
	mixin: function (spotcheck, options) {
		var orig_fromJson = spotcheck._fromJson,
			orig_reset = spotcheck.reset,
			orig_getAttachment = spotcheck._getAttachment,
			orig_addAttachment = spotcheck.addAttachment,
			orig_addComment = spotcheck.addComment,
			orig_delete = spotcheck.delete,
			orig_create = spotcheck.create,
			orig_get = spotcheck.get;

		var global = options.global;

		// Core method overrides
		// ----
		spotcheck._fromJson = function (data, opt) {
			//Use apply to call function so we can pass the
			//Kit context otherwise it would use Window context
			return orig_fromJson.apply(spotcheck, [data, opt]).then(function () {
				return loadUsers(data).then(function () {
					spotcheck.oId(spotcheck.id);
					spotcheck.oName(spotcheck.name);
					spotcheck.oStatus(spotcheck.status);
					spotcheck.oSummary(spotcheck.summary);
					spotcheck.oNumChecked(spotcheck.numChecked);
					spotcheck.oNumUnchecked(spotcheck.numUnchecked);
					spotcheck.oNumUnexpected(spotcheck.numUnexpected);
					spotcheck.oNote(spotcheck.note);
					spotcheck.oArchived(spotcheck.archived);

					spotcheck._triggerAction(':load');
					return data;
				});
			});
		};

		var userDict = {};
		var loadUsers = function (data) {
			var users = [];
			var tmp = {};
			spotcheck.users.forEach(function (userId) {
				if (!userDict[userId]) {
					tmp[userId] = true;
				} else {
					users.push(userDict[userId]);
				}
			});
			var userIds = Object.keys(tmp);

			var dfd;
			if (userIds.length === 0) {
				dfd = Promise.resolve(users);
			} else {
				dfd = global.getDataSource('users').getMultiple(userIds, 'name,picture,status');
			}

			return dfd.then(function (users) {
				users.forEach(function (user) {
					userDict[user._id] = user;
				});

				spotcheck.users = spotcheck.users.map(function (userId) {
					const user = userDict[userId];

					if (user.status === 'deleted') {
						user.name = i18n.general.deleted_user;
					}

					return user;
				});

				return data;
			});
		};

		spotcheck.reset = function () {
			//Use apply to call function so we can pass the
			//KeyValue context otherwise it would use Window context
			orig_reset.apply(spotcheck);

			//Reset observables to defaults
			spotcheck.oId(spotcheck.id);
			spotcheck.oName(spotcheck.name || '');
			spotcheck.oStatus(spotcheck.status || 'open');
			spotcheck.oSummary(spotcheck.summary || '');
			spotcheck.oNumChecked(spotcheck.numChecked || 0);
			spotcheck.oNumUnexpected(spotcheck.numUnexpected || 0);
			spotcheck.oNumUnchecked(spotcheck.numUnchecked || 0);
			spotcheck.oNote(spotcheck.note || '');
			spotcheck.oArchived(spotcheck.archived);

			spotcheck._triggerAction(':reset');
		};

		spotcheck._getAttachment = function (att, options) {
			return orig_getAttachment.apply(spotcheck, [
				att,
				$.extend(options, {
					canBeCover: false,
					isCover: false,
				}),
			]);
		};

		spotcheck.addComment = function () {
			return orig_addComment().then(function () {
				spotcheck._triggerAction(':changed');
			});
		};

		spotcheck.addAttachment = function (attachmentObj, skipRead) {
			return orig_addAttachment(attachmentObj, skipRead).then(function () {
				spotcheck._triggerAction(':changed');
			});
		};

		spotcheck.create = function (params) {
			return orig_create.apply(spotcheck, [params, true]).then(function (resp) {
				spotcheck._fromJson(resp);

				spotcheck._triggerAction(':created');

				return resp;
			});
		};

		spotcheck.delete = function () {
			var dfdConfirm = new Promise((resolve) => {
				ConfirmModal.showMessage({
					title: 'Remove spotcheck?',
					msg: 'Are you sure you want to remove this spotcheck?',
				}).then(function (resp) {
					if (resp) {
						resolve();
					}
				});
			});

			return new Promise((resolve) => {
				dfdConfirm.then(function () {
					var spotcheckId = spotcheck.oId();

					spotcheck._triggerAction(':deleting');

					orig_delete.apply(spotcheck).then(function (resp) {
						spotcheck._triggerAction(':deleted', spotcheckId);

						resolve(resp);
					});
				});
			});
		};

		/**
		 * Trigger spotcheck:changed with spotcheck object
		 */
		spotcheck._triggerAction = function (action, data) {
			var trigger = 'spotchecks' + action;

			system.log(trigger, data != undefined ? data : spotcheck);
			app.trigger(trigger, data != undefined ? data : spotcheck);
		};

		spotcheck.onUpdateName = function (e, newValue) {
			spotcheck.oName(newValue);

			if ($.trim(newValue) == '') {
				spotcheck.clearName().then(function () {
					spotcheck._triggerAction(':changed');
					app.trigger('notification', 'Updated spotcheck name');
				});
			} else if ($.trim(newValue).length >= 3) {
				spotcheck.setName(newValue).then(function () {
					spotcheck._triggerAction(':changed');
					app.trigger('notification', 'Updated spotcheck name');
				});
			} else {
				return 'Please enter at least 3 characters.';
			}
		};

		// Observables
		// ----
		spotcheck.oId = ko.observable(spotcheck.id || '');
		spotcheck.oName = ko.observable(spotcheck.name || '').extend({
			minLength: {
				params: 3,
				onlyIf: function () {
					return $.trim(ko.utils.unwrapObservable(spotcheck.oName)).length > 0;
				},
			},
		});
		spotcheck.oStatus = ko.observable(spotcheck.status || 'open');
		spotcheck.oSummary = ko.observable(spotcheck.summary || '');

		spotcheck.oTitle = ko.pureComputed(function () {
			var name = spotcheck.oName();

			return name || 'Untitled spotcheck';
		});
		spotcheck.oNumChecked = ko.observable(spotcheck.numChecked || 0);
		spotcheck.oNumUnchecked = ko.observable(spotcheck.numUnchecked || 0);
		spotcheck.oNumUnexpected = ko.observable(spotcheck.oNumUnexpected || 0);
		spotcheck.oNote = ko.observable(spotcheck.note || '');
		spotcheck.oArchived = ko.observable(spotcheck.archived);

		// hack to force refresh info tab on spotcheck detail
		spotcheck.oRefreshEntries = ko.observable();

		spotcheck.canEditName = ko.pureComputed(function () {
			var status = spotcheck.oStatus(),
				id = spotcheck.oId();
			return status == 'open';
		});

		spotcheck.isOwn = ko.pureComputed(function () {
			var user = global.central.user() || {},
				spotcheckUser = spotcheck.by || {},
				id = spotcheck.oId();
			return user._id == spotcheckUser._id;
		});

		// Computables
		// ----
		// Make sure the cr.Kit fields are updated when our computeds are updated as well
		// This enables the default: isEmpty, isDirty, _toJson to work automatically
		spotcheck._updateId = ko.computed(function () {
			spotcheck.id = spotcheck.oId();
		});
		spotcheck._updateName = ko.computed(function () {
			spotcheck.name = spotcheck.oName();
		});
	},
};

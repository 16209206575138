function renderCode(params) {
	// Immediatelly return base64 string of image
	params.response = 'text';

	if (!params.text) return Promise.resolve('');

	return fetch(`${process.env.CODES_API_URL}?${new URLSearchParams(params)}`)
		.then((response) => response.text())
		.catch(() => '');
}

export default {
	qrCode: function (val) {
		return renderCode({
			bcid: 'qrcode', // Barcode type
			text: val, // Text to encode
			scale: 5,
		});
	},
	barCode: function (val) {
		return renderCode({
			bcid: 'code128', // Barcode type
			text: val, // Text to encode
			scale: 3, // 3x scaling factor
			height: 10, // Bar height, in millimeters
		});
	},
	datamatrixCode: function (val) {
		return renderCode({
			bcid: 'datamatrix', // Barcode type
			text: val, // Text to encode
			scale: 3, // 3x scaling factor
		});
	},
};

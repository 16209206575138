import { LogRocket } from '@cheqroom/analytics';
import * as Sentry from '@sentry/react';

if (process.env.SENTRY_DSN) {
	const nonTracedUrls = new RegExp(
		[
			'fonts.googleapis.com',
			'lr-in-prod.com',
			'logr-ingest.com',
			'segment.cheqroom.io',
			'intercom.io',
			'typekit.net',
			'appcues.com',
			'vitally.io',
		]
			.map((url) => url.replace(/\./g, '\\.'))
			.join('|')
	);

	Sentry.init({
		dsn: process.env.SENTRY_DSN,
		environment: process.env.ENVIRONMENT || 'development',
		integrations: (integrations) => {
			return [
				// Exclude Dedupe integration because it currently prevents
				// errors from being sent to Sentry because they are thrown twice
				// once from legacy code and then also in React code
				...integrations.filter((integration) => integration.name !== 'Dedupe'),
				// The tracename is set in shell.js with the router:navigation:processing event
				Sentry.browserTracingIntegration({
					shouldCreateSpanForRequest: (url) => {
						// Skip sending spans for non-traced URLs
						return !nonTracedUrls.test(url);
					},
				}),
			];
		},
		tracePropagationTargets: ['localhost', /^https:\/\/((?!utils\.)([^.]*)\.)?cheqroom\.(dev|com)/],
		tracesSampleRate: process.env.ENVIRONMENT === 'development' ? 1 : 0.05,
		release: process.env.BUILD_VERSION,
		ignoreErrors: ["Error: Could not find what you're looking for"],
		beforeSend(event) {
			const logRocketSession = LogRocket.getSessionURL();
			if (logRocketSession !== null) {
				event.extra = { ...event.extra, 'LogRocket Session': logRocketSession };
			}

			return event;
		},
	});
}

export default Sentry;

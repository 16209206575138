export const extractQRCodeFromUrl = (code: string, customQrCodePrefixes?: string[]): string => {
	if (code.match(/(.*)cheqroom\.(com|dev)\/qr\//)) {
		return code.replace(/(.*)cheqroom\.(com|dev)\/qr\//, '');
	}

	const prefix = customQrCodePrefixes?.find((prefix) => code.includes(prefix));
	if (prefix) return code.replace(prefix, '');

	return code;
};

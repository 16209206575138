import * as Sentry from '@sentry/react';
import * as LogRocketBase from 'logrocket';

import { AnalyticsPlugin, AnalyticsProperties } from '../analytics';

interface INetworkMessage {
	reqId: string;
	method: string;
	url: string;
	headers: { [key: string]: string | undefined };
	body?: string;
}

interface LogRocketConfig {
	token: string;
	release?: string;
}

type RedactableObject = Record<string, unknown>;
type RedactableArray = RedactableObject[];

export class LogRocket implements AnalyticsPlugin {
	static redactionPlaceholder = '**REDACTED**';
	static headersToRedact = ['authorization', 'cookie'];
	static payloadKeysToRedact = [
		/** Auth */
		'username',
		'password',
		/** LDAP sync */
		'login',
		'caCert',
		/** Billing info */
		'card_type',
		'last_four',
	];

	constructor(private readonly config: LogRocketConfig) {}

	get name(): string {
		return 'logrocket';
	}

	identify({ userId, ...traits }: AnalyticsProperties): void {
		LogRocketBase.identify(userId, {
			name: traits.name,
			email: traits.email,
			workspaceId: traits.workspaceId,
			workspaceName: traits.workspaceName,
			organisationId: traits.organisationId,
		});
	}

	initialize(): void {
		LogRocketBase.init(this.config.token, {
			release: this.config.release,
			console: {
				isEnabled: {
					log: false,
					info: false,
					debug: false,
				},
			},
			network: {
				requestSanitizer: this.redactMessage,
				responseSanitizer: this.redactMessage,
			},
		});
	}

	page(): void {
		return;
	}

	track(): void {
		return;
	}

	static getSessionURL(): string | null {
		return LogRocketBase.sessionURL;
	}

	static deepRedact(obj: RedactableObject | RedactableArray): RedactableObject | RedactableArray {
		if (typeof obj !== 'object' || obj === null) {
			return obj; // Return non-object values as they are
		}

		if (Array.isArray(obj)) {
			return obj.map((item) => LogRocket.deepRedact(item)) as RedactableArray;
		}

		return Object.keys(obj).reduce((acc: RedactableObject, key: string) => {
			const value = obj[key] as RedactableObject | RedactableArray;
			if (LogRocket.payloadKeysToRedact.includes(key)) {
				acc[key] = LogRocket.redactionPlaceholder;
			} else if (typeof value === 'object' && value !== null) {
				acc[key] = LogRocket.deepRedact(value);
			} else {
				acc[key] = value;
			}
			return acc;
		}, {} as RedactableObject);
	}

	private redactMessage(message: INetworkMessage): INetworkMessage | null {
		try {
			if (Object.keys(message.headers).length) {
				for (const header of LogRocket.headersToRedact) {
					if (message.headers?.[header]) {
						message.headers[header] = LogRocket.redactionPlaceholder;
					}
				}
			}

			if (message.body && message.body.length > 0) {
				const parsedBody = JSON.parse(message.body) as RedactableObject | RedactableArray;
				const redacted = LogRocket.deepRedact(parsedBody);
				message.body = JSON.stringify(redacted);
			}

			return message;
		} catch (_) {
			return message;
		}
	}
}

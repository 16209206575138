import { createInstance, enums } from '@optimizely/react-sdk';

const Client = createInstance({
	logLevel: enums.LOG_LEVEL.ERROR,
	sdkKey: process.env.OPTIMIZELY_KEY,
	datafileOptions: {
		autoUpdate: true,
		updateInterval: 30 * 60 * 1000, // 30 minutes,
		...(process.env.OPTIMIZELY_PROXY &&
			process.env.OPTIMIZELY_KEY && {
				urlTemplate: `${process.env.OPTIMIZELY_PROXY}/datafiles/%s.json`,
			}),
	},
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
Client.onFileReload = (callback: (...args: any) => void) => {
	Client.notificationCenter.addNotificationListener(enums.NOTIFICATION_TYPES.OPTIMIZELY_CONFIG_UPDATE, callback);
};

export default Client;

import cr from '@cheqroom/core';
import ko from 'knockout';
import global from 'viewmodels/global.js';

export default {
	mixin: function (kv, options) {
		var orig_fromJson = kv._fromJson;

		var global = options.global;

		// Core method overrides
		// ----
		kv._fromJson = function (data, opt) {
			//Use apply to call function so we can pass the
			//KeyValue context otherwise it would use Window context
			return orig_fromJson.apply(kv, [data, opt]).then(function () {
				kv._readFromObj();
			});
		};

		kv.reset = function () {
			//Reset observables to defaults
			kv.oIsDeleting(false);
			kv.oIsAdding(false);
		};

		// Helper methods
		// ----
		kv._readFromObj = function () {
			kv.oBy(kv.by);
			kv.oCreated(kv.created);

			kv.ext = kv.getExt(kv.id);
			kv.name = kv.value || 'Unknown';
		};

		kv.getThumbnailUrl = function (size) {
			if (size === 'orig') {
				size = 'O';
			}

			if (kv.hasPreview()) {
				return global.getAttachmentImageUrl(kv, size);
			} else {
				return '';
			}
		};
		kv.getDownloadUrl = function () {
			return global.getAttachmentImageUrl(kv, 'O');
		};

		// Observables
		// ----
		kv.oBy = ko.observable(kv.by);
		kv.oCreated = ko.observable(kv.created);
		kv.oIsDeleting = ko.observable(false);
		kv.oIsAdding = ko.observable(false);

		// Computables
		// ----

		// Other properties
		// ----
		kv.ext = kv.getExt(kv.id);
		kv.name = kv.fileName || 'Unknown';
		kv.helper = global.coreHelper || new cr.Helper(); //need helper for getThumbnail
	},
};

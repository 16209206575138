import tw, { css, styled } from 'twin.macro';

import { StyledButton } from '../Button/Button.styles';
import { Props } from './ButtonGroup';

type StyledProps = Pick<Props, 'segmented'>;

export const StyledButtonGroup = styled.div<StyledProps>(({ segmented }) => [
	tw`flex`,
	segmented
		? css`
				${StyledButton} {
					&:not(:first-child):not(:last-child) {
						${tw`rounded-none`}
					}

					&:not(:first-child) {
						${tw`-ml-px`}
					}

					&:first-child {
						${tw`rounded-r-none`}
					}

					&:last-child {
						${tw`rounded-l-none`}
					}
				}
			`
		: tw`gap-2`,
]);

import app from 'durandal/app.js';
import system from 'durandal/system.js';
import cr from '@cheqroom/core';
import msgHandler from 'viewmodels/message.js';
import Sentry from '@cheqroom/web/src/services/sentry';

// ErrorHandler implements `onError()` and uses the msgHandler to show errors in the UI

var errHandler = {};

errHandler.attachToWindow = function () {
	// Listen for the global window.onerror function,
	// so it also uses our errorHandler.onError function
	window.onerror = function (msg, url, line, col, err) {
		// Grammarly plugin issue
		// https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
		if (msg === 'ResizeObserver loop limit exceeded') return;

		err = err || new Error(msg);
		err.url = url;
		err.line = line;
		err.col = col;
		errHandler.onError(err);
	};
	system.log('ErrorHandler attached to window.onError');
};

errHandler.onError = function (err, unrecoverable) {
	if (!err) return;

	// Session expired
	if (err && err.code == 401) {
		app.trigger('sessionExpired');
		return;
	}

	// Ignore errors
	// - Ignore Chrome specific error (https://groups.google.com/a/chromium.org/forum/#!topic/chromium-discuss/7VU0_VvC7mE)
	// - Ignore Google Drive specific error (https://github.com/mozilla/fxa-content-server/issues/4138)
	if (
		err &&
		err.message &&
		(err.message.indexOf('__gCrWeb') !== -1 || err.message.indexOf('docs-homescreen-gb-container') !== -1)
	) {
		return;
	}

	var info = null;

	// Errors coming from ApiDataSources will
	// have a err.opt with some more info filled in
	if (err.opt && err.opt.coll && err.opt.cmd) {
		info = err.opt.coll + '.' + err.opt.cmd;
		system.log('Error during ' + info);
	}

	system.log(err.message);
	system.log(err.stack);

	// If it's one of our own error types
	// See if we have set a specific error handler
	// None specified? Fallback to just an error notification
	if (err.code) {
		// If any error occurs while we're offline,
		// we'll always call NetworkNotConnected regardless
		if (!navigator.onLine) {
			var orig = err;
			err = new cr.api.NetworkNotConnected();
			err.original = orig;
			system.log(err.message);
		}

		switch (err.code) {
			// QUOTA_EXCEEDED_EXCEPTION
			case 22:
			case 1014:
				//Ignore quota exceptions triggered by localStorage
				return;
			// Invalid data error
			case 422:
				Sentry.captureException(err);
			// Unrecoverable errors
			case 999:
			case 408:
			case 400:
			case 500:
				msgHandler.showError(
					err.message + '. Changes not saved.',
					unrecoverable === undefined ? true : unrecoverable
				);
				break;
			// Back-end at capacity
			case 503:
				msgHandler.showError(err.message + '. Please try again in a few minutes.', true);
				break;
			// Payment required
			case 402:
				msgHandler.showError(
					"<strong>Your trial has expired</strong>, <a href='/admin/settings/account/billing'>update your billing info</a> to continue",
					false,
					false,
					true
				);
				break;
			// Recoverable errors
			case 403:
			case 404:
			default:
				msgHandler.showError(err.message);
				break;
		}
	} else {
		//Ignore Script error. message coming from 3rd Party script like (Google Analytics, Intercom)
		if (err && err.message == 'Script error.') return;

		Sentry.captureException(err);

		msgHandler.showError('An unknown error occurred');
	}
};

export default errHandler;

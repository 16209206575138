import featuresJSON from '../../public/pricing/features.json';

export enum Plan {
	ESSENTIALS = 'essentials',
	STANDARD = 'standard',
	PLUS = 'plus',
	PREMIUM = 'premium',
	ACADEMIC_ESSENTIALS = 'academic_essentials',
	ACADEMIC_PREMIUM = 'academic_premium',
	ACADEMIC_STARTER = 'academic_starter',
	ACADEMIC_PRO = 'academic_pro',
	ACADEMIC_MASTER = 'academic_master',
}

export interface Feature {
	id: string;
	index: number;
	title: string;
	essentials: boolean | number;
	standard: boolean | number;
	plus: boolean | number;
	premium: boolean | number | string;
	academic_essentials: boolean | number;
	academic_premium: boolean | number | string;
	academic_starter: boolean | number;
	academic_pro: boolean | number | string;
	academic_master: boolean | number | string;
	weight: number;
	group: string;
}

class Features {
	private _features: Feature[];

	constructor() {
		this._features = featuresJSON;
	}

	get features(): Feature[] {
		return this._features;
	}

	allFeaturesInPlan(plan: Plan): Feature[] {
		return this._features.filter((feature) => !!feature[plan]).sort((a, b) => b.weight - a.weight);
	}
}
const features = new Features();

export default features;

import tw, { styled } from 'twin.macro';

import { Props } from './Image';

type StyledProps = Pick<Props, 'fit'>;

const getFit = (fit?: StyledProps['fit']) => {
	switch (fit) {
		default:
		case 'contain':
			return tw`object-contain`;
		case 'cover':
			return tw`object-cover`;
		case 'fill':
			return tw`object-fill`;
		case 'none':
			return tw`object-fill`;
		case 'scale-down':
			return tw`object-scale-down`;
	}
};

export const StyledImage = styled.img<StyledProps>(({ fit }) => [tw`w-full h-full`, getFit(fit)]);

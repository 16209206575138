import { DateTime } from './date-time';

export class InvalidTimeOfDayException extends Error {}

export enum Month {
	JANUARY = 1,
	FEBRUARY = 2,
	MARCH = 3,
	APRIL = 4,
	MAY = 5,
	JUNE = 6,
	JULY = 7,
	AUGUST = 8,
	SEPTEMBER = 9,
	OCTOBER = 10,
	NOVEMBER = 11,
	DECEMBER = 12,
}

export enum DayOfWeekEnum {
	MONDAY = 1,
	TUESDAY = 2,
	WEDNESDAY = 3,
	THURSDAY = 4,
	FRIDAY = 5,
	SATURDAY = 6,
	SUNDAY = 7,
}

export class DayOfWeek {
	static monday(): DayOfWeek {
		return new DayOfWeek(DayOfWeekEnum.MONDAY);
	}

	static tuesday(): DayOfWeek {
		return new DayOfWeek(DayOfWeekEnum.TUESDAY);
	}

	static wednesday(): DayOfWeek {
		return new DayOfWeek(DayOfWeekEnum.WEDNESDAY);
	}

	static thursday(): DayOfWeek {
		return new DayOfWeek(DayOfWeekEnum.THURSDAY);
	}

	static friday(): DayOfWeek {
		return new DayOfWeek(DayOfWeekEnum.FRIDAY);
	}

	static saturday(): DayOfWeek {
		return new DayOfWeek(DayOfWeekEnum.SATURDAY);
	}

	static sunday(): DayOfWeek {
		return new DayOfWeek(DayOfWeekEnum.SUNDAY);
	}

	static fromISOWeekday(weekday: number): DayOfWeek {
		return new DayOfWeek(weekday as DayOfWeekEnum);
	}

	private constructor(private readonly day: DayOfWeekEnum) {}

	daysUntil(day: DayOfWeek): number {
		if (this.isSameDay(day)) return 0;
		if (this.day < day.day) return day.day - this.day;

		return 7 - this.day + day.day;
	}

	isSameDay(day: DayOfWeek): boolean {
		return this.day === day.day;
	}
}

export class TimeOfDay {
	private _hours: number;
	private _minutes: number;
	private _seconds: number;

	static fromString(timeString: string): TimeOfDay {
		if (!timeString.includes(':')) {
			throw new InvalidTimeOfDayException('Time should contain ":"');
		}

		const split = timeString.split(':');

		const hours = Number.parseInt(split[0]);
		const minutes = Number.parseInt(split[1]);
		const seconds = Number.parseInt(split[2]);

		if (isNaN(hours) || isNaN(minutes) || (split.length > 2 && isNaN(seconds))) {
			throw new InvalidTimeOfDayException('Time value is invalid');
		}

		return new TimeOfDay(hours, minutes, seconds || 0);
	}

	static fromMinutesSinceMidnight(givenMinutes: number): TimeOfDay {
		return new TimeOfDay(Math.floor(givenMinutes / 60), givenMinutes % 60, 0);
	}

	static fromDateTime(date: DateTime): TimeOfDay {
		return new TimeOfDay(date.hours, date.minutes, date.seconds);
	}

	constructor(hours: number, minutes: number, seconds: number) {
		if (hours >= 24 || hours < 0) {
			throw new InvalidTimeOfDayException('Hours should be between 0 and 24');
		}

		if (minutes >= 60 || minutes < 0) {
			throw new InvalidTimeOfDayException('Minutes should be between 0 and 60');
		}

		if (seconds >= 60 || seconds < 0) {
			throw new InvalidTimeOfDayException('Seconds should be between 0 and 60');
		}

		this._hours = hours;
		this._minutes = minutes;
		this._seconds = seconds;
	}

	get hours(): number {
		return this._hours;
	}

	get minutes(): number {
		return this._minutes;
	}

	get seconds(): number {
		return this._seconds;
	}

	get minutesSinceMidnight(): number {
		return this._hours * 60 + this._minutes;
	}

	get secondsSinceMidnight(): number {
		return this._hours * 3600 + this._minutes * 60 + this._seconds;
	}

	toString(): string {
		return `${this.hours.toString().padStart(2, '0')}:${this.minutes.toString().padStart(2, '0')}:${this.seconds
			.toString()
			.padStart(2, '0')}`;
	}

	isBefore(afterValue: TimeOfDay): boolean {
		return this.secondsSinceMidnight < afterValue.secondsSinceMidnight;
	}

	isEqual(other: TimeOfDay): boolean {
		return this.secondsSinceMidnight === other.secondsSinceMidnight;
	}
}

import { FC } from 'react';

export const Marker: FC = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.9556 6.01796C14.2205 3.32735 9.77948 3.32735 7.04444 6.01796C4.31852 8.6996 4.31852 13.0405 7.04444 15.7222L11.2994 19.908C11.6882 20.2905 12.3116 20.2907 12.7011 19.9075L16.9556 15.7222C19.6815 13.0405 19.6815 8.6996 16.9556 6.01796ZM5.64185 4.59221C9.1552 1.13593 14.8448 1.13593 18.3581 4.59221C21.8806 8.05747 21.8806 13.6826 18.3581 17.1479L14.1037 21.3333C12.9365 22.4815 11.0646 22.4826 9.89682 21.3338L5.64185 17.1479C2.11938 13.6826 2.11938 8.05747 5.64185 4.59221ZM12 9C11.4696 9 10.9609 9.21071 10.5858 9.58579C10.2107 9.96086 10 10.4696 10 11C10 11.5304 10.2107 12.0391 10.5858 12.4142C10.9609 12.7893 11.4696 13 12 13C12.5304 13 13.0391 12.7893 13.4142 12.4142C13.7893 12.0391 14 11.5304 14 11C14 10.4696 13.7893 9.96086 13.4142 9.58579C13.0391 9.21071 12.5304 9 12 9ZM9.17157 8.17157C9.92172 7.42143 10.9391 7 12 7C13.0609 7 14.0783 7.42143 14.8284 8.17157C15.5786 8.92172 16 9.93913 16 11C16 12.0609 15.5786 13.0783 14.8284 13.8284C14.0783 14.5786 13.0609 15 12 15C10.9391 15 9.92172 14.5786 9.17157 13.8284C8.42143 13.0783 8 12.0609 8 11C8 9.93913 8.42143 8.92172 9.17157 8.17157Z"
			fill="currentColor"
		/>
	</svg>
);

import debounce from 'viewmodels/debounce';
import ko from 'knockout';
import kov from 'knockout-validation';

export default {
	mixin: function (user, options) {
		var orig_fromJson = user._fromJson,
			orig_reset = user.reset,
			orig_isDirty = user.isDirty,
			orig_isEmpty = user.isEmpty,
			orig_isValid = user.isValid;

		// Core method overrides
		// ----
		user._fromJson = function (data, options) {
			//Use apply to call function so we can pass the
			//Base context otherwise it would use Window context
			return orig_fromJson.apply(user, [data, options]).then(function () {
				var data = user.oData();
				data.name(user.name);
				data.email(user.email);
				data.role(user.role);

				return data;
			});
		};

		user.reset = function () {
			// Use apply to call function so we can pass the
			// Base context otherwise it would use Window context
			orig_reset.apply(user);

			// Reset observables to defaults
			var data = user.oData();
			data.name(user.name);
			data.name.isModified(false);
			data.email(user.email);
			data.email.isModified(false);
			data.role(user.role);
			data.role.isModified(false);

			user.oStatus(user.status);
		};

		// Knockout validation rules
		// ----
		let abortController;
		ko.validation.rules['emailExists'] = {
			async: true,
			message: 'A user with that email address already exists',
			validator: debounce(function (val, params, callback) {
				// When existing user is edited, we don't want
				// to check its current email
				if (user.login != null && user.raw != null && user.oData().email() == user.raw.name) {
					callback(true);
				} else {
					if (user.ds) {
						if (abortController) {
							abortController.abort();
						}

						abortController = new AbortController();

						user.emailExists(abortController)
							.then(function (resp) {
								callback(!resp);
							})
							.catch(function (error) {
								callback({ isValid: false, message: 'Something went wrong' });
							});
					}
				}
			}, 250),
		};
		ko.validation.registerExtenders();

		// Observables
		// ----
		user.oName = ko.observable(user.name);
		user.oEmail = ko.observable(user.email);
		user.oRole = ko.observable(user.role);

		user.oData = ko.validatedObservable({
			name: user.oName.trimmed().extend({
				required: { message: 'Name is a required field' },
				minLength: 4,
			}),
			email: user.oEmail.extend({
				required: { message: 'Email is a required field' },
				validation: [
					{
						validator: function (value, params) {
							//Make sure isValidEmail checks latest value
							user.email = value;
							return user.isValidEmail();
						},
						message: 'Please enter a proper email address',
					},
				],
				emailExists: true,
			}),
			role: user.oRole.extend({
				required: { message: 'Role is a required field' },
			}),
		});

		// Computables
		// ----

		// Make sure the cr.User fields are updated when our computeds are updated as well
		// This enables the default: isEmpty, isDirty, _toJson to work automatically
		user._updateName = ko.computed(function () {
			user.name = user.oName();
		});
		user._updateEmail = ko.computed(function () {
			user.email = user.oEmail();
		});
		user._updateRole = ko.computed(function () {
			user.role = user.oRole();
		});

		user.isDirty = ko
			.computed(function () {
				var data = user.oData(),
					name = data.name(),
					email = data.email(),
					role = data.role();
				return orig_isDirty.apply(user);
			})
			.extend({ throttle: 50 });

		user.isEmpty = ko
			.computed(function () {
				var data = user.oData(),
					name = data.name(),
					email = data.email(),
					role = data.role();
				return orig_isEmpty.apply(user);
			})
			.extend({ throttle: 50 });

		user.isValid = ko
			.computed(function () {
				var data = user.oData(),
					name = data.name(),
					email = data.email(),
					role = data.role();
				return orig_isValid.apply(user);
			})
			.extend({ throttle: 50 });
	},
};

import * as XLSX from 'xlsx';

interface Range {
	start: { column: number; row: number };
	end: { column: number; row: number };
}

const RANGE_MAX = 10000000;

// helper function to create a worksheet object from an array of arrays
// based on https://raw.githubusercontent.com/clarketm/TableExport/master/src/v2/v2.1/Export2Excel.js
function createSheet(data: unknown[][]): XLSX.WorkSheet {
	const sheet: XLSX.WorkSheet = {};
	const range: Range = { start: { column: RANGE_MAX, row: RANGE_MAX }, end: { column: 0, row: 0 } };
	for (let rowIndex = 0; rowIndex != data.length; ++rowIndex) {
		for (let colIndex = 0; colIndex != data[rowIndex].length; ++colIndex) {
			if (range.start.row > rowIndex) range.start.row = rowIndex;
			if (range.start.column > colIndex) range.start.column = colIndex;
			if (range.end.row < rowIndex) range.end.row = rowIndex;
			if (range.end.column < colIndex) range.end.column = colIndex;

			const val: unknown = data[rowIndex][colIndex];
			if (val == null) continue;

			const cell_ref: string = XLSX.utils.encode_cell({ c: colIndex, r: rowIndex });

			if (typeof val === 'number') {
				sheet[cell_ref] = { v: val, t: 'n' };
			} else if (typeof val === 'boolean') {
				sheet[cell_ref] = { v: val, t: 'b' };
			} else if (val instanceof Date) {
				const dateVal =
					(Date.parse(val.toDateString()) - new Date(Date.UTC(1899, 11, 30)).getTime()) /
					(24 * 60 * 60 * 1000);
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
				sheet[cell_ref] = { v: dateVal, t: 'n', z: XLSX.SSF._table[14] };
			} else {
				sheet[cell_ref] = { v: String(val), t: 's' };
			}
		}
	}
	if (range.start.column < RANGE_MAX) {
		sheet['!ref'] = XLSX.utils.encode_range({
			s: { c: range.start.column, r: range.start.row },
			e: { c: range.end.column, r: range.end.row },
		});
	}
	return sheet;
}

export const aoa_to_xlsx = (data: unknown[][], filename: string) => {
	const workbook: XLSX.WorkBook = { SheetNames: [], Sheets: {} };
	const sheet: XLSX.WorkSheet = createSheet(data);

	workbook.SheetNames.push(filename);
	workbook.Sheets[filename] = sheet;

	const options: XLSX.WritingOptions = {
		bookType: 'xlsx',
		bookSST: false,
		type: 'binary',
	};

	XLSX.writeFile(workbook, filename, options);
};

import { FC, ReactNode } from 'react';

import { StyledButtonGroup } from './ButtonGroup.styles';

export interface Props {
	children: ReactNode[];
	segmented?: boolean;
}

export const ButtonGroup: FC<Props> = ({ children, segmented = false, ...props }) => (
	<StyledButtonGroup segmented={segmented} {...props}>
		{children}
	</StyledButtonGroup>
);

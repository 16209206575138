import {
	ASSIGN_TO_KIT_HARD_CONFLICTS,
	CHECKOUT_HARD_CONFLICTS,
	ItemConflict,
	RESERVATION_HARD_CONFLICTS,
} from '../get-equipment-conflict';

interface CommonParams {
	isMaintenanceUser: boolean;
	conflict: ItemConflict;
}

interface ReservationParams extends CommonParams {
	rule: 'reservation';
	canOverrideReserved: boolean;
}

interface CheckoutParams extends CommonParams {
	rule: 'check-out';
	canOverrideCheckouts: boolean;
}

interface AssignToKitParams extends CommonParams {
	rule: 'assign-to-kit';
}

export function isItemSelectable(params: ReservationParams): boolean;
export function isItemSelectable(params: CheckoutParams): boolean;
export function isItemSelectable(params: AssignToKitParams): boolean;
export function isItemSelectable({
	conflict,
	isMaintenanceUser,
	...params
}: ReservationParams | CheckoutParams | AssignToKitParams): boolean {
	if (params.rule === 'reservation') {
		if (conflict === ItemConflict.FLAGGED && isMaintenanceUser) {
			return true;
		}

		const isHardConflict = RESERVATION_HARD_CONFLICTS.includes(conflict);

		if (isHardConflict) return false;

		if (conflict !== ItemConflict.AVAILABLE) {
			return params.canOverrideReserved;
		}
	}

	if (params.rule === 'check-out') {
		if (conflict === ItemConflict.FLAGGED && isMaintenanceUser) {
			return true;
		}

		const isHardConflict = CHECKOUT_HARD_CONFLICTS.includes(conflict);

		if (isHardConflict) return false;

		if (conflict !== ItemConflict.AVAILABLE) {
			return params.canOverrideCheckouts;
		}
	}

	if (params.rule === 'assign-to-kit') {
		return !ASSIGN_TO_KIT_HARD_CONFLICTS.includes(conflict);
	}

	return true;
}

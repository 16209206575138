import 'jquery';
import 'jquery-printthis';
import 'jquery-fileupload';
import 'jquery-ui';
import 'jquery-ui/ui/widgets/sortable';

import system from 'durandal/system.js';
import app from 'durandal/app.js';
import global from 'viewmodels/global.js';
import router from 'plugins/router.js';
import dialog from 'plugins/dialog.js';
import bootstrapModal from 'bootstrapModal';

system.debug(process.env.ENVIRONMENT !== 'production');

app.configurePlugins([{ module: router }, { module: dialog }]);
bootstrapModal.install();

app.title = 'Cheqroom';

// Make sure we can ask the app object for an errorHandler
app.getErrorHandler = function () {
	return global.getErrorHandler();
};

app.start().then(function () {
	//Replace 'viewmodels' in the moduleId with 'views' to locate the view.
	//Look for partial views in a 'views' folder in the root.
	//viewLocator.useConvention();

	global.init(app);

	return global.authenticate().then(
		async () => {
			//Show the app by setting the root view model for our application.
			app.setRoot((await import('viewmodels/shell')).default);
		},
		(err) => {
			console.error(err);
			// Invalid access and/or session token, app triggered redirect to logout page
		}
	);
});

window.app = app;

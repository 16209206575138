import system from 'durandal/system';
import dialog from 'plugins/dialog';
import app from 'durandal/app';
import viewEngine from 'durandal/viewEngine';
import ko from 'knockout';
import $ from 'jquery';
import bootstrap from 'bootstrap';

// https://hiddedevries.nl/en/blog/2017-01-29-using-javascript-to-trap-focus-in-an-element
function trapFocus(element) {
	var KEYCODE_TAB = 9;

	var getFocusableEls = () => {
		var focusableEls = [
			...element.querySelectorAll(
				'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
			),
		];

		// Put active nav-link on top
		focusableEls.sort((a, b) => b.classList.contains('active') - a.classList.contains('active'));

		return focusableEls;
	};

	var keepFocusInModal = function () {
		var firstFocusableEl = getFocusableEls()[0];

		if (!element.contains(document.activeElement)) {
			firstFocusableEl && firstFocusableEl.focus();
		}
	};
	$(element).on('click focus', function () {
		keepFocusInModal();
	});

	element.addEventListener('keydown', function (e) {
		var focusableEls = getFocusableEls(),
			firstFocusableEl = focusableEls[0],
			lastFocusableEl = focusableEls[focusableEls.length - 1],
			isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB;

		if (!isTabPressed) {
			return;
		}

		if (e.shiftKey) {
			/* shift + tab */ if (document.activeElement === firstFocusableEl) {
				lastFocusableEl.focus();
				e.preventDefault();
			}
		} /* tab */ else {
			if (document.activeElement === lastFocusableEl) {
				firstFocusableEl.focus();
				e.preventDefault();
			}
		}
	});

	keepFocusInModal();
}

dialog.addContext('bootstrapModal', {
	blockoutOpacity: 0.2,
	removeDelay: 300,
	addHost: function (theDialog) {
		var body = $('body');
		var host = $(
			'<div class="modal fade" id="bootstrapModal" role="dialog" aria-labelledby="bootstrapModal" aria-hidden="true" aria-modal="true"></div>'
		).appendTo(body);
		theDialog.host = host.get(0);
	},
	removeHost: function (theDialog) {
		$('#bootstrapModal').modal('hide');
		$('body').removeClass('modal-open');
	},
	attached: null,
	compositionComplete: function (child, parent, context) {
		var theDialog = dialog.getDialog(context.model);
		var options = $.extend(
			{
				show: true,
				keyboard: true,
			},
			context.model.modalOptions
		);

		$(parent).modal(options);
		$(parent)
			.on('shown.bs.modal', function () {
				// tabindex=-1 causes issues with popovers that have input which can't have
				// focus, so we use a javascript workaround to trap trap focus in the modal
				// when using tab
				trapFocus($(parent).get(0));
			})
			.on('hidden.bs.modal', function (e) {
				theDialog.close();
				ko.removeNode(theDialog.host);
			});
	},
});
var bootstrapMarkup = [
	'<div data-view="plugins/messageBox" data-bind="css: getClass(), style: getStyle()">',
	'<div class="modal-content">',
	'<div class="modal-header">',
	'<h3 data-bind="html: title"></h3>',
	'</div>',
	'<div class="modal-body">',
	'<p class="message" data-bind="html: message"></p>',
	'</div>',
	'<div class="modal-footer">',
	'<!-- ko foreach: options -->',
	'<button data-bind="click: function () { $parent.selectOption($parent.getButtonValue($data)); }, text: $parent.getButtonText($data), css: $parent.getButtonClass($index)"></button>',
	'<!-- /ko -->',
	'<div style="clear:both;"></div>',
	'</div>',
	'</div>',
	'</div>',
].join('\n');
var bootstrapModal = function () {};
bootstrapModal.install = function () {
	app.showBootstrapDialog = function (obj, activationData) {
		return dialog.show(obj, activationData, 'bootstrapModal');
	};
	app.showBootstrapMessage = function (message, title, options, autoclose, settings) {
		return dialog.showBootstrapMessage(message, title, options, autoclose, settings);
	};

	dialog.showBootstrapDialog = function (obj, activationData) {
		return dialog.show(obj, activationData, 'bootstrapModal');
	};
	dialog.showBootstrapMessage = function (message, title, options, autoclose, settings) {
		if (system.isString(this.MessageBox)) {
			return dialog.show(
				this.MessageBox,
				[
					message,
					title || this.MessageBox.defaultTitle,
					options || this.MessageBox.defaultOptions,
					autoclose || false,
					settings || {},
				],
				'bootstrapModal'
			);
		}
		var bootstrapDefaults = {
			buttonClass: 'btn btn-default',
			primaryButtonClass: 'btn-primary autofocus',
			secondaryButtonClass: '',
			class: 'modal-dialog',
			style: null,
		};
		this.MessageBox.prototype.getView = function () {
			return viewEngine.processMarkup(bootstrapMarkup);
		};
		var bootstrapSettings = $.extend(bootstrapDefaults, settings);
		return dialog.show(
			new dialog.MessageBox(message, title, options, autoclose, bootstrapSettings),
			{},
			'bootstrapModal'
		);
	};
	dialog.MessageBox.prototype.compositionComplete = function (child, parent, context) {
		var theDialog = dialog.getDialog(context.model);
		var $child = $(child);
		if ($child.hasClass('autoclose') || context.model.autoclose) {
			$(theDialog.blockout).click(function () {
				theDialog.close();
			});
		}
	};
};

export default bootstrapModal;

import { useAnalytics } from '@cheqroom/analytics';
import { CircleCheck } from '@cheqroom/icons';
import { RocketLaunch } from '@cheqroom/illustrations';
import { Button, ButtonGroup, Icon, Illustration, Modal, Stack, Text } from '@cheqroom/ui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'twin.macro';

import { useIndefiniteArticle } from '../../hooks/indefinite-article/use-indefinite-article.hook';
import useNavigation from '../../hooks/navigation/use-navigation.hook';

type Props = {
	onClose?: () => void;
	features: {
		academic_essentials: boolean;
		academic_premium: boolean;
		essentials: boolean;
		group: string;
		id: string;
		index: number;
		plus: boolean;
		premium: boolean;
		standard: boolean;
		title: string;
		weight: number;
	}[];
	featureName: string;
	planName: string;
	upgradeLink: string;
	trackingSource: string;
	featureId: string;
};

const UpgradeModal: FC<Props> = ({
	onClose,
	features,
	featureName,
	planName,
	upgradeLink,
	featureId,
	trackingSource,
}) => {
	const { t } = useTranslation('upgrade-modal');

	const { navigate } = useNavigation();
	const [track] = useAnalytics();

	const planArticle = useIndefiniteArticle(planName);

	const handleUpgradeClick = () => {
		navigate(upgradeLink);

		window.app.trigger('intercom:event', { name: 'web-clicked-upgrade', feature: featureId });

		track('Subscription Changing', trackingSource, { feature: featureName });

		onClose?.();
	};

	return (
		<Modal
			tw="sm:w-[640px] w-auto"
			open
			header={
				<Modal.Header onClose={onClose}>
					{t('header.title', { featureName, indefiniteArticle: planArticle, planName })}
				</Modal.Header>
			}
			footer={
				<Modal.Footer>
					<ButtonGroup>
						{onClose && (
							<Button variation="secondary" onClick={onClose}>
								{t('footer.cancel')}
							</Button>
						)}
						<Button onClick={handleUpgradeClick}>{t('footer.confirm', { planName })}</Button>
					</ButtonGroup>
				</Modal.Footer>
			}
		>
			<Modal.Body tw="py-4 px-6">
				<Stack vertical spacing="loose">
					<Text color="subdued">{t('body.description')}</Text>
					<Stack justify="between" align="center">
						<Stack element="ul" vertical spacing="tight">
							{features.map(({ id, title }) => (
								<Stack key={id} element="li" align="center" spacing="extraTight">
									<Icon source={CircleCheck} color={theme`colors.green.500`} />
									<Text>{title}</Text>
								</Stack>
							))}
						</Stack>

						<Illustration source={RocketLaunch} tw="hidden sm:block" />
					</Stack>
				</Stack>
			</Modal.Body>
		</Modal>
	);
};

export default UpgradeModal;

import { FC } from 'react';

export const Deprecation: FC = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.9994 4H5C3.34315 4 2 5.34315 2 7V17C2 18.6569 3.34315 20 5 20H6.9986C6.99907 20 6.99953 20 7 20C7.00047 20 7.00093 20 7.0014 20H19C20.6569 20 22 18.6569 22 17V7C22 5.34315 20.6569 4 19 4H17.0006C17.0008 4 17.0004 4 17.0006 4C17.0004 4 16.9996 4 16.9994 4H7.0006C7.00081 4 7.0004 4 7.0006 4C7.0004 4 6.9996 4 6.9994 4ZM16 6H8V6.5C8 8.21323 6.85355 10 5 10H4V14H5C6.65686 14 8 15.3431 8 17V18H16V17C16 15.3431 17.3431 14 19 14H20V10H19C17.3431 10 16 8.65685 16 7V6ZM18 6V7C18 7.55228 18.4477 8 19 8H20V7C20 6.44772 19.5523 6 19 6H18ZM20 16H19C18.4477 16 18 16.4477 18 17V18H19C19.5523 18 20 17.5523 20 17V16ZM6 18L6 17C6 16.4477 5.55228 16 5 16H4V17C4 17.5523 4.44772 18 5 18H6ZM4 8H5C5.35559 8 6 7.54819 6 6.5V6H5C4.44772 6 4 6.44772 4 7V8ZM12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11ZM9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12Z"
			fill="currentColor"
		/>
	</svg>
);

import app from 'durandal/app.js';
import ko from 'knockout';
import cr from '@cheqroom/core';
import moment from 'moment';
import { capitalize } from 'viewmodels/helpers/format-categories';

export default {
	mixin: function (item, options = {}) {
		var orig_fromJson = item._fromJson,
			orig_reset = item.reset,
			orig_isDirty = item.isDirty,
			orig_isEmpty = item.isEmpty,
			orig_getAttachment = item._getAttachment,
			orig_addKeyValue = item.addKeyValue,
			orig_updateKeyValue = item.updateKeyValue,
			orig_updateName = item.updateName,
			orig_setField = item.setField,
			orig_updateBasicFields = item.updateBasicFields,
			orig_clearFlag = item.clearFlag,
			orig_setFlag = item.setFlag,
			orig_updateAllowedActions = item.updateAllowedActions,
			orig_toJson = item._toJson;

		var global = options.global;
		var helper = new cr.Helper();

		const getNamesForCategoryId = (categoryId, categories = []) => {
			const currentCategory = categories.find((category) => category._id === categoryId);

			if (!currentCategory) {
				return [];
			}

			const parent = categories.find((category) => category._id === currentCategory.parent);

			if (parent) {
				return [...getNamesForCategoryId(parent._id, categories), capitalize(currentCategory.name)];
			}

			return [capitalize(currentCategory.name)];
		};

		// Core method overrides
		// ----
		item._toJson = function (options) {
			const tempData = orig_toJson.apply(item, [options]);

			// Ignore purchaseDate, warrantyDate, residualValue for bulk items
			const { purchaseDate, warrantyDate, residualValue, ...restData } = tempData;

			return {
				...(item.oKind() === 'simple' ? tempData : restData),
				quantity: item.oQuantity(),
				kind: item.oKind(),
			};
		};

		item._fromJson = function (data, options) {
			//Use apply to call function so we can pass the
			//Base context otherwise it would use Window context
			return orig_fromJson.apply(item, [data, options]).then(function () {
				item.oId(item.id);
				item.oName(item.name);
				item.oCategory(item.category);
				item.oLocation(item.location);
				item.oFlag(item.flag || 'Unknown');
				item.oCodes(item.codes);
				item.oStatus(item.status);
				item.oKit(item.kit);
				item.oAddress(data.address || '');
				item.oOrderId(data.order && data.order._id ? data.order._id : '');
				item.oOrderCustomerId(data.order && data.order.customer ? data.order.customer : '');
				item.oOrderDue(data.order && data.order.due ? data.order.due : null);
				item.oCustody(data.custody || {});
				item.oCatalog(item.catalog);
				item.oGeo(data.geo ? data.geo : []);
				item.oBrand(item.brand);
				item.oModel(item.model);
				item.oWarrantyDate(item.warrantyDate ? item.warrantyDate : null);
				item.oPurchaseDate(item.purchaseDate ? item.purchaseDate : null);
				item.oPurchasePrice(item.purchasePrice != null ? item.purchasePrice : null);
				item.oExpired(data.expired);
				item.oAllowReserve(item.allowReserve);
				item.oAllowCheckout(item.allowCheckout);
				item.oAllowCustody(item.allowCustody);
				item.oKind(item.kind || 'simple');
				item.oQuantity(item.quantity);

				let itemValidationData = item.oData();
				itemValidationData.name(item.name);
				itemValidationData.name.isModified(false);
				itemValidationData.category(item.category);
				itemValidationData.category.isModified(false);
				itemValidationData.location(item.location);
				itemValidationData.location.isModified(false);

				// Force update of computables
				updateComputables(updateComputables() + 1);

				return data;
			});
		};

		item.setFlag = function (message, attachments, skipRead) {
			return orig_setFlag.apply(item, [message, attachments, skipRead]).then(function (resp) {
				app.trigger('items:setflag');

				return resp;
			});
		};

		item.clearFlag = function (message, attachments, skipRead) {
			return orig_clearFlag.apply(item, [message, attachments, skipRead]).then(
				function (resp) {
					return resp;
				},
				function (err) {
					if (err && err.code == 422 && err.opt && err.opt.detail.indexOf('Cannot clearFlag') != -1) {
						return item.get().then(function (resp) {
							return resp;
						});
					}
					return err;
				}
			);
		};

		item.reset = async function () {
			//Use apply to call function so we can pass the
			//Base context otherwise it would use Window context
			await orig_reset.apply(item);

			//Reset obsersables to defaults
			item.oId(item.id);
			item.oName(item.name);
			item.oCategory(item.category || undefined);
			item.oLocation(item.location || undefined);
			item.oFlag(item.flag || 'Unknown');
			item.oCodes(item.codes);
			item.oStatus(item.status);
			item.oKit(item.kit);
			item.oAddress(item.address || '');
			item.oOrderId('');
			item.oOrderCustomerId('');
			item.oOrderDue(null);
			item.oCustody(item.custody || {});
			item.oCatalog(item.catalog);
			item.oGeo([]);
			item.oExpired(null);
			item.oKind(item.kind || 'simple');
			item.oQuantity(item.quantity);

			var data = item.oData();
			data.name(item.name);
			data.name.isModified(false);
			data.category(item.category);
			data.category.isModified(false);
			data.location(item.location);
			data.location.isModified(false);
		};

		item.discardChanges = function () {
			return this._fromJson(item.raw);
		};

		item.updateName = function (name) {
			item.oName(name);

			return orig_updateName.apply(item, [name, true]);
		};

		item.onUpdateName = function (e, newValue) {
			if ($.trim(newValue).length >= 3) {
				item.updateName(newValue).then(function () {
					app.trigger('items:changed');
					app.trigger('timeline:refresh');
					app.trigger('notification', 'Updated item name');
				});
			} else {
				return 'Please enter at least 3 characters.';
			}
		};

		item._getAttachment = function (att, options) {
			return orig_getAttachment.apply(item, [
				att,
				$.extend(options, {
					canBeCover: true,
					isCover: item && item.raw ? item.raw.cover == att.value : false,
				}),
			]);
		};

		item.setField = function (field, value, skipUpdateTrigger = false) {
			return orig_setField.apply(item, [field, value]).then(function () {
				if (skipUpdateTrigger || options.skipUpdateTrigger) return;

				app.trigger('items:changed', [item.raw]);
			});
		};

		item.updateBasicFields = function (
			name,
			brand,
			model,
			warrantyDate,
			purchaseDate,
			purchasePrice,
			residualValue,
			skipUpdateTrigger = false
		) {
			const itemProps = [name, brand, model, warrantyDate, purchaseDate, purchasePrice, residualValue];
			return orig_updateBasicFields.apply(item, itemProps).then(function () {
				if (skipUpdateTrigger || options.skipUpdateTrigger) return;

				app.trigger('items:changed', [item.raw]);
			});
		};

		// Override add/update keyValue methods
		item.addKeyValue = function (kv, skipRead) {
			return orig_addKeyValue.apply(item, [kv.key, kv.value, kv.kind, skipRead]);
		};
		item.updateKeyValue = function (kv, skipRead) {
			return orig_updateKeyValue.apply(item, [kv.id, kv.key, kv.value, kv.kind, skipRead]);
		};

		item.updateName = function (name) {
			item.oName(name);

			// Here, we won't skip the update trigger
			// because the Knockout page has a title that is derived from this field
			return item.updateBasicFields(
				name,
				item.brand,
				item.model,
				item.warrantyDate,
				item.purchaseDate,
				item.purchasePrice,
				item.residualValue,
				false
			);
		};
		item.updateBrand = function (brand) {
			return item.updateBasicFields(
				item.name,
				brand,
				item.model,
				item.warrantyDate,
				item.purchaseDate,
				item.purchasePrice,
				item.residualValue,
				true
			);
		};
		item.updateModel = function (model) {
			return item.updateBasicFields(
				item.name,
				item.brand,
				model,
				item.warrantyDate,
				item.purchaseDate,
				item.purchasePrice,
				item.residualValue,
				true
			);
		};
		item.updateWarrantyDate = function (warrantyDate) {
			if (!moment.isMoment(warrantyDate)) {
				warrantyDate = moment.utc(warrantyDate);
			}

			return item.updateBasicFields(
				item.name,
				item.brand,
				item.model,
				warrantyDate,
				item.purchaseDate,
				item.purchasePrice,
				item.residualValue,
				true
			);
		};
		item.updatePurchaseDate = function (purchaseDate) {
			if (!moment.isMoment(purchaseDate)) {
				purchaseDate = moment.utc(purchaseDate);
			}

			return item.updateBasicFields(
				item.name,
				item.brand,
				item.model,
				item.warrantyDate,
				purchaseDate,
				item.purchasePrice,
				item.residualValue,
				true
			);
		};
		item.updatePurchasePrice = function (purchasePrice) {
			return item.updateBasicFields(
				item.name,
				item.brand,
				item.model,
				item.warrantyDate,
				item.purchaseDate,
				purchasePrice,
				item.residualValue,
				true
			);
		};
		item.updateResidualValue = function (residualValue) {
			return item.updateBasicFields(
				item.name,
				item.brand,
				item.model,
				item.warrantyDate,
				item.purchaseDate,
				item.purchasePrice,
				residualValue,
				true
			);
		};

		item.updateAllowedActions = function () {
			return orig_updateAllowedActions
				.apply(item, [item.allowReserve, item.allowCheckout, item.allowCustody, true])
				.then(function (resp) {
					item.raw = resp;

					item._canReserve = resp.canReserve;
					item._canOrder = resp.canOrder;
					item._canCustody = resp.canCustody;

					// Trigger status changed so status badge updates correctly
					item.oStatus.valueHasMutated();

					if (!options.skipUpdateTrigger) {
						app.trigger('items:changed', [item.raw]);
					}
				})
				.catch((err) => {
					// Ignore the error when the actions are identical
					if (err?.code === 422 && err?.message.includes('actions are identical')) return;
					else throw err;
				});
		};

		// Observables
		// ----
		item.oId = ko.observable(item.id || '');
		item.oName = ko.observable(item.name || '');
		item.oCategory = ko.observable(undefined);
		item.oLocation = ko.observable(undefined);
		item.oQuantity = ko.observable(undefined);
		item.oFlag = ko.observable(item.flag || 'Unknown');
		item.oStatus = ko.observable(item.status || '');
		item.oCodes = ko.observable(item.codes || []);
		item.oKit = ko.observable(item.kit || null);
		item.oAddress = ko.observable(item.address || '');
		item.oOrderId = ko.observable('');
		item.oOrderCustomerId = ko.observable('');
		item.oOrderDue = ko.observable(null);
		item.oCustody = ko.observable(item.custody || {});
		item.oCatalog = ko.observable(item.catalog);
		item.oGeo = ko.observable([]);
		item.oBrand = ko.observable(item.brand || '');
		item.oModel = ko.observable(item.model || '');
		item.oWarrantyDate = ko.observable(item.warrantyDate ? item.warrantyDate : null);
		item.oPurchaseDate = ko.observable(item.purchaseDate ? item.purchaseDate : null);
		item.oPurchasePrice = ko.observable(item.purchasePrice != null ? item.purchasePrice : null);
		item.oResidualValue = ko.observable(item.residualValue != null ? item.residualValue : null);
		item.oExpired = ko.observable(null);
		item.oAllowReserve = ko.observable(item.allowReserve != null ? item.allowReserve : true);
		item.oAllowCheckout = ko.observable(item.allowCheckout != null ? item.allowCheckout : true);
		item.oAllowCustody = ko.observable(item.allowCustody != null ? item.allowCustody : true);
		item.oKind = ko.observable(item.kind || 'simple');
		item.oQuantity = ko.observable(item.quantity);

		item.oData = ko.validatedObservable({
			name: item.oName.trimmed().extend({
				required: { message: 'Name is a required field' },
				minLength: 3,
				maxLength: 256,
			}),
			category: item.oCategory.extend({ required: true }),
			location: item.oLocation.extend({ required: true }),
			quantity: item.oQuantity.extend({ required: true }),
			kind: item.oKind.extend({ required: true }),
			brand: item.oBrand.trimmed(),
			model: item.oModel.trimmed(),
			warrantyDate: item.oWarrantyDate.extend({ date: true }),
			purchaseDate: item.oPurchaseDate.extend({ date: true }),
			purchasePrice: item.oPurchasePrice.extend({ number: true }),
			residualValue: item.oResidualValue.extend({ number: true }),
		});

		// Computables
		// ----
		var updateComputables = ko.observable(0);
		item.oKitName = ko.computed(function () {
			var kit = item.oKit(),
				update = updateComputables();
			return item.raw ? helper.ensureValue(item.raw.kit || {}, 'name') : '';
		});
		item.oCategoryName = ko.computed(function () {
			var category = item.oCategory(),
				update = updateComputables();
			return item.raw ? helper.ensureValue(item.raw.category || {}, 'name') : '';
		});

		item.oCategoryNames = ko.computed(function () {
			var categoryId = item.oCategory() || 'cheqroom.types.item.Unknown';
			const allCategories = global.central.itemCategories();

			if (typeof categoryId !== 'string') return ['Unknown'];

			return getNamesForCategoryId(categoryId, allCategories);
		});

		item.oLocationName = ko.computed(function () {
			var location = item.oLocation(),
				update = updateComputables();

			if (!location) return 'Unknown';

			return global.central._getLocationById(location).name;
		});

		item.oWarrantyDays = ko.computed(function () {
			var warranty = item.oWarrantyDate();
			if (warranty) {
				var now = moment.utc();
				return Math.round(moment.duration(now - warranty).asDays());
			}

			return null;
		});

		// Make sure the cr.Contact fields are updated when our computeds are updated as well
		// This enables the default: isEmpty, isDirty, _toJson to work automatically
		item._updateId = ko.computed(function () {
			var id = item.oId();
			item.id = id;
		});
		item._updateName = ko.computed(function () {
			item.name = item.oName();
		});
		item._updateCategory = ko.computed(function () {
			item.category = item.oCategory();
		});
		item._updateLocation = ko.computed(function () {
			item.location = item.oLocation();
		});
		item._updateQuantity = ko.computed(function () {
			item.quantity = item.oQuantity();
		});
		item._updateFlag = ko.computed(function () {
			var flag = item.oFlag();
			item.flag = flag == 'Unknown' ? '' : flag;
		});
		item._updateCatalog = ko.computed(function () {
			var catalog = item.oCatalog();
			item.catalog = catalog;
		});
		item._updateBrand = ko.computed(function () {
			var brand = item.oBrand();
			item.brand = brand;
		});
		item._updateModel = ko.computed(function () {
			var model = item.oModel();
			item.model = model;
		});
		item._updateWarrantyDate = ko.computed(function () {
			var warrantyDate = item.oWarrantyDate();

			if (warrantyDate == '') {
				item.warrantyDate = '';
			} else {
				item.warrantyDate = warrantyDate ? moment.utc(warrantyDate) : null;
			}
		});
		item._updatePurchaseDate = ko.computed(function () {
			var purchaseDate = item.oPurchaseDate();

			if (purchaseDate == '') {
				item.purchaseDate = '';
			} else {
				item.purchaseDate = purchaseDate ? moment.utc(purchaseDate) : null;
			}
		});
		item._updatePurchasePrice = ko.computed(function () {
			var purchasePrice = item.oPurchasePrice();
			item.purchasePrice = purchasePrice;
		});
		item._updateResidualValue = ko.computed(function () {
			var residualValue = item.oResidualValue();
			item.residualValue = residualValue;
		});

		item._updateAllowReserve = ko.computed(function () {
			var allowReserve = item.oAllowReserve();
			item.allowReserve = allowReserve;
		});
		item._updateAllowCheckout = ko.computed(function () {
			var allowCheckout = item.oAllowCheckout();
			item.allowCheckout = allowCheckout;
		});
		item._updateAllowCustody = ko.computed(function () {
			var allowCustody = item.oAllowCustody();
			item.allowCustody = allowCustody;
		});
		item._updateQuantity = ko.computed(function () {
			const quantity = item.oQuantity();
			item.quantity = quantity;
		});
		item._updateKind = ko.computed(function () {
			const kind = item.oKind();
			item.kind = kind;
		});

		item.canSave = ko
			.computed(function () {
				var name = item.oName(),
					location = item.oLocation(),
					category = item.oCategory(),
					flag = item.oFlag();

				return item.oData.isValid() && item.isDirty();
			})
			.extend({ throttle: 50 });

		item.isDirty = ko.computed(function () {
			var data = item.oData(),
				dataName = data.name(),
				dataCategory = data.category(),
				dataLocation = data.location(),
				itemName = item.oName(),
				itemCategory = item.oCategory(),
				itemLocation = item.oLocation(),
				itemQuantity = item.oQuantity(),
				itemFlag = item.oFlag(),
				itemBrand = item.oBrand(),
				itemModel = item.oModel(),
				itemPurchaseDate = item.oPurchaseDate(),
				itemPurchasePrice = item.oPurchasePrice(),
				itemWarrantyDate = item.oWarrantyDate(),
				itemResidualValue = item.oResidualValue(),
				itemAllowReserve = item.oAllowReserve(),
				itemAllowCheckout = item.oAllowCheckout(),
				itemAllowCustody = item.oAllowCustody(),
				update = updateComputables();

			return orig_isDirty.apply(item);
		});

		item.isEmpty = ko
			.computed(function () {
				var data = item.oData(),
					name = data.name(),
					category = data.category(),
					location = data.location();
				return orig_isEmpty.apply(item);
			})
			.extend({ throttle: 50 });

		item.hasGeo = ko.computed(function () {
			var geo = item.oGeo();
			return geo.length == 2 && geo[0] != 0 && geo[1] != 0;
		});

		item.inOwnCustody = ko.computed(function () {
			var contact = global.central.contact() || {},
				custody = item.oCustody() || {};
			return global.helper.ensureId(custody) == global.helper.ensureId(contact);
		});
	},
};

import { Button, ButtonGroup, Modal, Text, TextStyle } from '@cheqroom/ui';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
	plan: string;
	kind: string;
	upgradePlan: string;
	upgradeLink: string;
	planLimit: number;
	onClose: () => void;
	onNavigate: (upgradeLink: string) => void;
};

const SubscriptionLimitModal: FC<Props> = ({
	plan: planName,
	planLimit,
	kind: limitKind,
	upgradeLink,
	onNavigate,
	onClose,
}) => {
	const { t } = useTranslation('subscription-limit-modal');

	const handleClose = () => onClose?.();
	const handleConfirm = () => onNavigate(upgradeLink);

	return (
		<Modal
			open
			header={
				<Modal.Header>
					<Trans
						t={t}
						i18nKey="header.title"
						values={{
							limitKind: limitKind.charAt(0).toUpperCase() + limitKind.slice(1),
						}}
					/>
				</Modal.Header>
			}
			footer={
				<Modal.Footer>
					<ButtonGroup>
						<Button onClick={handleClose} variation="secondary">
							{t('footer.cancel')}
						</Button>
						<Button variation="primary" onClick={handleConfirm}>
							{t('footer.confirm')}
						</Button>
					</ButtonGroup>
				</Modal.Footer>
			}
		>
			<Modal.Body>
				<Modal.Section>
					<Text>
						<Trans
							t={t}
							i18nKey="body.description"
							values={{
								limitKind,
								planName,
								planLimit,
							}}
							components={[
								<TextStyle key="0" variation="strong">
									placeholder
								</TextStyle>,
							]}
						/>
					</Text>
				</Modal.Section>
			</Modal.Body>
		</Modal>
	);
};

export default SubscriptionLimitModal;

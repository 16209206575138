import ko from 'knockout';

export default {
	mixin: function (label, options) {
		var orig_fromJson = label._fromJson;

		// Core method overrides
		// ----
		label._fromJson = function (data, opt) {
			// Use apply to call function so we can pass the
			// colorLabel context otherwise it would use Window context
			return orig_fromJson.apply(label, [data, opt]).then(function () {
				label.oId(label.id);
				label.oName(label.name);
				label.oColor(label.color);
				label.oSelected(label.selected);
				label.oReadOnly(label.readonly);
				label.oDefault(label.default);
				return data;
			});
		};

		// Observables
		// ----
		label.oId = ko.observable(label.id);
		label.oName = ko.observable(label.name);
		label.oColor = ko.observable(label.color);
		label.oDelete = ko.observable(false);
		label.oHasFocus = ko.observable(false);
		label.oReadOnly = ko.observable(label.readonly);
		label.oSelected = ko.observable(label.selected);
		label.oDefault = ko.observable(label.default);

		// Computables
		// ----
		// Make sure the cr.Field properties are updated when our computeds are updated as well
		// This enables the default: isEmpty, isDirty, _toJson to work automatically
		label._updateName = ko.computed(function () {
			label.name = label.oName();
		});
		label._updateColor = ko.computed(function () {
			label.color = label.oColor();
		});
		label._updateSelected = ko.computed(function () {
			label.selected = label.oSelected();
		});
	},
};

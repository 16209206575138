import imageHelper from './image';
import attachmentHelper from './attachment';

var that = {};

that.contactGetUserSync = function (contact) {
	if (contact.user && contact.user.sync) {
		return contact.user.sync;
	}
};

that.contactCanReserve = function (contact) {
	return contact.status == 'active';
};

that.contactCanCheckout = function (contact) {
	return contact.status == 'active';
};

that.contactCanGenerateDocument = function (contact) {
	return contact.status == 'active';
};

that.contactCanArchive = function (contact) {
	return contact.status == 'active' && !that.contactGetUserSync(contact);
};

that.contactCanUndoArchive = function (contact) {
	return contact.status == 'archived' && !that.contactGetUserSync(contact);
};

that.contactCanDelete = function (contact) {
	return !that.contactGetUserSync(contact);
};

that.contactCanBlock = function (contact) {
	return contact.status == 'active';
};

that.contactCanUndoBlock = function (contact) {
	return contact.status == 'blocked';
};

/**
 * getContactMessages
 *
 * @memberOf common
 * @name  common#getContactMessages
 * @method
 *
 * @param  item
 * @param  permissionHandler
 * @param  dateHelper
 * @param  user
 * @return {promise}
 */
that.getContactMessages = function (contact, getDataSource, permissionHandler, dateHelper, user, group) {
	var messages = [],
		MessagePriority = {
			Critical: 0,
			High: 1,
			Medium: 2,
			Low: 3,
		},
		perm = permissionHandler;

	// Maintenance message
	if (contact.kind == 'maintenance') {
		var message = 'User can <strong>maintenance / repair</strong>';
		messages.push({
			kind: 'maintenance',
			priority: MessagePriority.Low,
			message: message,
			contact: {
				kind: 'maintenance',
				name: '',
			},
		});
	}

	// Blocked message
	if (contact.status == 'blocked') {
		var message =
			'User was <strong>blocked</strong> ' +
			(contact.blocked ? "<span class='text-muted'>" + contact.blocked.fromNow() + '</span>' : '');
		messages.push({
			kind: 'blocked',
			priority: MessagePriority.High,
			message: message,
		});
	}

	// Sort by priority High > Low
	return Promise.resolve().then(function () {
		return messages.sort(function (a, b) {
			return a.priority - b.priority;
		});
	});
};

export default that;

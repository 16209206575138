import { CheckoutStatus, ReservationStatus } from '../../../@types/graphql.d';
import {
	CheckoutConflictItem,
	getCheckoutItemConflict,
	getReservationItemConflict,
	ItemConflict,
	ReservationConflictItem,
} from './get-item-conflict';

export enum KitConflict {
	AVAILABLE = 'AVAILABLE',
	UNAVAILABLE = 'UNAVAILABLE',
	RESTRICTED = 'RESTRICTED',
	PARTIALLY_AVAILABLE = 'PARTIALLY_AVAILABLE',
}

export type CheckoutConflictKit = { items: CheckoutConflictItem[] };
export type ReservationConflictKit = { items: ReservationConflictItem[] };

type ReservationContext = {
	bookingLocationId: string | null;
	bookingStatus: ReservationStatus | null;
	isMaintenanceUser?: boolean;
};

type CheckoutContext = {
	bookingId: string | null;
	bookingLocationId: string | null;
	bookingStatus: CheckoutStatus | null;
	isMaintenanceUser?: boolean;
};

export function getKitConflict(
	kit: ReservationConflictKit | CheckoutConflictKit,
	rule: 'reservation' | 'check-out',
	context: ReservationContext | CheckoutContext
): KitConflict;
export function getKitConflict<Rule extends 'reservation' | 'check-out'>(
	kit: Rule extends 'reservation' ? ReservationConflictKit : CheckoutConflictKit,
	rule: Rule,
	context: Rule extends 'reservation' ? ReservationContext : CheckoutContext
): KitConflict {
	switch (rule) {
		case 'check-out': {
			const currentKit = kit as CheckoutConflictKit;
			const currentContext = context as CheckoutContext;
			return getCheckoutKitConflict(currentKit, {
				bookingLocationId: currentContext.bookingLocationId,
				checkoutStatus: currentContext.bookingStatus,
				checkoutId: currentContext.bookingId,
				isMaintenanceUser: currentContext.isMaintenanceUser,
			});
		}
		case 'reservation': {
			const currentKit = kit as ReservationConflictKit;
			const currentContext = context as ReservationContext;

			return getReservationKitConflict(currentKit, {
				bookingLocationId: currentContext.bookingLocationId,
				bookingStatus: currentContext.bookingStatus,
				isMaintenanceUser: currentContext.isMaintenanceUser,
			});
		}
		default:
			throw new Error(`Unknown rule: ${rule}`);
	}
}

function getCheckoutKitConflict(
	kit: CheckoutConflictKit,
	context: {
		bookingLocationId: string | null;
		checkoutStatus: CheckoutStatus | null;
		checkoutId: string | null;
		isMaintenanceUser?: boolean;
	}
) {
	if (kit.items.some((item) => item.isRestricted)) {
		return KitConflict.RESTRICTED;
	}

	const areAllItemsConflicted = kit.items.every((item) => {
		const itemConflict = getCheckoutItemConflict(item, {
			checkoutLocation: context.bookingLocationId,
			checkoutStatus: context.checkoutStatus,
			checkoutId: context.checkoutId,
			isMaintenanceUser: context.isMaintenanceUser,
		});

		return itemConflict !== ItemConflict.AVAILABLE;
	});

	if (areAllItemsConflicted) {
		return KitConflict.UNAVAILABLE;
	}

	const someItemsAreConflicted = kit.items.some((item) => {
		const itemConflict = getCheckoutItemConflict(item, {
			checkoutLocation: context.bookingLocationId,
			checkoutStatus: context.checkoutStatus,
			checkoutId: context.checkoutId,
			isMaintenanceUser: context.isMaintenanceUser,
		});

		return itemConflict !== ItemConflict.AVAILABLE;
	});

	if (someItemsAreConflicted) {
		return KitConflict.PARTIALLY_AVAILABLE;
	}

	return KitConflict.AVAILABLE;
}

function getReservationKitConflict(
	kit: ReservationConflictKit,
	context: {
		bookingLocationId: string | null;
		bookingStatus: ReservationStatus | null;
		isMaintenanceUser?: boolean;
	}
) {
	if (kit.items.some((item) => item.isRestricted)) {
		return KitConflict.RESTRICTED;
	}

	const areAllItemsConflicted = kit.items.every((item) => {
		const itemConflict = getReservationItemConflict(item, {
			reservationLocation: context.bookingLocationId,
			reservationStatus: context.bookingStatus,
			isMaintenanceUser: context.isMaintenanceUser,
		});

		return itemConflict !== ItemConflict.AVAILABLE;
	});

	if (areAllItemsConflicted) {
		return KitConflict.UNAVAILABLE;
	}

	const someItemsAreConflicted = kit.items.some((item) => {
		const itemConflict = getReservationItemConflict(item, {
			reservationLocation: context.bookingLocationId,
			reservationStatus: context.bookingStatus,
			isMaintenanceUser: context.isMaintenanceUser,
		});

		return itemConflict !== ItemConflict.AVAILABLE;
	});

	if (someItemsAreConflicted) {
		return KitConflict.PARTIALLY_AVAILABLE;
	}

	return KitConflict.AVAILABLE;
}

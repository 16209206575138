import cr from '@cheqroom/core';
import Features from '@cheqroom/web/src/services/features';

/**
 * The PricingHelper should hide a lot of the ugliness of:
 *
 * - having different subscription plans
 *   - that have different periods
 *   - that are active / inactive
 *   - that can be overridden in limits
 *
 * @param spec
 * @constructor
 */
var PricingHelper = function (spec) {
	spec = spec || {};
	this.allowMonthly = spec.allowMonthly != null ? spec.allowMonthly : false;
	this.global = spec.global;
	this.custom = spec.custom;

	this.subscriptions = {}; // all subscriptions, even the ones we're not promoting actively
	// you can use central.subscriptions observable to get a live view on just the active ones
	this.features = features;
};

/**
 * Initializes the PricingHelper by fetching a list of active plans from the API
 * It can include a plan that is not actively promoted anymore, but that this group is subscribed to
 * @returns {*}
 */
PricingHelper.prototype.init = function () {
	var that = this;
	return this.global.central.getSubscriptions(this.custom, true).then(function (subscriptions) {
		that.subscriptions = subscriptions;
	});
};

// ================================
// ================================
// ================================

/**
 * PricingPlan is a wrapper around the different ways we get data for showing a plan
 * 1) The plans we got from API `getSubscription` with pricing options
 *    we want to show these e.g. in the upgrade or downgrade modal as possibilities
 * 2) The plan you're currently on, which comes from the `group.subscription`
 *    which has slightly other data formatting
 *
 *
 * @param spec
 * @constructor
 */
var PricingPlan = function (spec) {
	spec = spec || { limits: {} };
	this.id = spec._id || '';
	this.index = spec.index || 0;
	this.numItems = spec.limits.maxItemsSoft || 100; // maxItems for custom plans
	this.numLocations = spec.limits.maxLocations || 999;
	this.numBulkItems = spec.limits.maxBulkItems || 0;
	this.verify = spec.verify || ''; // the verification for custom plans
	this.recurlyPlanCode = spec.recurlyPlanCode || '';
	this.durationFriendly = spec.durationFriendly;
	this.custom = spec.custom || false;
	this.deprecated = spec.retired;
	this.contact = spec.contact;
	this.latest = spec.latest || false;

	this.family = spec.family;
	this.groupKey = spec.groupKey;

	this.priceMonthly = Math.round(spec.price / 100 / spec.durationMonths);
	this.price = Math.round(spec.price / 100);
	this.currency = spec.currency;

	this.kind = this.groupKey.replace(/\s+/gim, '_');
	this.name = spec.nameFriendly;

	this.raw = spec;
};

PricingPlan.prototype.getFriendlyPriceMonthly = function () {
	return '$' + this.priceMonthly.toLocaleString();
};

PricingPlan.getFriendlyPlanName = function (plan) {
	if (!plan || !plan.groupKey || plan.retired) return 'Legacy plan';

	return plan.groupKey.capitalize();
};

PricingPlan.prototype.getFriendlyPrice = function () {
	return '$' + this.price.toLocaleString();
};

PricingPlan.prototype.getFriendlyBillingPeriod = function () {
	return 'billed ' + (this.durationFriendly === 'bi-yearly' ? 'every 2 years' : this.durationFriendly);
};

/**
 * createPlanFromParams
 * Factory method to create a PricingPlan object from a planId and periodBilling
 * We'll overwrite some parameters that came from the query string
 */
PricingHelper.createPlanFromParams = function (allPlans) {
	// https://app.cheqroom.com/admin/account?tab=billing&plan=1215_cr_30&recurly_plan_code=1215_cr_300_yearly&price=300&period=yearly

	var planId = cr.common.getUrlParam('plan');
	var verify = cr.common.getUrlParam('verify');
	var items = cr.common.getUrlParam('items');

	var planData = allPlans.find(function (data) {
		return data._id == planId;
	});

	if (planData == null) {
		console.log('Unknown pricing plan ' + planId);
	}

	planData.contact = false;
	planData.verify = verify;
	planData.limits.maxItemsSoft = Number(items);

	return new PricingPlan(planData);
};

/**
 * Factory method to create a PricingPlan object from a plan we got via
 * `/groups/heavy_equipment_2_0/call/getSubscriptions?_v=2.5.0.00&_=1485986802491`
 * @param planId (our id, e.g. `1215_cr_90`)
 * @param planData
 * @param periodNice
 * @param periodBilling
 * @returns {PricingPlan}
 */
PricingHelper.createPlanFromGroupSubscription = function (planData) {
	return new PricingPlan(planData);
};

// ================================
// ================================
// ================================

/**
 * Gets a list of all possible periods
 * @returns {string[]}
 */
PricingHelper.prototype.getPeriods = function () {
	return this.allowMonthly ? ['monthly', 'yearly'] : ['yearly'];
};

/**
 * Gets a list of all plans
 * (which is the default list we got from the API)
 * but we return it in a list
 * @returns {*}
 */
PricingHelper.prototype.getPlans = function (filterCallback) {
	var plans = [];
	$.each(this.subscriptions, function (name, plan) {
		plan.name = name;

		if (filterCallback) {
			if (filterCallback(plan)) {
				plans.push(plan);
			}
		} else {
			plans.push(plan);
		}
	});
	return plans;
};

/**
 * Gets a list of all active plans
 * (which is the default list we got from the API)
 * but we return it in a list
 * @returns {*}
 */
PricingHelper.prototype.getActivePlans = function () {
	return this.getPlans(function (plan) {
		return plan.active;
	});
};

/**
 * Gets a friendly name for a certain plan name
 * @param plan (e.g. `starter`, `1215_cr_30`)
 * @param period (e.g. `monthly`, `yearly`)
 * @returns {string}
 */
PricingHelper.prototype.getFriendlyPlanName = function (plan, period) {
	var planData = this.subscriptions[plan],
		planPeriodData = planData[period],
		planTitle = planPeriodData.monthly; // even though we can ask for the name of a yearly plan,
	// we'll always use the monthly version in our name
	if (planData.retired) {
		// if we have an old plan with title, use the title instead
		planTitle = planData.title || planTitle;
	}
	return 'Cheqroom ' + planTitle + ' plan (' + period + ')';
};

/**
 * Gets a plan by its name, and optionally override how many numItems are allowed
 * @param plan (e.g. `starter`, `1215_cr_30`)
 * @param numItems (optional)
 * @returns {}
 */
PricingHelper.prototype.getPlan = function (plan, numItems) {
	var planData = this.subscriptions[plan];
	if (numItems) {
		planData = $.extend({}, planData);
		planData.numItems = numItems;
	}
	return planData;
};

/**
 * Gets a plan by its name, and optionally overrides from the stuff that is set in the group
 * @param plan
 * @param group
 * @returns {}
 */
PricingHelper.prototype.getPlanForGroup = function (plan, group) {
	var planData = this.subscriptions[plan];
	if (group) {
		planData = $.extend({}, planData);

		// Overwrite the items from the group.limits.maxItems
		if (group.limits && group.limits.maxItemsSoft) {
			planData.numItems = group.limits.maxItemsSoft;
		}

		// Overwrite the price from the group.price and group.subscription.period
		if (group.price && group.subscription && group.subscription.period) {
			var planPeriodData = planData[group.subscription.period];
			planPeriodData.USD = parseInt(group.price.split(' ').shift());
			planPeriodData.monthly = planPeriodData.USD;
			if (group.subscription.period == 'yearly') {
				planPeriodData.monthly = planPeriodData.USD / 12;
			}
		}
	}
	return planData;
};

PricingHelper.getIssueDetail = function (issue, data) {
	switch (issue.issue) {
		case 'subscription':
		case 'preview':
		case 'payment':
			return {
				id: issue.issue,
				description: issue.message,
			};
		case 'items':
			return {
				id: issue.issue,
				title: 'Items',
				description: 'Reduce the number of items to ' + data.numItems + ' or lower',
				help: 'https://help.cheqroom.com/en/articles/625145-removing-an-item-from-your-inventory',
			};
		case 'locations':
			return {
				id: issue.issue,
				title: 'Locations',
				description: 'Reduce the number of locations to ' + data.maxLocations + ' or lower',
				help: 'https://help.cheqroom.com/en/articles/720980-managing-inventory-locations',
			};
		case 'custom_user_roles':
			return {
				id: issue.issue,
				title: 'Custom User Roles',
				description:
					issue.count > 0
						? `${issue.count} ${'user'.pluralize(issue.count)} will receive the no_access role`
						: '',
			};
		case 'notifications_sms':
			return {
				id: issue.issue,
				title: 'SMS notifications',
				description: issue.count > 0 ? issue.count + ' will be disabled' : '',
			};
		case 'block_contacts':
			return {
				id: issue.issue,
				title: 'Block users',
				description: issue.count > 0 ? `${issue.count} ${'user'.pluralize(issue.count)} will be unblocked` : '',
			};
		case 'webhooks':
			return {
				id: issue.issue,
				title: 'Webhooks',
				description: issue.count > 0 ? issue.count + ' will be disabled' : '',
			};
		case 'user_sync':
			return {
				id: issue.issue,
				title: 'User sync',
				description: 'Synced users will be archived',
			};
		case 'kits':
			return {
				id: issue.issue,
				title: 'Kits',
				description: issue.count > 0 ? issue.count + ' will be removed' : '',
			};
		case 'reservations':
			return {
				id: issue.issue,
				title: 'Reservations',
				description: issue.count > 0 ? issue.count + ' will be archived' : '',
			};
		case 'orders':
			return {
				id: issue.issue,
				title: 'Check-outs',
				description: issue.count > 0 ? issue.count + ' will be archived' : '',
			};
		case 'custody':
			return {
				id: issue.issue,
				title: 'Custody',
				description:
					issue.count > 0
						? `${issue.count} ${'item'.pluralize(issue.count)} will be released from custody`
						: '',
			};
		case 'notifications_email':
			return {
				id: issue.issue,
				title: 'Email notifications',
				description: issue.count > 0 ? issue.count + ' will be disabled' : '',
			};
		case 'notifications_push':
			return {
				id: issue.issue,
				title: 'Push notifications',
				description: issue.count > 0 ? issue.count + ' will be disabled' : '',
			};
		case 'sso':
			return {
				id: issue.issue,
				title: 'SSO',
				description: 'Will be disabled',
			};
		case 'spotcheck':
			return {
				id: issue.issue,
				title: 'Spotcheck',
				description: issue.count > 0 ? issue.count + ' will be archived' : '',
			};
		case 'labels':
			return {
				id: issue.issue,
				title: 'Colored labels',
				description: issue.count > 0 ? issue.count + ' will be removed' : '',
			};
		case 'public_inventory':
			return {
				id: issue.issue,
				title: 'Equipment List',
				description: issue.count > 0 ? issue.count + ' will be removed' : '',
			};
		case 'import':
			return {
				id: issue.issue,
				title: 'Import',
			};
		case 'export':
			return {
				id: issue.issue,
				title: 'Export',
			};
		case 'ical':
			return {
				id: issue.issue,
				title: 'iCal',
			};
		case 'activity_feed':
			return {
				id: issue.issue,
				title: 'Activity feed',
			};
		case 'geo':
			return {
				id: issue.issue,
				title: 'Geo positions',
			};
		case 'depreciations':
			return {
				id: issue.issue,
				title: 'Depreciation',
			};
		case 'reservations_close':
			return {
				id: issue.issue,
				title: 'Close reservations',
			};
		case 'reservations_repeat':
			return {
				id: issue.issue,
				title: 'Repeat reservations',
			};
		case 'reporting':
			return {
				id: issue.issue,
				title: 'Reporting',
			};
		case 'generate_pdf':
			return {
				id: issue.issue,
				title: 'Generate pdf(s)',
			};
		case 'business_hours':
			return {
				id: issue.issue,
				title: 'Opening Hours',
			};
		case 'integrations_slack':
			return {
				id: issue.issue,
				title: 'Slack',
			};
		case 'support_chat':
			return {
				id: issue.issue,
				title: 'Support chat',
			};
		case 'booking_restrictions':
			return {
				id: issue.issue,
				title: 'Booking restrictions',
				description:
					issue.count > 0 ? `${issue.count} ${'item group'.pluralize(issue.count)} will be removed` : '',
			};
		case 'contact_groups':
			return {
				id: issue.issue,
				title: 'User groups',
				description:
					issue.count > 0
						? `${issue.count} ${'user group'.pluralize(
								issue.count
							)} will be removed. Please note that this will not delete any users linked to ${
								issue.count === 1 ? 'this user group' : 'these user groups'
							}`
						: '',
			};
		case 'equipment_picking':
			return {
				id: issue.issue,
				title: 'Equipment picking',
			};
		case 'holidays':
			return {
				id: issue.issue,
				title: 'Closed days',
				description: issue.count > 0 ? issue.count + ' will be removed' : '',
			};
		case 'location_business_hours':
			return {
				id: issue.issue,
				title: 'Location specific opening hours',
			};
		case 'workspaces':
			return {
				id: issue.issue,
				title: 'You have too many workspaces',
				description: `You need to remove ${issue.count} ${issue.count === 1 ? 'workspace' : 'workspaces'}.`,
			};
		case 'equipment_access':
			return {
				id: issue.issue,
				title: 'Equipment Access',
			};
		case 'bulk_items':
			return {
				id: issue.issue,
				title: 'Bulk items',
				description: `You need to manually retire ${issue.count} ${
					issue.count === 1 ? 'bulk item' : 'bulk items'
				} until you've reached ${data.maxBulkItems}.`,
			};
		default:
			return {
				id: issue.issue,
				title: 'Unknown',
				description: 'Unknown',
			};
	}
};

PricingHelper.getUpgradePlanForFeature = function (featureId, family, limits) {
	var feature =
		features.find(function (f) {
			return f.id == featureId;
		}) || {};

	var limitHelper = function (family, max) {
		if (family === 'business') {
			if (feature.essentials > max) {
				return 'essentials';
			} else if (feature.standard > max) {
				return 'standard';
			} else if (feature.plus > max) {
				return 'plus';
			} else {
				//unlimited
				return 'premium';
			}
		} else if (family === 'academic') {
			if (feature.academic_essentials > max) {
				return 'academic_essentials';
			} else if (feature.academic_starter > max) {
				return 'academic_starter';
			} else if (feature.academic_pro > max) {
				return 'academic_pro';
			} else if (feature.academic_master > max) {
				return 'academic_master';
			} else {
				//unlimited
				return 'academic_premium';
			}
		}

		return null;
	};

	if (feature.id === 'locations') {
		return limitHelper(family, limits.maxLocations);
	}
	if (feature.id === 'items') {
		return limitHelper(family, limits.maxItemsSoft);
	}

	if (family == 'business') {
		if (feature.essentials) {
			return 'essentials';
		} else if (feature.standard) {
			return 'standard';
		} else if (feature.plus) {
			return 'plus';
		} else if (feature.premium) {
			return 'premium';
		}
	} else if (family == 'academic') {
		if (feature.academic_essentials) {
			return 'academic_essentials';
		} else if (feature.academic_starter) {
			return 'academic_starter';
		} else if (feature.academic_pro) {
			return 'academic_pro';
		} else if (feature.academic_master) {
			return 'academic_master';
		}
	}

	return null;
};

PricingHelper.getLowerPlans = function (newPlan) {
	switch (newPlan) {
		case 'premium':
			return ['plus', 'standard', 'essentials'];
		case 'plus':
			return ['standard', 'essentials'];
		case 'standard':
			return ['essentials'];
		case 'academic_premium':
			return ['academic_essentials'];
	}

	return [];
};

PricingHelper.getFeaturesForPlan = function (newPlan) {
	return features
		.filter(function (f) {
			return (
				f[newPlan] &&
				PricingHelper.getLowerPlans(newPlan).every(function (p) {
					return !f[p];
				})
			);
		})
		.sort(function (a, b) {
			return b.weight - a.weight;
		});
};

var features = [];

PricingHelper.loadFeatures = function () {
	features = Features.features;

	return Promise.resolve(Features.features);
};

export default PricingHelper;

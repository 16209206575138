import { ReactElement } from 'react';

import useModal, { ModalProps } from '../modal/use-modal';

export interface OutgoingProps {
	isShown: boolean;
	onCancel: () => void | Promise<unknown>;
	onClose: () => void | Promise<unknown>;
	onConfirm: () => void | Promise<unknown>;
}

type RenderDialogFn<Props> = (outgoingProps: ModalProps & Props) => ReactElement;

export default function useConfirmation<
	Props = Record<string, unknown> & { onCancel?: () => void; onClose?: () => void },
>(
	renderDialog: RenderDialogFn<OutgoingProps & Props>
): readonly [
	(
		onConfirmation: () => void | Promise<unknown>,
		props?: Props & { onCancel?: () => void; onClose?: () => void }
	) => void,
	() => void,
] {
	const [show, hide] = useModal<OutgoingProps & Props>(renderDialog);

	const showDialog = (
		onConfirmation: () => void | Promise<unknown>,
		props = {} as Props & { onCancel?: () => void | Promise<unknown>; onClose?: () => void | Promise<unknown> }
	) => {
		const onConfirm = async () => {
			await onConfirmation();
			hide();
		};

		const onCancel = async () => {
			await props.onCancel?.();
			hide();
		};

		const onClose = async () => {
			await props.onClose?.();
			hide();
		};

		show({
			...props,
			isShown: true,
			onCancel,
			onClose,
			onConfirm,
		});
	};

	return [showDialog, hide] as const;
}

import tw, { css, styled } from 'twin.macro';

import { Props as TableProps } from './Table';

export type align = 'left' | 'center' | 'right';

export interface CellLayout {
	align?: align;
}

const getCellLayoutStyles = ({ align = 'left' }: CellLayout) => {
	return [
		// Horizontal content alignment
		align === 'left' && tw`text-left`,
		align === 'center' && tw`text-center`,
		align === 'right' && tw`text-right`,
	];
};

export const StyledTableWrapper = styled.div<TableProps>(({ embedded, scrollable }) => [
	tw`relative bg-white`,
	scrollable ? tw`overflow-x-auto w-full` : tw`overflow-x-visible min-w-min`,
	embedded
		? css`
				${StyledTableHeaderCell} {
					${tw`first:!rounded-tl-none last:!rounded-tr-none`}
				}
			`
		: tw`border border-gray-200 rounded-md`,
]);

export const StyledTableHeaderCell = styled.th<CellLayout>((layout) => [
	tw`
    w-auto py-3 px-4 leading-[18px] text-left font-semibold text-xs text-gray-500
    border-b border-gray-200 first:rounded-tl-md last:rounded-tr-md
  `,
	...getCellLayoutStyles(layout),
]);

export const StyledTableRow = styled.tr(({ onClick, tabIndex }) => [
	tw`w-auto`,
	css`
		td {
			${tw`border-b border-gray-200`}
		}

		&:last-of-type {
			td {
				${tw`border-none`}
			}
		}
	`,
	!!onClick && tw`hover:cursor-pointer`,
	tabIndex === 0 && tw` focus:ring-indigo-100 focus:ring-4 focus:outline-none focus:ring-inset`,
]);

export const StyledTableBody = styled.tbody(() => [tw`py-2 px-4`]);

export const StyledTableCell = styled.td<CellLayout>((layout) => [
	tw`p-4 font-normal text-sm`,
	...getCellLayoutStyles(layout),
]);

export const StyledTableHead = styled.thead<{ disabled?: boolean }>(({ disabled }) => [
	tw`bg-gray-50`,
	disabled ? tw`opacity-50 pointer-events-none` : '',
]);

export const StyledSortableButton = styled.button<{ sorted: boolean }>(({ sorted }) => [
	tw`hover:bg-gray-200 rounded py-[2px] px-2 -mx-2 flex gap-2 items-center outline-none focus:outline-none`,

	css`
		svg {
			${sorted ? tw`text-gray-500` : tw`text-gray-300`}
		}

		&:hover {
			svg {
				${!sorted && tw`!text-gray-400`}
			}
		}
	`,
]);

import { useState } from 'react';

type StorageType = 'localStorage' | 'sessionStorage';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStorage = <T>(storageType: StorageType, key: string, initialValue?: T) => {
	const storageHandler = window[storageType];

	const [storage, setStorage] = useState(() => {
		let value;
		try {
			const item = storageHandler.getItem(key);
			const parsedItem = item ? (JSON.parse(item) as T) : null;

			value = parsedItem ?? initialValue;
			return value;
		} catch {
			return initialValue;
		} finally {
			storageHandler.setItem(key, JSON.stringify(value));
		}
	});

	const updateStorage = (value: T) => {
		try {
			storageHandler.setItem(key, JSON.stringify(value));
			setStorage(value);
		} catch {
			// ignore
		}
	};

	const clearStorage = () => {
		try {
			storageHandler.clear();
			setStorage(undefined);
		} catch {
			// ignore
		}
	};

	return [storage, updateStorage, clearStorage] as const;
};

export const capitalize = (value) => {
	if (!value) return '';

	return value
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
};

export const getCategoryById = (categoryId, categories) =>
	categories.find((category) => category._id === categoryId || category._id === `cheqroom.types.item.${categoryId}`);

export const getChildrenForCategoryId = (categoryId, categories) =>
	categories.filter((category) => category.parent === categoryId);

export const getLevelForCategoryId = (categoryId, categories) => {
	const currentCategory = getCategoryById(categoryId, categories);

	const parent = getCategoryById(currentCategory.parent, categories);

	if (parent) {
		return getLevelForCategoryId(parent._id, categories) + 1;
	}

	return 1;
};

export const getLeveledTitleForCategoryId = (categoryId, categories, seperator = ' > ') => {
	const currentCategory = getCategoryById(categoryId, categories);

	const parent = getCategoryById(currentCategory.parent, categories);

	if (parent) {
		return `${getLeveledTitleForCategoryId(parent._id, categories)}${seperator}${capitalize(currentCategory.name)}`;
	}

	return capitalize(currentCategory.name);
};

export const getAllDescendantIdsForCategoryId = (categoryId, categories) => {
	const category = getCategoryById(categoryId, categories);
	if (!category) {
		return [];
	}

	const children = category.children;

	if (!children || children.length === 0) {
		return [categoryId];
	}

	const childIds = children.map((child) => getAllDescendantIdsForCategoryId(child._id, categories));

	return [categoryId, ...childIds.flat()];
};

export const getAllParentIdsForCategoryId = (categoryId, categories) => {
	const category = getCategoryById(categoryId, categories);
	if (!category) {
		return [];
	}

	const parent = getCategoryById(category.parent, categories);
	if (!parent) {
		return [categoryId];
	}

	const parentIds = getAllParentIdsForCategoryId(parent._id, categories);

	return [...parentIds.flat(), categoryId];
};

export const formatCategories = (categories) => {
	const itemCategoryDict = {};

	var category = null,
		indent = '&nbsp;&nbsp;&nbsp;';

	for (var i = 0; i < categories.length; i++) {
		category = categories[i];
		category.level = getLevelForCategoryId(category._id, categories);
		category.children = getChildrenForCategoryId(category._id, categories);
		category.hasChildren = category.children.length > 0;

		// Indent each level with some spaces
		// Heavy Equipment
		//    Cranes
		category.title = category.name;

		for (var j = 0; j < category.level - 1; j++) {
			category.title = indent + category.title;
		}

		// Leveled title
		// f.e: Heavy Equipment > Cranes
		category.levelTitle = getLeveledTitleForCategoryId(category._id, categories);

		itemCategoryDict[category._id] = category;
	}

	return itemCategoryDict;
};

export default {
	capitalize,
	formatCategories,
	getLeveledTitleForCategoryId,
	getAllDescendantIdsForCategoryId,
	getAllParentIdsForCategoryId,
	getCategoryById,
};

import api from './api';
import Transaction from './transaction';
import Conflict from './conflict';
import moment from 'moment';
import { isEmptyObject } from './common/utils';

// Allow overriding the ctor during inheritance
// http://stackoverflow.com/questions/4152931/javascript-inheritance-call-super-constructor-or-use-prototype-chain
var tmp = function () {};
tmp.prototype = Transaction.prototype;

/**
 * @name Reservation
 * @class Reservation
 * @constructor
 * @extends Transaction
 * @propery {Array}  conflicts               - The reservation conflicts
 */
var Reservation = function (opt) {
	var spec = Object.assign(
		{
			crtype: 'cheqroom.types.reservation',
			_fields: ['*'],
		},
		opt
	);
	Transaction.call(this, spec);

	this.conflicts = [];
	this.order = null;
};

Reservation.prototype = new tmp();
Reservation.prototype.constructor = Reservation;

/**
 * Overwrite how we get a min date for reservation
 * Min date is a timeslot after now
 */
Reservation.prototype.getMinDateFrom = function () {
	return this.getNextTimeSlot();
};

/**
 * Checks if from date is valid for open/creating reservation
 * otherwise return always true
 *
 * @return {Boolean}
 */
Reservation.prototype.isValidFromDate = function () {
	var from = this.from,
		status = this.status,
		now = this.getNow();

	if (status == 'creating' || status == 'open') {
		return from != null && from.isAfter(now);
	}

	return true;
};

/**
 * Checks if to date is valid for open/creating reservation
 * otherwise return always true
 *
 * @return {Boolean}
 */
Reservation.prototype.isValidToDate = function () {
	var from = this.from,
		to = this.to,
		status = this.status,
		now = this.getNow();

	if (status == 'creating' || status == 'open') {
		return to != null && to.isAfter(from) && to.isAfter(now);
	}

	return true;
};

/**
 * Checks if the reservation can be booked
 * @method
 * @name Reservation#canReserve
 * @returns {boolean}
 */
Reservation.prototype.canReserve = function () {
	return (
		this.status == 'creating' &&
		this.location &&
		this.contact &&
		this.contact.status == 'active' &&
		this.isValidFromDate() &&
		this.isValidToDate() &&
		this.items &&
		this.items.length
	);
};

/**
 * Checks if the reservation can be undone (based on status)
 * @method
 * @name Reservation#canUndoReserve
 * @returns {boolean}
 */
Reservation.prototype.canUndoReserve = function () {
	return this.status == 'open';
};

/**
 * Checks if the reservation can be cancelled
 * @method
 * @name Reservation#canCancel
 * @returns {boolean}
 */
Reservation.prototype.canCancel = function () {
	return this.status == 'open';
};

/**
 * Checks if the reservation can be closed
 * @method
 * @name Reservation#canClose
 * @returns {boolean}
 */
Reservation.prototype.canClose = function () {
	return this.status == 'open';
};

/**
 * Checks if the reservation can be edited
 * @method
 * @name Reservation#canEdit
 * @returns {boolean}
 */
Reservation.prototype.canEdit = function () {
	return this.status == 'creating';
};

/**
 * Checks if the reservation can be deleted
 * @method
 * @name Reservation#canDelete
 * @returns {boolean}
 */
Reservation.prototype.canDelete = function () {
	return this.status == 'creating';
};

/**
 * Checks if items can be added to the reservation (based on status)
 * @method
 * @name Reservation#canAddItems
 * @returns {boolean}
 */
Reservation.prototype.canAddItems = function () {
	return this.status == 'creating';
};

/**
 * Checks if items can be removed from the reservation (based on status)
 * @method
 * @name Reservation#canRemoveItems
 * @returns {boolean}
 */
Reservation.prototype.canRemoveItems = function () {
	return this.status == 'creating';
};

/**
 * Checks if items can be swapped in the reservation (based on status)
 * @method
 * @name Reservation#canSwapItems
 * @returns {boolean}
 */
Reservation.prototype.canSwapItems = function () {
	return this.status == 'creating' || this.status == 'open';
};

/**
 * Checks if the reservation can be turned into an order
 * @method
 * @name Reservation#canMakeOrder
 * @returns {boolean}
 */
Reservation.prototype.canMakeOrder = function () {
	// Only reservations that meet the following conditions can be made into an order
	// - status: open
	// - to date: is in the future
	// - items: all are available
	if (
		this.status == 'open' &&
		this.contact &&
		this.contact.status == 'active' &&
		this.to != null &&
		this.to.isAfter(this.getNow())
	) {
		var unavailable = this._getUnavailableItems();
		return isEmptyObject(unavailable);
	} else {
		return false;
	}
};

/**
 * Checks if the reservation has an order linked to it
 * @method
 * @name Reservation#canGoToOrder
 * @returns {boolean}
 */
Reservation.prototype.canGoToOrder = function () {
	return this.order != null;
};

/**
 * Checks if the reservation can be into recurring reservations (based on status)
 * @method
 * @name Reservation#canReserveRepeat
 * @returns {boolean}
 */
Reservation.prototype.canReserveRepeat = function () {
	return (
		(this.status == 'open' || this.status == 'closed' || this.status == 'closed_manually') &&
		this.contact &&
		this.contact.status == 'active'
	);
};

/**
 * Checks if we can generate a document for this reservation (based on status)
 * @name Reservation#canGenerateDocument
 * @returns {boolean}
 */
Reservation.prototype.canGenerateDocument = function () {
	return this.status == 'open' || this.status == 'closed';
};

//
// Document overrides
//
Reservation.prototype._toJson = function (options) {
	var data = Transaction.prototype._toJson.call(this, options);
	data.fromDate = this.from != null ? this.from.toJSONDate() : 'null';
	data.toDate = this.to != null ? this.to.toJSONDate() : 'null';
	return data;
};

Reservation.prototype._fromJson = function (data, options) {
	var that = this;

	// Already set the from, to and due dates
	// Transaction._fromJson might need it during _getConflicts
	that.from = data.fromDate == null || data.fromDate == 'null' ? null : data.fromDate;
	that.to = data.toDate == null || data.toDate == 'null' ? null : data.toDate;
	that.due = null;
	that.order = data.order || null;
	that.repeatId = data.repeatId || null;
	that.repeatFrequency = data.repeatFrequency || '';

	return Transaction.prototype._fromJson.call(this, data, options).then(function () {
		return data;
	});
};

Reservation.prototype.visibleConflicts = function (conflicts, showAllConflicts) {
	if (conflicts.length == 0 || !this.from) return conflicts;

	var from = this.from,
		to = this.to,
		now = moment();

	if (
		showAllConflicts ||
		(from != null && to != null && (now.isBetween(from, to) || from.isBefore(now) || to.isBefore(now)))
	)
		return conflicts;

	// Don't show order conflicts if reservation is not due now
	return conflicts.filter(function (conflict) {
		// Show conflict is:
		//  - Not order conflict
		// OR
		//  - Order conflict that is between current date range
		return (
			conflict.kind != 'order' ||
			(conflict.kind == 'order' &&
				from != null &&
				to != null &&
				conflict.fromDate &&
				conflict.toDate &&
				(from.isBetween(conflict.fromDate, conflict.toDate, null, '[]') ||
					to.isBetween(conflict.fromDate, conflict.toDate, null, '[]')))
		);
	});
};

//
// Base overrides
//

//
// Transaction overrides
//
/**
 * Gets a list of Conflict objects
 * used during Transaction._fromJson
 * @returns {promise}
 * @private
 */
Reservation.prototype._getConflicts = function () {
	var that = this,
		conflicts = [],
		conflict = null;

	// Reservations can only have conflicts
	// when status open OR creating and we have a (location OR (from AND to)) AND at least 1 item
	// So we'll only hit the server if there are possible conflicts.
	//
	// However, some conflicts only start making sense when the reservation fields filled in
	// When you don't have any dates set yet, it makes no sense to show "checked out" conflict
	if (
		['creating', 'open'].indexOf(this.status) != -1 &&
		this.items &&
		this.items.length &&
		(this.location ||
			(this.from && this.to) ||
			this.items.filter(function (it) {
				return it.canReserve !== 'available';
			}))
	) {
		var locId = this.location ? this._getId(this.location) : null;
		var showOrderConflicts = this.from && this.to && this.status == 'open';
		var showLocationConflicts = locId != null;
		var showStatusConflicts = true; // always show conflicts for expired, custody
		var showPermissionConflicts = true; // always show permission conflicts (canReserve)
		var showFlagConflicts = !(
			this.contact != null &&
			this.contact.status == 'active' &&
			this.contact.kind == 'maintenance'
		); // always show flag conflicts except for maintenance contact (flag unavailable settings)

		this.abortConflictsController = new AbortController();

		return this.ds
			.call(this.id, 'getConflicts', null, null, null, { abortController: this.abortConflictsController })
			.then(function (cnflcts) {
				cnflcts = cnflcts || [];

				// Now we have 0 or more conflicts for this reservation
				// run over the items again and find the conflict for each item
				that.items.forEach(function (item) {
					conflict = cnflcts.find(function (conflictObj) {
						return conflictObj.item == item._id;
					});

					if (conflict && conflict.kind == 'flag' && !showFlagConflicts) {
						conflict = null;
					}

					// Does this item have a server-side conflict?
					if (conflict) {
						var kind = conflict.kind || '';
						kind = kind || (conflict.order ? 'order' : '');
						kind = kind || (conflict.reservation ? 'reservation' : '');

						// skip to next
						if (kind == 'flag' && !showFlagConflicts) return true;

						conflicts.push(
							new Conflict({
								kind: kind,
								item: item._id,
								itemName: item.name,
								doc: conflict.conflictsWith,
								fromDate: conflict.fromDate,
								toDate: conflict.toDate,
								locationCurrent: conflict.locationCurrent,
								locationDesired: conflict.locationDesired,
							})
						);
					} else {
						if (showFlagConflicts && that.unavailableFlagHelper(item.flag)) {
							conflicts.push(
								new Conflict({
									kind: 'flag',
									item: item._id,
									flag: item.flag,
									doc: item.order,
								})
							);
						} else if (showPermissionConflicts && item.canReserve == 'unavailable_allow') {
							conflicts.push(
								new Conflict({
									kind: 'not_allowed_reservation',
									item: item._id,
									itemName: item.name,
								})
							);
						} else if (
							that.status == 'open' &&
							showPermissionConflicts &&
							item.canOrder == 'unavailable_allow'
						) {
							conflicts.push(
								new Conflict({
									kind: 'not_allowed_order',
									item: item._id,
									itemName: item.name,
								})
							);
						} else if (showStatusConflicts && item.status == 'expired') {
							conflicts.push(
								new Conflict({
									kind: 'expired',
									item: item._id,
									itemName: item.name,
									doc: item.order,
								})
							);
						} else if (showStatusConflicts && item.status == 'in_custody') {
							conflicts.push(
								new Conflict({
									kind: 'custody',
									item: item._id,
									itemName: item.name,
									doc: item.order,
								})
							);
						} else if (showOrderConflicts && !that.isAvailableForCheckout(item)) {
							conflicts.push(
								new Conflict({
									kind: 'order',
									item: item._id,
									itemName: item.name,
									doc: item.order,
								})
							);
						} else if (showLocationConflicts && item.location != locId) {
							conflicts.push(
								new Conflict({
									kind: 'location',
									item: item._id,
									itemName: item.name,
									locationCurrent: item.location,
									locationDesired: locId,
									doc: item.order,
								})
							);
						}
					}
				});

				return conflicts;
			});
	}

	return Promise.resolve(conflicts);
};

/**
 * setFromDate
 * The from date must be:
 * - bigger than minDate
 * - smaller than maxDate
 * - at least one interval before .to date (if set)
 * @method
 * @name Reservation#setFromDate
 * @param date
 * @param skipRead
 * @returns {*}
 */
Reservation.prototype.setFromDate = function (date, skipRead) {
	if (this.status != 'creating') {
		throw new api.ApiUnprocessableEntity('Cannot set reservation from date, status is ' + this.status);
	}

	var that = this;
	var dateHelper = this._getDateHelper();
	var interval = dateHelper.roundMinutes;

	// TODO: Should never get here
	// Must be at least 1 interval before to date, if it's already set
	if (that.to && that.to.diff(date, 'minutes') < interval) {
		throw new api.ApiUnprocessableEntity(
			'Cannot set reservation from date, after (or too close to) to date ' + that.to.toJSONDate()
		);
	}

	that.from = date;

	//If reservation doesn't exist yet, we set from date in create call
	//otherwise use setFromDate to update transaction
	if (!that.existsInDb()) {
		return that._createTransaction(skipRead);
	} else {
		return that._doApiCall({
			method: 'setFromDate',
			params: { fromDate: date },
			skipRead: skipRead,
		});
	}
};

/**
 * Clear the reservation from date
 * @method
 * @name Reservation#clearFromDate
 * @param skipRead
 * @returns {*}
 */
Reservation.prototype.clearFromDate = function (skipRead) {
	if (this.status != 'creating') {
		throw new api.ApiUnprocessableEntity('Cannot clear reservation from date, status is ' + this.status);
	}

	this.from = null;
	return this._doApiCall({ method: 'clearFromDate', skipRead: skipRead });
};

/**
 * setToDate
 * The to date must be:
 * - bigger than minDate
 * - smaller than maxDate
 * - at least one interval after the .from date (if set)
 * @method
 * @name Reservation#setToDate
 * @param date
 * @param skipRead
 * @returns {*}
 */
Reservation.prototype.setToDate = function (date, skipRead) {
	// Cannot change the to-date of a reservation that is not in status "creating"
	if (this.status != 'creating') {
		throw new api.ApiUnprocessableEntity('Cannot set reservation to date, status is ' + this.status);
	}

	// The to date must be:
	// 1) at least 30 minutes into the feature
	// 2) at least 15 minutes after the from date (if set)
	var that = this;
	var dateHelper = this._getDateHelper();
	var interval = dateHelper.roundMinutes;

	if (that.from && that.from.diff(date, 'minutes') > interval) {
		throw new api.ApiUnprocessableEntity(
			'Cannot set reservation to date, before (or too close to) to date ' + that.from.toJSONDate()
		);
	}

	that.to = date;

	//If reservation doesn't exist yet, we set to date in create call
	//otherwise use setToDate to update transaction
	if (!that.existsInDb()) {
		return that._createTransaction(skipRead);
	} else {
		return that._doApiCall({ method: 'setToDate', params: { toDate: date }, skipRead: skipRead });
	}
};

/**
 * Clears the reservation to date
 * @method
 * @name Reservation#clearToDate
 * @param skipRead
 * @returns {*}
 */
Reservation.prototype.clearToDate = function (skipRead) {
	if (this.status != 'creating') {
		throw new api.ApiUnprocessableEntity('Cannot clear reservation to date, status is ' + this.status);
	}

	this.to = null;
	return this._doApiCall({ method: 'clearToDate', skipRead: skipRead });
};

//
// Business logic calls
//

/**
 * Searches for Items that are available for this reservation
 * @method
 * @name Reservation#searchItems
 * @param params
 * @param useAvailabilies (should always be true, we only use this flag for Order objects)
 * @param onlyUnbooked
 * @returns {*}
 */
Reservation.prototype.searchItems = function (params, useAvailabilies, onlyUnbooked, skipItems) {
	return this._searchItems(params, null, true, onlyUnbooked, skipItems || this.items);
};

/**
 * Books the reservation and sets the status to `open`
 * @method
 * @name Reservation#reserve
 * @param skipRead
 * @param skipErrorHandling
 * @returns {*}
 */
Reservation.prototype.reserve = function (skipRead, skipErrorHandling) {
	var that = this;
	return this._doApiCall({ method: 'reserve', skipRead: skipRead }).then(
		function (resp) {
			return resp;
		},
		function (err) {
			if (!skipErrorHandling) {
				if (err && err.code == 422 && err.opt && err.opt.detail.indexOf('reservation has status open') != -1) {
					return that.get();
				}
			}

			//IMPORTANT
			//Need to return a new deferred reject because otherwise
			//done would be triggered in parent deferred
			return Promise.reject(err);
		}
	);
};

/**
 * Unbooks the reservation and sets the status to `creating` again
 * @method
 * @name Reservation#undoReserve
 * @param skipRead
 * @param skipErrorHandling
 * @returns {*}
 */
Reservation.prototype.undoReserve = function (skipRead, skipErrorHandling) {
	var that = this;
	return this._doApiCall({ method: 'undoReserve', skipRead: skipRead }).then(
		function (resp) {
			return resp;
		},
		function (err) {
			if (!skipErrorHandling) {
				if (
					err &&
					err.code == 422 &&
					err.opt &&
					err.opt.detail.indexOf('reservation has status creating') != -1
				) {
					return that.get();
				}
			}

			//IMPORTANT
			//Need to return a new deferred reject because otherwise
			//done would be triggered in parent deferred
			return Promise.reject(err);
		}
	);
};

/**
 * Cancels the booked reservation and sets the status to `cancelled`
 * @method
 * @name Reservation#cancel
 * @param message
 * @param skipRead
 * @param skipErrorHandling
 * @returns {*}
 */
Reservation.prototype.cancel = function (message, skipRead, skipErrorHandling) {
	var that = this;
	return this._doApiCall({ method: 'cancel', params: { message: message || '' }, skipRead: skipRead }).then(
		function (resp) {
			return resp;
		},
		function (err) {
			if (!skipErrorHandling) {
				if (
					err &&
					err.code == 422 &&
					err.opt &&
					err.opt.detail.indexOf('reservation has status cancelled') != -1
				) {
					return that.get();
				}
			}

			//IMPORTANT
			//Need to return a new deferred reject because otherwise
			//done would be triggered in parent deferred
			return Promise.reject(err);
		}
	);
};

/**
 * Cancels repeated reservations and sets the status to `cancelled`
 * @method
 * @name Reservation#cancelRepeat
 * @param message
 * @param skipRead
 * @param skipErrorHandling
 * @returns {*}
 */
Reservation.prototype.cancelRepeat = function (message, skipRead, skipErrorHandling) {
	var that = this;
	return this._doApiCall({ method: 'cancelRepeat', params: { message: message || '' }, skipRead: skipRead }).then(
		function (resp) {
			return resp;
		},
		function (err) {
			if (!skipErrorHandling) {
				if (
					err &&
					err.code == 422 &&
					err.opt &&
					err.opt.detail.indexOf('reservation has status cancelled') != -1
				) {
					return that.get();
				}
			}

			//IMPORTANT
			//Need to return a new deferred reject because otherwise
			//done would be triggered in parent deferred
			return Promise.reject(err);
		}
	);
};

/**
 * Closes the booked reservation and sets the status to `closed_manually`
 * @method
 * @name Reservation#close
 * @param message
 * @param skipRead
 * @param skipErrorHandling
 * @returns {*}
 */
Reservation.prototype.close = function (message, skipRead, skipErrorHandling) {
	var that = this;
	return this._doApiCall({ method: 'close', params: { message: message || '' }, skipRead: skipRead }).then(
		function (resp) {
			return resp;
		},
		function (err) {
			if (!skipErrorHandling) {
				if (
					err &&
					err.code == 422 &&
					err.opt &&
					err.opt.detail.indexOf('reservation has status closed_manually') != -1
				) {
					return that.get();
				}
			}

			//IMPORTANT
			//Need to return a new deferred reject because otherwise
			//done would be triggered in parent deferred
			return Promise.reject(err);
		}
	);
};

/**
 * Uncloses the reservation and sets the status to `open` again
 * @method
 * @name Reservation#undoClose
 * @param skipRead
 * @param skipErrorHandling
 * @returns {*}
 */
Reservation.prototype.undoClose = function (skipRead, skipErrorHandling) {
	var that = this;
	return this._doApiCall({ method: 'undoClose', skipRead: skipRead }).then(
		function (resp) {
			return resp;
		},
		function (err) {
			if (!skipErrorHandling) {
				if (err && err.code == 422 && err.opt && err.opt.detail.indexOf('reservation has status open') != -1) {
					return that.get();
				}
			}

			//IMPORTANT
			//Need to return a new deferred reject because otherwise
			//done would be triggered in parent deferred
			return Promise.reject(err);
		}
	);
};

/**
 * Turns an open reservation into an order (which still needs to be checked out)
 * @method
 * @name Reservation#makeOrder
 * @param skipErrorHandling
 * @returns {*}
 */
Reservation.prototype.makeOrder = function (bookable_items, skipErrorHandling) {
	var that = this;
	return this._doApiCall({
		method: 'pickOrderWithBookableItems',
		skipRead: true,
		params: {
			bookable_items: bookable_items.map(({ itemId, quantity }) => ({
				item: itemId,
				quantity,
			})),
		},
		usePost: true,
	}).then(
		function (resp) {
			return resp;
		},
		function (err) {
			if (!skipErrorHandling) {
				if (
					err &&
					err.code == 422 &&
					err.opt &&
					err.opt.detail.indexOf('reservation has status closed') != -1
				) {
					return that.get().then(function (resp) {
						var orderId = that._getId(resp.order);

						// need to return fake order object
						return { _id: orderId };
					});
				}
			}

			//IMPORTANT
			//Need to return a new deferred reject because otherwise
			//done would be triggered in parent deferred
			return Promise.reject(err);
		}
	);
};

/**
 * Generates a PDF document for the reservation
 * @method
 * @name Reservation#generateDocument
 * @param {string} template id
 * @param {string} signature (base64)
 * @param {bool} skipRead
 * @returns {promise}
 */
Reservation.prototype.generateDocument = function (template, signature, skipRead) {
	return this._doApiLongCall({
		method: 'generateDocument',
		params: { template: template, signature: signature },
		skipRead: skipRead,
	});
};

/**
 * Checks if reservation can be reserved again
 * @method
 * @name Reservation#canReserveAgain
 * @param skipRead
 * @returns {promise}
 */
Reservation.prototype.canReserveAgain = function () {
	var params = {
		_fields: 'null', //hack
	};
	return this._doApiLongCall({ method: 'canReserveAgain', params: params, skipRead: true });
};

/**
 * Creates a new, incomplete reservation with the same info
 * as the original reservation but other fromDate, toDate
 * Important; the response will be another Reservation document!
 * @method
 * @name Reservation#reserveAgain
 * @param fromDate
 * @param toDate
 * @param customer
 * @param location
 * @param skipRead
 * @returns {promise}
 */
Reservation.prototype.reserveAgain = function (params, skipRead) {
	return this._doApiLongCall({ method: 'reserveAgain', params: params, skipRead: skipRead });
};

/**
 * Creates a list of new reservations with `open` status
 * as the original reservation but other fromDate, toDate
 * Important; the response will be a list of other Reservation documents
 * @method
 * @name Reservation#reserveRepeat
 * @param frequency (days, weeks, weekdays, months)
 * @param customer
 * @param location
 * @param until
 * @returns {promise}
 */
Reservation.prototype.reserveRepeat = function (frequency, until, customer, location) {
	return this._doApiLongCall({
		method: 'reserveRepeat',
		params: {
			frequency: frequency,
			until: until,
			customer: customer,
			location: location,
		},
		skipRead: true,
	}); // response is a array of reservations
};

Reservation.prototype._getUnavailableItems = function () {
	var unavailable = {};

	if (this.status == 'open' && this.location && this.items != null && this.items.length > 0) {
		this.items.forEach(function (item) {
			if (item.status != 'available') {
				unavailable['status'] = unavailable['status'] || [];
				unavailable['status'].push(item._id);
			}
		});
	}

	return unavailable;
};

export default Reservation;

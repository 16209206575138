import debounce from 'viewmodels/debounce';
import Mousetrap from 'mousetrap';
import analytics from '@cheqroom/web/src/services/analytics';

//https://craig.is/killing/mice

const originalHandleKey = Mousetrap.prototype.handleKey;

//	Add small delay so keyboard shortcuts can’t potentially trigger when scanner triggers key events in quick succession
Mousetrap.prototype.handleKey = debounce(function (...args) {
	return originalHandleKey.call(this, ...args);
}, 50);

export default {
	init: function (options) {
		var global = options.global,
			app = options.app,
			perm = global.getPermissionHandler();

		// Focus global search
		Mousetrap.bind('/', function () {
			$('#navbar-search input').focus();

			return false;
		});

		// New reservation
		if (perm.hasReservationPermission('read') && perm.hasReservationPermission('create')) {
			Mousetrap.bind('+ r', function () {
				app.router.navigate('/reservations/new');

				analytics.track('Reservation Starting', 'Keyboard Shortcut');

				return false;
			});
		}

		// New check-out
		if (perm.hasCheckoutPermission('read') && perm.hasCheckoutPermission('create')) {
			Mousetrap.bind('+ c', function () {
				app.router.navigate('/check-outs/new');

				analytics.track('Check-out Starting', 'Keyboard Shortcut');

				return false;
			});
		}

		// New item
		if (perm.hasItemPermission('create')) {
			Mousetrap.bind('+ i', function () {
				app.router.navigate('/items/new?redirect=false');

				analytics.track('Item Adding', 'Keyboard Shortcut');

				return false;
			});
		}

		// New kit
		if (perm.hasKitPermission('create')) {
			Mousetrap.bind('+ k', function () {
				app.router.navigate('/kits/new');

				analytics.track('Kit Adding', 'Keyboard Shortcut');

				return false;
			});
		}

		// New contact
		if (perm.hasContactPermission('create')) {
			Mousetrap.bind('+ o', function () {
				app.router.navigate('/users/new');

				analytics.track('User Adding', 'Keyboard Shortcut');

				return false;
			});
		}

		// Prev page
		Mousetrap.bind(['left', 'j'], function () {
			$('.prev-page').trigger('click');

			return false;
		});

		// Next page
		Mousetrap.bind(['right', 'k'], function () {
			$('.next-page').trigger('click');

			return false;
		});

		// Edit editable title on page
		Mousetrap.bind('t', function () {
			$('h3.editable').trigger('click');

			return false;
		});
	},
	reset: function () {
		Mousetrap.reset();
	},
};

import { FC } from 'react';

export const Barcode: FC = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3 4C3.55228 4 4 4.44772 4 5V19C4 19.5523 3.55228 20 3 20C2.44772 20 2 19.5523 2 19V5C2 4.44772 2.44772 4 3 4ZM7 4C7.55228 4 8 4.44772 8 5V19C8 19.5523 7.55228 20 7 20C6.44772 20 6 19.5523 6 19V5C6 4.44772 6.44772 4 7 4ZM10 4C10.5523 4 11 4.44772 11 5V19C11 19.5523 10.5523 20 10 20C9.44772 20 9 19.5523 9 19V5C9 4.44772 9.44772 4 10 4ZM13 4C13.5523 4 14 4.44772 14 5V19C14 19.5523 13.5523 20 13 20C12.4477 20 12 19.5523 12 19V5C12 4.44772 12.4477 4 13 4ZM18 4C18.5523 4 19 4.44772 19 5V19C19 19.5523 18.5523 20 18 20C17.4477 20 17 19.5523 17 19V5C17 4.44772 17.4477 4 18 4ZM21 4C21.5523 4 22 4.44772 22 5V19C22 19.5523 21.5523 20 21 20C20.4477 20 20 19.5523 20 19V5C20 4.44772 20.4477 4 21 4Z"
			fill="currentColor"
		/>
	</svg>
);

export default [
	{
		symbol: '$',
		name: 'US Dollar',
		code: 'USD',
	},
	{
		symbol: '$',
		name: 'Canadian Dollar',
		code: 'CAD',
	},
	{
		symbol: '€',
		name: 'Euro',
		code: 'EUR',
	},
	{
		symbol: 'د.إ',
		name: 'United Arab Emirates Dirham',
		code: 'AED',
	},
	{
		symbol: '؋',
		name: 'Afghan Afghani',
		code: 'AFN',
	},
	{
		symbol: 'L',
		name: 'Albanian Lek',
		code: 'ALL',
	},
	{
		symbol: 'AMD',
		name: 'Armenian Dram',
		code: 'AMD',
	},
	{
		symbol: '$',
		name: 'Argentine Peso',
		code: 'ARS',
	},
	{
		symbol: '$',
		name: 'Australian Dollar',
		code: 'AUD',
	},
	{
		symbol: '₼',
		name: 'Azerbaijani Manat',
		code: 'AZN',
	},
	{
		symbol: 'KM',
		name: 'Bosnia-Herzegovina Convertible Mark',
		code: 'BAM',
	},
	{
		symbol: '৳',
		name: 'Bangladeshi Taka',
		code: 'BDT',
	},
	{
		symbol: 'лв',
		name: 'Bulgarian Lev',
		code: 'BGN',
	},
	{
		symbol: '.د.ب',
		name: 'Bahraini Dinar',
		code: 'BHD',
	},
	{
		symbol: 'FBu',
		name: 'Burundian Franc',
		code: 'BIF',
	},
	{
		symbol: '$',
		name: 'Brunei Dollar',
		code: 'BND',
	},
	{
		symbol: 'Bs.',
		name: 'Bolivian Boliviano',
		code: 'BOB',
	},
	{
		symbol: 'R$',
		name: 'Brazilian Real',
		code: 'BRL',
	},
	{
		symbol: 'P',
		name: 'Botswanan Pula',
		code: 'BWP',
	},
	{
		symbol: 'p.',
		name: 'Belarusian Ruble',
		code: 'BYR',
	},
	{
		symbol: 'BZ$',
		name: 'Belize Dollar',
		code: 'BZD',
	},
	{
		symbol: 'FC',
		name: 'Congolese Franc',
		code: 'CDF',
	},
	{
		symbol: 'Fr.',
		name: 'Swiss Franc',
		code: 'CHF',
	},
	{
		symbol: '$',
		name: 'Chilean Peso',
		code: 'CLP',
	},
	{
		symbol: '¥',
		name: 'Chinese Yuan',
		code: 'CNY',
	},
	{
		symbol: '$',
		name: 'Colombian Peso',
		code: 'COP',
	},
	{
		symbol: '₡',
		name: 'Costa Rican Colón',
		code: 'CRC',
	},
	{
		symbol: '$',
		name: 'Cape Verdean Escudo',
		code: 'CVE',
	},
	{
		symbol: 'Kč',
		name: 'Czech Republic Koruna',
		code: 'CZK',
	},
	{
		symbol: 'Fdj',
		name: 'Djiboutian Franc',
		code: 'DJF',
	},
	{
		symbol: 'kr',
		name: 'Danish Krone',
		code: 'DKK',
	},
	{
		symbol: 'RD$',
		name: 'Dominican Peso',
		code: 'DOP',
	},
	{
		symbol: 'دج',
		name: 'Algerian Dinar',
		code: 'DZD',
	},
	{
		symbol: 'kr',
		name: 'Estonian Kroon',
		code: 'EEK',
	},
	{
		symbol: '£',
		name: 'Egyptian Pound',
		code: 'EGP',
	},
	{
		symbol: 'Nfk',
		name: 'Eritrean Nakfa',
		code: 'ERN',
	},
	{
		symbol: 'Br',
		name: 'Ethiopian Birr',
		code: 'ETB',
	},
	{
		symbol: '£',
		name: 'British Pound Sterling',
		code: 'GBP',
	},
	{
		symbol: '₾',
		name: 'Georgian Lari',
		code: 'GEL',
	},
	{
		symbol: 'GH₵',
		name: 'Ghanaian Cedi',
		code: 'GHS',
	},
	{
		symbol: 'FG',
		name: 'Guinean Franc',
		code: 'GNF',
	},
	{
		symbol: 'Q',
		name: 'Guatemalan Quetzal',
		code: 'GTQ',
	},
	{
		symbol: '$',
		name: 'Hong Kong Dollar',
		code: 'HKD',
	},
	{
		symbol: 'L',
		name: 'Honduran Lempira',
		code: 'HNL',
	},
	{
		symbol: 'kn',
		name: 'Croatian Kuna',
		code: 'HRK',
	},
	{
		symbol: 'Ft',
		name: 'Hungarian Forint',
		code: 'HUF',
	},
	{
		symbol: 'Rp',
		name: 'Indonesian Rupiah',
		code: 'IDR',
	},
	{
		symbol: '₪',
		name: 'Israeli New Sheqel',
		code: 'ILS',
	},
	{
		symbol: '₹',
		name: 'Indian Rupee',
		code: 'INR',
	},
	{
		symbol: 'ع.د',
		name: 'Iraqi Dinar',
		code: 'IQD',
	},
	{
		symbol: '﷼',
		name: 'Iranian Rial',
		code: 'IRR',
	},
	{
		symbol: 'kr',
		name: 'Icelandic Króna',
		code: 'ISK',
	},
	{
		symbol: 'J$',
		name: 'Jamaican Dollar',
		code: 'JMD',
	},
	{
		symbol: 'JD',
		name: 'Jordanian Dinar',
		code: 'JOD',
	},
	{
		symbol: '¥',
		name: 'Japanese Yen',
		code: 'JPY',
	},
	{
		symbol: 'KSh',
		name: 'Kenyan Shilling',
		code: 'KES',
	},
	{
		symbol: '៛',
		name: 'Cambodian Riel',
		code: 'KHR',
	},
	{
		symbol: 'CF',
		name: 'Comorian Franc',
		code: 'KMF',
	},
	{
		symbol: '₩',
		name: 'South Korean Won',
		code: 'KRW',
	},
	{
		symbol: 'KD',
		name: 'Kuwaiti Dinar',
		code: 'KWD',
	},
	{
		symbol: '₸',
		name: 'Kazakhstani Tenge',
		code: 'KZT',
	},
	{
		symbol: '£',
		name: 'Lebanese Pound',
		code: 'LBP',
	},
	{
		symbol: '₨',
		name: 'Sri Lankan Rupee',
		code: 'LKR',
	},
	{
		symbol: 'Lt',
		name: 'Lithuanian Litas',
		code: 'LTL',
	},
	{
		symbol: 'Ls',
		name: 'Latvian Lats',
		code: 'LVL',
	},
	{
		symbol: 'LD',
		name: 'Libyan Dinar',
		code: 'LYD',
	},
	{
		symbol: 'MAD',
		name: 'Moroccan Dirham',
		code: 'MAD',
	},
	{
		symbol: 'lei',
		name: 'Moldovan Leu',
		code: 'MDL',
	},
	{
		symbol: 'Ar',
		name: 'Malagasy Ariary',
		code: 'MGA',
	},
	{
		symbol: 'ден',
		name: 'Macedonian Denar',
		code: 'MKD',
	},
	{
		symbol: 'K',
		name: 'Myanma Kyat',
		code: 'MMK',
	},
	{
		symbol: 'MOP$',
		name: 'Macanese Pataca',
		code: 'MOP',
	},
	{
		symbol: '₨',
		name: 'Mauritian Rupee',
		code: 'MUR',
	},
	{
		symbol: '$',
		name: 'Mexican Peso',
		code: 'MXN',
	},
	{
		symbol: 'RM',
		name: 'Malaysian Ringgit',
		code: 'MYR',
	},
	{
		symbol: 'MT',
		name: 'Mozambican Metical',
		code: 'MZN',
	},
	{
		symbol: '$',
		name: 'Namibian Dollar',
		code: 'NAD',
	},
	{
		symbol: '₦',
		name: 'Nigerian Naira',
		code: 'NGN',
	},
	{
		symbol: 'C$',
		name: 'Nicaraguan Córdoba',
		code: 'NIO',
	},
	{
		symbol: 'kr',
		name: 'Norwegian Krone',
		code: 'NOK',
	},
	{
		symbol: '₨',
		name: 'Nepalese Rupee',
		code: 'NPR',
	},
	{
		symbol: '$',
		name: 'New Zealand Dollar',
		code: 'NZD',
	},
	{
		symbol: '﷼',
		name: 'Omani Rial',
		code: 'OMR',
	},
	{
		symbol: 'B/.',
		name: 'Panamanian Balboa',
		code: 'PAB',
	},
	{
		symbol: 'S/.',
		name: 'Peruvian Nuevo Sol',
		code: 'PEN',
	},
	{
		symbol: '₱',
		name: 'Philippine Peso',
		code: 'PHP',
	},
	{
		symbol: '₨',
		name: 'Pakistani Rupee',
		code: 'PKR',
	},
	{
		symbol: 'zł',
		name: 'Polish Zloty',
		code: 'PLN',
	},
	{
		symbol: 'Gs',
		name: 'Paraguayan Guarani',
		code: 'PYG',
	},
	{
		symbol: '﷼',
		name: 'Qatari Rial',
		code: 'QAR',
	},
	{
		symbol: 'lei',
		name: 'Romanian Leu',
		code: 'RON',
	},
	{
		symbol: 'Дин.',
		name: 'Serbian Dinar',
		code: 'RSD',
	},
	{
		symbol: '₽',
		name: 'Russian Ruble',
		code: 'RUB',
	},
	{
		symbol: 'R₣',
		name: 'Rwandan Franc',
		code: 'RWF',
	},
	{
		symbol: '﷼',
		name: 'Saudi Riyal',
		code: 'SAR',
	},
	{
		symbol: 'ج.س.',
		name: 'Sudanese Pound',
		code: 'SDG',
	},
	{
		symbol: 'kr',
		name: 'Swedish Krona',
		code: 'SEK',
	},
	{
		symbol: '$',
		name: 'Singapore Dollar',
		code: 'SGD',
	},
	{
		symbol: 'S',
		name: 'Somali Shilling',
		code: 'SOS',
	},
	{
		symbol: '£',
		name: 'Syrian Pound',
		code: 'SYP',
	},
	{
		symbol: '฿',
		name: 'Thai Baht',
		code: 'THB',
	},
	{
		symbol: 'د.ت',
		name: 'Tunisian Dinar',
		code: 'TND',
	},
	{
		symbol: 'T$',
		name: 'Tongan Paʻanga',
		code: 'TOP',
	},
	{
		symbol: '₺',
		name: 'Turkish Lira',
		code: 'TRY',
	},
	{
		symbol: 'TT$',
		name: 'Trinidad and Tobago Dollar',
		code: 'TTD',
	},
	{
		symbol: 'NT$',
		name: 'New Taiwan Dollar',
		code: 'TWD',
	},
	{
		symbol: 'TSh',
		name: 'Tanzanian Shilling',
		code: 'TZS',
	},
	{
		symbol: '₴',
		name: 'Ukrainian Hryvnia',
		code: 'UAH',
	},
	{
		symbol: 'USh',
		name: 'Ugandan Shilling',
		code: 'UGX',
	},
	{
		symbol: '$U',
		name: 'Uruguayan Peso',
		code: 'UYU',
	},
	{
		symbol: 'лв',
		name: 'Uzbekistan Som',
		code: 'UZS',
	},
	{
		symbol: 'Bs',
		name: 'Venezuelan Bolívar',
		code: 'VEF',
	},
	{
		symbol: '₫',
		name: 'Vietnamese Dong',
		code: 'VND',
	},
	{
		symbol: 'FCFA',
		name: 'CFA Franc BEAC',
		code: 'XAF',
	},
	{
		symbol: 'CFA',
		name: 'CFA Franc BCEAO',
		code: 'XOF',
	},
	{
		symbol: '﷼',
		name: 'Yemeni Rial',
		code: 'YER',
	},
	{
		symbol: 'R',
		name: 'South African Rand',
		code: 'ZAR',
	},
	{
		name: 'Zambian Kwacha',
		code: 'ZMK',
		symbol: 'ZK',
	},
	{
		name: 'Mongolian Tughrik',
		code: 'MNT',
		symbol: '₮',
	},
];

import { FocusScope } from '@react-aria/focus';
import { FC, ReactElement, ReactNode, useLayoutEffect, useRef } from 'react';

import { useKeyPress } from '../../hooks/useKeyPress';
import { ESCAPE_KEY_CODE } from '../../util/keyCodes';
import { Body } from './components/Body/Body';
import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';
import { Section } from './components/Section/Section';
import { StyledModal } from './Modal.styles';

export interface ModalComposition {
	Body: typeof Body;
	Footer: typeof Footer;
	Header: typeof Header;
	Section: typeof Section;
}

export type Size = 'small' | 'medium' | 'large' | 'full-page';

export interface Props {
	open: boolean;
	onClose?: () => void;
	/** Set size of modal */
	size?: Size;
	header: ReactElement<typeof Header> | null;
	children?: ReactNode;
	footer: ReactElement<typeof Footer> | null;
	closeOnEsc?: boolean;
}

const Modal: FC<Props> & ModalComposition = ({
	children,
	onClose,
	size = 'medium',
	header,
	footer,
	closeOnEsc = false,
	...rest
}) => {
	const contentRef = useRef<HTMLSpanElement>(null);

	useLayoutEffect(() => {
		if (contentRef.current === null) return;

		if (contentRef.current.contains(document.activeElement)) return;

		contentRef.current.focus();
	}, []);

	useKeyPress({
		handlers: [
			{
				targetKey: ESCAPE_KEY_CODE,
				onDown: onClose,
			},
		],
		shouldAttach: closeOnEsc,
	});

	return (
		<StyledModal {...rest} size={size} aria-modal="true" role="alertdialog">
			<FocusScope contain restoreFocus>
				{header}

				<span ref={contentRef} tabIndex={-1}>
					{children}
				</span>

				{footer}
			</FocusScope>
		</StyledModal>
	);
};

Modal.Body = Body;
Modal.Footer = Footer;
Modal.Header = Header;
Modal.Section = Section;

export { Modal };

import { FC } from 'react';

export const DragIndicator: FC = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M7 5C7 4.44772 7.44772 4 8 4H10C10.5523 4 11 4.44772 11 5V7C11 7.55228 10.5523 8 10 8H8C7.44772 8 7 7.55228 7 7V5Z"
			fill="currentColor"
		/>
		<path
			d="M13 5C13 4.44772 13.4477 4 14 4H16C16.5523 4 17 4.44772 17 5V7C17 7.55228 16.5523 8 16 8H14C13.4477 8 13 7.55228 13 7V5Z"
			fill="currentColor"
		/>
		<path
			d="M7 11C7 10.4477 7.44772 10 8 10H10C10.5523 10 11 10.4477 11 11V13C11 13.5523 10.5523 14 10 14H8C7.44772 14 7 13.5523 7 13V11Z"
			fill="currentColor"
		/>
		<path
			d="M13 11C13 10.4477 13.4477 10 14 10H16C16.5523 10 17 10.4477 17 11V13C17 13.5523 16.5523 14 16 14H14C13.4477 14 13 13.5523 13 13V11Z"
			fill="currentColor"
		/>
		<path
			d="M7 17C7 16.4477 7.44772 16 8 16H10C10.5523 16 11 16.4477 11 17V19C11 19.5523 10.5523 20 10 20H8C7.44772 20 7 19.5523 7 19V17Z"
			fill="currentColor"
		/>
		<path
			d="M13 17C13 16.4477 13.4477 16 14 16H16C16.5523 16 17 16.4477 17 17V19C17 19.5523 16.5523 20 16 20H14C13.4477 20 13 19.5523 13 19V17Z"
			fill="currentColor"
		/>
	</svg>
);

import customFilterView from 'viewmodels/custom-filter-view.js';

// Other helpers
// ----
export default {
	/**
	 * Color name to hex
	 * https://stackoverflow.com/questions/1573053/javascript-function-to-convert-color-names-to-hex-codes
	 */
	colourNameToHex: function (str) {
		var ctx = document.createElement('canvas').getContext('2d');
		ctx.fillStyle = str;
		return ctx.fillStyle;
	},
	getCategoryFilterText: function (filter) {
		if (filter.length == 0) {
			return 'Any category';
		} else {
			return (
				'Category in ' +
				filter
					.slice(0)
					.map(function (f) {
						return f.name;
					})
					.joinAdvanced(', ', ' and ')
			);
		}
	},
	getLocationFilterText: function (filter) {
		if (filter.length == 0) {
			return 'Any location';
		} else {
			return filter
				.slice(0)
				.map(function (l) {
					return l.name;
				})
				.joinAdvanced(', ', ' and ');
		}
	},
	getLabelFilterText: function (filter) {
		if (filter.length == 0) {
			return 'Any label';
		} else {
			return (
				'Label in ' +
				filter
					.slice(0)
					.map(function (f) {
						return f.name;
					})
					.joinAdvanced(', ', ' and ')
			);
		}
	},
	getContactFilterText: function (filter) {
		if (filter.length == 0) {
			return 'Any user';
		} else {
			return (
				'User in' +
				filter
					.slice(0)
					.map(function (f) {
						return f.name;
					})
					.joinAdvanced(', ', ' and ')
			);
		}
	},
	getItemFilterText: function (filter, kits) {
		if (filter == '-1' || filter == null) {
			return 'Any item';
		} else if (filter == true || filter == 'true') {
			return (
				'In kit' +
				(kits && kits.length > 0
					? ' ' +
						kits
							.slice(0)
							.map(function (f) {
								return f.name;
							})
							.joinAdvanced(', ', ' and ')
					: '')
			);
		} else if (filter == false || filter == 'false') {
			return 'Not in kit';
		}
	},
	getCustomFilterText: function (field, filter) {
		return customFilterView.getButtonText(field, filter);
	},
};

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { QueryClient, QueryFunctionContext } from '@tanstack/react-query';

import Authentication from '../services/authentication';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: process.env.NODE_ENV !== 'development',
		},
	},
});

/* 
	The query function context is needed to pass the AbortSignal to the fetch function.
	only the query function context has the signal property (since we can't abort a pending mutation).
*/
export const fetchGraphQLEndpoint = <TData, TVariables>(
	query: string,
	variables?: TVariables
): ((context?: QueryFunctionContext) => Promise<TData>) => {
	return async (context) => {
		const isQuery = !!context;

		const res = await fetch(process.env.GRAPHQL_URL as string, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${await Authentication.getAccessToken()}`,
			},
			body: JSON.stringify({
				query,
				variables,
			}),
			signal: isQuery ? context?.signal : null,
		});

		const json = await res.json();

		if (json.errors && !json.data) {
			const { message } = json.errors[0] || 'Error..';
			throw new Error(message as string);
		}

		return { ...json.data, extensions: json.extensions };
	};
};

export const fetchPythonEndpoint = <TData, TBody = BodyInit>(
	endpoint: string,
	body?: TBody,
	method: 'GET' | 'POST' = 'POST',
	anonymous = false
): (() => Promise<TData>) => {
	return async () => {
		let res;

		if (method === 'GET') {
			const url = new URL(`${process.env.API_URL as string}/${endpoint}`);
			Object.entries(body || {}).forEach(([key, value]) => {
				url.searchParams.append(key, value as string);
			});

			res = await fetch(url, {
				method,
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
					...(!anonymous ? { authorization: `Bearer ${await Authentication.getAccessToken()}` } : null),
				},
			});
		} else {
			res = await fetch(`${process.env.API_URL as string}/${endpoint}`, {
				method,
				headers: {
					...(body instanceof FormData ? null : { 'Content-Type': 'application/json' }),
					...(!anonymous ? { authorization: `Bearer ${await Authentication.getAccessToken()}` } : null),
				},
				...(body && { body: body instanceof FormData ? body : JSON.stringify(body) }),
			});
		}

		const json = await res.json();

		if (json.httpError) {
			throw new Error(json.httpError as string);
		}

		return json;
	};
};

//https://stackoverflow.com/questions/11290898/detect-when-input-box-filled-by-keyboard-and-when-by-barcode-scanner/15354814#15354814

export default {
	_timeoutHandler: 0,
	_inputString: '',
	isScanInput: function (e, callback) {
		if (this._timeoutHandler) {
			clearTimeout(this._timeoutHandler);
			this._inputString += String.fromCharCode(e.which);
		}

		return new Promise((resolve) => {
			this._timeoutHandler = setTimeout(
				$.proxy(function () {
					if (this._inputString.length <= 2) {
						this._inputString = '';
						resolve(false);
						return;
					}

					if (callback) callback();

					resolve(true);

					this._inputString = '';
				}, this),
				50
			);
		});
	},
};

import app from 'durandal/app.js';
import system from 'durandal/system.js';
import ko from 'knockout';
import kov from 'knockout-validation';
import moment from 'moment';
import mixinComment from 'viewmodels/mixins/comment.js';
import mixinAttachment from 'viewmodels/mixins/attachment.js';
import mixinField from 'viewmodels/mixins/field.js';

/**
 * mixes in some observable fields into any object that is derived from cr.Base
 * It links up the _fromJson and reset methods so it can update the observables accordingly.
 * @param base
 */
export default {
	mixin: function (base, options) {
		var orig_fromJson = base._fromJson,
			orig_reset = base.reset,
			orig_getAttachment = base._getAttachment,
			orig_getComment = base._getComment,
			orig_addComment = base.addComment,
			orig_updateComment = base.updateComment,
			orig_deleteComment = base.deleteComment,
			orig_getKeyValue = base._getKeyValue,
			orig_getField = base._getField,
			orig_setField = base.setField,
			orig_clearField = base.clearField,
			orig_setLabel = base.setLabel,
			orig_clearLabel = base.clearLabel,
			orig_delete = base._delete;

		var global = options.global;

		// Core method overrides
		// ----
		base._fromJson = function (data, options) {
			//bugfix
			base.comment.reset();

			return orig_fromJson.apply(base, [data, options]).then(function () {
				base._readFromObj();
			});
		};

		// Override delete method, so document gets reset after delete
		// (default behavior)
		base._delete = function () {
			return this.ds.delete(this.id);
		};

		base.reset = async function () {
			await orig_reset.apply(base);
			base.comment.reset();

			base.isBusyFields(false);
			base.isEditingFields(false);

			base._readFromObj();
		};

		base.addComment = function () {
			var comment = base.comment;

			comment.oIsSubmitting(true);

			return orig_addComment
				.apply(base, [comment.oComment(), true])
				.then(function (data) {
					// Show new comment
					var comments = base.oComments();
					comments.unshift(
						base._getComment(data.comments[0], {
							forKind: base.crtype,
						})
					);
					base.oComments(comments);

					// Reset add comment
					comment.reset();
					return data;
				})
				.finally(function () {
					comment.oIsSubmitting(false);
				});
		};

		base.updateComment = function (comment, skipRead) {
			comment.oIsSubmitting(true);

			return orig_updateComment
				.apply(base, [comment.id, comment.oComment(), true])
				.then(function (data) {
					comment._fromJson(
						data.comments.find(function (comm) {
							return comm.id == comment.id;
						})
					);

					comment.reset();
					return data;
				})
				.finally(function () {
					comment.oIsSubmitting(false);
					comment.oIsEditing(false);
				});
		};

		base.deleteComment = function (comment) {
			comment.oIsSubmitting(true);
			comment.oIsDeleting(true);

			return orig_deleteComment
				.apply(base, [comment.id, true])
				.then(function (data) {
					var comments = base.oComments();
					base.oComments(
						comments.filter(function (comm) {
							return comm.id != comment.id;
						})
					);

					//Reset add comment
					comment.reset();
					return data;
				})
				.finally(function () {
					comment.oIsSubmitting(false);
					comment.oIsDeleting(false);
				});
		};

		base.deleteAttachment = function (attachment) {
			attachment.oIsDeleting(true);

			return base.detach
				.apply(base, [attachment.value, true])
				.then(function (resp) {
					var attachments = base.oAttachments();
					base.oAttachments(
						attachments.filter(function (att) {
							return att.value != attachment.value;
						})
					);

					base.raw = resp;

					base._setCover(resp.cover);
				})
				.finally(function () {
					attachment.oIsDeleting(false);
				});
		};

		base.addAttachment = function (attachmentObj, skipRead) {
			// Get Attachment object
			var attachment = base._getAttachment(attachmentObj, {
				by: global.central.user(),
				value: attachmentObj._id,
				value_url: attachmentObj._id_url,
				forKind: base.crtype,
			});

			// Show attachment as adding
			attachment.oIsAdding(true);

			var allAttachments = base.oAttachments();
			var origAttachments = allAttachments.slice(0);
			allAttachments.unshift(attachment);
			base.oAttachments(allAttachments);

			return base.attach
				.apply(base, [attachmentObj._id, true])
				.then(function (resp) {
					attachment.oIsAdding(false);

					// Contact bugfix
					// if no cover image make sure to set cover image (pdf images)
					if (!base.cover && attachment.canBeCover) {
						return base.setCover(attachmentObj, true).then(function (resp) {
							if (skipRead !== true) {
								base.raw = resp;
								base._setCover(resp.cover);
							}
						});
					}
				})
				.catch(function () {
					base.oAttachments(origAttachments);
				});
		};

		base._setCover = function (cover) {
			base.oCover(global.common.isImage(cover) ? cover : '');
		};

		base.setField = function (field, value) {
			base.fields[field] = value;

			base.isBusyFields(true);

			return orig_setField.apply(base, [field, value, true]).finally(function () {
				base.isBusyFields(false);
			});
		};

		base.clearField = function (field) {
			delete base.fields[field];

			base.isBusyFields(true);

			return orig_clearField.apply(base, [field, true]).finally(function () {
				base.isBusyFields(false);
			});
		};

		base.setLabel = function (label) {
			base.oLabel(label);

			return orig_setLabel.apply(base, [label.id, true]).then(function () {
				app.trigger('timeline:refresh');
			});
		};

		base.clearLabel = function (label) {
			base.oLabel(null);

			return orig_clearLabel.apply(base, [true]).then(function () {
				app.trigger('timeline:refresh');
			});
		};

		base._getAttachment = function (kv, opt) {
			opt = $.extend(opt || {}, {
				global: global,
				forKind: base.crtype,
			});

			// Get Attachment object
			var attachment = orig_getAttachment.apply(base, [
				$.extend(kv, {
					ds: global.getDataSource('attachments'),
				}),
				opt,
			]);

			// Add attachment related observables
			mixinAttachment.mixin(attachment, opt);

			return attachment;
		};

		base._getComment = function (kv, opt) {
			opt = $.extend(opt || {}, {
				global: global,
				forKind: base.crtype,
			});

			// Get Comment object
			var comment = orig_getComment.apply(base, [kv, opt]);

			// Add comment related observables
			mixinComment.mixin(comment, opt);

			return comment;
		};

		base._getField = function (field, opt) {
			opt = $.extend(opt || {}, {
				global: global,
			});

			// Get Field object
			var field = orig_getField.apply(base, [field, opt]);

			// Add field related observables
			mixinField.mixin(field, opt);

			return field;
		};

		base._getKeyValue = function (kv, options) {
			// Convert dates to moment objects
			if (kv.kind == 'date' || kv.kind == 'datetime') {
				kv.value = moment(kv.value);
			}

			return orig_getKeyValue.apply(base, [kv, options]);
		};

		// Helper methods
		// ----
		base._readFromObj = function () {
			base.oId(base.id);
			base.oBy(base.by);
			base.oModified(base.modified);
			base.oAttachments(base.attachments);
			base.oComments(base.comments);
			base.oBarcodes(base.barcodes);

			base._setCover(base.cover);
		};

		// Observables
		// ----
		base.oId = ko.observable(base.id);
		base.oBy = ko.observable(base.by);
		base.oModified = ko.observable(base.modified);
		base.oAttachments = ko.observable(base.attachments);
		base.oComments = ko.observable(base.comments);
		base.oBarcodes = ko.observable(base.barcodes);
		base.oLabel = ko.observable();
		base.oCover = ko.observable(base.cover || '');

		base.isEditingFields = ko.observable(false);
		base.isBusyFields = ko.observable(false);

		base.isOwn = ko.observable(false);

		// Computables
		// ----
		base._updateId = ko.computed(function () {
			base.id = base.oId();
		});

		base._updateLabel = ko.computed(function () {
			var label = base.oLabel();

			base.label = label ? label.id : null;
		});

		base._updateCover = ko.computed(function () {
			base.cover = base.oCover();
		});

		// Other properties
		// ----
		//Override internal helper to use helper with setttings
		//from web app (staging/production)
		//base.helper = global.coreHelper;
		base.comment = base._getComment(
			{
				parent: base,
				ds: base.ds,
				by: global.central.user(),
			},
			{
				isEditing: true,
				global: global,
			}
		);
	},
};

import { FC } from 'react';

export const Qr: FC = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1 4C1 2.34315 2.34315 1 4 1H8C9.65685 1 11 2.34315 11 4V8C11 9.65685 9.65685 11 8 11H4C2.34315 11 1 9.65685 1 8V4ZM4 3C3.44772 3 3 3.44772 3 4V8C3 8.55228 3.44772 9 4 9H8C8.55228 9 9 8.55228 9 8V4C9 3.44772 8.55228 3 8 3H4ZM13 4C13 2.34315 14.3431 1 16 1H20C21.6569 1 23 2.34315 23 4V8C23 9.65685 21.6569 11 20 11H16C14.3431 11 13 9.65685 13 8V4ZM16 3C15.4477 3 15 3.44772 15 4V8C15 8.55228 15.4477 9 16 9H20C20.5523 9 21 8.55228 21 8V4C21 3.44772 20.5523 3 20 3H16ZM5 6C5 5.44772 5.44772 5 6 5H6.01C6.56228 5 7.01 5.44772 7.01 6C7.01 6.55228 6.56228 7 6.01 7H6C5.44772 7 5 6.55228 5 6ZM17 6C17 5.44772 17.4477 5 18 5H18.01C18.5623 5 19.01 5.44772 19.01 6C19.01 6.55228 18.5623 7 18.01 7H18C17.4477 7 17 6.55228 17 6ZM1 16C1 14.3431 2.34315 13 4 13H8C9.65685 13 11 14.3431 11 16V20C11 21.6569 9.65685 23 8 23H4C2.34315 23 1 21.6569 1 20V16ZM4 15C3.44772 15 3 15.4477 3 16V20C3 20.5523 3.44772 21 4 21H8C8.55228 21 9 20.5523 9 20V16C9 15.4477 8.55228 15 8 15H4ZM13 14C13 13.4477 13.4477 13 14 13H14.01C14.5623 13 15.01 13.4477 15.01 14C15.01 14.5523 14.5623 15 14.01 15H14C13.4477 15 13 14.5523 13 14ZM17 14C17 13.4477 17.4477 13 18 13H22C22.5523 13 23 13.4477 23 14C23 14.5523 22.5523 15 22 15H18C17.4477 15 17 14.5523 17 14ZM5 18C5 17.4477 5.44772 17 6 17H6.01C6.56228 17 7.01 17.4477 7.01 18C7.01 18.5523 6.56228 19 6.01 19H6C5.44772 19 5 18.5523 5 18ZM13 18C13 17.4477 13.4477 17 14 17H22C22.5523 17 23 17.4477 23 18V22C23 22.5523 22.5523 23 22 23H18C17.4477 23 17 22.5523 17 22C17 21.4477 17.4477 21 18 21H21V19H14C13.4477 19 13 18.5523 13 18ZM13 22C13 21.4477 13.4477 21 14 21H14.01C14.5623 21 15.01 21.4477 15.01 22C15.01 22.5523 14.5623 23 14.01 23H14C13.4477 23 13 22.5523 13 22Z"
			fill="currentColor"
		/>
	</svg>
);

import { AnalyticsPlugin, AnalyticsProperties } from '../analytics';

interface BeamerConfig {
	product_id: string;
	selector: string;
	button: boolean;
	lazy: boolean;
	top: number;
	right: number;
}

export class Beamer implements AnalyticsPlugin {
	constructor(private readonly config: BeamerConfig) {}

	get name(): string {
		return 'beamer';
	}

	private formatProps(props: AnalyticsProperties) {
		// format arrays to long string
		return Object.entries(props).reduce(
			(previous, [key, value]) => ({
				...previous,
				[key]: Array.isArray(value) ? (value as string[]).join(', ') : (value as string | boolean | number),
			}),
			{}
		);
	}

	identify(props: AnalyticsProperties): void {
		const formattedProps = this.formatProps(props);
		window.beamer_config = {
			...window.beamer_config,
			...formattedProps,
			user_firstname: props.name,
			user_lastname: ' ', // Pass empty string as last name to beamer otherwise it will use random value
			user_email: props.email,
			user_id: props.userId,
		};
	}

	initialize(): void {
		window.beamer_config = this.config;

		const script = document.createElement('script');
		script.defer = true;
		script.src = 'https://app.getbeamer.com/js/beamer-embed.js';

		script.onload = () => {
			window.Beamer.init();
		};

		document.head.appendChild(script);
	}

	page(): void {
		return;
	}

	track(): void {
		return;
	}
}

import dialog from 'plugins/dialog.js';
import ko from 'knockout';

import View from 'views/column-modal.html';

var ColumnModal = function (spec = {}) {
	var that = this;

	this.view = View;

	this.app = spec.app;
	this.global = spec.global;

	this.fields = spec.fields;

	// Sort fields by type and alpabetically
	this.fields.sort(function (a, b) {
		var isSystemField = function (f) {
			return f && f.id.indexOf('fields.') == -1;
		};

		var compareType = isSystemField(b) - isSystemField(a);
		var compareName = a.name.localeCompare(b.name);

		return compareType || compareName;
	});

	this.search = ko.observable('');
	this.searchFields = ko.computed(() => {
		const search = this.search().trim().toLowerCase();

		if (search === '') return this.fields;

		return this.fields.filter((field) => {
			return field.name.toLowerCase().includes(search);
		});
	});

	var selection = [];
	$.each(spec.selection, function (i, fieldId) {
		selection.push(
			that.fields.find(function (f) {
				return f.id == fieldId;
			})
		);
	});

	this.selection = ko.observableArray(selection);
};

// Durandal events
// ----
ColumnModal.show = function (spec) {
	return dialog.showBootstrapDialog(new ColumnModal(spec));
};

ColumnModal.prototype.close = function (selection) {
	dialog.close(this, selection);
};

ColumnModal.prototype.compositionComplete = function (v, p) {
	var that = this;

	// This event is fired when the modal has been made visible to the user
	// http://stackoverflow.com/questions/22547472/how-can-i-get-auto-focus-to-an-input-element-in-a-dynamically-generated-knockout
	$(p)
		.on('show.bs.modal', function (e) {
			that._makeSortable(v);
		})
		.on('hidden.bs.modal', function (e) {
			that.close();
		});
};

ColumnModal.prototype._makeSortable = function (v) {
	var that = this;

	$('#selected-columns', v).sortable({
		handle: '.drag-handle',
		items: 'li:not(.unsortable)',
		start: function (event, ui) {
			// creates a temporary attribute on the element with the old index
			$(ui.item).attr('data-previndex', $(ui.item).index());
		},
		update: function (event, ui) {
			var $item = $(ui.item);
			var newPos = parseInt($item.index());
			var oldPos = parseInt($item.attr('data-previndex'));
			$item.removeAttr('data-previndex');

			//https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
			function array_move(arr, old_index, new_index) {
				new_index = ((new_index % arr.length) + arr.length) % arr.length;
				arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
				//return arr; // for testing
			}

			array_move(that.selection(), oldPos, newPos);
		},
		/*
		 * Force sortable helper to have same size as original row
		 * http://stackoverflow.com/questions/1307705/jquery-ui-sortable-with-table-and-tr-width
		 */
		helper: function (e, tr) {
			var $originals = tr.children();
			var $helper = tr.clone();
			$helper.children().each(function (index) {
				// Set helper cell sizes to match the original sizes (+11 for padding)
				$(this).width($originals.eq(index).width() + 11);
			});
			return $helper;
		},
	});
};

ColumnModal.prototype.isSystemField = function (field) {
	return field && field.id.indexOf('fields.') == -1;
};

// UI events
// ----
ColumnModal.prototype.clickedApply = function () {
	this.close(this.selection());
};

ColumnModal.prototype.clickedRemoveColumn = function (e) {
	const idx = $(e.currentTarget).parents('li').index();
	var selection = this.selection();

	if (idx > -1) {
		selection.splice(idx, 1);

		this.selection([]);
		this.selection(selection);
	}
};

export default ColumnModal;

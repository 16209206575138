import { useLocalStorage } from '@cheqroom/hooks';

import { useAuth } from '../../context/Authentication.context';
import { FieldType, SystemFieldType } from './types';

const CUSTOMIZE_ITEM_VIEW_DEFAULT_SELECTION = [SystemFieldType.barcode, SystemFieldType.qr, SystemFieldType.created];

type SaveItemFieldIds = (ids: FieldType[]) => void;

const useItemFieldsConfig = (): [FieldType[], SaveItemFieldIds] => {
	const { id: userId } = useAuth();
	const [selectedFieldIds, saveSelectedFieldIds] = useLocalStorage<FieldType[]>(
		`${userId!}app.item-view`,
		CUSTOMIZE_ITEM_VIEW_DEFAULT_SELECTION
	);

	return [selectedFieldIds as FieldType[], saveSelectedFieldIds];
};

export default useItemFieldsConfig;

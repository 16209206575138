import mixinColorLabel from 'viewmodels/mixins/colorLabel.js';

/**
 * mixes in some observable fields into any object that is derived from cr.Base
 * It links up the _fromJson and reset methods so it can update the observables accordingly.
 * @param document
 */
export default {
	mixin: function (doc, options) {
		var orig_getColorLabel = doc._getColorLabel;

		var global = options.global;

		doc._getColorLabel = function (label, opt) {
			opt = $.extend(opt || {}, {
				global: global,
			});

			// Get ColorLabel object
			var label = orig_getColorLabel(label, opt);

			// Add field related observables
			mixinColorLabel.mixin(label, opt);

			return label;
		};
	},
};

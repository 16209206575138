import { Cross, DragIndicator, LockClosed } from '@cheqroom/icons';
import { Icon, IconButton, Stack, Text } from '@cheqroom/ui';
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import tw, { styled, theme } from 'twin.macro';

import { SystemField, SystemFieldType as ItemSystemFieldType } from '../../hooks/item-fields/types';
import { Field as ReservationsFieldType } from '../../pages/Reservations/Overview/python.hooks';

type Props = {
	selectedColumns: SystemField<ItemSystemFieldType | ReservationsFieldType | string>[];
	changeColumnOrder: (fromIndex: number, toIndex: number) => void;
	onRemoveColumn: (id: string) => void;
	blockBeforeIndex?: number;
};

const ReorderFields: FC<Props> = ({ selectedColumns, changeColumnOrder, onRemoveColumn, blockBeforeIndex }) => {
	const { t } = useTranslation('customize-overview-columns');

	const handleDragEnd = (result: DropResult) => {
		const { source, destination } = result;
		if (!destination || (blockBeforeIndex !== undefined && destination.index <= blockBeforeIndex)) return;

		if (destination.droppableId === 'reorder-columns') {
			changeColumnOrder(source.index, destination.index);
		}
	};

	return (
		<Stack vertical spacing="loose" tw="w-full">
			<Stack vertical spacing="extraTight">
				<Text weight="bold">{t('customize_columns.reorder_columns.title')}</Text>
				<Text size="small" color="subdued">
					{t('customize_columns.reorder_columns.description')}
				</Text>
			</Stack>
			<DragDropContext onDragEnd={handleDragEnd}>
				<Droppable droppableId="reorder-columns">
					{(provided) => (
						<div tw="flex flex-col w-full" ref={provided.innerRef} {...provided.droppableProps}>
							{selectedColumns.map((column, index) => {
								const disabled = !column.removable;

								return (
									<Draggable
										key={column.id}
										draggableId={column.id}
										index={index}
										isDragDisabled={disabled}
									>
										{(provided) => (
											<StyledField
												ref={provided.innerRef}
												{...(!disabled && provided.draggableProps)}
												align="center"
												justify="between"
												disabled={disabled}
											>
												<Stack spacing="tight" align="center">
													<div
														{...(!disabled && provided.dragHandleProps)}
														tw="flex items-center"
													>
														<Icon
															source={DragIndicator}
															color={theme`colors.gray.300`}
															size="small"
														/>
													</div>
													<Text>{column.name}</Text>
												</Stack>
												{disabled ? (
													<div tw="flex m-1.5 border border-transparent">
														<Icon
															source={LockClosed}
															size="small"
															color={theme`colors.gray.400`}
														/>
													</div>
												) : (
													<IconButton
														source={Cross}
														size="small"
														variation="secondary"
														inverted
														onClick={() => onRemoveColumn(column.id)}
													/>
												)}
											</StyledField>
										)}
									</Draggable>
								);
							})}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</Stack>
	);
};

export const StyledField = styled(Stack)<{ disabled: boolean }>`
	${tw`bg-white p-1 pl-2 border border-gray-200 rounded-md w-full my-1`}
	${({ disabled }) => disabled && tw`bg-gray-100 text-gray-400`}
`;

export default ReorderFields;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createContext, FC, ReactNode, useContext } from 'react';

import { Analytics, Properties } from './analytics';

type AnalyticsState = Analytics | null;

const AnalyticsContext = createContext<AnalyticsState>(null);

interface AnalyticsProviderProps {
	analytics: Analytics;
	children: ReactNode;
}

export const AnalyticsProvider: FC<AnalyticsProviderProps> = ({ analytics, children }) => (
	<AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>
);

export const useAnalytics = () => {
	const analytics = useContext(AnalyticsContext);

	if (!analytics) {
		throw new Error('useAnalytics should be used within an AnalyticsProvider');
	}

	const track = (event: string, source: string, props?: Properties) => analytics.track(event, source, props);

	return [track] as const;
};

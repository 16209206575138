import { FC } from 'react';

export const LockClosed: FC = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7V9H18C19.6569 9 21 10.3431 21 12V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V12C3 10.3431 4.34315 9 6 9H7V7ZM9 9H15V7C15 5.34315 13.6569 4 12 4C10.3431 4 9 5.34315 9 7V9ZM6 11C5.44772 11 5 11.4477 5 12V19C5 19.5523 5.44772 20 6 20H18C18.5523 20 19 19.5523 19 19V12C19 11.4477 18.5523 11 18 11H6ZM10 15.5C10 14.3954 10.8954 13.5 12 13.5C13.1046 13.5 14 14.3954 14 15.5C14 16.6046 13.1046 17.5 12 17.5C10.8954 17.5 10 16.6046 10 15.5Z"
			fill="currentColor"
		/>
	</svg>
);

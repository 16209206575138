import tw, { styled } from 'twin.macro';

import { StyledIconButton } from '../../../IconButton/IconButton.styles';
import { Size } from '../../Modal';

export const StyledHeader = styled.header<{ size: Size; truncate: boolean }>`
	${tw`flex items-center justify-between py-4 px-6`}
	${({ size }) => size === 'full-page' && tw`py-10`}
	/* Make sure the header is trucated to allow for a gap between the title and the controls */
	${({ truncate }) => truncate && tw`gap-4`}


	${StyledIconButton} {
		${tw`-m-1.5`}
	}
`;

import { ComponentPropsWithoutRef, FC } from 'react';

import { StyledImage } from './Image.styles';

type ImageFit = 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';

export interface Props extends ComponentPropsWithoutRef<'img'> {
	alt: string;
	fallback?: string;
	fit?: ImageFit;
}

const DEFAULT_FALLBACK = 'https://app.cheqroomcdn.com/image-M.png';

export const Image: FC<Props> = ({ alt, fallback = DEFAULT_FALLBACK, src, ...rest }) => (
	<StyledImage
		alt={alt}
		src={src ?? fallback}
		onError={(event) => {
			event.currentTarget.onerror = null;
			event.currentTarget.src = fallback;
		}}
		{...rest}
	/>
);

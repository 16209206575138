function supports_canvas() {
	return !!document.createElement('canvas').getContext;
}

function supports_local_storage() {
	try {
		return 'localStorage' in window && window['localStorage'] !== null;
	} catch (e) {
		return false;
	}
}

function supports_session_storage() {
	try {
		return 'sessionStorage' in window && window['sessionStorage'] !== null;
	} catch (e) {
		return false;
	}
}

function supports_cookies() {
	return navigator.cookieEnabled;
}

function supports_json() {
	return typeof JSON === 'object' && typeof JSON.parse === 'function';
}

function supports_css_variables() {
	return window.CSS && CSS.supports('color', 'var(--fake-var)');
}

function supports_fetch() {
	return 'fetch' in window;
}

if (
	!supports_json() ||
	!supports_canvas() ||
	!supports_local_storage() ||
	!supports_session_storage() ||
	!supports_cookies() ||
	!supports_css_variables() ||
	!supports_fetch()
) {
	window.location = 'unsupported.html';
}

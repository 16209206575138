import { AnalyticsPlugin, AnalyticsProperties, Properties } from '../analytics';

interface AppcuesConfig {
	appcuesId: string;
}

export class Appcues implements AnalyticsPlugin {
	constructor(private readonly config: AppcuesConfig) {}

	get name(): string {
		return 'appcues';
	}

	private formatProps(props: AnalyticsProperties) {
		// format arrays to long string
		return Object.entries(props).reduce(
			(previous, [key, value]) => ({
				...previous,
				[key]: Array.isArray(value) ? (value as string[]).join(', ') : (value as string | boolean | number),
			}),
			{} as AnalyticsProperties
		);
	}

	identify(props: AnalyticsProperties): void {
		window.Appcues?.identify(props.userId, this.formatProps(props));
	}

	initialize(): void {
		// We need to add this extra configuration object because RequireJS does not play nicely
		// When an external script loads other external scripts
		window.AppcuesSettings = {
			skipAMD: true,
		};

		if (window.Appcues) return;

		const script = document.createElement('script');
		script.defer = true;
		script.src = `https://fast.appcues.com/${this.config.appcuesId}.js`;

		document.head.appendChild(script);
	}

	page(): void {
		window.Appcues?.page();
	}

	track(event: string, source?: string, props?: Properties): void {
		window.Appcues?.track(event, props);
	}
}

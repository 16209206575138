import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';

export class PhoneFormatter {
	static toInternational(number: string, country?: CountryCode): string {
		try {
			return parsePhoneNumber(number, country).format('INTERNATIONAL');
		} catch {
			return number;
		}
	}

	static toNational(number: string): string {
		try {
			return parsePhoneNumber(number).format('NATIONAL');
		} catch {
			return number;
		}
	}
}

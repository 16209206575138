import debounce from 'viewmodels/debounce';
import system from 'durandal/system.js';
import activator from 'durandal/activator.js';
import viewEngine from 'durandal/viewEngine.js';
import ko from 'knockout';
import ColumnModal from 'viewmodels/column-modal.js';
import Nanobar from 'nanobar';

import ViewbarView from 'views/viewbar.html';
import ViewbarBottomView from 'views/viewbar-bottom.html';
import PreHeaderTemplate from 'views/preheader.html';
import FooterButtonsTemplate from 'views/footer-buttons.html';
import analytics from '@cheqroom/web/src/services/analytics';
import featureFlags from '@cheqroom/web/src/services/feature-flags';
import CustomizeOverview from '@cheqroom/web/src/components/CustomizeOverview/CustomizeOverview';

var nanobar = new Nanobar();

var pageTypes = {};

/**
 * storageHandler that saves to localStorage
 * We're using a separate storageHandler class, so storing data locally can change over time
 * We're also using deferreds during loading and saving, so in the future we could also store this in the db
 * @name storageHandler
 * @param spec
 */
pageTypes.storageHandler = function (spec) {
	spec = spec || {};

	// everything we save in the localStorage
	// will be prefixed with this in the key
	// e.g. set up a localStorage per login
	var prefix = spec.prefix || '';

	return {
		load: function (view) {
			var key = prefix + view._getFullId(),
				value = localStorage.getItem(key);

			system.log('Loading storage for', key);

			if (value) {
				var data = JSON.parse(value);
				return view._fromStorageData(data);
			} else {
				return Promise.resolve();
			}
		},
		save: function (view) {
			var key = prefix + view._getFullId();

			system.log('Saving storage for', key);

			return view._toStorageData().then(function (data) {
				if (data) {
					var value = JSON.stringify(data);
					localStorage.setItem(key, value);
				}
			});
		},
	};
};

// Base classes
// **************************************************************

/**
 * baseView is a base class for all inheriting types
 * It adds to the DurandalJS activation lifecycle so we can avoid a lot of boilerplate code in our web app
 * You'll need to override at least `_viewActivate`
 * @name baseView
 * @param spec
 */
pageTypes.baseView = function (spec) {
	var defaults = {
		id: 'base', // needed for making storage keys (e.g. app.tabPage.tab1)
		app: null, // ref to the app object
		router: null, // ref to the router object
		parent: null, // ref to the parent view (e.g. to the tabPage for a tabView)
		storage: null, // ref to a storageHandler object
		routeMap: null, // turns positional activate(arg1, arg2, data) into a dict
		_subscriptionsPubSub: null, // http://durandaljs.com/documentation/Leveraging-Publish-Subscribe.html
		_subscriptionsKO: null, // http://knockoutjs.com/documentation/observables.html
	};

	var view = $.extend({}, defaults, spec || {});

	// If no custom error handler was set,
	// the view will use the errorHandler object from app
	if (view.onError == null) {
		view.onError = function (err) {
			view.app.getErrorHandler().onError(err);

			// BUGFIX Return err object, otherwise durandal error
			// in some occasions (Cannot read property 'message' of undefined)
			return err;
		};
	}

	view.addViewToKnockoutTemplateCache = () => {
		const templateView = view.getView?.() || view.view;
		const knockoutTemplateHash = viewEngine.hashCode(templateView).toString();

		ko.templates[knockoutTemplateHash] = templateView;
		view.knockoutTemplateHash = knockoutTemplateHash;
	};

	view.CustomizeOverview = CustomizeOverview;
	view.featureFlags = featureFlags;

	/**
	 * We'll add extra steps to the DurandalJS activation lifecycle
	 * - _subscribeEvents: for registering pub/sub events
	 * - _loadFromStorage: for loading some settings from localStorage
	 * - _viewActivate: the actual activation function
	 * @name activate
	 * @private
	 * @returns {promise}
	 */
	view.activate = function (dat) {
		// Initialize observables
		view.isBusy = ko.observable(false).extend({ notify: 'always', rateLimit: 50 }); // for showing busy icons per panel

		// Durandal will pass data to this function depending on the router
		// http://durandaljs.com/documentation/Using-The-Router.html
		// #route/:id        --> #route/11               --> ["11"]
		// #route/:id(/:tab) --> #route/11/specs         --> ["11", "specs"]
		//                   --> #route/11/specs?sort=up --> ["11", "specs", {sort: "up"}]
		var args = [].slice.apply(arguments);

		// We'll transform all this into a single dict object
		// You can use the view.routeMap to have nice names for the data dict keys
		// Without a routeMap:
		// #route/:id(/:tab) --> #route/11/specs?sort=up --> {0: "11", 1: "specs", sort: "up"}
		// With a routeMap like: ["pk", "tab"]
		// #route/:id(/:tab) --> #route/11/specs?sort=up --> {pk: "11", tab: "specs", sort: "up"}
		var data = null,
			key = null,
			last = null;
		if (args.length > 0) {
			// The last elements after the ? is always in the form of a dict
			last = args[args.length - 1];
			if (last != null && typeof last == 'object') {
				data = args.pop();
			} else {
				data = {};
			}

			// Run over the rest of the positional arguments
			for (var i = 0; i < args.length; i++) {
				key = view.routeMap && view.routeMap.length > 0 ? view.routeMap[i] : i;
				if (key == null) {
					key = i;
				}
				if (data[key] == null) {
					data[key] = args[i];
				}
			}
		}

		view.isBusy(true);
		view._loadFromStorage(data);
		return view._subscribeEvents(data).then(function () {
			return view._viewActivate(data).finally(function () {
				view.isBusy(false);
			});
		});
	};

	/**
	 * We'll add extra steps to the DurandalJS activation lifecycle
	 * - _saveToStorage: for saving some settings to localStorage
	 * - _unSubscribeEvents: for releasing subscribed events
	 * - _viewDeactivate: the actual deactivation function
	 * @name deactivate
	 * @private
	 * @returns {promise}
	 */
	view.deactivate = function () {
		view._saveToStorage();
		return view._unSubscribeEvents().then(function () {
			return view._viewDeactivate();
		});
	};

	view.canActivate = function () {
		return true;
	};

	// If you need to subscribe to pub/sub or ko events,
	// inheriting classes can do that here
	// for KO subscriptions, add the subscription to `view._subscriptionsKO` array
	// for PubSub subscriptions, add the callback function to `view._subscriptionsPubSub` array (of tuples)
	view._subscribeEvents = function (data) {
		return Promise.resolve();
	};

	view._unSubscribeEvents = function () {
		var subscription = null;
		if (view._subscriptionsPubSub) {
			while (view._subscriptionsPubSub.length) {
				// Each entry is a tuple of (subscription, callback)
				subscription = view._subscriptionsPubSub.pop();
				subscription[0].off(null, subscription[1]);
			}
		}
		if (view._subscriptionsKO) {
			while (view._subscriptionsKO.length) {
				subscription = view._subscriptionsKO.pop();
				if (subscription) {
					subscription.dispose();
				}
			}
		}
		return Promise.resolve();
	};

	view._viewActivate = function (data) {
		return Promise.resolve();
	};

	view._viewDeactivate = function () {
		return Promise.resolve();
	};

	/**
	 * Make a unique id for this view
	 * It can be used e.g. for saving to localstorage
	 * @name _getFullId
	 * @private
	 * @returns {string}
	 */
	view._getFullId = function () {
		var parentId = view._parent ? view._parent._getFullId() : 'app';
		return parentId + '.' + view.id;
	};

	view._toStorageData = function () {
		return Promise.resolve();
	};

	view._fromStorageData = function (data) {
		return Promise.resolve();
	};

	view._loadFromStorage = function (data) {
		if (view.storage) {
			view.storage.load(view);
		} else {
			system.log('No storageHandler set, skipping storage.load');
		}
	};

	view._saveToStorage = function () {
		if (view.storage) {
			view.storage.save(view);
		} else {
			system.log('No storageHandler set, skipping storage.save');
		}
	};

	view.getRoot = function () {
		return view._parent ? view._parent.getRoot() : view;
	};

	view.setRootBusy = function (busy) {
		var root = view.getRoot();
		if (root) {
			root.isBusy(busy);
		}
	};

	return view;
};

/**
 * basePage is a base class for all inheriting pages
 * It has support for an (observable) page title and browser url cleaning
 * You'll need to override at least `_pageActivate`
 * @name basePage
 * @param spec
 */
pageTypes.basePage = function (spec = {}) {
	var defaults = {
		title: ko.observable(''),
		breadcrumbs: ko.observable([]),
		refreshOn: null, // (optional) pubsub subscriptions that will automatically trigger `page.refresh`
		_refreshSubscriptions: null, // will contain the pubsub for `refreshOn`
		_data: null,
	};

	if (spec.title != null && !ko.isObservable(spec.title)) {
		spec.title = ko.observable(spec.title);
	}

	var view = pageTypes.baseView(spec),
		super_viewActivate = view._viewActivate,
		super_viewDeactivate = view._viewDeactivate,
		super_subscribeEvents = view._subscribeEvents,
		page = $.extend(view, defaults, spec || {});

	page.preHeaderTemplate = PreHeaderTemplate;
	page.footerButtonsTemplate = FooterButtonsTemplate;

	page._subscribeEvents = function (data) {
		return super_subscribeEvents(data).then(function () {
			// BasePages can have an array of `refreshOn` subscriptions
			// It will automatically bind with those subscriptions and will call
			// `refresh`when any of those pubsub events is triggered
			page._subscriptionsPubSub = page._subscriptionsPubSub || [];
			if (page.refreshOn != null && page.refreshOn.length) {
				var subscription = null;
				$.each(page.refreshOn, function (i, sub) {
					subscription = page.app.on(sub, page._refreshDocs);
					page._subscriptionsPubSub.push([subscription, page._refreshDocs]);
				});
			}

			page._subscriptionsKO = page._subscriptionsKO || [];

			if (page.isBusy()) nanobar.go(30);
			page._subscriptionsKO.push(
				page.isBusy.subscribe(function (value) {
					if (value) {
						nanobar.go(30);
					} else {
						nanobar.go(100);
					}
				})
			);

			// Initially use the page.title and set it to router.title
			var title = page.title();
			if (page.app && page.app.router) {
				page.app.router.title(title);
			}

			// Update the router.title when the page.title changes
			page._subscriptionsKO.push(
				page.title.subscribe(function (value) {
					if (page.app && page.app.router) {
						page.app.router.title(value);
					}
				})
			);

			// Update page breadcrumbs
			//
			// Using isNavigating subscription because otherwise breadcrumb
			// would be flickering, now we only update breadcrumbs when page isn't
			// navigating anymore
			page._subscriptionsKO.push(
				app.router.isNavigating.subscribe(function (val) {
					if (!val) {
						var breadcrumbs = app.router.breadcrumbs ? app.router.breadcrumbs() : null;
						if (breadcrumbs && breadcrumbs.length > 0) {
							breadcrumbs[breadcrumbs.length - 1].friendlyTitle = ko.computed(function () {
								return page.title();
							});

							page.breadcrumbs(breadcrumbs);
						}
					}
				})
			);
		});
	};

	page._viewActivate = function (data) {
		data = data || {};

		// The only this a page does extra is call _pageActivate
		// which can do some (optional) browser url cleaning
		return super_viewActivate(data).then(function () {
			return page._pageActivate(data);
		});
	};

	page._viewDeactivate = function () {
		return super_viewDeactivate().then(function () {
			return page._pageDeactivate();
		});
	};

	page._pageActivate = function (data) {
		// Store the data with which this page was activated
		// We might need it on subsequent calls
		// e.g. changing tabs, filters, views
		page._data = data;

		// If we received some querystring options,
		// the page might want to clean the browser url
		if (data != null && !$.isEmptyObject(data)) {
			page._getCleanBrowserUrl(data);
		}

		return Promise.resolve();
	};

	page._pageDeactivate = function () {
		return Promise.resolve();
	};

	page._getCleanBrowserUrl = function (data) {
		return null;
	};

	page._refreshDocs = debounce(function (docs) {
		// This is a special version of the refresh function,
		// It's only called when events are triggered from page.refreshOn
		// By default we do a full refresh, but inheriting pages
		// can add a more efficient implementation
		// (only refreshing parts that are needed)
		return page.refresh(true);
	}, 50);

	page.refresh = function (force) {
		return page._viewActivate(page._data);
	};

	return page;
};

// Stuff for tab-pages
// **************************************************************

/**
 * tabView is the class for a single tab inside a tabPage
 * @name tabView
 * @param spec
 */
pageTypes.tabView = function (spec) {
	var defaults = {
		name: '',
		icon: '',
		parent: null,
	};

	var baseView = pageTypes.baseView(spec),
		super_viewActivate = baseView._viewActivate,
		super_viewDeactivate = baseView._viewDeactivate,
		view = $.extend(baseView, defaults, spec || {});

	view.viewBarTemplate = ViewbarView;
	view.bottomViewbarTemplate = ViewbarBottomView;

	view._viewActivate = function (data) {
		// TODO: Not sure if we need this
		// Now leaving this in for clarity and consistency
		return super_viewActivate(data);
	};

	view._viewDeactivate = function () {
		// TODO: Not sure if we need this
		// Now leaving this in for clarity and consistency
		return super_viewDeactivate();
	};

	view.initForPage = function (page) {
		view._parent = page;
		view.storage = page.storage;

		view.addViewToKnockoutTemplateCache();
	};

	view.refresh = function (force) {
		return view._parent.refresh(force);
	};

	return view;
};

/**
 * tabPage is the object that hosts multiple tabViews
 * @name tabPage
 * @param spec
 */
pageTypes.tabPage = function (spec) {
	var defaults = {
		tabs: ko.observable([]),
		tab: null, //activator.create(),
		// don't create the activator yet, we'll need a custom `areSameItem` function
		// so we can activate the same listView module several times with different data
		tabCurrent: null,
		tabDefault: null,
		tabLast: null,
		rememberLastTab: false,
	};

	var basePage = pageTypes.basePage(spec),
		page = $.extend(basePage, defaults, spec || {}),
		super_pageActivate = page._pageActivate,
		super_deactivate = page.deactivate;

	// We provide a custom `areSameItem` function for the activator
	// Because we might want to activate the same module several times,
	// but with different parameters
	page.areSameItem = function (currentItem, newItem, curActivationData, newActivationData) {
		return false;
	};

	page.tab = activator.create(null, { areSameItem: page.areSameItem });

	page._pageActivate = function (data) {
		return super_pageActivate(data).then(function () {
			data = data || {};

			// Get the tab via the name we got in the querystring
			// (basePage will handle cleaning the browser url if needed)
			//
			// If no tab was passed, we'll get the one from the localstorage
			// If no tab was found, we'll get the one from the default
			// If no tab was found, we'll get the first one
			var tab = page._getTabView(data.tab);
			return page._tabActivate(data, tab);
		});
	};

	page.deactivate = function () {
		// Don't store last tab
		if (!page.rememberLastTab) {
			page.tabLast = null;
		}

		// Deactivate of a tabPage should also
		// call the deactivate workflow on the tabView
		var tabView = page._getTabView();
		if (tabView) {
			return tabView.deactivate().then(function () {
				return super_deactivate();
			});
		} else {
			return super_deactivate();
		}
	};

	page._tabActivate = function (data, tab) {
		return page.tab.activateItem(tab, data).then(function () {
			page.tabLast = tab ? tab.name : null;
			return page._saveToStorage();
		});
	};

	page._toStorageData = function () {
		var data = {};

		if (page.rememberLastTab) {
			data.tabLast = page.tabLast;
		}

		return Promise.resolve(data);
	};

	page._fromStorageData = function (data) {
		// Loads tabLast from storage
		if (data) {
			if (data.tabLast) {
				page.tabLast = data.tabLast;
			}
		}

		return Promise.resolve();
	};

	page._getTabs = function () {
		// Set the observable page.tabs
		// based on permission handler
		// and return a list of tabs
		return ko.utils.unwrapObservable(page.tabs);
	};

	// Helper function to get a tab by name
	// If no tabName is passed, find the tabLast (from localStorage)
	// If no tab was found, find the tabDefault
	// If no tab was found, just return the first tab
	page._getTabView = function (tabName) {
		var tabs = page._getTabs();

		tabs.forEach(function (tab, i) {
			// Make sure each tab is initialized
			// TODO: It's not really an init,
			// since it can be called several times
			tab.initForPage(page);
		});

		if (tabs.length > 1) {
			tabName = tabName || page.tabLast || page.tabDefault;
			if (tabName) {
				var matches = $.grep(tabs, function (tab, i) {
					return tab.name == tabName;
				});
				if (matches.length > 0) {
					return matches[0];
				}
			}
			return tabs[0];
		} else if (tabs.length == 1) {
			return tabs[0];
		} else {
			return null;
		}
	};

	return page;
};

// Stuff for list-pages
// **************************************************************

/**
 * listAction is a class that contains an action on one or more selected document
 * @name listAction
 * @param spec
 * @returns {*}
 */
pageTypes.listAction = function (spec) {
	var DEFAULTS = {
		icon: 'fa fa-ban',
		divider: false,
		header: false,
		name: 'Unknown action',
		getName: function (action, data, view, page) {
			return action.name;
		},
		getNameBulk: function (action, data, view, page) {
			return action.nameBulk || this.getName(action, data, view, page);
		},
		availableSingle: function (action, data, view, page) {
			return true;
		},
		availableBulk: function (action, data, view, page) {
			return data.every(function (d) {
				return action.availableSingle(action, d, view, page);
			});
		},
		disabledSingle: function (action, data, view, page) {
			return false;
		},
		disabledBulk: function (action, data, view, page) {
			return data.some(function (d) {
				return action.disabledSingle(action, d, view, page);
			});
		},
		clickedSingle: function (action, data, view, page) {},
		clickedBulk: function (action, data, view, page) {
			$.each(data, function (i, d) {
				action.clickedSingle(action, d, view, page);
			});
		},
	};

	return $.extend(DEFAULTS, spec);
};

/**
 * listActionDivider is a helper class to make a ---- list divider
 * @name listActionDivider
 * @param spec
 * @returns {*}
 */
pageTypes.listActionDivider = function (spec) {
	spec = spec || {
		divider: true,
		header: false,
		icon: '',
		disabledSingle: false,
		disabledBulk: false,
		availableSingle: true,
		availableBulk: true,
		name: '-',
		nameBulk: '-',
	};
	return pageTypes.listAction(spec);
};

/**
 * listActionHeader is a helper class to make a list header
 * http://v4-alpha.getbootstrap.com/components/dropdowns/#menu-headers
 * @name listActionHeader
 * @param spec
 * @returns {*}
 */
pageTypes.listActionHeader = function (spec) {
	spec = spec || {
		header: true,
		divider: false,
		icon: '',
		disabledSingle: false,
		disabledBulk: false,
		availableSingle: true,
		availableBulk: true,
	};
	return pageTypes.listAction(spec);
};

/**
 * listActionSection is a section of actions
 * @name listActionSection
 * @param spec
 * @returns {*}
 */
pageTypes.listActionSection = function (spec) {
	spec = spec || {};
	spec.name = spec.name || '';
	spec.actions = spec.actions || [];
	return $.extend({}, spec);
};

/**
 * Helper function to filter a list of ListActionSections (2D) to a simple list (1D) list of ListActions
 * with dividers and headers where needed
 * @name listActionSectionsToActions
 * @param sections
 * @param data
 * @param view
 * @param page
 * @param isBulk
 * @returns {Array}
 */
pageTypes.listActionSectionsToActions = function (sections, data, view, page, isBulk) {
	var sectionActions = null,
		actions = [];

	$.each(sections, function (i, section) {
		sectionActions = [];
		$.each(section.actions, function (j, action) {
			if (isBulk) {
				// action.availableBulk can be a simple boolean or a function
				if (typeof action.availableBulk === 'function') {
					if (action.availableBulk(action, data, view, page)) {
						sectionActions.push(action);
					}
				} else if (action.availableBulk) {
					sectionActions.push(action);
				}
			} else {
				// action.availableSingle can be a simple boolean or a function
				if (typeof action.availableSingle === 'function') {
					if (action.availableSingle(action, data, view, page)) {
						sectionActions.push(action);
					}
				} else if (action.availableSingle) {
					sectionActions.push(action);
				}
			}
		});

		// After filtering, does this section have any actions?
		if (sectionActions.length) {
			// Does this section need a name?
			if (section.name) {
				actions.push(pageTypes.listActionHeader({ name: section.name }));
			}

			// Add all the section actions
			actions = actions.concat(sectionActions);

			// Finish with a divider
			actions.push(pageTypes.listActionDivider());
		}
	});

	// If the last element is a divider, pop it off
	if (actions.length > 0 && actions[actions.length - 1].divider) {
		actions.pop();
	}

	return actions;
};

/**
 * listSort is one of the sort options for a listPage
 * It has a friendly `name` and a `sort` which is sent to the ApiDataSource as _sort
 * @name listSort
 * @param spec
 */
pageTypes.listSort = function (spec) {
	var defaults = {
		name: 'Untitled',
		sort: '',
	};
	return $.extend({}, defaults, spec || {});
};

/**
 * listFilter is one of the filter options for a listPage
 * It has friendly `name` and a `filter` which is sent to the ApiDataSource as listName
 * @listFilter
 * @param spec
 */
pageTypes.listFilter = function (spec) {
	var defaults = {
		name: 'Untitled',
		filter: '',
		empty: 'No results',
	};
	return $.extend({}, defaults, spec || {});
};

/**
 * listView is a single view in a listPage
 * @name listView
 * @param spec
 */
pageTypes.listView = function (spec) {
	var defaults = {
		name: '',
		ds: null,
		fields: null, // the `_fields` that will be used in the API call
		fieldDefs: null, // e.g. the list of `itemFields` that can be used for this listView
		objects: ko.observable(spec.objects || []),
		totalCount: ko.observable(spec.totalCount || 0),
		pageIndex: ko.observable(spec.pageIndex || 0),
		pageSize: ko.observable(spec.pageSize || 10),
		pageSizes: [10, 25, 50],
		parent: null,
		actions: ko.observable([]),
		selectable: false,
		selectedIds: ko.observableArray(),
		selectedFieldIds: ko.observable(spec.selectedFieldIds || []),
	};

	// Delete the properties we've overwritten with knockout vars
	// otherwise they will overwrite the knockout stuff again during $.extend
	delete spec.objects;
	delete spec.totalCount;
	delete spec.pageIndex;
	delete spec.pageSize;

	var baseView = pageTypes.baseView(spec),
		view = $.extend(baseView, defaults, spec || {}),
		super_viewActivate = view._viewActivate,
		super_viewDeactivate = view._viewDeactivate,
		_pageIndexSubscription = null,
		_pageSizeSubscription = null;

	// Make sure the fieldDefs is observable
	if (!ko.isObservable(view.fieldDefs)) {
		view.fieldDefs = ko.observable(view.fieldDefs || []);
	}
	if (!ko.isObservable(view.selectedFieldIds)) {
		view.selectedFieldIds = ko.observable(view.selectedFieldIds || []);
	}

	// Store default selected fields
	view.defaultSelectedFieldIds = view.selectedFieldIds();

	view.selectedFields = ko.pureComputed(function () {
		var fields = [],
			fieldIds = view._ensureFieldsExist(view.selectedFieldIds()),
			fieldDefs = view.fieldDefs();

		if (fieldDefs && fieldDefs.length) {
			$.each(fieldDefs, function (i, def) {
				fields.push({
					id: def.id,
					name: def.name,
					selected: fieldIds.indexOf(def.id) >= 0,
					removable: def.removable !== undefined ? def.removable : true,
				});
			});
		}

		// Sort field according to order in selected fields ids array
		return fields.sort(function (a, b) {
			return fieldIds.indexOf(a.id) - fieldIds.indexOf(b.id);
		});
	});

	view.initForPage = function (page) {
		view.parent = view._parent = page;
		view.storage = page.storage;

		view.addViewToKnockoutTemplateCache();
	};

	view.refresh = function (force) {
		// Calls the parent to refresh the current listview
		// It uses the same data and settings as before
		// but it can be that e.g. the listView.pageIndex() changed
		return view._parent.refresh(force);
	};

	view.resetPaging = function () {
		view.pageIndex(0);
	};

	view._toStorageData = function () {
		// Saves pageSize and selectedFields in storage
		return Promise.resolve({
			pageSize: view.pageSize(),
			fields: view.selectedFieldIds(),
		});
	};

	view._fromStorageData = function (data) {
		// Loads pageSize and selectedFields from storage
		if (data) {
			if (data.pageSize) {
				// Make sure pageSize exists
				if (view.pageSizes.indexOf(data.pageSize) != -1) {
					view.pageSize(data.pageSize);
				}
			}
			if (data.fields) {
				var contactIdx = -1;
				var didChange = false;

				var selectedFields = data.fields
					.map((fieldId) => {
						// Customer has his own lastLogin field
						if (fieldId === 'user.lastLogin') {
							didChange = true;
							return 'lastLogin';
						}

						return fieldId;
					})
					.filter(function (fieldId, idx) {
						// Deprecated In custody of field (replaced by contact)
						if (fieldId == 'custody') {
							// Store contact idx to insert new contact column
							if (contactIdx == -1) {
								contactIdx = idx;
							}
							return false;
						}

						// Deprecated Checked out by field (replaced by contact)
						if (fieldId == 'order.customer.name') {
							// Store contact idx to insert new contact column
							if (contactIdx == -1) {
								contactIdx = idx;
							}
							return false;
						}

						return true;
					});

				if (contactIdx != -1) {
					selectedFields.splice(contactIdx, 0, 'contact');
				}

				// name can't be removed
				if (selectedFields.indexOf('name') == -1) {
					selectedFields.unshift('name');
				}

				const currentFields = view.selectedFieldIds();
				if (JSON.stringify(currentFields) !== JSON.stringify(selectedFields)) {
					view.selectedFieldIds(selectedFields);
				}

				// Make sure we update localStorage if some were deprecated or renamed
				if (didChange) {
					view._saveToStorage();
				}
			}
		}

		return Promise.resolve();
	};

	view._subscribePageIndexSize = function () {
		// We'll set up some listeners to rebind the page when pageIndex and / or pageSize has changed
		// We're not using `view._subscribeEvents` because that is triggered too early and would
		// always call `refresh` each time the pageSize is initially set
		if (_pageIndexSubscription == null) {
			_pageIndexSubscription = view.pageIndex.subscribe(function (newPageIndex) {
				view.refresh();
			});
		}
		if (_pageSizeSubscription == null) {
			_pageSizeSubscription = view.pageSize.subscribe(function (newPageSize) {
				view.resetPaging();
				view.refresh();
				view._saveToStorage();
			});
		}
	};

	view._unSubscribePageIndexSize = function () {
		if (_pageIndexSubscription) {
			_pageIndexSubscription.dispose();
			_pageIndexSubscription = null;
		}
		if (_pageSizeSubscription) {
			_pageSizeSubscription.dispose();
			_pageSizeSubscription = null;
		}
	};

	view._viewActivate = function (data) {
		view.setRootBusy(true);
		return super_viewActivate(data)
			.then(function () {
				// By default a list page will
				// call ds.search and put all results
				// in an observed `objects()`
				var params = view._makeSearchParams();
				return view._handleSearchRequest(params).then(function (resp) {
					return view._handleSearchResponse(resp).then(function () {
						// Check if paging is still correct (delete/archive/expire)
						var maxPageIndex = Math.ceil(view.totalCount() / view.pageSize()) - 1;
						if (view.pageIndex() > maxPageIndex && maxPageIndex > 0) {
							view.pageIndex(maxPageIndex);
							view.refresh(); //manually trigger refresh because pageIndex subscription isn't active
						}
					});
				}, view.onError);
			})
			.finally(function () {
				view.setRootBusy(false);
			});
	};

	view._viewDeactivate = function () {
		// Stop listening to pageIndex and / or pageSize changes
		view._unSubscribePageIndexSize();

		return super_viewDeactivate();
	};

	view.detached = function () {
		// Remove the selection when we navigate away from this view
		// We're not doing it in `_viewDeactivate` because then you see it
		if (view.selectable) {
			view.selectNone();
		}
	};

	view._makeSearchParams = function () {
		// Makes a dict of search params
		// to be passed to ApiDatasource.search()
		// By default we take what the page makes for us
		// and mix in the paging stuff,
		// but an inheriting class can totally override this
		var params = view._parent._makeSearchParams();
		if (params) {
			if (view.fields) {
				// Does this view have more specific fields,
				// than the one set by the listPage? Use those
				params._fields = view.fields;
			}
			// Add the _skip, _limit control parameters
			params._limit = view.pageSize();
			params._skip = view.pageIndex() * params._limit;
		}
		return params;
	};

	view._getDataSource = function () {
		// Get the datasource for the view
		// If no ds was set, we'll take the one from the page
		return view.ds || view._parent.ds;
	};

	view._handleSearchRequest = function (params) {
		system.log('_handleSearchRequest');

		// Making a ApiDataSource search request
		return view._getDataSource().search(params);
	};

	view._handleSearchResponse = function (resp) {
		// The default implementation is just taking the docs and count
		view.objects(resp.docs || []);
		view.totalCount(resp.count || 0);

		$.each(resp.docs || [], function (i, doc) {
			view._handleSearchResponseDoc(doc);
		});

		// Remove the selection when we got the results of a new query
		if (view.selectable) {
			view.selectNone();
		}

		return Promise.resolve(resp.docs);
	};

	view._handleSearchResponseDoc = function (doc) {
		// Add an observable array to each document for single actions
		doc.actions = ko.observable([]);
		return doc;
	};

	// Paging stuff
	// ====
	view.isFirstPage = ko.pureComputed(function () {
		return view.pageIndex() == 0;
	});

	view.isLastPage = ko.pureComputed(function () {
		var count = view.totalCount(),
			index = view.pageIndex(),
			size = view.pageSize();

		return (index + 1) * size >= count;
	});

	view.nextPage = function () {
		if (view.isLastPage()) return;

		view.pageIndex(view.pageIndex() + 1);
	};

	view.prevPage = function () {
		if (view.isFirstPage()) return;

		view.pageIndex(view.pageIndex() - 1);
	};

	view.goToPage = function (page) {
		view.pageIndex(page - 1);
	};

	view.pages = ko.computed(function () {
		var totalPages = Math.ceil(view.totalCount() / view.pageSize());

		var pages = [];
		var currentPage = view.pageIndex() + 1;
		var pageRange = 3;

		var rangeStart = currentPage;
		var rangeEnd = currentPage + pageRange;

		if (rangeEnd > totalPages) {
			rangeEnd = totalPages;
			rangeStart = totalPages - pageRange;
			rangeStart = rangeStart < 1 ? 1 : rangeStart;
		}

		if (rangeStart <= 1) {
			rangeStart = 1;
			rangeEnd = Math.min(pageRange + 1, totalPages);
		}

		if (rangeStart <= 3) {
			for (let i = 1; i < rangeStart; i++) {
				pages.push(i);
			}
		} else {
			pages.push(1);
			pages.push('...');
		}

		// Main loop
		for (let i = rangeStart; i <= rangeEnd; i++) {
			pages.push(i);
		}

		if (rangeEnd >= totalPages - 2) {
			for (let i = rangeEnd + 1; i <= totalPages; i++) {
				pages.push(i);
			}
		} else {
			pages.push('...');
			pages.push(totalPages);
		}

		return pages;
	});

	// Selection stuff
	// ====
	view._isAllSelected = function () {
		var selected = view.selectedIds(),
			objects = view.objects();
		return selected && selected.length > 0 && objects && objects.length == selected.length;
	};

	view._isAnySelected = function () {
		var selected = view.selectedIds();
		return selected && selected.length > 0;
	};

	view._isNoneSelected = function () {
		var selected = view.selectedIds();
		return selected == null || selected.length == 0;
	};

	view.selectAll = function () {
		var ids = $.map(view.objects(), function (doc) {
			return doc._id;
		});
		view.selectedIds(ids);
	};

	view.selectNone = function () {
		view.selectedIds.removeAll();
	};

	// Using a special computed for select all / none
	// `<input type="checkbox" data-bind="checked: $root.selectedAll" />`
	// http://stackoverflow.com/questions/9081546/knockout-check-uncheck-all-combo-box
	view.selectedAll = ko
		.computed({
			read: function () {
				var selected = view.selectedIds(),
					objects = view.objects();
				return selected && selected.length > 0 && objects && objects.length == selected.length;
			},
			write: function (value) {
				if (value) {
					view.selectAll();
				} else {
					view.selectNone();
				}
			},
		})
		.extend({ throttle: 50 });

	view.selectedAny = ko
		.computed(function () {
			var selected = view.selectedIds();
			return selected && selected.length > 0;
		})
		.extend({ throttle: 50 });

	view.getSelected = function () {
		var objects = view.objects(),
			selectedIds = view.selectedIds(),
			selected = [];
		if (selectedIds.length > 0 && objects.length > 0) {
			$.each(objects, function (i, obj) {
				if (obj && obj._id && $.inArray(obj._id, selectedIds) >= 0) {
					selected.push(obj);
				}
			});
		}
		return selected;
	};

	/**
	 * Gets a list of single actions for one selected document
	 * @returns {Array}
	 */
	view.getActionsForSingleSelected = function () {
		var selected = view.getSelected();
		if ($.isArray(selected)) {
			selected = selected.length > 0 ? selected[0] : null;
		}
		return view.getActionsForSingle(selected);
	};

	/**
	 * Gets a list of bulk actions for multiple selected documents
	 * @returns {Array}
	 */
	view.getActionsForBulkSelected = function () {
		var selected = view.getSelected();
		return view.getActionsForBulk(selected);
	};

	view.hasBulkActions = function () {
		var selected = view.getSelected();
		if (selected.length == 0) return false;

		return view.getActionsForBulkSelected().length > 0;
	};

	/**
	 * Gets a list of all possible actions for this view
	 * We'll later use the listAction functions `availableSingle` and `availableBulk`
	 * to decide what will ultimately be in the actions list on screen
	 * @returns {Array}
	 */
	view.getActionSections = function (data) {
		return view._parent.getActionSections(data);
	};

	/**
	 * Gets a list of actions for a single selected document
	 * Inheriting classes can override this,
	 * If it's not overridden, we'll take the implementation from the page
	 * @name getActionsForSingle
	 * @param data
	 * @returns {Array}
	 */
	view.getActionsForSingle = function (data) {
		return pageTypes.listActionSectionsToActions(view.getActionSections(data), data, view, view._parent, false);
	};

	/**
	 * Gets a list of actions for multiple selected documents
	 * Inheriting classes can override this
	 * If it's not overridden, we'll take the implementation from the page
	 * @name getActionsForBulk
	 * @param data
	 * @returns {Array}
	 */
	view.getActionsForBulk = function (data) {
		return pageTypes.listActionSectionsToActions(view.getActionSections(data), data, view, view._parent, true);
	};

	// Fields stuff
	// ====

	view._ensureFieldsExist = function (selection) {
		if (selection.length == 0) selection = view.defaultSelectedFieldIds;

		// Custom fields might have changed compared to the ones in local storage
		// Make sure that all selected fields exist according to the `fieldDefs`
		var fieldDefs = view.fieldDefs();
		if (fieldDefs && fieldDefs.length && selection && selection.length) {
			var copy = selection.slice(0),
				found = null;
			for (var i = copy.length - 1; i >= 0; i--) {
				found = $.grep(fieldDefs, function (def, j) {
					return def.id == copy[i];
				});
				if (found.length == 0) {
					copy.splice(i, 1);
				}
			}

			// Only update observable if arrays have changed
			if (!selection.every((f) => copy.includes(f)) || !copy.every((f) => selection.includes(f))) {
				view.selectedFieldIds(copy.length == 0 ? view.defaultSelectedFieldIds : copy);
			}

			if (copy.length == 0) {
				copy = view.defaultSelectedFieldIds;
			}

			return copy;
		} else {
			return selection;
		}
	};

	return view;
};

/**
 * listPage is a page containing multiple listViews
 * @name listPage
 * @param spec
 */
pageTypes.listPage = function (spec) {
	var defaults = {
		emptyMsg: ko.observable(null),
		emptyBtnText: ko.observable(null),
		ds: null, // the ApiDataSource
		fields: null,
		search: ko.observable('').extend({ rateLimit: spec.searchThrottle || 1000 }),
		searchLast: null,
		listViews: ko.observable([]),
		listView: null, //activator.create(),
		// don't create the activator yet, we'll need a custom `areSameItem` function
		// so we can activate the same listView module several times with different data
		listViewCurrent: null,
		listViewDefault: null,
		listViewLast: null,
		listFilters: ko.observable([]),
		listFilter: ko.observable(),
		listFilterCurrent: null,
		listFilterDefault: null,
		listFilterLast: null,
		listSorts: ko.observable([]),
		listSort: ko.observable(),
		listSortOrder: ko.observable(''),
		listSortCurrent: null,
		listSortDefault: null,
		listSortLast: null,
		listSortOrderLast: '',
		dirtyOn: null, // (optional) pubsub subscriptions that will automatically trigger `page._setDirty`
		_dirty: false, // the dirty flag, for when the browser comes back to the page
		// and our custom activator `areSameItem` would not detect any changes
		_dirtySubscriptions: null, // will contain the pubsub for `dirtyOn
		_searchSubscription: null, // will contain the event listener when search changed

		// Advanced filtering
		showAdvancedFilter: ko.observable(true),
		advancedFilter: ko.observable(true),
		customFieldFilters: ko.observable({}),
		selectedFilters: ko.observableArray([]),
	};

	var basePage = pageTypes.basePage(spec),
		page = $.extend(basePage, defaults, spec || {}),
		super_pageActivate = page._pageActivate,
		super_deactivate = page.deactivate,
		super_subscribeEvents = page._subscribeEvents;

	const handleSort = (sortOrder, sortValue) =>
		sortValue
			.split(',')
			.map((sort) => `${sortOrder}${sort.replace('-', '')}`)
			.join(',');
	// We provide a custom `areSameItem` function for the activator
	// Because we might want to activate the same module several times,
	// but with different parameters
	page.areSameItem = function (currentItem, newItem, curActivationData, newActivationData) {
		if (Array.isArray(curActivationData) && curActivationData.length > 0) curActivationData = curActivationData[0];
		if (Array.isArray(newActivationData) && newActivationData.length > 0) newActivationData = newActivationData[0];

		var same = currentItem == newItem;
		if (same) {
			if (page._dirty) {
				system.log('Reloading page because dirty');
				same = false;
			} else if (newActivationData['force']) {
				system.log('Reloading page because force');
				same = false;
			} else {
				// Check if any of the following has changed?
				// If so, need to call activate again
				same = [
					'view',
					'search',
					'filter',
					'sort', // page-level checks
					'pageIndex',
					'pageSize', // view-level checks
				].every(function (name) {
					var curVal = curActivationData ? curActivationData[name] : null,
						newVal = newActivationData ? newActivationData[name] : null,
						isSame = curVal == newVal;
					return isSame;
				});
			}
		}

		return same;
	};

	page.viewBarTemplate = ViewbarView;
	page.bottomViewbarTemplate = ViewbarBottomView;

	page.listView = activator.create(null, { areSameItem: page.areSameItem });

	page._setUpSearchListener = function () {
		// Setting up a knockout listener,
		// that listens for throttled search() changes
		// and calls `page._listViewActivate`
		if (page._searchSubscription == null) {
			page._searchSubscription = page.search.subscribe(function (newSearch) {
				page.resetPaging();
				page._listViewActivateThrottled(page._data, newSearch, null, null, null);
			});
		}
	};

	page._pageActivate = function (data) {
		data = data || {};

		var koFilterSubscription = {};

		if (page._computedFilters) {
			page._computedFilters.dispose();
		}

		return super_pageActivate(data).then(function () {
			var search = data.search || page.search();
			page.search(search);

			const listView = page._getListView(data.view);
			const listFilter = page._getListFilter(data.filter);
			const listSort = page._getListSort(data.sort);

			// We want to check if the order property has been passed via the query-string.
			// If it was not passed via query-string, we want to skip this piece of code
			// If we always copmare data.order === '-' then it will always reset the sorting order
			// even if the query-string was not passed in the URL
			if (data.hasOwnProperty('order')) {
				page.listSortOrder(data.order || '');
			}

			// Set the right filter and sort
			page.listFilter(listFilter);
			page.listSort(listSort);

			// Bind to changes when custom field is added/removed
			// - update storage
			// - update page
			page._computedFilters = ko.computed(function () {
				var selectedFilters = page.selectedFilters();
				var customFieldFilters = page.customFieldFilters();

				var temp = {};
				$.each(selectedFilters, function (i, filter) {
					var filterId = typeof filter === 'string' ? filter : filter.id;

					temp[filterId] = customFieldFilters[filterId] || ko.observable();

					if (!koFilterSubscription[filterId]) {
						var koSub = temp[filterId].subscribe(function (val) {
							page.resetPaging();
							page.refresh(true);
							page.storage.save(page);
						});
						koFilterSubscription[filterId] = true;
						page._subscriptionsKO.push(koSub);
					}
				});

				// Check if removed filter was active on page
				// if so reload page
				var selectedFilterIds = selectedFilters.map(function (f) {
					return f.id;
				});
				var removedCustomFieldFilters = Object.keys(customFieldFilters).filter(function (f) {
					return selectedFilterIds.indexOf(f) == -1;
				});
				$.each(removedCustomFieldFilters, function (i, id) {
					if (!$.isEmptyObject(ko.utils.unwrapObservable(customFieldFilters[id]))) {
						page.resetPaging();
						page.refresh(true);
						return false;
					}
				});

				page.customFieldFilters(temp);
				page.storage.save(page);
			});
			page._subscriptionsKO.push(page._computedFilters);

			// Add default custom filter
			var customFilters = page
				._getCustomFilters(true)
				.filter(function (f) {
					return f.search;
				})
				.map(function (f) {
					f.id = 'fields.' + f.name;
					return f;
				});
			var selectedFilters = page.selectedFilters();
			$.each(customFilters, function (i, cf) {
				if (
					!selectedFilters.find(function (f) {
						return f.id == cf.id;
					})
				) {
					selectedFilters.splice(0, 0, cf);
				}
			});
			page.selectedFilters(selectedFilters);

			return page
				._listViewActivate(data, search, listView, listFilter, listSort, null, null, data.force)
				.then(function () {
					if (page.analyticsSourceName) {
						const eventName = page.analyticsSourceName.replace('Page', 'Opened');

						analytics.track(eventName, page.analyticsSourceName, {
							view: page?.listView()?.name?.toLowerCase(),
							columns: page?.listView()?.selectedFieldIds(),
						});
					}

					// Set up the search listener
					// We do this after we've set the ko.search observable
					// which we got from the querystring
					// The setup itself will only run once,
					// and not trigger again when you visit the same page again later
					page._setUpSearchListener(data);
				});
		});
	};

	page.deactivate = function () {
		// Deactivate of a listPage should also
		// call the deactivate workflow on the listView
		var listView = page._getListView();
		if (listView) {
			return listView.deactivate().then(function () {
				return super_deactivate();
			});
		} else {
			return super_deactivate();
		}
	};

	page._listViewActivate = function (data, search, listView, listFilter, listSort, pageIndex, pageSize, force) {
		search = search || page.search();
		listView = listView || page.listView();
		listFilter = listFilter || page.listFilter();
		listSort = listSort || page.listSort();

		// Get sort order
		var listSortOrder = page.listSortOrder();

		// If we have a listFilter, we'll use that as our document title
		// Even though changing filter is not a router hashbang change
		if (listFilter && listFilter.title) {
			page.title(listFilter.title);
		}

		// Put them all in a dict, so we can let `areSameItem`
		// check if the listView needs to be rebound or not
		// Take a copy of the data,
		// so we don't extend the dict that came from querystring
		var newData = $.extend({}, data || {});

		newData['force'] = force;
		newData['filter'] = listFilter ? listFilter.filter : null;
		newData['sort'] = listSort ? handleSort(listSortOrder, listSort.sort) : null;
		newData['search'] = search;
		newData['pageIndex'] = pageIndex != null ? pageIndex : listView ? listView.pageIndex() : 0;
		newData['pageSize'] = pageSize != null ? pageSize : listView ? listView.pageSize() : 25;

		return page.listView.activateItem(listView, newData).then(function (succeeded) {
			// Bugfix for:
			// When a listPage with an internal listView fails, then the parent activator still showed success
			// Activator.activateItem's resolve is actually a `success` boolean
			// (Durandal activator.js:330)
			//
			// If a table.activate fails because of 401 token expired
			// handleRequest in listView has a global error handler
			// that intercepts the error, looks at expired status and redirects
			//
			// We reject explicitly here so the listpage doesn't bind any further
			// and avoids binding errors
			if (!succeeded) {
				return $.Deferred().reject(new Error('durandal err'));
			}

			var msg = null;
			if (listView.totalCount() == 0) {
				msg = page._getEmptyMessage(search, listView, listFilter, listSort);
			}
			page.emptyMsg(msg);

			page.listViewLast = listView ? listView.name : null;
			page.listFilterLast = listFilter ? listFilter.filter : null;
			page.listSortLast = listSort ? listSort.name : null;
			page.listSortOrderLast = listSortOrder;

			// This used to be in `view._viewActivate`
			// but then it was no longer called when you
			// navigated away from the list page and came back
			listView._subscribePageIndexSize();

			page._clearDirty();
			page._saveToStorage();
		});
	};

	page._listViewActivateThrottled = debounce(page._listViewActivate, 250);

	/**
	 * Makes a dict object of parameters to pass to ApiDataSource.search calls
	 * This listPage does not add anything for paging yet, that is decided in the listView object
	 * @name _makeSearchParams
	 * @returns {{}}
	 * @private
	 */
	page._makeSearchParams = function () {
		// By default we make a simple object with search params for our API
		// search?_v=2.4.3.06&query=&_sort=status&_limit=20&_skip=0&_fields=*&listName=active&_=1473752103345
		// The listPage doesn't add skip or limit yet,
		// that's up to the listView that contains this data
		var listSearch = page.search(),
			listFilter = page.listFilter(),
			listSort = page.listSort(),
			listSortOrder = page.listSortOrder(),
			params = {};
		if (page.fields) {
			params._fields = page.fields;
		}
		if (listSearch) {
			params.query = listSearch;
		}
		if (listFilter && listFilter.filter) {
			params.listName = listFilter.filter;
		}
		if (listSort && listSort.sort) {
			params._sort = handleSort(listSortOrder, listSort.sort);
		}

		// Add custom filter params
		var customFieldFilters = page.customFieldFilters();
		$.each(Object.keys(customFieldFilters), function (i, filter) {
			var customFilter = customFieldFilters[filter]();
			params = $.extend(params, customFilter);
		});

		return params;
	};

	page._subscribeEvents = function (data) {
		return super_subscribeEvents(data).then(function () {
			// BasePages can habe an array of `refreshOn`
			// ListPages can also have an array of `dirtyOn` subscriptions
			// It will automatically bind with those subscriptions and will call
			// `_setDirty` when any of those pubsub events is triggered
			//
			// We added this because when you click from list to detail page and then
			// back to list, the page.activate would go off, but the activator areSameItem
			// did not detect any changes. We can indicate that a list page should do
			// a full reload next time it's activated using `dirtyOn` or we can trigger
			// a refresh right away using `refreshOn`
			//
			// We instantiate these only once for the entire app and never clean them up
			if (page._dirtySubscriptions == null) {
				page._dirtySubscriptions = [];

				if (page.dirtyOn != null && page.dirtyOn.length) {
					var subscription = null;
					$.each(page.dirtyOn, function (i, sub) {
						subscription = page.app.on(sub, page._setDirty);
						page._dirtySubscriptions.push([subscription, page._setDirty]);
					});
				}

				// Cache bust page on logout event
				var logoutPubSub = page.app.on('logout', page._logout);
				page._dirtySubscriptions.push([logoutPubSub, page._logout]);

				// Cache bust page on location change and also make sure to reset paging
				var locationPubSub = page.app.on('location:change', page._locationChange);
				page._dirtySubscriptions.push([locationPubSub, page._locationChange]);
			}
		});
	};

	page._getCustomFilters = function (includeSearch) {
		return [];
	};

	/**
	 * Makes a dict object of stuff that needs to be saved to storage via the storageHandler
	 * @name _toStorageData
	 * @returns {promise}
	 * @private
	 */
	page._toStorageData = function () {
		var selectedFilters = page.selectedFilters() || [],
			customFieldFilters = {};
		$.each(selectedFilters, function (i, filter) {
			var filterId = typeof filter !== 'string' ? filter.id : filter;
			customFieldFilters[filterId] = page.customFieldFilters()[filterId]() || {};
		});

		return Promise.resolve({
			advancedFilter: page.advancedFilter(),
			list: page.listViewLast,
			filter: page.listFilterLast,
			sort: page.listSortLast,
			order: page.listSortOrderLast,
			customFieldFilters: customFieldFilters,
		});
	};

	/**
	 * Reads a dict object of stuff that was read from storage via storageHandler and applies it to the class
	 * @param data
	 * @returns {promise}
	 * @private
	 */
	page._fromStorageData = function (data) {
		if (data) {
			if (data.list) {
				page.listViewLast = data.list;
				page.listFilterLast = data.filter;
				page.listSortLast = data.sort;

				//Update list sort order
				page.listSortOrderLast = data.order;
				page.listSortOrder(data.order || '');
			}

			if (data.customFieldFilters) {
				var selectedFilters = Object.keys(data.customFieldFilters);
				var customFields = page._getCustomFilters(true);

				page.selectedFilters(
					selectedFilters
						.map(function (f) {
							var field = customFields.find(function (cf) {
								return cf.id == f;
							});

							// BUGFIX
							// Workaround to clear invalid filter which causes 500
							if (field) {
								if (['number', 'int', 'float', 'numberonly'].indexOf(field.kind) != -1) {
									var deprecated = false;
									Object.keys(data.customFieldFilters[f]).forEach(function (key) {
										// key bugfix
										newKey = key.replace(/\s+/gim, '_');
										if (newKey != key) {
											// clear filter if rename fails
											deprecated = true;
										}

										if (key.endsWith('__in')) {
											deprecated = true;
											return;
										}
									});
									if (deprecated) {
										data.customFieldFilters[f] = {};
									}
								}
							}

							return field;
						})
						.filter(function (f) {
							// Make sure selected filters still exists
							return f != undefined;
						})
				);

				$.each(selectedFilters, function (i, filter) {
					var filterId = typeof filter === 'string' ? filter : filter.id;

					var customFieldFilter = page.customFieldFilters()[filterId];
					if (!customFieldFilter) {
						customFieldFilter = page.customFieldFilters()[filterId] = ko.observable();
					}
					customFieldFilter(data.customFieldFilters[filterId]);
				});
			}

			if (data.advancedFilter !== undefined) {
				page.advancedFilter(data.advancedFilter);
			}
		}

		return Promise.resolve();
	};

	// Helper function that can be overridden to set a empty message
	// It's triggered when `page.listView.activateItem` in `page._listViewActivate` was done
	page._getEmptyMessage = function (search, view, filter, sort) {
		return filter && filter.empty ? filter.empty : 'No results';
	};

	page._refreshEmptyMessage = function () {
		const msg = page._getEmptyMessage(page.search(), page.listView(), page.listFilter(), page.listSort());
		page.emptyMsg(msg);
	};

	// Helper function to get a listView by name
	// If no listViewName is passed, find the listViewLast (from localStorage)
	// If no listView was found, find the listViewDefault
	// If no listView was found, just return the first listView
	page._getListView = function (listViewName) {
		var listViews = ko.utils.unwrapObservable(page.listViews);
		if (listViews == null) {
			return null;
		} else if (listViews.length > 1) {
			listViewName = listViewName || page.listViewLast || page.listViewDefault;
			if (listViewName) {
				var matches = $.grep(listViews, function (listView, i) {
					return listView.name == listViewName;
				});
				if (matches.length > 0) {
					return matches[0];
				}
			}
			return listViews[0];
		} else if (listViews.length == 1) {
			return listViews[0];
		} else {
			return null;
		}
	};

	// Helper function to get a filter by name
	// If no filterName is passed, find the filterLast (from localStorage)
	// If no filter was found, find the filterDefault
	// If no filter was found, just return the first filter
	page._getListFilter = function (filterName) {
		var listFilters = ko.utils.unwrapObservable(page.listFilters).filter(function (filter) {
			return !filter.hasOwnProperty('header') && !filter.hasOwnProperty('seperator');
		});
		if (listFilters == null) {
			return null;
		} else if (listFilters.length > 1) {
			filterName = filterName || page.listFilterLast || page.listFilterDefault;
			if (filterName !== undefined) {
				var matches = $.grep(listFilters, function (listFilter, i) {
					return listFilter.filter === filterName;
				});
				if (matches.length > 0) {
					return matches[0];
				}
			}
			return listFilters[0];
		} else if (listFilters.length == 1) {
			return listFilters[0];
		} else {
			return null;
		}
	};

	// Helper function to get a sort by name
	// If no sortName is passed, find the sortLast (from localStorage)
	// If no sort was found, find the sortDefault
	// If no sort was found, just return the first sort
	page._getListSort = function (sortName) {
		var listSorts = ko.utils.unwrapObservable(page.listSorts);
		if (listSorts == null) {
			return null;
		} else if (listSorts.length > 1) {
			sortName = sortName || page.listSortLast || page.listSortDefault;
			if (sortName) {
				var matches = $.grep(listSorts, function (listSort, i) {
					return listSort.name == sortName;
				});
				if (matches.length > 0) {
					return matches[0];
				}
			}
			return listSorts[0];
		} else if (listSorts.length == 1) {
			return listSorts[0];
		} else {
			return null;
		}
	};

	page._logout = function () {
		page._setDirty();
		page.resetPaging();
		page.search('');
	};

	// Helper function to indicate that activate should do a full activate
	// next time the browser shows the page, even if nothing has changed
	// in the activator areSameItem logic
	page._setDirty = function () {
		page._dirty = true;
	};

	// Helper function to indicate that the activator areSameItem logic
	// shouldn't be forced the do a full activate
	// usually called when an activate was done succesfully
	page._clearDirty = function () {
		page._dirty = false;
	};

	page._locationChange = function () {
		page.resetPaging();
		page._setDirty();
	};

	/**
	 * Changes the active listView
	 * @param name
	 * @returns {promise}
	 */
	page.setListViewByName = function (name) {
		var view = page._getListView(name);
		page.resetPaging();
		// VT: Don't set the activator directly!
		//     it will cause activator.isActivating() to be true
		//     and the timing of the .done responses will be off,
		//     only set it via activator.activateItem()
		//page.listView(view);
		return page._listViewActivateThrottled(page._data, null, view, null, null);
	};

	/**
	 * Changes the active listSort
	 * @param name
	 * @returns {promise}
	 */
	page.setListSortByName = function (name) {
		var sort = page._getListSort(name);
		page.resetPaging();
		page.listSort(sort);
		return page._listViewActivateThrottled(page._data, null, null, null, sort);
	};

	/**
	 * Changes the active listFilter
	 * @param name
	 * @returns {promise}
	 */
	page.setListFilter = function (filter) {
		page.resetPaging();
		page.listFilter(filter);
		return page._listViewActivateThrottled(page._data, null, null, filter, null);
	};

	/**
	 * Refresh the current listview
	 * @param force
	 * @returns {promise}
	 */
	page.refresh = function (force) {
		return page._listViewActivateThrottled(
			null, // data
			null, // search
			null, // listView
			null, // listFilter
			null, // listSort
			null, // pageIndex
			null, // pageSize
			force
		);
	};

	/**
	 * Resets the paging for all listViews of the listPage
	 * @name resetPaging
	 * @returns {promise}
	 */
	page.resetPaging = function () {
		var listViews = ko.utils.unwrapObservable(page.listViews);
		$.each(listViews, function (i, vw) {
			if (vw && vw.pageIndex) {
				vw.pageIndex(0);
			}
		});
		return Promise.resolve();
	};

	page.resetCustomFilters = function () {
		// reset custom filters
		var customFieldFilters = page.customFieldFilters();
		$.each(Object.keys(customFieldFilters), function (i, id) {
			var customFilter = customFieldFilters[id];
			customFilter(null);
		});
	};

	/**
	 * Gets a list of all possible actions for this page
	 * We'll later use the listAction functions `availableSingle` and `availableBulk`
	 * to decide what will ultimately be in the actions list on screen
	 * @returns {Array}
	 */
	page.getActionSections = function (data) {
		return [];
	};

	/**
	 * Gets a list of actions for a single selected document
	 * Inheriting classes will override this
	 * @name getActionsForSingle
	 * @param data
	 * @returns {Array}
	 */
	page.getActionsForSingle = function (data) {
		return pageTypes.listActionSectionsToActions(view.getActionSections(data), data, page.listView(), page, false);
	};

	/**
	 * Gets a list of actions for multiple selected documents
	 * Inheriting classes will override this
	 * @name getActionsForBulk
	 * @param data
	 * @returns {Array}
	 */
	page.getActionsForBulk = function (data) {
		return pageTypes.listActionSectionsToActions(view.getActionSections(data), data, page.listView(), page, true);
	};

	/**
	 * Populate bulk action menu for selected items
	 */
	page.clickedBulkActions = function () {
		var actions = page.listView().getActionsForBulkSelected();
		page.listView().actions(actions);
	};

	/**
	 * Change list view (card/list/table)
	 */
	page.changeListView = function (view) {
		if (page.analyticsSourceName) {
			const eventName = page.analyticsSourceName.replace('Page', 'Opened');

			analytics.track(eventName, page.analyticsSourceName, {
				view: view.name.toLowerCase(),
				columns: view.selectedFieldIds(),
			});
		}

		return page.setListViewByName(view.name);
	};

	/**
	 * Change list filter (all/available/...)
	 */
	page.changeListFilter = function (filter) {
		return page.setListFilter(filter);
	};

	/**
	 * Change list sort
	 */
	page.changeListSort = function (sort) {
		return page.setListSortByName(sort.name);
	};

	/**
	 * Change visible list columns
	 */
	page.changeListColumns = function () {
		if (page.analyticsSourceName) {
			analytics.track('Overview Table Editing', page.analyticsSourceName);
		}

		ColumnModal.show({
			fields: page.listView().selectedFields(),
			selection: page.listView().selectedFieldIds(),
		}).then(function (selected) {
			if (selected) {
				const selectedIds = selected.map((f) => f.id);

				page.listView().selectedFieldIds(selectedIds);
				page.listView()._saveToStorage();

				if (page.analyticsSourceName) {
					analytics.track('Overview Table Edited', page.analyticsSourceName, {
						columns: selectedIds,
					});
				}
			}
		});
	};

	page._bindTooltips = (v) => $("[data-toggle='tooltip']", v).tooltip();

	return page;
};

export default pageTypes;
